import React from 'react';
import Axios from 'axios';
import { Api } from '../../const';
import { getUser, getAccessToken } from '../../const/function';
import { inject, observer } from 'mobx-react';
import store from '../../store';

// Get Distance matrix
function getDistanceMatrix(res) {

    var propData = res.data.result
    var propId = propData._id
    getUser().then(data => {
        window.store.update("User", data)
        if ((data && data.otherLocations[0] && data.otherLocations[0].location || data.schoolLocation) || data.workLocation) {
            var destinationLocation = propData.Property.Address.AddressText
            var otherLocation = data.otherLocations[0].location.replace('|', ',').replace("#", '').replace('+', '')
            var workLocation = data.workLocation.replace('|', ',').replace("#", '').replace('+', '')
            var schoolLocation = data.schoolLocation.replace('|', ',').replace("#", '').replace('+', '')
            var origin = [workLocation, schoolLocation, otherLocation]
            var destination = destinationLocation.replace('|', ',').replace("#", '').replace('+', '');
            var body = {
                origin: origin,
                destination: [destination]
            }
            var head = {
                headers: {
                    "x-accesstoken": localStorage.getItem('token')
                }
            }
            Axios.post(Api.baseUrl + "/api/GoogleDistanceMatrix", body, head).then(res => {
                if (res) {
                    var distanceObj = {};
                    var distaceArr = [];
                    for (var i = 0; i < res.data.origin_addresses.length; i++) {
                        for (var j = 0; j < res.data.destination_addresses.length; j++) {
                            var origin = res.data.origin_addresses[i];
                            var destination = res.data.destination_addresses[j];
                            distanceObj = {};
                            if (res.data.rows[0].elements[j].status == 'OK') {
                                var distance = res.data.rows[i].elements[j] && res.data.rows[i].elements[j].distance
                                    && res.data.rows[i].elements[j].distance.value
                                    && res.data.rows[i].elements[j].distance.value ? res.data.rows[i].elements[j].distance.value : 0;
                                // var distanceInMiles = parseFloat(distance * 0.000621371).toFixed(2);
                                var distanceInKm = parseFloat(distance / 1000).toFixed(2);
                                var Distance = distanceInKm + ' Km'
                                distanceObj['Location' + i] = Distance;
                                // distanceObj = Distance;

                            } else {
                                // console.log(destination + ' is not reachable by land from ' + origin);
                            }

                        }
                        distaceArr.push(distanceObj)
                    }
                    // var distance = res.data.rows[0].elements[0].distance.value;
                    // var distanceInMiles = parseFloat(distance * 0.000621371).toFixed(2);
                    // var workDistance = distanceInMiles + ' Miles'
                }

                // var origin = originLocation.replace('|', ',').replace("#", '').replace('+', '');
                // updateMotegagefun(Distance, Distance)
                UpdateMortProp(propId, distaceArr)
            })
        }
    })
    // }
    // }
}
export default getDistanceMatrix;

//update mortgage data
function UpdateMortProp(propId, distaceArr) {
    var body;
    if (store.data && store.data.Properties) {
        store.data.Properties.map((i, j) => {
            if (i._id == propId) {
                // i.Mortgage.Scenarios[0].downPayment   
                // }
                //  var mortgageRequired = ClosingBool === true ? toggleMortgageValue.toFixed(2) : TotalMotage.toFixed(2)

                // var a = DownPayment2 ? DownPayment2 : 0


                body = {
                    "bankName": i.Mortgage && i.Mortgage.bankName,
                    "bankName1": i.Mortgage && i.Mortgage.bankName1,
                    "askingPrices": i.Mortgage && i.Mortgage.askingPrices,
                    "askingPrices1": i.Mortgage && i.Mortgage.askingPrices1,
                    "totalMotage1": i.Mortgage && i.Mortgage.totalMotage1,
                    "totalMotage2": i.Mortgage && i.Mortgage.totalMotage2,
                    // "bankName": i.Mortgage.Scenarios[0] && i.Mortgage.Scenarios[0].bankName,
                    "askingPrice": '',
                    "Scenarios": [
                        {
                            "scenarioName": "Scenario 1",
                            "downPayment": i.Mortgage.Scenarios[0] && i.Mortgage.Scenarios[0].downPayment,
                            "downPaymentPercent": i.Mortgage.Scenarios[0] && i.Mortgage.Scenarios[0].downPaymentPercent,
                            "mortgageInsurance": i.Mortgage.Scenarios[0] && i.Mortgage.Scenarios[0].mortgageInsurance,
                            "totalMortgageRequired": i.Mortgage.Scenarios[0] && i.Mortgage.Scenarios[0].totalMortgageRequired,
                            "amortizationPeriod": i.Mortgage.Scenarios[0] && i.Mortgage.Scenarios[0].amortizationPeriod,
                            "morgageType": i.Mortgage.Scenarios[0] && i.Mortgage.Scenarios[0].morgageType,
                            "mortgageRate": i.Mortgage.Scenarios[0] && i.Mortgage.Scenarios[0].mortgageRate,
                            "totalMortgagePayment": i.Mortgage.Scenarios[0] && i.Mortgage.Scenarios[0].totalMortgagePayment,
                            "mortgageTerm": i.Mortgage.Scenarios[0] && i.Mortgage.Scenarios[0].mortgageTerm,
                            "mortgageFrequency": i.Mortgage.Scenarios[0] && i.Mortgage.Scenarios[0].mortgageFrequency
                        }
                    ],
                    "landTranferTax": {
                        "provincial": i.Mortgage.landTranferTax && i.Mortgage.landTranferTax.provincial,
                        "municipal": i.Mortgage.landTranferTax && i.Mortgage.landTranferTax.municipal,
                        "isFirstProperty": i.Mortgage.landTranferTax && i.Mortgage.landTranferTax.isFirstProperty
                    },
                    "lawyerFee": i.Mortgage.lawyerFee,
                    "titleInsuranceFee": i.Mortgage.titleInsuranceFee,
                    "estoppelCertificateFee": i.Mortgage.estoppelCertificateFee,
                    "homeInspectionFee": i.Mortgage.homeInspectionFee,
                    "appraisalFee": i.Mortgage.appraisalFee,
                    "additionalCosts": i.Mortgage.additionalCosts,
                    "totalCost": i.Mortgage.totalCost,
                    "isClosingCost": i.Mortgage.isClosingCost,
                    "closingCostPayment": i.Mortgage.closingCostPayment,
                    DistanceMatrix: {
                        "SchoolLocation": distaceArr && distaceArr[0] && distaceArr[0].Location0 ? distaceArr[0].Location0 : 0,
                        "WorkLocation": distaceArr && distaceArr[1] && distaceArr[1].Location1 ? distaceArr[1].Location1 : 0,
                        "OtherLocation": distaceArr && distaceArr[2] && distaceArr[2].Location2 ? distaceArr[2].Location2 : 0,
                    }
                }
            }
        })
    }
    // var mortgageRequired = ClosingBool === true ? toggleMortgageValue.toFixed(2) : TotalMotage.toFixed(2)
    // var DownPayment1 = DownPayment.toLocaleString()
    // var DownPayment2 = DownPayment1 ? DownPayment1.replace(/,/g, "") : DownPayment1;
    // var a = DownPayment2 ? DownPayment2 : 0
    // console.log("AmortizationPeriod", BankDetails.AmortizationPeriod)
    // var body = {
    //     // "bankName": BankDetails.Bank,
    //     // "askingPrice": TotalMotage,
    //     // "Scenarios": [
    //     //     {
    //     //         "scenarioName": "Scenario 1",
    //     //         "downPayment": a,
    //     //         "downPaymentPercent": DownPaymentPercentage,
    //     //         "mortgageInsurance": Insurance,
    //     //         "totalMortgageRequired": mortgageRequired,
    //     //         "amortizationPeriod": BankDetails.AmortizationPeriod,
    //     //         "morgageType": BankDetails.RateType,
    //     //         "mortgageRate": BankDetails.Rate,
    //     //         "totalMortgagePayment": MonthlyPayment,
    //     //         "mortgageTerm": BankDetails.Term,
    //     //         "mortgageFrequency": BankDetails.Frequency
    //     //     }
    //     // ],
    //     // "landTranferTax": {
    //     //     "provincial": Landinfo.Province,
    //     //     "municipal": Landinfo.City,
    //     //     "isFirstProperty": Landinfo.Closing ? true : false
    //     // },
    //     // "lawyerFee": LawyerFee,
    //     // "titleInsuranceFee": TitleInsurance,
    //     // "estoppelCertificateFee": EstoppelCertificate,
    //     // "homeInspectionFee": HomeInsurance,
    //     // "appraisalFee": Appraisal,
    //     // "additionalCosts": TotalAdditionCost,
    //     // "totalCost": TotalAdditionCost,
    //     // "isClosingCost": BankDetails.Closing ? true : false,
    //     // "closingCostPayment": toggleMortgageValue,
    //     DistanceMatrix: {
    //         "SchoolLocation": distaceArr[0].Location0 ? distaceArr[0].Location0 : 0,
    //         "WorkLocation": distaceArr[1].Location1 ? distaceArr[1].Location1 : 0,
    //         "OtherLocation": distaceArr[2].Location2 ? distaceArr[2].Location2 : 0,
    //     }
    // }
    var head = {
        headers: {
            "x-accesstoken": localStorage.getItem('token')
        }
    }
    Axios.post(Api.baseUrl + "/api/Property/Mortgage/" + propId, body, head)
        .then(res => {
            if (res.data.result.message === "InvalidToken") {
                return getAccessToken().then(res => res ? UpdateMortProp(propId, head) : '')
            }
            else {
                getUser().then(data => {
                    store.update("User", data)
                    if (data.province) {
                        store.update("Province", data.province)
                    }
                    if (data.properties) {
                        // console.log("data.properties 205", data.properties)
                        var properties = {}
                        var property = []
                        data && data.collaboration && data.collaboration.length > 0
                            && data.collaboration.map((i, j) => {
                                // console.log("iiiiiiii collab property 209", i)
                                // if (property.find(x => x._id == i.collaborateCategory) == null) {

                                // }   
                                properties = {}
                                i.properties && i.properties.length > 0 && i.properties.map((j, k) => {
                                    // console.log("collab properties id data 215", j)
                                    properties = j
                                    property.push(properties)
                                })

                            })
                        // console.log("proeprty array 221", property)
                        store.update("Properties", data.properties)
                    }

                    // setloading(false)

                }).catch(error => {
                    // setloading(false)
                })
            }
            // objOtherLocation.name = value.name + "'s Location";
            //             //                 objOtherLocation.distance = "0 Miles";
            //             //                 otherLocationArray.push(objOtherLocation);
            //             //                 otherLocationArrays = otherLocationArray
            //             //                 index++;
            //             //                 setotherLocation(otherLocationArrays);
            // getUser().then(data => {
            //   store.update("User", data)
            //   if (data.province) {
            //     store.update("Province", data.province)
            //   }
            //   if (data.properties) {
            //     store.update("Properties", data.properties)
            //   }

            //   setloading(false)

            // }).catch(error => {
            //   setloading(false)
            // })
        })
        .catch(err => {
            console.log(err)
            // setloading(false)
        })
}


