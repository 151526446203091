//list of sites from scrap data

export const webSites = [
  {
    site: "https://www.realtor.ca",
    path: "/AIBotRealtorScrapProperties"
  },
  {
    site: "https://www.royallepage.ca",
    path: "/AIBotRoyalLeScrapProperties"
  },
  // {
  //   site: "https://www.centris.ca",
  //   path: "/AIBotCentrisScrapProperties"
  // },
  // {
  //   site: "https://www.remax.ca",
  //   path: "/AIBotRemaxCaScrapProperties"
  // },
  // {
  //   site: "https://www.c21.ca",
  //   path: "/AIBotC21CaScrapProperties"
  // },

]