import { decorate, observable, action } from 'mobx'

//Store class
class Store {

  //auth state
  auth = {
    token: '',
  }

  //set Auth 
  setAuth(data) {
    if (data.accessToken) {
      this.auth = data
    }
    if (data === null) {
      this.auth = {
        token: '',
      }
    }
  }

//propertie object declear states
  propertie = {
    Price: 0,
    CategoryId: "none"
  }

  //updateProperties function action and data
  updatePro(action, data) {
    switch (action) {
      case 'Price':
        this.propertie.Price = data
        break;
      case 'CategoryId':
        this.propertie.CategoryId = data
        break;
      default:
        break;
      // code block
    }
  }

  //data object declear states
  data = {
    Cat: null,
    Mortgage: null,
    User: null,
    Province: null,
    Properties: [],
    GenerateToken: null,
    MortgageRateDetail: null,
    PropId: null,
    changePropCategoryHandle: null,

  }

  //update function pass parameter action and data 
  update(action, data) {
    switch (action) {
      case 'Cat':
        this.data.Cat = data
        break;
      case "Mortgage":
        this.data.Mortgage = data
        break;
      case "User":
        this.data.User = data
        // console.log("data", data)
        // if (data === undefined) {
        //   this.data.Province = ''
        //   this.data.Properties = ''
        // }
        // else {
        this.data.Province = data && data.province
        this.data.Properties = data && data.properties
        // }


        break;
      case "Properties":
        this.data.Properties = data
        break;
      case "Province":
        this.data.Province = data
        break;
      case "GenerateToken":
        this.data.GenerateToken = data
        break;
      case "PropId":
        this.data.PropId = data
        break;
      case "MortgageRateDetail":
        this.data.MortgageRateDetail = data
        break;
      case "changePropCategoryHandle":
        this.data.changePropCategoryHandle = data
      default:
        break;
      // code block
    }

  }
}

//decorate Store and all object states user get data into these states
decorate(Store, {
  auth: observable,
  data: observable,
  propertie: observable,
  update: action,
  updatePro: action,
  setAuth: action,
})

export default window.store = new Store() 