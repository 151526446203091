import React, { useEffect, useState } from 'react'
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Fab, Grid, Dialog, Slide, MenuItem, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react'
import { getCat, postCategory, getCollaborationInvitations } from '../../const/function';


const useStyles = makeStyles(theme => (
  {
    select: {
      margin: theme.spacing(1),
      padding: 0,
      width: "100%",
      height: '45px',
      position: 'raletve'
    },
    icon: {
      fill: "#f02834",
    },
    selectEmpty: {
      marginTop: theme.spacing(1),
    },
    button: {
      background: "#f02834",
      color: '#fff'
    },
    texterror: {
      color: 'red'
    }
  }
));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

//category component
function Category({ store, idata, isEditAble, onSelectCategoryId }) {
  const classes = useStyles();
  const [state, setState] = useState("none");
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [InputErr, setInputErr] = useState(false)
  const [InputEmpty, setInputEmpty] = useState(false)

  var categoryObj = {}
  var categoryArr = []
  var categoryArr1 = []

//select category list in flyout
  const handleClickOpen = () => {
    setInputEmpty(false)
    setInputErr(false)
    setOpen(true);
  };

  //close the popup or dropdown of categories
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (idata) { setState(idata) }

  }, [])

  useEffect(() => {
    if (idata) { setState(idata) }
    else {
      if (store.data.Cat) {
        setState(store.data.Cat[0]._id)
      }
    }
  }, [store.data.Cat])

  useEffect(() => {
    onSelectCategoryId({ state });
  }, [state])

  //create a new category
  function create() {
    setInputEmpty(false)
    setInputErr(false)
    if (name.trim() === "") {
      setInputEmpty(true)
      return false
    }
    if (name.trim().length <= 3) {
      setInputErr(true)
      return false
    }

    // Axios.post(Api.baseUrl + "/api/Category", {
    //   name
    // }, {
    //   headers: {
    //     "x-accesstoken": localStorage.getItem('token')
    //   }
    // })
    //   .then(res => {
    //     if (res.data.result.message === "InvalidToken") {
    //       return getAccessToken().then(res => res ? create() : '')
    //     }

    //add new category
    return postCategory(name).then(res => {
      // console.log("flyout", res)
      setName("")
      getCat().then(data => {
        store.update("Cat", data)
        setInputErr(false)
        setOpen(false);
        if (res) {
          setState(res.date._id)
        }
      })
    })
  }


  //get collaborationInvitation
  useEffect(() => {
    // console.log("multiple call invitation and categoriees 119",store)
    // getCat().then(res => {
    //   var catList = res ? res : []
    //   console.log("res 144", res)
    //   if (res.length > 0) {
    //     res.map((i,j)=>{
    //       categoryArr1.push(i)
    //     })
    //   }
  
  //get collaboration invitation list
    if(!store.data.Cat){
    getCollaborationInvitations().then(res => {
      var inviteList = res && res.data
      inviteList && inviteList.map((i, j) => {
        categoryObj = {}
        if (categoryArr1.find(x => x._id == i.collaborateCategory) == null) {
          // categoryObj = {
          //   CategoryName: i.category,
          //   UserId: i.userid,
          //   _id: i.collaborateCategory,
          //   __v: 0
          // }
          categoryObj = i
          categoryArr1.push(categoryObj)
        }
      })
      if (categoryArr1.length > 0) {
        getCat().then(res => {
          // var catList = res ? res : []
          // console.log("res 144", res)
          if (res.length > 0) {
            res && res.length > 0 && res.map((i, j) => {
              // console.log("res 149", categoryArr1, i)
              categoryObj = {}
              if (categoryArr1.find(x => x.collaborateCategory == i._id)) {
                categoryArr1.map((m, n) => {
                  if (m.collaborateCategory === i._id) {
                    if (m.invitedUserRole === "Editor") {
                      if (categoryArr.find(x => x._id == i._id) == null) {
                        categoryObj = {
                          CategoryName: i.CategoryName,
                          UserId: i.UserId,
                          _id: i._id,
                          __v: 0
                        }
                        categoryArr.push(categoryObj)
                        // console.log("categoryArr 162", categoryArr)
                      }
                    }
                  }
                })
              }
              else {
                if (categoryArr.find(x => x._id == i._id) == null) {
                  categoryObj = {
                    CategoryName: i.CategoryName,
                    UserId: i.UserId,
                    _id: i._id,
                    __v: 0
                  }
                  categoryArr.push(categoryObj)
                }
                // console.log("categoryArr 178", categoryArr)
              }
            })
            store.update("Cat", categoryArr)
          }
        })
      }
      else {
        getCat().then(res => {
          // var catList = res ? res : []
          // console.log("res 144", res)
          if (res && res.length > 0) {
            res.map((i, j) => {
              if (categoryArr.find(x => x._id == i._id) == null) {
                categoryArr.push(i)
              }
            })
            // console.log('category 193', categoryArr)
            store.update("Cat", categoryArr)
          }
        })
      }
    })
  }
    // if (catList.find(x => x._id == i.collaborateCategory)) {
    //   if (i.invitedUserRole === "Editor") {
    //     if (categoryArr.find(x => x._id == i.collaborateCategory) == null) {
    //       categoryObj = {
    //         CategoryName: i.category,
    //         UserId: i.userid,
    //         _id: i.collaborateCategory,
    //         __v: 0
    //       }
    //       categoryArr.push(categoryObj)
    //     }
    //     store.update("Cat", categoryArr)
    //     console.log("categoryArr 140", categoryArr)
    //     // categoryObj = {
    //     //   CategoryName: i.category,

    //   }
    // }
    //     else {
    //   if (categoryArr.find(x => x._id == i.collaborateCategory) == null) {
    //     categoryObj = {
    //       CategoryName: i.category,
    //       UserId: i.userid,
    //       _id: i.collaborateCategory,
    //       __v: 0
    //     }
    //     categoryArr.push(categoryObj)
    //   }
    //   store.update("Cat", categoryArr)
    //   console.log("categoryArr 156", categoryArr)
    // }
    //     })
    //   })
    // })

    // getCollaborationInvitations().then(res => {
    //   var inviteList = res.data
    //   inviteList.map((i, j) => {
    //     console.log("iiiiiii 121", i)
    //     categoryObj = {}
    //     if (i.invitedUserRole === "Editor") {
    //       if (categoryArr.find(x => x._id == i.collaborateCategory) == null) {
    //         categoryObj = {
    //           CategoryName: i.category,
    //           UserId: i.userid,
    //           _id: i.collaborateCategory,
    //           __v: 0
    //         }
    //         categoryArr.push(categoryObj)
    //       }
    //       console.log("categoryArr 132", categoryArr)
    //       // categoryObj = {
    //       //   CategoryName: i.category,
    //       //   UserId: i.userid,
    //       //   _id: i.collaborateCategory,
    //       //   __v: 0
    //       // }
    //       // categoryArr.push(categoryObj)
    //     }
    //   })


    //   getCat().then(res => {
    //     console.log("res 144", res)
    //     if (res.length > 0) {
    //       categoryObj = {}
    //       res.map((i, j) => {
    //         categoryObj = {}
    //         categoryObj = i
    //         if (categoryArr.find(x => x._id == i._id) == null) {
    //           categoryArr = [...categoryArr, categoryObj]
    //         }
    //       })
    //       console.log("categoryArr 150", categoryArr)
    //     }
    //     console.log("categoryArr 152", categoryArr)
    //     store.update("Cat", categoryArr)
    //   })
    // })
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <Select
          className={classes.select}
          value={state}
          variant="outlined"
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
          onChange={e => {
            setState(e.target.value)
            store.updatePro("CategoryId", e.target.value)
          }}
        >
          <MenuItem disabled value="none" > Select Category</MenuItem >
          {store.data.Cat &&
            store.data.Cat.length > 0 &&
            store.data.Cat.map(i => <MenuItem value={i._id} key={i._id}>{i.CategoryName} </MenuItem >)
          }
        </Select>
      </Grid>
      <Grid item xs={2}>
        <Fab color="secondary"
          style={{ marginTop: "8px", marginLeft: '20px' }}
          aria-label="add" size="small"
          onClick={handleClickOpen} className={classes.button}>
          <AddIcon />
        </Fab>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle id="form-dialog-title">Create Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            variant="outlined"
            type="text"
            fullWidth
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          {
            InputErr && <Typography className={classes.texterror} >Invalid Category name </Typography>
          }
          {
            InputEmpty && <Typography className={classes.texterror} >Please Enter Category name </Typography>
          }
        </DialogContent>
        <DialogActions style={{ padding: "20px", }}>
          <Button onClick={handleClose} color="secondary" size="small" variant="outlined">
            Cancel
          </Button>
          <Button onClick={create} color="secondary" className={classes.button} size="small" variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default inject('store')(observer(Category))