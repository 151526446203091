import React, { useState, useEffect } from 'react';
import '../../App.css';
import SwipeableTextMobileStepper from '../Stepper';
import Login from '../../containers/Login'
import Signup from '../../containers/Signup';
import SocialLogin from '../SocialLogin';
import Axios from 'axios';
import { Api } from '../../const';
import { inject, observer } from 'mobx-react';
import { CircularProgress } from '@material-ui/core';
import Header from '../Header';
import { useHistory } from 'react-router';

//Flyout or extension
function Extension({ store, isSignupRoute }) {
  const [isloginscreen, setisloginscreen] = useState(true)
  const [isLogin, setIsLogin] = useState(false)
  const [loading, setloading] = useState(false)
  const [show, setshow] = useState(true)
  const [googleId, setgoogleId] = useState("")
  const [facebookId, setfacebookId] = useState("")
  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")
  const [first, setfirst] = useState("")
  const [last, setlast] = useState("")
  const [zip, setzip] = useState("")
  const [isProfileInfo, setisProfileInfo] = useState(false)
  const [IsExtension, setIsExtension] = useState(false)
  let location = useHistory()

  useEffect(() => {
    if (isSignupRoute) {
      setisloginscreen(false)
    }
    if (localStorage.getItem("token")) {
      setIsLogin(true)
    }
  })

  useEffect(() => {
    // console.log("in set Is Extension ", window.location.pathname.split('/')[1])
    if (window.location.pathname && window.location.pathname.split('/')[1] === "extension") {
      setIsExtension(true)
    }
  }, [])

  //Google login
  function GLogin(response) {
    // console.log("48 google and face response extension", response)
    if (response.error) {
      return false
    }
    setloading(true)
    Axios.post(Api.baseUrl + "/api/AIBotSocialLogin", {
      googleId: response.profileObj.googleId,
      email: response.profileObj.email
    })
      .then(d => {
        console.log(d)
        if (d.data.message === "NotFound") {
          // console.log("from", d.data.message)
          setisloginscreen(false)
          setloading(false)
          setshow(false)
          setgoogleId(response.profileObj.googleId)
          setemail(response.profileObj.email)
          setfirst(response.profileObj.givenName)
          setlast(response.profileObj.familyName)
        }
        else {
          store.setAuth(d.data.result)
          localStorage.setItem("token", d.data.result.accessToken)
          localStorage.setItem("refreshToken", d.data.result.refreshToken)
          setloading(false)
          if (IsExtension) {
            setIsLogin(true)
          }
          else {
            location.push('/home')
          }
        }
      })
      .catch(err => {
        console.log(err)
        setloading(false)
      })
  }

  //Facebook Login
  function FBLogin(user) {
    setloading(true)
    localStorage.setItem("userProfilePic", user._profile.profilePicURL)
    console.log(user, user._profile.id)
    // console.log("48 google and face response extension", user)
    Axios.post(Api.baseUrl + "/api/AIBotSocialLogin", {
      facebookId: user._profile.id,
      email: user._profile.email || " "
    }).then(d => {
      console.log(d)
      if (d.data.message === "NotFound") {
        setloading(false)
        setisloginscreen(false)
        setshow(false)
        setfacebookId(user._profile.id)
        setemail(user._profile.email || " ")
        setfirst(user._profile.firstName)
        setlast(user._profile.lastName)
      }
      else {
        localStorage.setItem("token", d.data.result.accessToken)
        localStorage.setItem("refreshToken", d.data.result.refreshToken)
        if (!IsExtension) {
          location.push('/home')
        }
        else {
          store.setAuth(d.data.result)
          setIsLogin(true)
        }
        setloading(false)
      }
    })
      .catch(erre => {
        console.log(erre)
        setloading(false)
      })
  }

  //change Screen in case of sign up
  function changeScreen(d) {
    setisloginscreen(d)
    setemail("")
    setpassword("")
    setfirst("")
    setlast("")
    setzip("")
  }

  return (
    <div className="App">
      {loading && <div style={{
        position: 'fixed',
        zIndex: 20000,
        height: '100%',
        background: "#0000004D",
        width: "100%",
        textAlign: "center",
        paddingTop: "30vmin"
      }}>
        <CircularProgress size={30} />
      </div>}
      {isLogin ? <React.Fragment style={{ position: 'relative' }}>
        <SwipeableTextMobileStepper
          changeScreen={changeScreen}
          setisProfileInfo={setisProfileInfo}
          setloading={setloading}
          setIsLogin={setIsLogin}
          data={"data"}
          setshow={setshow}
          setisloginscreen={setisloginscreen}
        />
      </React.Fragment>
        :
        <React.Fragment>
          <Header />
          {/* <ArrowKey /> */}
          <div style={{
            margin: 'auto',
            maxWidth: "400px",
            marginTop: '100px'
          }}>

            <p style={{
              fontSize: '24px',
              marginTop: '20px',
              marginBottom: '20px',
              opacity: 0.65
            }}>
              {!isProfileInfo ? "" : "Fill your Personal Information"}
            </p>
            {/* Welcome to Ai Bot */}
            <div style={{
              background: '#ffffff',
              padding: '20px',
              boxShadow: "1px 1px 20px #00000029",
              borderRadius: "2px"
            }} >
              <p style={{
                fontSize: '20px',
                marginTop: '5px',
                marginBottom: '15px',
                marginLeft: '10px',
                opacity: 0.55
              }}>
                {!isProfileInfo && <React.Fragment> {isloginscreen ? "Sign in" : "Sign up"}</React.Fragment>}
              </p>
              {!isProfileInfo && <React.Fragment> {show && <SocialLogin GLogin={GLogin} FBLogin={FBLogin} />}
                <h4 style={{ textAlign: 'center', opacity: 0.4, marginBlockStart: 10, marginBlockEnd: 0, fontSize: 18 }}> 
                {isloginscreen ? "" : ""}  </h4>
              </React.Fragment>}
              {
                isloginscreen ?
                  <Login
                    setisloginscreen={setisloginscreen}
                    setIsLogin={setIsLogin}
                    email={email}
                    password={password}
                    setemail={setemail}
                    setpassword={setpassword}
                    IsExtension={IsExtension}
                  />
                  :
                  <Signup
                    isProfileInfo={isProfileInfo}
                    setisProfileInfo={setisProfileInfo}
                    setisloginscreen={setisloginscreen}
                    setIsLogin={setIsLogin}
                    googleId={googleId}
                    facebookId={facebookId}
                    email={email}
                    password={password}
                    setemail={setemail}
                    setpassword={setpassword}
                    first={first}
                    setfirst={setfirst}
                    last={last}
                    setlast={setlast}
                    zip={zip}
                    setzip={setzip}
                    show={show}
                    IsExtension={IsExtension}
                  />
              }
              {!isProfileInfo && <p className="TandC">
                By signing up, signing in or continuing, I  agree to  Narus Technologies Inc &nbsp;
                <a href="#" target="_blank" >Terms of Use</a>&nbsp; and &nbsp;
                <a href="#" target="_blank">Privacy Policy.</a>
              </p>}
            </div>
            {!isProfileInfo && <h4 style={{ textAlign: 'center', marginBottom: '40px' }}>
              <span style={{ opacity: 0.6 }}>{isloginscreen ? "Don't have an account yet?" : "Already have an account?"}</span>
              <span onClick={() => {

                if (IsExtension) {
                  changeScreen(!isloginscreen)
                }
                else {
                  location.push(isloginscreen ? '/register' : '/login')
                }
              }} style={{ color: '#f02834', fontWeight: 'bold', cursor: "pointer" }} >
                {isloginscreen ? " Join now" : " Sign in"}</span>
            </h4>}
          </div>

        </React.Fragment>
      }
    </div>

  );
}
export default inject("store")(observer(Extension));
