import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import { Api } from '../../const';
import validator from 'validator';
import Header from '../../components/Header';
import swal from 'sweetalert';

// style
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    buttonProgress: {
        color: "green",
        position: 'absolute',
        left: 0,
        right: 0,
        margin: "auto",
        marginTop: "6px",
        textTransform: 'none'
    },

    textField: {
        // width: "300px",
    },
    text: {
        marginTop: '10px',
        fontSize: '14px',
        cursor: 'pointer',
    },
    wrapper: {
        paddingTop: "15px",
        paddingBottum: "15px"
    },
    inputs: {
        padding: "8px"
    },
    button: {
        background: "#f02834",
        color: '#fff',
        width: '100%',
        textTransform: 'none'
    },
    texterror: {
        paddingLeft: '10px',
        fontSize: '14px',
        color: 'red'
    },
}));

//forget password component
export default function ForgotPassword({ }) {
    const classes = useStyles();
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [loading, setloading] = useState(false)
    const [EmailError, setEmailError] = useState(false)
    const [PassError, setPassError] = useState(false)
    const [ServerError, setServerError] = useState(false)
    const [isSend, setisSend] = useState(false)
    const [isToken, setIsToken] = useState(false)
    const [confirmpassword, setconfirmpassword] = useState('')
    const [EmailIdAlreadyExist, setEmailIdAlreadyExist] = useState(false)
    const [NewPasswordError, setNewPasswordError] = useState(false)
    const [ConfirmPasswordError, setConfirmPasswordError] = useState(false)
    const [resettoken, setresettoken] = useState('')



    function useQuery() {
        return new URLSearchParams(window.location.search);
    }

    let query = useQuery();

    //get reset token
    useEffect(() => {
        setresettoken(query.get("code"))
        if (query.get("code")) {
            setIsToken(true)
        }
    }, [])


    //email send for forget password
    function emailsend() {
        setServerError(false)
        if (!validator.isEmail(email)) {
            setEmailError(true)
            return false
        }
//call api
        setloading(true)
        Axios.post(Api.baseUrl + '/api/User/Manage/forgetPassword', {
            email
        })
            .then(res => {
                if (res.data.status != 200) {
                    setEmailError(false)
                    setPassError(false)
                    setServerError(true)
                    setloading(false)
                    return false
                } else {
                    setEmailError(false)
                    setPassError(false)
                    setServerError(false)
                    setemail('')
                    sweetpopup(res.data.message)
                    setloading(false)
                }


                // window.location.pathname = '/home'
            })
            .catch(err => {
                setloading(false)
                console.log(err)
            })
    }
    //popup open when success message
    function sweetpopup(message) {
        return (
            swal({
                title: "Success!",
                text: message,
                icon: "success",
            })
        )
    }


    //check email
    const validationEmail = (email) => {
        Axios.get(Api.baseUrl + '/api/User/CheckEmail/' + email)
            .then(d => {
                if (d.data.message === "EmailIdAlreadyExist") {
                    setEmailIdAlreadyExist(false)
                    setisSend(true)

                }
                else {
                    setEmailIdAlreadyExist(true)
                    return false;
                }
            }).catch(err => {
                setloading(false)
                console.log(err)
            })
    }

    //change page
    function changeScreen(path) {
        window.location.pathname = path
    }

    //forget password
    function passwordsend() {

        setloading(true)
        Axios.post(Api.baseUrl + '/api/User/Manage/forgetPassword', {
            password
        }, {
            headers: {
                "x-resettoken": resettoken
            }
        })
            .then(res => {
                if (res.data.status != 200) {
                    setServerError(true)
                    setconfirmpassword('')
                    setpassword('')
                    setloading(false)
                    return false
                } else {
                    setServerError(false)
                    setconfirmpassword('')
                    setpassword('')
                    sweetpopup(res.data.message)
                    setloading(false)
                }


                // window.location.pathname = '/home'
            })
            .catch(err => {
                setloading(false)
                console.log(err)
            })
    }

    //validation of new password
    function validateNewPassword(pass) {
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,32})");
        var result = strongRegex.test(pass);
        if (pass.length < 1) {
            setNewPasswordError(false)
            setisSend(false)
        }
        else {
            if (result === true) {
                setNewPasswordError(false)
                setisSend(false)
            } else {
                setNewPasswordError(true)
                setisSend(false)
            }
        }
    }

    //validation of confirm password
    function validateConfirmPassword(pass) {
        if (pass.length < 1) {
            setConfirmPasswordError(false)
            setisSend(false)
        }
        else {
            if (pass === password) {
                setConfirmPasswordError(false)
                setisSend(true)
            } else {
                setisSend(false)
                setConfirmPasswordError(true)
            }
        }
    }

    return (<div className="App">
        {/* {loading && <div style={{
            position: 'fixed',
            zIndex: 20000,
            height: '100%',
            background: "#0000004D",
            width: "100%",
            textAlign: "center",
            paddingTop: "30vmin"
        }}>
            <CircularProgress size={30} />
        </div>} */}
        <Header />
        <div style={{
            margin: 'auto',
            maxWidth: "400px",
            marginTop: '100px'
        }}>

            {/* <p style={{
                fontSize: '24px',
                marginTop: '20px',
                marginBottom: '20px',
                opacity: 0.65
            }}> */}
            {/* {"Welcome to Ai Bot"} */}
            {/* </p> */}

            <div style={{
                background: '#ffffff',
                padding: '20px',
                boxShadow: "1px 1px 20px #00000029",
                borderRadius: "2px"
            }} >
                <p style={{
                    fontSize: '20px',
                    marginTop: '5px',
                    marginBottom: '15px',
                    marginLeft: '10px',
                    // opacity: 0.55
                }}>
                    {<React.Fragment> {"Forgot Password"}</React.Fragment>}
                </p>

                <div className={classes.inputs}>
                    {
                        ServerError && <Typography className={classes.texterror}  >Invalid email or password</Typography>
                    }
                    {!isToken ? <React.Fragment>
                        <Typography className={classes.text}  >Email Address</Typography>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            placeholder="example@domain"
                            type="email"
                            margin="dense"
                            // onBlur={e => validationEmail(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={email}
                            onChange={(e) => {
                                setemail(e.target.value)
                                validationEmail(e.target.value)
                            }}
                            variant="outlined"
                            disabled={loading}
                        />
                        {
                            EmailIdAlreadyExist && <Typography className={classes.texterror} >  Email address not exist </Typography>
                        }
                        {
                            EmailError && <Typography className={classes.texterror} >Invalid email address </Typography>
                        }
                    </React.Fragment> :
                        <React.Fragment>
                            <Typography className={classes.text}>Password</Typography>
                            <TextField
                                className={classes.textField}
                                fullWidth
                                type="password"
                                placeholder="******"
                                margin="dense"
                                value={password}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    setpassword(e.target.value)
                                    validateNewPassword(e.target.value)
                                }}
                                variant="outlined"
                                disabled={loading}
                            />
                            {
                                NewPasswordError && <Typography className={classes.texterror} > Must be enter 8 digits password with uppercase lowercase numeric and symbol </Typography>
                            }
                            {
                                PassError && <Typography className={classes.texterror} > Invalid Password </Typography>
                            }
                            <Typography className={classes.text}>Confirm Password</Typography>
                            <TextField
                                className={classes.textField}
                                fullWidth
                                type="password"
                                placeholder="******"
                                margin="dense"
                                value={confirmpassword}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    setconfirmpassword(e.target.value)
                                    validateConfirmPassword(e.target.value)
                                }}
                                variant="outlined"
                                disabled={loading}
                            />
                            {
                                ConfirmPasswordError && <Typography className={classes.texterror} >Password doesn't match enter correct password </Typography>
                            }
                            {
                                PassError && <Typography className={classes.texterror} >Invalid password </Typography>
                            }
                        </React.Fragment>}
                    <div className={classes.wrapper}>
                        <Button variant="contained" disabled={loading || !isSend} onClick={isToken ? passwordsend : emailsend}
                            color="secondary" className={classes.button}> {isToken ? "Save Password" : "Send Email"} </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </div>

                {<p className="TandC">
                    By signing up, signing in or continuing, I  agree to  Narus Technologies Inc &nbsp;
        <a href="#" target="_blank" >Terms of Use</a>&nbsp; and &nbsp;
        <a href="#" target="_blank">Privacy Policy.</a>
                </p>}
            </div>
            {<h4 style={{ textAlign: 'center', marginBottom: '40px' }}>
                <span style={{ opacity: 0.6 }}>{"Already have an account?"}</span>
                <span onClick={(path) => changeScreen(path = 'login')} style={{ color: '#f02834', fontWeight: 'bold', cursor: 'pointer' }} >
                    {" Sign in"}</span>
            </h4>}
        </div>

    </div>

    );
}
