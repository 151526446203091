import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, Typography, CircularProgress, Grid, Checkbox } from '@material-ui/core';
import Axios from 'axios';
import { Api } from '../../const';
import validator from 'validator';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  buttonProgress: {
    color: "green",
    position: 'absolute',
    left: 0,
    right: 0,
    margin: "auto",
    marginTop: "6px",
    textTransform: 'none'
  },

  textField: {
    // width: "300px",
  },
  text: {
    marginTop: '10px',
    fontSize: '14px',
    cursor: 'pointer',
  },
  wrapper: {
    paddingTop: "15px",
    paddingBottum: "15px"
  },
  inputs: {
    padding: "8px"
  },
  button: {
    background: "#f02834",
    color: '#fff',
    width: '100%',
    textTransform: 'none'
  },
  texterror: {
    paddingLeft: '10px',
    fontSize: '14px',
    color: 'red'
  },
}));

//Login component
export default function Login({ setisloginscreen, setIsLogin, email, password, setpassword, setemail, IsExtension }) {
  const classes = useStyles();

  const [loading, setloading] = useState(false)
  const [EmailError, setEmailError] = useState(false)
  const [PassError, setPassError] = useState(false)
  const [ServerError, setServerError] = useState(false)
  const [KeepMe, setKeepMe] = useState(false)
  let location = useHistory()

  //login function use to login user
  function login() {
    setServerError(false)
    if (!validator.isEmail(email)) {
      setEmailError(true)
      return false
    }
    // var reg = new RegExp("^(?=.*\d)(?=.*[a-z])(?=.*[0-9]).{8,32}$");
    // var test = reg.test(password);
    if (password.length > 5) {
      setPassError(false);
    } else {
      setPassError(true);
      return false
    }

    setloading(true)
    Axios.post(Api.baseUrl + '/api/AIBotLogin', {
      email,
      password
    })
      .then(d => {
        if (!d.data.result) {
          setEmailError(false)
          setPassError(false)
          setServerError(true)
          setloading(false)
          return false
        }
        setEmailError(false)
        setPassError(false)
        setServerError(false)
        localStorage.setItem("token", d.data.result.accessToken)
        localStorage.setItem("refreshToken", d.data.result.refreshToken)
        localStorage.setItem("email", email)
        console.log("IsExtensionIsExtensionIsExtension =>>>>", IsExtension)
        if (!IsExtension) {
          location.push('/home')
        }
        else {
          setIsLogin(true)
          setloading(false)
        }
        // if (IsExtension) {
        //   localStorage.setItem("token", d.data.result.accessToken)
        //   localStorage.setItem("refreshToken", d.data.result.refreshToken)
        // }
        // else {
        //   localStorage.setItem("token", d.data.result.accessToken)
        //   localStorage.setItem("refreshToken", d.data.result.refreshToken)
        //   // var token = d.data.result.accessToken
        //   // var refreshToken = d.data.result.refreshToken
        // state: {
        //       token: d.data.result.accessToken,
        //       refreshToken: d.data.result.refreshToken
        //     }
        // history.push('/home', state)
        //   window.location.push({
        //     pathname: '/home',
        //     state: {
        //       token: d.data.result.accessToken,
        //       refreshToken: d.data.result.refreshToken
        //     }
        //   })
        // }
      })
      .catch(err => {
        setloading(false)
        console.log(err)
      })
  }

  //use to move on Forgot page
  function IsForgot(path) {
    window.location.pathname = path
  }
  return (
    <div className={classes.root}>
      {!localStorage.getItem("token") &&
        <div className={classes.inputs}>
          {
            ServerError && <Typography className={classes.texterror}  >Invalid email or password</Typography>
          }
          <Typography className={classes.text}  >Email Address</Typography>
          <TextField
            className={classes.textField}
            fullWidth
            placeholder="example@domain"
            type="email"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            value={email}
            onChange={(e) => setemail(e.target.value)}
            variant="outlined"
          />
          {
            EmailError && <Typography className={classes.texterror} >Invalid email address </Typography>
          }
          <Typography className={classes.text}>Password</Typography>
          <TextField
            className={classes.textField}
            fullWidth
            type="password"
            placeholder="******"
            margin="dense"
            value={password}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setpassword(e.target.value)}
            variant="outlined"
          />
          {
            PassError && <Typography className={classes.texterror} >Invalid password </Typography>
          }
          <Grid container spacing={2} >
            <Grid item xs={6} >
              <Typography className={classes.text} >
                <Checkbox
                  checked={KeepMe}
                  onChange={() => setKeepMe(!KeepMe)}
                  style={{
                    padding: '0px',
                    color: KeepMe && "#f02834"
                  }}
                /> Keep me signed </Typography>
            </Grid>
            <Grid item xs={6} >
              <Typography className={classes.text} disabled={loading} style={{ textAlign: 'right', opacity: 0.5, fontWeight: 'bold' }} onClick={(path) => IsForgot(path = 'forgotPassword')} > Forgot Password </Typography>
            </Grid>
          </Grid>
          <div className={classes.wrapper}>
            <Button variant="contained" disabled={loading} onClick={login} color="secondary" className={classes.button}> Sign In </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </div>
      }
    </div>
  );
}
