import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Grid, Select, MenuItem, makeStyles, Switch } from '@material-ui/core'

//Mortgage Amount in Edit Property 
const MortgageAmount = ({ store, TotalMotage, dataforSet, setBankDetails, ExpectedPayment, NeededValue, DownPayment, Insurance, DownPaymentPercentage, TotalAdditionCost, onSelectToggleMortgageAmount }) => {
  const classes = useStyles()
  const [RateType, setRateType] = useState("Fixed")
  const [OpenCloseRateType, setOpenCloseRateType] = useState("Open")
  const [Term, setTerm] = useState("")
  const [Bank, setBank] = useState("5e70c2e30ada0763803423c7")
  const [EMI, setEMI] = useState(0)
  const [Rate, setRate] = useState(0)
  const [Frequency, setFrequency] = useState(12)
  const [Closing, setClosing] = useState(false)
  const [TotalClosingCost, setTotalClosingCost] = useState(0)
  const [AmortizationPeriod, setAmortizationPeriod] = useState(25)
  const [TermArray, setTermArray] = useState([]);

  //Library use for calculating 
  const MortgageCalculator = require('mortgage-calculator-p')('ca');  // ca means canada, if empty, it'll be the normal one because canada calculation is slightly different

  //set total closing cost
  useEffect(() => {
    var formatVal = ExpectedPayment.toString();
    var val = formatVal.replace(/,/g, '');
    // (Downpayment - Closing Costs < Purchase Price * 0.20) 
    // setTotalClosingCost((TotalAdditionCost - Insurance) + NeededValue)
    // console.log("closing cost", DownPayment, TotalClosingCost, val,Insurance,TotalAdditionCost,NeededValue)
    var a = DownPayment - TotalClosingCost
    var b = val * 0.20
    if (a < b) {
      // console.log("28 mortgage amount TotalAdditionCost Insurance NeededValue", TotalAdditionCost, Insurance, NeededValue)
      setTotalClosingCost((TotalAdditionCost - Insurance) + NeededValue)
    }
    else {
      setTotalClosingCost((TotalAdditionCost - Insurance) + NeededValue)
    }
    // console.log("Totsl xlosing cost *****",TotalClosingCost)
    // if (store.data.Mortgage) {
    //   setTerm(store.data.Mortgage[0].MortageDetails[0].Term)
    // }
  }, [Insurance, TotalAdditionCost, DownPaymentPercentage, DownPayment])

  // set api data using state
  useEffect(() => {
    setEMI(dataforSet.Scenarios && dataforSet.Scenarios.length > 0 && dataforSet.Scenarios[0].totalMortgagePayment ? dataforSet.Scenarios[0].totalMortgagePayment : EMI)
    setRate(dataforSet.Scenarios && dataforSet.Scenarios.length > 0 && dataforSet.Scenarios[0].mortgageRate ? dataforSet.Scenarios[0].mortgageRate : Rate)
    setTotalClosingCost(dataforSet.closingCostPayment && dataforSet.closingCostPayment ? dataforSet.closingCostPayment : TotalClosingCost)
  }, [dataforSet])

  //set default data
  useEffect(() => {
    // if (store.data.Mortgage) {
    //   setTerm(store.data.Mortgage[0].MortageDetails[0].Term)
    // }
    onmouseenter()
  }, [])

  //re-call store
  useEffect(() => {
    if (store.data.Mortgage) {
      // console.log(store.data.Mortgage[0].MortageDetails)
    }
  }, [store])

  //convert into JSON
  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  //according to closing cost toggle send values Mortgage Amount to Main component
  useEffect(() => {
    if (Closing === true) {
      onSelectToggleMortgageAmount({ TotalClosingCost, EMI, Rate, RateType, Frequency, Term, Closing, AmortizationPeriod });
    }
    else {
      // setTotalClosingCost(0)
      onSelectToggleMortgageAmount({ TotalClosingCost, EMI, Rate, RateType, Frequency, Term, Closing, AmortizationPeriod });
    }
  }, [Closing, TotalClosingCost, EMI, Rate])

  //set rate value and call mortgage libarary
  useEffect(() => {
    var forRate = isJson(Term) ? JSON.parse(Term) : Term
    if (TotalMotage && RateType && Term && Bank && Frequency && OpenCloseRateType) {
      var rate = forRate.SpecialOffer ? forRate.SpecialOffer : forRate.APR
      setRate(rate)
      var emi = (TotalMotage * (rate / 100)) / Frequency
      // console.log("term", forRate)

      // console.log("totalmortgage rate term", AmortizationPeriod, TotalMotage, rate, (rate / 100), forRate.Term)
      const principal = TotalMotage;
      const annual_rate = rate;   // in percentage
      const amortization_period = AmortizationPeriod; //forRate.Term;  // in years
      const mc = new MortgageCalculator(principal, annual_rate, amortization_period);
      // console.log("mc", mc.show_all_periodic_payment)
      var mcpayment = mc.show_all_periodic_payment

      SetEMIPayment(Frequency, mcpayment)

      // console.log("emi", emi)
      // setEMI(emi.toFixed(2))
      // console.log("emi111111111", EMI)
    }

    setBankDetails({
      OpenCloseRateType,
      RateType,
      Term,
      Bank,
      EMI,
      Frequency,
      Closing,
      AmortizationPeriod,
      Rate: Term ? forRate.SpecialOffer ? forRate.SpecialOffer : forRate.APR : ""
    })
  }, [TotalMotage, RateType, Term, Bank, Frequency, Closing, OpenCloseRateType, AmortizationPeriod])

  // set EMI values and these values added into MOrtgage Needed in Mortgage Payment 
  function SetEMIPayment(Frequency, mcpayment) {
    // var freq;
    //Select monthly frequecy
    if (Frequency === 12) {
      // freq = "monthly"
      setEMI(mcpayment.monthly)
    } else if (Frequency === 52) {
      // freq = "weekly"
      //Select weekly frequecy
      setEMI(mcpayment.weekly)
    }
    else if (Frequency === 26) {
      //Select biweekly frequecy
      setEMI(mcpayment.biWeekly)
    }
    else {
      // freq = "semiMonthly"
      setEMI(mcpayment.monthly)
    }
    // for (var i = 0; i <= mcpayment.length; i++) {
    // if (freq === mcpayment) {
    // setEMI(emi.toFixed(2))
    // }
    // }
  }

  function onmouseenter() {
    if (dataforSet.bankName) {
      //set api data into term,Amortization,RateType,Frequency,Closing,OpenCloseRate Type
      var forRate = dataforSet.Scenarios.length > 0 && dataforSet.Scenarios[0].mortgageTerm ?
        JSON.parse(dataforSet.Scenarios[0].mortgageTerm) : ''
      setBank(dataforSet.bankName)
      var d = dataforSet.Scenarios[0].mortgageTerm

      setTerm(forRate.Term)
      setAmortizationPeriod(dataforSet.Scenarios[0].amortizationPeriod ? dataforSet.Scenarios[0].amortizationPeriod : AmortizationPeriod)
      setRateType(dataforSet.Scenarios[0].morgageType)
      setFrequency(dataforSet.Scenarios[0].mortgageFrequency)
      setClosing(dataforSet.isClosingCost ? true : false)
      setOpenCloseRateType(forRate.OpenClose)
    }
  }

  //refelect on state change set term values get data from store
  useEffect(() => {
    store.data.Mortgage && store.data.Mortgage.length > 0 && store.data.Mortgage.map((b) => {
      if (b._id === Bank) {
        return b.MortageDetails.map((i, k) => {
          if (i.Type === RateType && i.OpenClose === OpenCloseRateType) {
            if (Term == i.Term) {
              return (
                setTerm(JSON.stringify(i))
              )
            }
          }
        })
      }
    })
  }, [Bank, RateType, OpenCloseRateType])

  var termArrayList = []
  useEffect(() => {
    store.data.Mortgage && store.data.Mortgage.length > 0 && store.data.Mortgage.map((b) => {
      if (b._id === Bank) {
        return b.MortageDetails.map((i, k) => {
          if (i.Type === RateType && i.OpenClose === OpenCloseRateType) {
            termArrayList.push(i);
           }
          setTermArray(termArrayList);
        })
      }
    })
  }, [Bank, RateType, OpenCloseRateType])

  return (
    <div>
      <p>
        <Switch checked={Closing} onChange={() => setClosing(!Closing)} /> Do you want to add the closing costs to the mortgage?
      </p>
      {(Closing && DownPaymentPercentage < 20) ? <p>(* <span style={{ color: 'red' }}>Closing cost add when downpayment is gretaer than 20%</span>)</p> : ''}
      <div className="widget" data-widget="calc-payment" data-ltt="only" data-lang="en"></div>
      <Grid container spacing={1} >
        {/*  {Closing &&
          <React.Fragment>
            <Grid item xs={5}>
              <p>Closing cost payment</p>
            </Grid>
            <Grid item xs={4}>

              {TotalClosingCost != 0 ?
                <p> ${TotalClosingCost.toLocaleString(undefined, { minimumFractionDigits: 2 })} </p>
                :
                <p>"Sorry, you cannot add closing costs to the mortgage.
                   Otherwise, you will be required to get mortgage insurance"</p>
              }

            </Grid>
          </React.Fragment>
        }
        */}
        <Grid item xs={5}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={5}>
          <p> Mortgage Rate Type</p>
        </Grid>
        {/* <Grid item xs={4}>
          <Select
            className={classes.select}
            value={Bank}
            margin="dense"
            variant="outlined"
            onChange={e => setBank(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {
              store.data.Mortgage && store.data.Mortgage.map((i, k) => {
                return <MenuItem key={"key bk term" + k + i._id} value={i._id}>{i.Bank} </MenuItem>
              })
            }
          </Select>
        </Grid> */}
        <Grid item xs={3}>
          <Select
            className={classes.select}
            value={RateType}
            margin="dense"
            variant="outlined"
            onChange={e => setRateType(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            <MenuItem value={"Fixed"}>Fixed</MenuItem>
            <MenuItem value={"Variable"}>Variable</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select
            className={classes.select}
            value={OpenCloseRateType}
            margin="dense"
            variant="outlined"
            onChange={e => setOpenCloseRateType(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            <MenuItem value={"Open"}>Open</MenuItem>
            <MenuItem value={"Closed"}>Closed</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={5}>
          Mortgage Provider
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={Bank}
            margin="dense"
            variant="outlined"
            onChange={e => setBank(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {
              store.data.Mortgage && store.data.Mortgage.map((i, k) => {
                return <MenuItem key={"key bk term" + k + i._id} value={i._id}>{i.Bank} </MenuItem>
              })
            }
          </Select>
        </Grid>
        <Grid item xs={5}>
          <p> Term </p>
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={Term}
            margin="dense"
            variant="outlined"
            onChange={e => { setTerm(e.target.value) }}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {/* {store.data.Mortgage && store.data.Mortgage.length > 0 && store.data.Mortgage.map((b) => {
              if (b._id === Bank) {
                return b.MortageDetails.map((i, k) => {
                  if (i.Type === RateType) {
                    return <MenuItem key={"key term" + k} value={JSON.stringify(i)}>{i.Term} year {i.OpenClose}</MenuItem>
                  }
                })
              }
            })} */}

            {TermArray && TermArray.length > 0 ? TermArray.map((i, j) =>
              (i.Type === RateType && i.OpenClose === OpenCloseRateType)
              && <MenuItem key={"key term" + j} value={JSON.stringify(i)}>{parseInt(i.Term)} year {i.OpenClose}</MenuItem>
            ) : 
            <MenuItem>Not Available</MenuItem>
            }

            {/* {store.data.Mortgage && store.data.Mortgage.length > 0 && store.data.Mortgage.map((b) => {
              if (b._id === Bank) {
                return b.MortageDetails.map((i, k) => {
                  if (i.Type === RateType && i.OpenClose === OpenCloseRateType) {
                    return <MenuItem key={"key term" + k} value={JSON.stringify(i)}>{parseInt(i.Term)} year {i.OpenClose}</MenuItem>
                  }
                })
              }
            })} */}
          </Select>
        </Grid>
        <Grid item xs={5}>
          <p>Amortization Period</p>
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={AmortizationPeriod}
            defaultValue={25}
            // selected={25}
            margin="dense"
            variant="outlined"
            onChange={e => setAmortizationPeriod(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {
              [0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].map((i) => {
                return (<MenuItem value={i} >{i} Year</MenuItem>)
              })
            }
          </Select>
        </Grid>
        <Grid item xs={5}>
          <p>Frequency</p>
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={Frequency}
            margin="dense"
            variant="outlined"
            onChange={e => setFrequency(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            <MenuItem value={12}>Monthly</MenuItem>
            <MenuItem value={52}> weekly</MenuItem>
            <MenuItem value={26}>Bi-weekly</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={5}>
          <p>Interest Rate</p>
        </Grid>
        <Grid item xs={4}>
          <p> {Rate}% </p>
        </Grid>
        <Grid item xs={5}>
          <p> Mortgage Payment</p>
        </Grid>
        <Grid item xs={4}>
          <p>$ {EMI ? EMI.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 }) : "Select banks "} </p>
        </Grid>
      </Grid>
    </div>
  )
}
export default inject('store')(observer(MortgageAmount))


const useStyles = makeStyles(theme => ({
  select: {
    width: '100%',
  },
  icon: {
    fill: "#f02834",
  }
}));