import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Select, MenuItem, Switch, TextField, InputAdornment } from '@material-ui/core';
import { inject, observer } from 'mobx-react'
import { provincial_ltt, municipal_ltt, ltt_rebate } from '../../const/motgage';

//design set
const useStyles = makeStyles(theme => ({
  flyout: {
    width: "480px",
    marginTop: '50px'
  },
  select: {
    width: '100%',
  },
  root: {
    // flexGrow: 1,
    margin: theme.spacing(1),
  },
  comment: {
    margin: theme.spacing(1)
  },
  img: {
    margin: theme.spacing(1),
    height: 180,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
  subHead: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#f02834',
    margin: 0,
  },
  propName: {
    margin: theme.spacing(1),
    fontSize: '19px',
    fontWeight: "bold"
  },
  Panels: {
    width: '500px'
  },
  summary: {
    margin: '0px',
    paddingRight: '10px',
    paddingLeft: '10px'
  },
  details: {
    width: '100%',
    paddingRight: '0px',
    paddingLeft: '0px'
  },
  icon: {
    fill: "#f02834",
  }
}));

// Aditional Cost component
const AdditionalCost = ({ Insurance, addressTexts, store, askPrice, setLandinfo, dataforSet, onSelectTotalAmount, DownPaymentPercentage, ownershipTypes }) => {
  const classes = useStyles()
  const [Province, setProvince] = useState("")
  const [City, setCity] = useState("")
  const [Provincial, setProvincial] = useState(0)
  const [Rebate, setRebate] = useState(0)
  const [Municipal, setMunicipal] = useState(0)
  const [LawyerFees, setLawyerFees] = useState(1000)
  const [Closing, setClosing] = useState(false)
  const [TitleInsurance, setTitleInsurance] = useState(250)
  const [HomeInsurance, setHomeInsurance] = useState(475)
  const [EstoppelCertificate, setEstoppelCertificate] = useState(0)
  const [Appraisal, setAppraisal] = useState(500)
  const [TotalAmount, setTotalAmount] = useState(0)
  const [ToggleRebateRate, setToggleRebateRate] = useState(0)
  const [InsuranceDown, setInsuranceDown] = useState(0)

  //call OnChangeProvince function
  useEffect(() => {
    {
      onChangeProvince(addressTexts)
    }
  }, [addressTexts, dataforSet])

  //call OnChangeMunciple function
  useEffect(() => {
    {
      onChangeMunciple(addressTexts)
    }
  }, [Province, addressTexts])

//set province values
  function onChangeProvince(addressTexts) {
    var str = addressTexts && addressTexts.toLowerCase();
    store.data.Province && store.data.Province.length > 0 && store.data.Province.map((i, k) => {
      var pp = i.province.toLowerCase()
      var a = str.search(pp)
      if (a > -1) {
        return (setProvince(i.code), onChangeMunciple(addressTexts))
      }
    })
  }

  //set munciple values
  function onChangeMunciple(addressTexts) {
    var str = addressTexts && addressTexts.toLowerCase();
    store.data.Province && store.data.Province.length > 0 && store.data.Province.map((i, k) => {
      if (i.code === Province) {
        return i.cities.map(j => {
          var jj = j.slug.toLowerCase()
          var citiess = str.search(jj)
          if (citiess > -1) {
            return (setCity(j.slug))
          }
        })
      }
    })
  }

  //set province , munciple and rebate
  useEffect(() => {
    if (Province) {
      setProvincial(provincial_ltt(Province, askPrice))
    }
    if (Province, City) {
      setRebate(ltt_rebate(Province, City, "fthb", askPrice, ""))
    }

    if (City) {
      setMunicipal(municipal_ltt(Province, City, askPrice))
    }
    setLandinfo({ Province, City, Closing: Closing ? true : false })
  }, [Province, City, Closing, Provincial, Municipal, askPrice])

  //set intial values of lawyer , insurnce,homeInsurnce,Apprasial and estoppleCertificate
  useEffect(() => {
    setLawyerFees(dataforSet && dataforSet.lawyerFee ? dataforSet.lawyerFee : LawyerFees)
    setTitleInsurance(dataforSet && dataforSet.titleInsuranceFee ? dataforSet.titleInsuranceFee : TitleInsurance)
    setHomeInsurance(dataforSet && dataforSet.homeInspectionFee ? dataforSet.homeInspectionFee : HomeInsurance)
    setAppraisal(dataforSet && dataforSet.appraisalFee ? dataforSet.appraisalFee : Appraisal)
    setEstoppelCertificate(dataforSet && dataforSet.estoppelCertificateFee ? dataforSet.estoppelCertificateFee : EstoppelCertificate)
  }, [])

// set insurance when downPaymentPercentage 20 or less 20%
  useEffect(() => {
    if (DownPaymentPercentage < 21) {
      setInsuranceDown(Insurance.toFixed(2))
    } else {
      setInsuranceDown(0)
    }
  }, [DownPaymentPercentage, Insurance])

  //set EstoppelCertificates values in true conditions
  useEffect(() => {
    if (ownershipTypes === "Condominium/Strata" || ownershipTypes === "Strata" || ownershipTypes === "Condominium") {
      setEstoppelCertificate(dataforSet.estoppelCertificateFee ? dataforSet.estoppelCertificateFee : 0)
    }
    else {
      setEstoppelCertificate(0)
    }
  }, [])

  //toggle set
  useEffect(() => {
    if (Closing === true) {
      setToggleRebateRate(Rebate);
    }
    else {
      setToggleRebateRate(0);
    }
  }, [Closing])

  //set dataof province city and closing
  useEffect(() => {
    if (dataforSet.landTranferTax) {
      setProvince(dataforSet.landTranferTax.provincial)
      setCity(dataforSet.landTranferTax.municipal)
      setClosing(dataforSet.landTranferTax.isFirstProperty ? true : false)
    }
  }, [dataforSet, Closing])

  // set Total Amount
  useEffect(() => {
    setTotalAmount(Number(LawyerFees) + Number(TitleInsurance) +
      Number(EstoppelCertificate) + Number(HomeInsurance) + Number(Appraisal) + Number(Provincial) + Number(Municipal)
      + Number(InsuranceDown))
    // + Number(ToggleRebateRate)
  }, [LawyerFees, TitleInsurance, EstoppelCertificate, HomeInsurance, Appraisal, Provincial, Municipal,
    InsuranceDown, ToggleRebateRate])

 //data child to parent
    useEffect(() => {
    onSelectTotalAmount({ TotalAmount, LawyerFees, TitleInsurance, EstoppelCertificate, HomeInsurance, Appraisal, InsuranceDown });
  }, [TotalAmount, LawyerFees, TitleInsurance, EstoppelCertificate, HomeInsurance, Appraisal, InsuranceDown])
  return (
    <div>
      <Grid container spacing={1} >
        <Grid item xs={6}>
          <p style={{ marginLeft: "-15px" }}>    <Switch checked={Closing} onChange={(e, c) => { console.log(c); setClosing(c) }} />   First time home buyer ?   </p>
        </Grid>

        <Grid item xs={3}>
          <Select
            className={classes.select}
            value={Province}
            margin="dense"
            variant="outlined"
            onChange={e => setProvince(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {
              store.data.Province && store.data.Province.length > 0 && store.data.Province.map((i, k) => {
                return <MenuItem key={"Province" + k} value={i.code}>{i.province}</MenuItem>
              })
            }
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select
            className={classes.select}
            value={City}
            margin="dense"
            variant="outlined"
            onChange={e => setCity(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {
              store.data.Province && store.data.Province.length > 0 && store.data.Province.map((i, k) => {
                // console.log("iiiiii in cities",i)
                if (i.code === Province) {
                  return i.cities.map(j => <MenuItem key={"city" + j.id} value={j.slug}>{j.name}</MenuItem>)
                }
              })
            }
          </Select>
        </Grid>
        {Closing &&
          <React.Fragment>
            <Grid item xs={5}>
              <p>Rebate Rate</p>
            </Grid>
            <Grid item xs={4}>
              <p><b>${Rebate.toLocaleString()}</b> </p>
            </Grid>
          </React.Fragment>
        }
        <Grid item xs={5}>
          <p>Land Transfer Tax</p>
        </Grid>
        <Grid item xs={3}>
          <p>Provincial Rate <br />${Provincial.toLocaleString()}</p>
        </Grid>
        <Grid item xs={3}>
          <p>Municipal Rate<br />${Municipal.toLocaleString()}</p>
        </Grid>
        <Grid item xs={5}>
          <p> Lawyer Fees</p>
        </Grid>
        <Grid item xs={4}>
          <InputField Value={LawyerFees} onChange={setLawyerFees} />
        </Grid>
        <Grid item xs={5}>
          <p> Title Insurance Fee</p>
        </Grid>
        <Grid item xs={4}>
          <InputField Value={TitleInsurance} onChange={setTitleInsurance} />
        </Grid>
        <Grid item xs={5}>
          <p>Estoppel Certificate Fee</p>
        </Grid>
        <Grid item xs={4}>
          <InputField Value={EstoppelCertificate} onChange={setEstoppelCertificate} />
        </Grid>
        <Grid item xs={5}>
          <p>Home Inspection Fees</p>
        </Grid>
        <Grid item xs={4}>
          <InputField Value={HomeInsurance} onChange={setHomeInsurance} />
        </Grid>
        <Grid item xs={5}>
          <p> Appraisal Fees</p>
        </Grid>
        <Grid item xs={4}>
          <InputField Value={Appraisal} onChange={setAppraisal} />
        </Grid>
        <Grid item xs={5}>
          <p> Mortgage  Insurance</p>
        </Grid>
        <Grid item xs={3}>
          <p>${InsuranceDown.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
        </Grid>
        <Grid item xs={5}>
          <p>Closing Cost</p>
        </Grid>
        <Grid item xs={3}>
          <p>${TotalAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('store')(observer(AdditionalCost))

//common input fields
const InputField = ({ Value, onChange }) => {
  return (
    <TextField
      value={Value === undefined || Value === '' ? 0 : (Value).toLocaleString()}
      fullWidth
      margin="dense"
      onChange={(e) => {
        var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
        val = parseFloat(val);
        onChange(val)
      }}
      variant="outlined"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
    />
  )
}