import React, { useState, useEffect } from 'react'
import Category from '../../components/Category'
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField, Grid, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails,
  InputAdornment, Button
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { inject, observer } from 'mobx-react'
import AdditionalCost from '../../components/AdditionalCost';
import MortgageAmount from '../../components/MortgageAmount';
import { Api } from '../../const';
import Axios from 'axios';
import { getUser, getAccessToken, updatePropertyCommentCategory } from '../../const/function';

//style
const useStyles = makeStyles(theme => ({
  flyout: {
    width: "458px",
    marginTop: '80px',
  },
  select: {
    width: '100%',
  },
  root: {
    // flexGrow: 1,
    margin: theme.spacing(1),
    maxWidth: '445px'
  },
  comment: {
    margin: theme.spacing(1),
    maxWidth: '445px'
  },
  img: {
    margin: theme.spacing(1),
    height: 180,
    overflow: 'hidden',
    display: 'block',
    cursor: 'pointer',
    width: '100%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  subHead: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#f02834',
    margin: 0,
  },
  propName: {
    margin: theme.spacing(1),
    fontSize: '15px',
    fontWeight: "bold"
  },
  Panels: {
    // width: '458px',
    maxWidth: '445px',
    marginLeft: '8px'
  },
  summary: {
    margin: '0px',
    paddingRight: '10px',
    paddingLeft: '10px'
  },
  details: {
    width: '100%',
    paddingRight: '0px',
    paddingLeft: '0px'
  },
  isEditAbleButton: {
    padding: '10px',
    paddingBottom: '20px'
  },
  button: {
    background: "#f02834",
    color: '#fff',
    float: 'right',
    marginRight: '10px'
  }
}));

//Extension component
function Flyout({ data, store, isEditAble, setloading }) {
  const classes = useStyles();
  const [comments, setcomments] = useState('')
  const [TotalMotage, setTotalMotage] = useState('')
  const [Insurance, setInsurance] = useState(0)
  const [BankDetails, setBankDetails] = useState('')
  const [Landinfo, setLandinfo] = useState('')
  const [ExpandedMortgageCalculator, setExpandedMortgageCalculator] = useState(false)
  const [ExpandedDescription, setExpandedDescription] = useState(false)
  const [ExpandedMortgageAmount, setExpandedMortgageAmount] = useState(false)
  const [ExpandedAdditional, setExpandedAdditional] = useState(false)
  const [TotalAdditionCost, setTotalAdditionCost] = useState(0)
  const [NeededValue, setNeededValue] = useState(0)
  const [toggleMortgageValue, setToggleMortgageValue] = useState(0)
  const [AskingPriceLandTax, setAskingPriceLandTax] = useState(0)
  const [DownPaymentPercentage, setDownPaymentPercentage] = useState(0)
  const [DownPayment, setDownPayment] = useState(0)
  const [ExpectedPayment, setExpectedPayment] = useState(0)
  const [MortgagePrice, setMortgagePrice] = useState(0)
  const [MonthlyPayment, setMonthlyPayment] = useState(0)
  const [PropertyTax, setPropertyTax] = useState(0)
  const [Frequency, setFrequency] = useState(0)
  const [Rate, setRate] = useState(0)
  const [LawyerFee, setLawyerFee] = useState(0)
  const [TitleInsurance, setTitleInsurance] = useState(0)
  const [HomeInsurance, setHomeInsurance] = useState(0)
  const [EstoppelCertificate, setEstoppelCertificate] = useState(0)
  const [Appraisal, setAppraisal] = useState(0)
  const [CategoryId, setCategoryId] = useState(0)
  const [MortgageAmounts, setMortgageAmounts] = useState(0)
  const [MortgageInsuranceDown, setMortgageInsuranceDown] = useState(0)
  const [ClosingBool, setClosingBool] = useState(false)
  const [scholLocation, setscholLocation] = useState(0)
  const [workLocation, setworkLocation] = useState(0)
  const [otherLocation, setotherLocation] = useState(0)


  function update(id) {
    if (!store.propertie.CategoryId && store.propertie.CategoryId === 'none') {
      return false
    }
    setloading(true)
    //update comment 
    updatePropertyCommentCategory(id, CategoryId, comments).then(res => {

      if (res && res.message === "UpdatedSuccess") {
        setworkLocation(res.data.Mortgage && res.data.Mortgage.DistanceMatrix && res.data.Mortgage.DistanceMatrix.WorkLocation ? res.data.Mortgage.DistanceMatrix.WorkLocation : 0 + " Km")
        setscholLocation(res.data.Mortgage && res.data.Mortgage.DistanceMatrix && res.data.Mortgage.DistanceMatrix.SchoolLocation ? res.data.Mortgage.DistanceMatrix.SchoolLocation : 0 + " Km")
        setotherLocation(res.data.Mortgage && res.data.Mortgage.DistanceMatrix && res.data.Mortgage.DistanceMatrix.OtherLocation ? res.data.Mortgage.DistanceMatrix.OtherLocation : 0 + " Km")
        updateMotegage(id, res)
        setloading(false)
      }
      setloading(false)
    })

    // var body = {
    //   categoryId: CategoryId,
    //   comment: comments ? comments : ''
    // }
    // var head = {
    //   headers: {
    //     "x-accesstoken": localStorage.getItem('token')
    //   }
    // }
    // Axios.post(Api.baseUrl + "/api/Property/" + id, body, head)
    //   .then(res => {
    //     if (res.data.result.message === "InvalidToken") {
    //       return getAccessToken().then(res => res ? update(id) : '')
    //     }
    //     else {
    //       updateMotegage(id, head)
    //     }
    //     // updateMotegage(id, head)
    //   }).catch(err => {
    //     console.log(err)
    //     setloading(false)
    //   })
  }

  //update mortgage values of property call api
  function updateMotegage(id, res) {
    var mortgageRequired = ClosingBool === true ? toggleMortgageValue.toFixed(2) : TotalMotage.toFixed(2)
    var DownPayment1 = DownPayment.toLocaleString()
    var DownPayment2 = DownPayment1 ? DownPayment1.replace(/,/g, "") : DownPayment1;
    var a = DownPayment2 ? DownPayment2 : 0
    // var ask = data.Property.Price
    // ask = (a && a.split("$")[1].replace(/,/g, ''))
    // var as1 = MortgagePrice ? MortgagePrice : AskingPriceLandTax
    // as1 = parseFloat(as1)
    var scenario2 = res.data && res.data.Mortgage && res.data.Mortgage.Scenarios && res.data.Mortgage.Scenarios.length > 0 ? res.data.Mortgage.Scenarios : ''
     setworkLocation(res.data.Mortgage && res.data.Mortgage.DistanceMatrix && res.data.Mortgage.DistanceMatrix.WorkLocation ? res.data.Mortgage.DistanceMatrix.WorkLocation : 0 + " Km")
    setscholLocation(res.data.Mortgage && res.data.Mortgage.DistanceMatrix && res.data.Mortgage.DistanceMatrix.SchoolLocation ? res.data.Mortgage.DistanceMatrix.SchoolLocation : 0 + " Km")
    setotherLocation(res.data.Mortgage && res.data.Mortgage.DistanceMatrix && res.data.Mortgage.DistanceMatrix.OtherLocation ? res.data.Mortgage.DistanceMatrix.OtherLocation : 0 + " Km")
    var body = {
      "bankName": BankDetails.Bank,
      "bankName1": res.data && res.data.Mortgage && res.data.Mortgage.bankName1 ? res.data.Mortgage.bankName1 : BankDetails.Bank,
      "askingPrice": TotalMotage,
      "askingPrices": AskingPriceLandTax,
      "Scenarios": [
        {
          "scenarioName": "Scenario 1",
          "downPayment": a,
          "downPaymentPercent": DownPaymentPercentage,
          "mortgageInsurance": Insurance,
          "totalMortgageRequired": mortgageRequired,
          "amortizationPeriod": BankDetails.AmortizationPeriod,
          "morgageType": BankDetails.RateType,
          "mortgageRate": BankDetails.Rate,
          "totalMortgagePayment": MonthlyPayment,
          "mortgageTerm": BankDetails.Term,
          "mortgageFrequency": BankDetails.Frequency
        },
        {
          "scenarioName": "Scenario 2",
          "downPayment": scenario2[1] && scenario2[1].downPayment ? scenario2[1].downPayment : a,
          "downPaymentPercent": scenario2[1] && scenario2[1].downPaymentPercent ? scenario2[1].downPaymentPercent : DownPaymentPercentage,
          "mortgageInsurance": scenario2[1] && scenario2[1].mortgageInsurance ? scenario2[1].mortgageInsurance : Insurance,
          "totalMortgageRequired": scenario2[1] && scenario2[1].totalMortgageRequired ? scenario2[1].totalMortgageRequired : mortgageRequired,
          "amortizationPeriod": scenario2[1] && scenario2[1].amortizationPeriod ? scenario2[1].amortizationPeriod : BankDetails.AmortizationPeriod,
          "morgageType": scenario2[1] && scenario2[1].morgageType ? scenario2[1].morgageType : BankDetails.RateType,
          "mortgageRate": scenario2[1] && scenario2[1].mortgageRate ? scenario2[1].mortgageRate : BankDetails.Rate,
          "totalMortgagePayment": scenario2[1] && scenario2[1].totalMortgagePayment ? scenario2[1].totalMortgagePayment : MonthlyPayment,
          "mortgageTerm": scenario2[1] && scenario2[1].mortgageTerm ? scenario2[1].mortgageTerm : BankDetails.Term,
          "mortgageFrequency": scenario2[1] && scenario2[1].mortgageFrequency ? scenario2[1].mortgageFrequency : BankDetails.Frequency
        }
      ],
      "landTranferTax": {
        "provincial": Landinfo.Province,
        "municipal": Landinfo.City,
        "isFirstProperty": Landinfo.Closing ? true : false
      },
      "lawyerFee": LawyerFee,
      "titleInsuranceFee": TitleInsurance,
      "estoppelCertificateFee": EstoppelCertificate,
      "homeInspectionFee": HomeInsurance,
      "appraisalFee": Appraisal,
      "additionalCosts": TotalAdditionCost,
      "totalCost": TotalAdditionCost,
      "isClosingCost": BankDetails.Closing ? true : false,
      "closingCostPayment": toggleMortgageValue,
      DistanceMatrix: {
        "SchoolLocation": res.data.Mortgage && res.data.Mortgage.DistanceMatrix && res.data.Mortgage.DistanceMatrix.SchoolLocation ? res.data.Mortgage.DistanceMatrix.SchoolLocation : 0 + " Km",
        "WorkLocation": res.data.Mortgage && res.data.Mortgage.DistanceMatrix && res.data.Mortgage.DistanceMatrix.WorkLocation ? res.data.Mortgage.DistanceMatrix.WorkLocation : 0 + " Km",
        "OtherLocation": res.data.Mortgage && res.data.Mortgage.DistanceMatrix && res.data.Mortgage.DistanceMatrix.OtherLocation ? res.data.Mortgage.DistanceMatrix.OtherLocation : 0 + " Km",
      }
    }

    var head = {
      headers: {
        "x-accesstoken": localStorage.getItem('token')
      }
    }
    Axios.post(Api.baseUrl + "/api/Property/Mortgage/" + id, body, head)
      .then(res => {
        if (res.data.result.message === "InvalidToken") {
          return getAccessToken().then(res => res ? updateMotegage(id, head) : '')
        }
        else {
          getUser().then(data => {
            store.update("User", data)
            if (data.province) {
              store.update("Province", data.province)
            }
            if (data.properties) {
              // console.log("data.properties 205", data.properties)
              var properties = {}
              var property = []
              data && data.collaboration && data.collaboration.length > 0
                && data.collaboration.map((i, j) => {
                  // console.log("iiiiiiii collab property 209", i)
                  // if (property.find(x => x._id == i.collaborateCategory) == null) {

                  // }   
                  properties = {}
                  i.properties && i.properties.length > 0 && i.properties.map((j, k) => {
                    // console.log("collab properties id data 215", j)
                    properties = j
                    property.push(properties)
                  })

                })
              // console.log("proeprty array 221", property)
              store.update("Properties", data.properties)
            }

            setloading(false)

          }).catch(error => {
            setloading(false)
          })
        }
        // getUser().then(data => {
        //   store.update("User", data)
        //   if (data.province) {
        //     store.update("Province", data.province)
        //   }
        //   if (data.properties) {
        //     store.update("Properties", data.properties)
        //   }

        //   setloading(false)

        // }).catch(error => {
        //   setloading(false)
        // })
      })
      .catch(err => {
        console.log(err)
        setloading(false)
      })
  }

  //get data from child component(category component)
  function handleCategoryId(e) {
    setCategoryId(e.state)
  }

  //according to DownnPayment Percentage set Mortgage Amount
  useEffect(() => {
    var a = TotalMotage ? TotalMotage : 0
    var b;
    // = toggleMortgageValue ? toggleMortgageValue : toggleMortgageValue + TotalAdditionCost
    if (DownPaymentPercentage >= 20) {
      var additionalCost = parseFloat(TotalAdditionCost);
      b = TotalMotage + additionalCost
    }
    else {
      b = TotalMotage
    }
    var mortgageRequired = ClosingBool === true ? b : a
    mortgageRequired = parseFloat(mortgageRequired).toFixed(2)
    setMortgageAmounts(mortgageRequired)
  }, [TotalMotage, toggleMortgageValue, MortgageAmounts, MortgageInsuranceDown, ClosingBool])

  useEffect(() => {
    // var a = data.Property.Price
    // setMortgagePrice(a && a.split("$")[1].replace(/,/g, ''))
  }, [data])

  //Mortgage Price
  useEffect(() => {
    var a = data.Property.Price
    var b = data.Mortgage && data.Mortgage.askingPrices && data.Mortgage.askingPrices ? data.Mortgage.askingPrices : AskingPriceLandTax
    setMortgagePrice(b ? b : a && a.split("$")[1].replace(/,/g, ''))
  }, [])

  //update categoryId
  useEffect(() => {
    if (isEditAble) {
      store.updatePro("CategoryId", data.Category)
    }
    _onmouseenter()
  }, [])

  //toggle change then change Total Amount
  function handleToggleMortgageTotalAmount(toggleMortgageValue) {
    setToggleMortgageValue(toggleMortgageValue.TotalClosingCost)
    var emi = toggleMortgageValue.EMI ? toggleMortgageValue.EMI : 0
    setMonthlyPayment(emi)
    setPropertyTax(toggleMortgageValue.Rate)
    setFrequency(toggleMortgageValue.Frequency)
    setRate(toggleMortgageValue.RateType)
    setClosingBool(toggleMortgageValue.Closing)
  }

//Set comments
  function _onmouseenter() {
    setcomments(data.Comment ? data.Comment : '')
  }

  //open close collapse
  const toggleMortgageCalculator = () => setExpandedMortgageCalculator(!ExpandedMortgageCalculator);
  const toggleAdditionalCost = () => setExpandedAdditional(!ExpandedAdditional);
  const toggleMortgageAmount = () => setExpandedMortgageAmount(!ExpandedMortgageAmount);
  const toggleDescription = () => setExpandedDescription(!ExpandedDescription);

  //handle total amount
  function handleTotalAmount(e) {
    var totalAdditionalCost = e.TotalAmount
    setTotalAdditionCost(totalAdditionalCost.toFixed(2))
    setLawyerFee(e.LawyerFees)
    setTitleInsurance(e.TitleInsurance)
    setHomeInsurance(e.HomeInsurance)
    setEstoppelCertificate(e.EstoppelCertificate)
    setAppraisal(e.Appraisal)
    setMortgageInsuranceDown(e.InsuranceDown)
  }

  //set needed value get data from calculator component 
  function handleNeededAmount(a) {
    setNeededValue(a.Needed)
    setAskingPriceLandTax(a.Asking)
    setDownPaymentPercentage(a.Percent)
    setDownPayment(a.Down)
    setExpectedPayment(a.Asking)
  }

  return (<div style={{
    height: '630px'
    //, overflowY: 'scroll', overflowX: 'hidden'
  }}>
    <div className={classes.flyout}>
      <Grid container spacing={2} >
        <Grid xs={6} item ><img
          onClick={() => window.open(data.Weblink, '_blank')}
          className={classes.img}
          src={data.Property != null ? data && data.Property && data.Property.Photo.length > 0 && data.Property.Photo[0].MedResPath : ''}
          alt={"tutorialSteps[activeStep].label"}
        /></Grid>
        <Grid xs={6} item style={{ marginBlockStart: '-10px' }}>
          <p style={{ marginBottom: '-10px' }}>Price<span> ${AskingPriceLandTax.toLocaleString(undefined, { minimumFractionDigits: 2 })}</span></p>
          <p style={{ marginBottom: '-10px' }}>Mortgage<span> ${(parseFloat(MortgageAmounts).toLocaleString(undefined, { 'minimumFractionDigits':2,'maximumFractionDigits':2 }))}</span></p>
          <p style={{ marginBottom: '-10px' }}>Monthly Payment<span> ${MonthlyPayment.toLocaleString(undefined, { 'minimumFractionDigits':2,'maximumFractionDigits':2 })}</span></p>
          <p style={{ marginBottom: '-10px' }}>Mortgage Payment<span> {Frequency === 12 ? "Monthly" : Frequency === 52 ? "Weekly" : Frequency === 36 ? "Bi-Weekly" : "Bi-Weekly"}</span></p>
          <p style={{ marginBottom: '-10px' }}>Payment<span> {Rate} @ {PropertyTax}%</span></p>
          <p>Closing Cost<span> ${TotalAdditionCost.toLocaleString(undefined, { minimumFractionDigits: 2 })}</span></p>
        </Grid>
      </Grid>


      <Category idata={data.Category} isEditAble={isEditAble} onSelectCategoryId={handleCategoryId} />
      <TextField
        className={classes.comment}
        multiline
        placeholder="Write description..."
        fullWidth
        variant="outlined"
        rows="4"
        onChange={e => setcomments(e.target.value)}
        value={comments}
      />
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <p className={classes.subHead}>Property Information </p>
          <p className={classes.propName}>{data && data.Property && data.Property.Address && data.Property.Address.AddressText}  </p>
        </Grid>
        <Grid item xs={4}>
          <span>Bedrooms </span>
          <span>
            <b>
              {
                data && data.Building && data.Building.Bedrooms
              }
            </b>
          </span>
        </Grid>
        <Grid item xs={4}>
          <span> Bathrooms </span>
          <span>
            <b>
              {
                data && data.Building && data.Building.BathroomTotal
              }
            </b>
          </span>
        </Grid>
        <Grid item xs={4}>
          balcony
      </Grid>
        <Grid item xs={4}>
          Car parking
      </Grid>
        <Grid item xs={4}>
          Facing
      </Grid>
        <Grid item xs={4}>
          <span>Area </span>
          <span>
            <b>
              {
                data && data.Building && data.Building.SizeInterior ? (parseFloat(data.Building.SizeInterior).toLocaleString(undefined, { minimumFractionDigits: 2 }) + " sqft")
                  : " "
              }
              {/* {
              data.Building.SizeInterior &&
                data.Building.SizeInterior.indexOf(" ") === -1 ? parseFloat(data.Building.SizeInterior).toLocaleString(undefined, { minimumFractionDigits: 2 })
                : parseFloat(data.Building.SizeInterior.split(' ')[0]).toLocaleString(undefined, { minimumFractionDigits: 2 })
            }
            {

              data.Building.SizeInterior && data.Building.SizeInterior.indexOf(" ") != -1 ? data.Building.SizeInterior.split(' ')[1] : ''
            } */}
            </b>
          </span>
        </Grid>
        <Grid item xs={7}>
          <span>Ownership</span>
          <span><b> {data && data.Property && data.Property.OwnershipType}</b></span>
        </Grid>
      </Grid>

      <div className={classes.Panels}>
        <ExpansionPanel>
          <ExpansionPanelSummary
            className={classes.summary}
            expandIcon={ExpandedDescription ? <RemoveIcon style={{ color: "#f02834" }} /> : <AddIcon style={{ color: "#f02834" }} />}
            aria-controls="panel1a-content"
            onClick={toggleDescription}
          >
            <Typography className={classes.subHead}>Description</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details} >
            <p style={{ marginLeft: "10px" }}>
              {
                data.PublicRemarks
              }
            </p>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel >
          <ExpansionPanelSummary
            className={classes.summary}
            expandIcon={ExpandedMortgageCalculator ? <RemoveIcon style={{ color: "#f02834" }} /> : <AddIcon style={{ color: "#f02834" }} />}
            aria-controls="panel1a-content"
            onClick={toggleMortgageCalculator}
          >
            <Typography className={classes.subHead}>Mortgage Calculator<span style={{ marginLeft: '49px', color: "black" }}>${NeededValue.toLocaleString(undefined, { minimumFractionDigits: 2 })}</span></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details} >
            <Calculator
              dataforSet={data.Mortgage}
              setTotalMotage={setTotalMotage}
              Price={MortgagePrice}
              setInsurance={setInsurance}
              onSelectNeededAmount={handleNeededAmount}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel >
          <ExpansionPanelSummary
            className={classes.summary}
            expandIcon={ExpandedAdditional ? <RemoveIcon style={{ color: "#f02834" }} /> : <AddIcon style={{ color: "#f02834" }} />}
            aria-controls="panel1a-content"
            onClick={toggleAdditionalCost}
          >
            <Typography className={classes.subHead}> Additional Costs <span style={{ marginLeft: '72px', color: "black" }}>
              ${parseFloat(TotalAdditionCost).toLocaleString(undefined, { minimumFractionDigits: 2 })}</span></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <AdditionalCost
              dataforSet={data.Mortgage}
              setLandinfo={setLandinfo}
              Insurance={Insurance}
              askPrice={AskingPriceLandTax}
              DownPaymentPercentage={DownPaymentPercentage}
              ownershipTypes={data.Property.OwnershipType}
              addressTexts={data.Property.Address.AddressText}
              // askPrice={data.Property.Price.split("$")[1].replace(/,/g, '')}
              onSelectTotalAmount={handleTotalAmount}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel >
          <ExpansionPanelSummary
            className={classes.summary}
            expandIcon={ExpandedMortgageAmount ? <RemoveIcon style={{ color: "#f02834" }} /> : <AddIcon style={{ color: "#f02834" }} />}
            aria-controls="panel1a-content"
            onClick={toggleMortgageAmount}
          >
            <Typography className={classes.subHead}>{ClosingBool === true ? "New Mortgage Payment" : "Mortgage Payment"} <span style={{ marginLeft: '63px', color: "black" }}>
              ${(parseFloat(MortgageAmounts).toLocaleString(undefined, { 'minimumFractionDigits':2,'maximumFractionDigits':2 }))}</span></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <MortgageAmount
              TotalMotage={TotalMotage}
              dataforSet={data.Mortgage}
              setBankDetails={setBankDetails}
              Insurance={Insurance}
              NeededValue={NeededValue}
              ExpectedPayment={ExpectedPayment}
              DownPayment={DownPayment}
              DownPaymentPercentage={DownPaymentPercentage}
              TotalAdditionCost={TotalAdditionCost}
              onSelectToggleMortgageAmount={handleToggleMortgageTotalAmount}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
      {/* { */}
      {/* isEditAble && */}
      <div className={classes.isEditAbleButton}>
        <Button color="secondary" className={classes.button} onClick={() => update(data._id)} size="small" variant="contained">
          save
      </Button>
        <Button color="secondary" className={classes.button} size="small" variant="contained">
          Cancel
      </Button>
      </div>
      {/* } */}
    </div>
  </div>)
}

export default inject('store')(observer(Flyout))

//get insurance  
function getInsurance(Needed, d) {
  if (9.99 >= d) {
    return (Needed * 4) / 100
  }
  if (14.99 >= d) {
    return (Needed * 3.1) / 100
  }
  if (19.99 >= d) {
    return (Needed * 2.8) / 100
  }
  else {
    return 0
  }
}

//calcultor component
const Calculator = ({ setTotalMotage, Price, setInsurance, onSelectNeededAmount, dataforSet }) => {
  const classes = useStyles();
  const [Asking, setAsking] = useState(0)
  const [Expected, setExpected] = useState(0)
  const [Down, setDown] = useState(0)
  const [Percent, setPercent] = useState(5)
  const [Needed, setNeeded] = useState(0)

  //using useEffect set values into states
  useEffect(() => {
    var val = parseFloat(Price);
    setAsking(val)
    setExpected(val)
  }, [Price])

//change asking price then call a function setclau
  useEffect(() => {
    setclau()
  }, [Asking])

  //set down payment and percentage
  useEffect(() => {
    setDown(dataforSet.Scenarios.length > 0 && dataforSet.Scenarios[0].downPayment ? dataforSet.Scenarios[0].downPayment : Down)
    setPercent(dataforSet.Scenarios.length > 0 && dataforSet.Scenarios[0].downPaymentPercent ? dataforSet.Scenarios[0].downPaymentPercent : Percent)
  }, [dataforSet])

  useEffect(() => {
    setTotalMotage((Needed + getInsurance(Needed, Percent)))
    setInsurance(getInsurance(Needed, Percent))
  }, [Needed, Percent])

//change asking or excepted price
  function setclau(e) {

    if (e) {
      if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
        e.target.value = 0
      }
      var val1 = e.target.value
      var val2 = val1.replace(/,/g, "")
      var downpayment = (val2 * Percent) / 100;
      if ((downpayment).length <= 0 || downpayment === "NaN" || downpayment === "Na" || downpayment === 0) {
        setDown(0)
        setPercent(0)
        setNeeded(0)
      } else {
        setDown(downpayment)
        setNeeded(val2 - ((val2 * Percent) / 100))
      }

    }
    else {
      setDown((Asking * Percent) / 100)
      setNeeded(Asking - ((Asking * Percent) / 100))
    }
  }

  //set percentage
  function setclauPer(e) {
    if (e) {
      var downpayment = (Asking * e.target.value) / 100;
      if (e.target.value.length <= 0 || e.target.value === "NaN" || e.target.value === "Na") {
        setDown(0)
        setPercent(0)
        setNeeded(Asking)
      } else {
        setDown(downpayment)
        setNeeded(Asking - ((Asking * e.target.value) / 100))
      }
    }
  }

  //move data from here to parent component
  useEffect(() => {
    onSelectNeededAmount({ Needed, Asking, Percent, Down, Expected });
  }, [Needed, Asking, Percent, Down, Expected])

//set downpayment percentage
  function setDownPer(e) {

    var a = e.target.value
    a = a.replace(/,/g, "")
    if ((a).length <= 0 || a === "NaN" || a === "Na") {
      setDown(0)
      setPercent(0)
      setNeeded(Asking)
    } else {
      var per = ((100 * a) / Asking)
      setPercent(per.toFixed(2))
      setNeeded(Asking - ((Asking * per.toFixed(2)) / 100))
    }
  }

  return (<Grid container>
    <Grid container item spacing={1} className={classes.root} >
      <Grid item xs={5}> <p>  Asking Price  </p>   </Grid>
      <Grid item xs={4}>
        <TextField fullWidth margin="dense"
          value={(Expected).toLocaleString()}
          onChange={e => {
            var val = e.target.value.replace(/,/g, '');
            val = parseFloat(val);
            var formatVal = val.toLocaleString();
            setExpected(formatVal != "NaN" ? formatVal : 0)
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
        // InputLabelProps={{
        //   shrink: true,
        // }}
        />

      </Grid>
    </Grid>
    <Grid container item spacing={1} className={classes.root} >
      <Grid item xs={5}>
        <p>Expected purchase price </p>
      </Grid>
      <Grid item xs={4}>
        <TextField
          value={(Asking).toLocaleString()}
          onChange={e => {
            var val = e.target.value.replace(/,/g, '');
            val = parseFloat(val === '' || val === NaN ? 0 : val);
            setAsking(val)
            setclau(e)
          }}
          fullWidth
          margin="dense"
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
    <Grid container item spacing={1} className={classes.root} >
      <Grid item xs={5}>
        <p> Down payment  </p>
      </Grid>
      <Grid item xs={4}>
        <TextField
          // value={dataforSet.Scenarios.length > 0 && dataforSet.Scenarios[0].downPayment ? dataforSet.Scenarios[0].downPayment : (Down).toLocaleString()}
          value={(Down).toLocaleString()}
          onChange={e => {
            var val = e.target.value.replace(/,/g, '');
            val = parseFloat(val);
            var formatVal = val.toLocaleString();
            setDown(formatVal)
            setDownPer(e)
          }}
          fullWidth
          margin="dense"
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          value={Percent}
          onChange={e => {
            setPercent(e.target.value === '' ? 0 : Number(e.target.value))
            setclauPer(e)
          }}
          fullWidth
          margin="dense"
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
    <Grid container item spacing={1} className={classes.root}  >
      <Grid item xs={5}><p>Mortgage Needed</p></Grid>
      <Grid item xs={3}><p>${Needed.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p></Grid>
    </Grid>
  </Grid>
  )
}  