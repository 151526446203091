import React, { useState, useEffect, useRef } from 'react';
import { Grid, Container, CircularProgress, Table, TableBody, TableCell, Button, TextField, Select, } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DehazeIcon from '@material-ui/icons/Dehaze';
import swal from 'sweetalert';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    MortgageRate, GetAllMortgageRate, DeleteMortgageRate, GetByIdMortgageRate, UpdateMortgageRate
} from '../../const/function';
import store from '../../store';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

//Mortgage Detail component
function MortgageDetailTable({ }) {
    const [getAllList, setGetAllList] = useState([])
    const [editList, setEditList] = useState('')
    const [loading, setloading] = useState(false)
    var userEmail = localStorage.getItem("email")
    // const swalFun=()=>{
    //     return (
    //       swal({
    //           title: "UnAuthorized",
    //           text: "you don't have access!",
    //           icon: "warning",
    //           buttons: {
    //             confirm: {
    //                 value: window.location.pathname = '/home',
    //             },
    //         },
    //       })
    //   )
    //   }


    useEffect(() => {
        setloading(true)
        GetAllMortgageRate().then(res => {
            if (res && res.data != undefined) {
                setGetAllList(res.data)
                store.update("MortgageRateDetail", res.data)
                setloading(false)
            } else {
                setloading(false)
            }
        })
    }, [])

    const StyledTableCell = withStyles((theme) => ({
        head: {
            // backgroundColor: theme.palette.common.black,
            backgroundColor: 'snow',
            // opacity: 0.5,
            // color: theme.palette.common.white,
            color: '#000',
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            // '&:nth-of-type(odd)': {
            // backgroundColor: theme.palette.action.hover,
            backgroundColor: 'ghostwhite',
            color: theme.palette.common.white,
            // },
        },
    }))(TableRow);

    function editgetListHandle(a) {
        setEditList(a)
    }

    function setloadingvalHandle(a) {
        setloading(a)
    }
    function setloadingSaveHandle(a) {
        setloading(a)
    }


    return (
        <Container>
            {userEmail === "stevebrar@narustechnology.com" || "az@gmail.com" ?
                // {userEmail==="az@gmail.com" ?
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container >
                        <Grid xs={12} sm={12} md={8} lg={8} xl={8} item>
                            <h2>Mortgage Rate Detail</h2>
                        </Grid>
                        <Grid xs={12} sm={12} md={4} lg={4} xl={4} item >
                            <MortgageMainModal editLists={editList} setloadingSave={setloadingSaveHandle} />
                        </Grid>
                    </Grid>

                    <TableContainer component={Paper} >
                        <Table
                            // className={classes.table}
                            aria-label="customized table" id="tableCompare">
                            <TableHead>
                                <TableRow style={{ width: '100%' }}>
                                    <StyledTableCell >Id</StyledTableCell>
                                    <StyledTableCell  >Bank Name</StyledTableCell>
                                    <StyledTableCell  >Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading && <div style={{
                                    position: 'fixed',
                                    zIndex: 20000,
                                    height: '100%',
                                    opacity: 1,
                                    width: "100%",
                                    background: "#ffffff94",
                                    textAlign: "center",
                                    paddingTop: "30vmin",
                                    left: '8px',
                                    marginTop: '-122px'
                                }}>
                                    <CircularProgress size={30} />
                                </div>}
                                {store.data && store.data.MortgageRateDetail
                                    && store.data.MortgageRateDetail.length
                                    && store.data.MortgageRateDetail.map((row, j) => (
                                        <StyledTableRow key={j}>
                                            <StyledTableCell scope="row">
                                                {j + 1}
                                            </StyledTableCell>
                                            <StyledTableCell scope="row">
                                                {row.Bank}
                                            </StyledTableCell>
                                            <StyledTableCell scope="row">
                                                <ActionMenu MortgaeData={row} editgetList={editgetListHandle} setloadingval={setloadingvalHandle} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                : <Grid style={{ textAlign: "center" }}>
                    <h1>UnAuthorized</h1>
                    <h4>you don't have access!</h4>
                    <nav><Link to="/home">Go To Property Board</Link></nav>
                </Grid>}
        </Container >
    )
}

export default inject("store")(observer(MortgageDetailTable));

//Edit Delete of Mortgage Detail
function ActionMenu({ MortgaeData, editgetList, setloadingval }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    //open a popup or menu list
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    //close popup
    const handleClose = () => {
        setAnchorEl(null);
    };

    //Edit Mortgage Detail
    const EditMortgage = (id) => {
        GetByIdMortgageRate(id).then(res => {
            var editlist = res && res.message
            editgetList({ editlist })
        })
    }

    //Delete Mortage
    const DeletMortgage = (id) => {
        setloadingval(true)
        DeleteMortgageRate(id).then(res => {
            if (res.message === "MortgageNotFound") {
                swal({
                    title: "Delete Mortgage Bank Detail",
                    text: res.message,
                    icon: "warning",
                })

                setloadingval(false)
            } else {
                swal({
                    title: "Delete Mortgage Bank Detail",
                    text: "Successfully Deleted",
                    icon: "success",
                })
                store.update("MortgageRateDetail", res.data)
                setloadingval(false)
            }

        })
    }

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <DehazeIcon />
            </Button>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => EditMortgage(MortgaeData._id)}>Edit</MenuItem>
                <MenuItem onClick={() => DeletMortgage(MortgaeData._id)}>Delete</MenuItem>
            </Menu>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[4],
        padding: theme.spacing(2, 4, 3),
        height: '80vmin',
        overflowY: "scroll",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: "auto"
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    root1: {
        '& > *': {
            width: '100%',
            height: '40px'
        },
    },
    overClass: {
        overflowY: 'scroll'
    },
    select: {
        width: '100%',
        padding: "10px",
        height: '40px',
        background: '#fff',
    }
}));

function MortgageMainModal({ editLists, setloadingSave }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [componetopen, setcomponetopen] = useState(['']);
    const [btnIncrement, setbtnIncrement] = useState(1)
    const [BankName, setBankName] = useState('')
    const [Term, setTerm] = useState({})
    const [Type, setType] = useState({})
    const [OpenClose, setOpenClose] = useState({})
    const [SpecialOffer, setSpecialOffer] = useState({})
    const [Apr, setApr] = useState({})
    const [IsEdit, setIsEdit] = useState(false)
    const [MortgageId, setMortgageId] = useState('')
    const [Month, setMonth] = useState({})
    const [AddNewString, setAddNewString] = useState("")

    var aArr = []

    // set list of mortgage banks
    useEffect(() => {
        EditList(editLists)
    }, [editLists])

// open popup for add new mortgage detail 
    const handleOpen = () => {
        setAddNewString("AddNewCase")
        setOpen(true);
    };

//close mortgage detail popup which one use for add new mortgage detail
    const handleClose = () => {
        setBankName('')
        setApr({})
        setOpenClose({})
        setSpecialOffer({})
        setTerm({})
        setType({})
        setMonth({})
        setOpen(false);
        setcomponetopen([''])
    };

    //user add multiple detail in Mortgage bank then user wants to delete detail then invoke this function
    const handleDeleteBankDetail = (event) => {
        if (AddNewString === "AddNewCase") {
            // var arr = componetopen.filter(i => [i] !== event)
            var arr = componetopen.filter(i => componetopen.indexOf(i) !== event)
            setcomponetopen(arr)
            // var data = {
            //     isDelete: true,
            //     arr: arr
            // }
            // MortgageUpdate(data)
        }
        else {
            var arr = componetopen.filter(i => i._id !== event)
            // setcomponetopen(arr)
            var data = {
                isDelete: true,
                arr: arr
            }
            MortgageUpdate(data)
        }

        // if (event !== componetopen[event]) {
        //     // const items = componetopen.filter(item =>(console.log("item[event] 272",item), item[event] !== event))
        //     componetopen.splice(event, 1);
        //     console.log("272 items", componetopen)
        //     setcomponetopen(componetopen)
        // if(componetopen.length>=0){

        // }
        // }
    }



    // function useStateCallback(initialState) {
    //     const [state, setState] = useState(initialState);
    //     const cbRef = useRef(null);

    //     const setStateCallback = (state, cb) => {
    //       cbRef.current = cb; 
    //       setState(state);
    //     };

    //     useEffect(() => {
    //       if (cbRef.current) {
    //         cbRef.current(state);
    //         cbRef.current = null;
    //       }
    //     }, [state]);

    //     return [state, setStateCallback];
    //   }


    //edit list function 
   
   //detail of mortgage banks show data in table 
    function EditList(editLists) {
        if (editLists) {
            setIsEdit(true)
            setMortgageId(editLists.editlist._id)
            setcomponetopen(editLists.editlist.MortageDetails)
            setBankName(editLists.editlist.Bank)
            editLists && editLists.editlist && editLists.editlist.MortageDetails
                && editLists.editlist.MortageDetails.length > 0 && editLists.editlist.MortageDetails.map((i, j) => {
                    var years = Math.floor(i.Term);
                    var monthsVal = i.Term
                    monthsVal = monthsVal ? monthsVal.toString() : monthsVal
                    monthsVal = monthsVal ? monthsVal.split(".", 2) : monthsVal
                    var TermVal = Term
                    TermVal["term" + j] = years;
                    setTerm(TermVal)
                    var MonthVal = Month
                    MonthVal["month" + j] = monthsVal ? monthsVal[1] : monthsVal
                    setMonth(MonthVal)
                    var TypeVal = Type
                    TypeVal["type" + j] = i.Type
                    setType(TypeVal)
                    var OpenVal = OpenClose
                    OpenVal["open" + j] = i.OpenClose
                    setOpenClose(OpenVal)
                    var AprVal = Apr
                    AprVal["apr" + j] = i.APR
                    setApr(AprVal)
                    var SpecialVal = SpecialOffer
                    SpecialVal["specialOffer" + j] = i.SpecialOffer
                    setSpecialOffer(SpecialVal)
                })
            setOpen(true);
        }
    }


    //add new row
    var btnInceamtValue;
    const addRow = () => {
        // console.log("component 349", componetopen,AddNewString)
        for (var i = 0; i <= componetopen.length; i++) {
            setbtnIncrement(btnIncrement + 1)
            var aObj = {}
            aObj = parseInt(componetopen.length) + i
            setcomponetopen(parseInt(componetopen) + i)
            aArr = [...aArr, aObj]
            setcomponetopen(aArr)
            btnInceamtValue = btnIncrement + 1
            // console.log("setcompoent  358", componetopen)
        }
    }
  
    //Mortgage Save and update
    function MortgageUpdate(data) {
        // setcomponetopen(arr)
        // console.log("332 component open", data, componetopen)
        // setcomponetopen(arr)
        if (!BankName) {
            swal({
                text: "Please enter bank name",
                icon: "error",
            })
            return
        }
        var inputError = false
        componetopen.map((i, j) => {
            if (!Term["term" + j] || !Month["month" + j] || !Type["type" + j] ||
                !OpenClose["open" + j] || !SpecialOffer["specialOffer" + j] || !Apr["apr" + j]) {
                inputError = true
            }
        })

        if (inputError) {
            swal({
                text: "Please enter all required input. ",
                icon: "error",
            })
            return
        }
        setloadingSave(true)
        var MortArr = []
        if (data.isDelete === true) {
            // var MortArrs = []
            // console.log("Mortarr", MortArr)
            MortArr = data.arr && data.arr.length > 0 && data.arr.map((i, j) => {
                // console.log("i,j 390", i, j)
                // var monthYear = parseInt(Term["term" + j]) * 12 + parseInt(Month["month" + j])
                var monthYear = parseInt(i.Term) * 12 + parseInt(i.Month)
                var termInYear = parseFloat(monthYear / 12).toFixed(1);
                // }
                // var s = parseInt(Term["term" + j]) + "." + parseInt(Month["month" + j])
                // var s = parseInt(i.Term) + "." + parseInt(i.Month)
                var MortRateDetails = {}
                MortRateDetails = {
                    // "Term": s,
                    // "Type": Type["type" + j] ? Type["type" + j] : '',
                    // "OpenClose": OpenClose["open" + j] ? OpenClose["open" + j] : '',
                    // "SpecialOffer": SpecialOffer["specialOffer" + j] ? SpecialOffer["specialOffer" + j] : '',
                    // "APR": Apr["apr" + j] ? Apr["apr" + j] : ''
                    "Term": i.Term,
                    "Type": i.Type ? i.Type : '',
                    "OpenClose": i.OpenClose ? i.OpenClose : '',
                    "SpecialOffer": i.SpecialOffer ? i.SpecialOffer : '',
                    "APR": i.APR ? i.APR : ''
                }
                // console.log("MortRateDetails 403", MortRateDetails)
                return MortRateDetails
                // MortArr.push(MortRateDetails)
            })
        }
        else {
            componetopen && componetopen.length > 0 && componetopen.map((i, j) => {
                // console.log("409 componetopen i,j", i, j)
                var monthYear = parseInt(Term["term" + j]) * 12 + parseInt(Month["month" + j])
                var termInYear = parseFloat(monthYear / 12).toFixed(1);
                // }
                var s = parseInt(Term["term" + j]) + "." + parseInt(Month["month" + j])
                var MortRateDetail = {}
                MortRateDetail = {
                    "Term": s,
                    "Type": Type["type" + j] ? Type["type" + j] : '',
                    "OpenClose": OpenClose["open" + j] ? OpenClose["open" + j] : '',
                    "SpecialOffer": SpecialOffer["specialOffer" + j] ? SpecialOffer["specialOffer" + j] : '',
                    "APR": Apr["apr" + j] ? Apr["apr" + j] : ''
                }
                // console.log("MortRateDetail 422", MortRateDetail)
                MortArr.push(MortRateDetail)
            })
        }
        // console.log("422 mortArr", MortArr)
        var body = {
            "Bank": BankName,
            "MortageDetails": MortArr
        }
        if (IsEdit === true) {
            UpdateMortgageRate(MortgageId, body).then(res => {
                setIsEdit(false)
                swal({
                    title: "Update Mortgage Bank Detail",
                    text: "Update Successfully",
                    icon: "success",
                })
                store.update("MortgageRateDetail", res.data)
                handleClose()
                setcomponetopen([''])
                setloadingSave(false)
            })
        } else {
            MortgageRate(body).then(res => {
                swal({
                    title: "Save Mortgage Bank Detail",
                    text: "Save Successfully",
                    icon: "success",
                })
                store.update("MortgageRateDetail", res.data)
                handleClose()
                setcomponetopen([''])
                setloadingSave(false)
            })
        }

    }


    //set term
    var setTermfun = (e) => {
        var d = Term
        d[e.id] = e.value
        setTerm(d)
    }

    //set month
    var setMonthfun = (e) => {
        var d = Month
        d[e.name] = parseInt(e.value)
        setMonth(d)
    }

    //set type func
    const setTypefun = (e) => {
        var d = Type
        d[e.name] = e.value
        setType(d)
    }

    //open close func
    const setOpenClosefun = (e) => {
        var d = OpenClose
        d[e.name] = e.value
        setOpenClose(d)
    }

    //special offer state set
    const setSpecialOfferfun = (e) => {
        var d = SpecialOffer
        d[e.id] = e.value
        setSpecialOffer(d)
    }

    //set Apr function
    const setAprfun = (e) => {
        var d = Apr
        d[e.id] = e.value
        setApr(d)
    }

    //open modal for add new mortgage bank detail
    const modalbody = (
        <Grid className={classes.paper}>
            <span style={{ position: 'absolute', cursor: 'pointer', top: '15px', right: "15px" }} onClick={handleClose}><CloseIcon /></span>
            <h2 id="simple-modal-title">Mortgage Bank Detail</h2>
            <label >Bank Name</label>
            <Grid item xs={12} sm={12} md={12} className={classes.root}>
                <TextField
                    id="outlined-basic" variant="outlined" className={classes.root1}
                    value={BankName} onChange={e => setBankName(e.target.value)}
                />
            </Grid>
            <h2>Mortgage Rate Detail</h2>
            {componetopen && componetopen.map((i, j) => {
                return <div key={j}>
                    <Grid className={classes.root} container>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <label >Term</label>
                        </Grid>
                        <span defaultValue={j} onClick={() => AddNewString === "AddNewCase" ? handleDeleteBankDetail(j) : handleDeleteBankDetail(i._id)}><DeleteIcon /></span>
                        <Grid item xs={5} sm={5} md={5}  >
                            <TextField id={"term" + j} variant="outlined" className={classes.root1}
                                // value={Term["term" + j]}
                                type="number"
                                placeholder="year"
                                defaultValue={Term["term" + j]}
                                // onInput={saveBtnDisabled}
                                onChange={e => setTermfun(e.target)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}  >

                            <Select
                                className={classes.select}
                                variant="outlined"
                                defaultValue={Month["month" + j]}
                                onChange={e => setMonthfun(e.target)}
                                label="Month"
                                inputProps={{ name: "month" + j }}
                            >
                                <MenuItem disabled aria-label="None" value="" >None</MenuItem >
                                <MenuItem value="0">00</MenuItem >
                                <MenuItem value="1">01</MenuItem >
                                <MenuItem value="2">02</MenuItem >
                                <MenuItem value="3">03</MenuItem >
                                <MenuItem value="4">04</MenuItem >
                                <MenuItem value="5">05</MenuItem >
                                <MenuItem value="6">06</MenuItem >
                                <MenuItem value="7">07</MenuItem >
                                <MenuItem value="8">08</MenuItem >
                                <MenuItem value="9">09</MenuItem >
                                <MenuItem value="10">10</MenuItem >
                                <MenuItem value="11">11</MenuItem >
                                {/* <MenuItem value="12">12</MenuItem > */}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}  >
                            <label >Type</label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Select
                                className={classes.select}
                                variant="outlined"
                                defaultValue={Type["type" + j]}
                                onChange={e => setTypefun(e.target)}
                                label="Open/Close"
                                inputProps={{ name: "type" + j }}
                            >
                                <MenuItem disabled aria-label="None" value="" >None</MenuItem >
                                <MenuItem value={"Fixed"}>Fixed</MenuItem >
                                <MenuItem value={"Variable"}>Variable</MenuItem >

                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <label >Open/Close</label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Select
                                className={classes.select}
                                variant="outlined"
                                defaultValue={OpenClose["open" + j]}
                                onChange={e => setOpenClosefun(e.target)}
                                label="Open/Close"
                                inputProps={{ name: "open" + j }}
                            >
                                <MenuItem disabled aria-label="None" value="" >None</MenuItem>
                                <MenuItem value={"Open"}>Open</MenuItem>
                                <MenuItem value={"Closed"}>Closed</MenuItem>
                                {/* <MenuItem value={"Convertible"}>Convertible</MenuItem > */}

                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <label >SpecialOffer</label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className={classes.root1}>
                            <TextField id={"specialOffer" + j} variant="outlined" className={classes.root1}
                                // value={SpecialOffer["specialOffer" + j]}
                                defaultValue={SpecialOffer["specialOffer" + j]}
                                type="number"
                                onChange={e => setSpecialOfferfun(e.target)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <label >APR</label>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className={classes.root1}>
                            <TextField id={"apr" + j} variant="outlined" className={classes.root1}
                                // value={Apr["apr" + j]}
                                type="number"
                                defaultValue={Apr["apr" + j]}
                                // onInput={saveBtnDisabled}
                                onChange={e => setAprfun(e.target)}
                            />
                        </Grid>
                    </Grid>
                    <hr />
                </div>
            })
            }
            <Grid container spacing={3} >
                <Grid item md={4} >
                    <Button variant="contained" color="secondary" onClick={MortgageUpdate} >
                        Save
                    </Button>
                </Grid>
                <Grid item md={8} item >
                    <Button variant="contained" color="primary" onClick={addRow}  >
                        Add More Mortgage Rate
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );

    //Add new mortgage design
    return (
        <div>
            <Button variant="contained" color="primary"
                style={{ top: "17px", float: 'right' }}
                onClick={handleOpen}>
                Add New
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {modalbody}
            </Modal>
        </div>
    );
}
