import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

// import AddToBrowser from '../../assest/AddToBrowser.gif';
// import RealtorSites from '../../assest/RealtorSites.png';
// import AddToFavorite from '../../assest/AddToFavorite.gif';
// import MortgageCalculator from '../../assest/MortgageCalculator.png';
// import DashboardNew from '../../assest/DashboardNew.png';

import AddToBrowser from '../../assest/corousalAssets/addDasshh.gif';
import RealtorSites from '../../assest/corousalAssets/realtorsitess.png';
import AddToFavorite from '../../assest/corousalAssets/addFav.gif';
import MortgageCalculator from '../../assest/corousalAssets/Mortgage-Calculatorrr.png';
import DashboardNew from '../../assest/corousalAssets/PropertyBoarddd.png';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Remax from '../../assest/Remax.png';
import RealtorCa from '../../assest/RealtorCa.png';
import RoyalPage from '../../assest/RoyalPage.png';

// How its work slider on property board
const CoursalSlider = () => {
    const [ImgVal, setImgVal] = useState(0)
    const [seconds, setSeconds] = useState(0);

    const useStyles = makeStyles(theme => ({
        flyout: {
            marginTop: '5px',
            maxWidth: '1250px',
            margin: '0 auto',
        },
        logoImg: {
            maxWidth: '80%',
            margin: '0 auto'
        },
    }));

    const classes = useStyles();

    const slides = [
        { id: 1, title: 'Install WyzeGuide on your browser', description: '' },
        {
            id: 2, title: 'Visit these leading Canadian real estate sites', description:
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <a href='https://www.realtor.ca/' style={{ marginRight: '15px' }}><Paper elevation={3} style={{ maxWidth: '90px', height: '50px', display: 'flex', alignItems: 'center' }}> <img className={classes.logoImg} src={RealtorCa} /></Paper></a> <br />
                    <a href='https://www.remax.ca/' style={{ marginRight: '15px' }}><Paper elevation={3} style={{ maxWidth: '90px', height: '50px', display: 'flex', alignItems: 'center' }}> <img className={classes.logoImg} src={Remax} /></Paper></a> <br />
                    <a href="https://www.royallepage.ca/en/" style={{ marginRight: '15px' }}><Paper elevation={3} style={{ maxWidth: '90px', height: '50px', display: 'flex', alignItems: 'center' }}> <img className={classes.logoImg} src={RoyalPage} /></Paper></a>
                </div>
        },
        { id: 3, title: 'Find and favorite a property you like', description: <><p>WyzeGuide’s favourite button  <img width="18px" src='https://i.imgur.com/suVUvnO.png'></img> <img width="18px" src='https://i.imgur.com/NXEXy2P.png'></img>  will automatically appear on the right edge of the browser</p></> },
        { id: 4, title: "Calculate your mortgage payment with WyzeGuide's mortgage calculator.", description: 'WyzeGuide has the latest mortage rates.  In the fly-out or Property Board add your down payment, select a mortgage rate from top banks, calculate your mortgage payments and closing costs' },
        { id: 5, title: 'Visit your WyzeGuide Property Board to share and rank properties', description: <p>Review all your saved properties, share with family and friends, compare, rank and decide which property is best for you.<br />Answer these questions by completing your profile:<ul style={{ paddingLeft: '20px', margin: '0' }}><li>Is my dream home affordable? </li><li> How far is my dream home from important locations?</li></ul></p> },
        // { title: 'Decide', description: 'Wyzeguide, an artificial intelligence bot will help rank order your properties and make the difficult decision simpler and easier' }
    ];

    //List of images
    const ImgSlider = [
        { img: AddToBrowser },
        { img: RealtorSites },
        { img: AddToFavorite },
        { img: MortgageCalculator },
        { img: DashboardNew },
    ]

    //change img in how its work 
    const setImgValfun = (e) => {
        setImgVal(e)
        setSeconds(e + 1);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setImgVal(seconds)
            setSeconds(seconds => seconds + 1);
        }, 5800);
        if (seconds > 4) {
            setSeconds(0)
        }
        return () => clearInterval(interval);
    }, [seconds]);
    return (
        <>
            <Grid container item xs={12} sm={7} md={7} lg={7} xl={7} justify="center" className="corousalBG" >
                {ImgSlider.map((slide, index) => <div key={index} className="corousalInn" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item style={{maxHeight: '610px', objectFit: 'cover'}}>
                        <img src={slide.img} alt="Avatar"
                            style={{
                                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                width: '100%',
                                display: ImgVal === index ? '' : 'none'
                            }}
                            id={index}
                        />
                    </Grid>
                </div>)}
            </Grid>
            <Grid xs={12} sm={5} md={5} lg={5} xl={5} container item spacing={2} style={{paddingLeft:'20px'}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="">
                    {slides.map((slide, index) => <div key={index} style={{ direction: 'ltr' }}>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                            <div className="slider_work" style={{
                                cursor: 'pointer',
                                opacity: ImgVal === index ? 1 : 0.7
                            }}
                                onClick={() => setImgValfun(index)}
                                defaultValue={index} id={index}><strong style={{ color: 'red', float: 'left', fontSize: '16px' }}>{slide.id}. {slide.title}</strong>
                                <br />
                                <p style={{marginTop: '30px'}}> {slide.description}</p></div>
                        </Grid>
                    </div>)}
                </Grid>
            </Grid>
        </>
    )
}
export default CoursalSlider;