import React from 'react'
import SocialButton from '../../components/SocialButton';
import GoogleLogin from 'react-google-login';
import fbIcon from '../../assest/facebook.png'

export default function SocialLogin({ GLogin, FBLogin }) {
 //handle error of facebook
  const handleSocialLoginFailure = (err) => {
    console.error(err)
  }
  //both google and facebook buttons
  return (
    <React.Fragment>
      <div className="FBLogin">
        <SocialButton
          provider='facebook'
          // appId='750946568989297'
          appId='484342435846196'
          onLoginSuccess={FBLogin}
          onLoginFailure={handleSocialLoginFailure}
        >
          <img src={fbIcon} style={{
            position: 'absolute',
            height: "21px",
            marginTop: "-2px",
            width: "23px",
            marginLeft: "10px"
          }} /> <span style={{ marginLeft: '45px' }}>Continue with Facebook</span>
        </SocialButton>
      </div>
      <div className="GoogleLogin">
        <GoogleLogin
          clientId="21835537100-3b7a273f29dp519ci81hm9c2apmdf3ii.apps.googleusercontent.com"
          buttonText="Continue with Google"
          onSuccess={GLogin}
          onFailure={GLogin}
          cookiePolicy={'single_host_origin'}
        />
      </div>
    </React.Fragment>
  )
}