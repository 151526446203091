// import Login from '../Login/index';
// import Signup from '../Signup';
import Landing from '../LandingPage';
import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from '../../App';
import Home from '../Home';
import EditProfile from '../EditProfile';
import MonthlyExpense from '../MonthlyExpenses';
import CompareProperty from '../ComparisonProperties';
import ChangePassword from '../ChangePassword';
import Extension from '../../components/Extension'
import ForgotPassword from '../ForgotPasssword';
import PropertyDetail from '../PropertyDetail';
import TokenVerification from '../CollaborationInviteToken';
import MortgageDetailTable from '../MortgageRateDetials';

class Routers extends Component {
  render() {
//set routing of web site
    return (
      <BrowserRouter>
        <div>
          <Suspense fallback={<div>loading...</div>}>
            <Switch>
              <Route path="/" component={Landing} exact />
              <Route path="/auth" component={App} />
              <Route path="/login" component={Extension} />
              <Route path="/register" component={() => <Extension isSignupRoute={true} />} />
              <Route path='/forgotPassword' component={ForgotPassword} />
              <Route path="/changePassword" component={ChangePassword} />
              <Route path="/extension" component={Extension} />
              <Route path='/invitation' component={TokenVerification}></Route>
              <Route path="/home" component={Home} />
              <Route path="/profileSetting" component={EditProfile} />
              <Route path="/budget" component={MonthlyExpense} />
              <Route path="/comparisonProperties" component={CompareProperty} />
              <Route path='/detailProperty' component={PropertyDetail} />
              <Route path='/MortgageDetail' component={MortgageDetailTable}></Route>
            </Switch>
          </Suspense>
        </div>
      </BrowserRouter>
    );
  }
}

export default Routers;