import React, { Suspense, useState, useEffect } from 'react';
import { Container, Grid, GridList, TextField, Divider, Table, Button, TableBody, TableCell, CircularProgress, Switch } from '@material-ui/core';
import { getUser, getAccessToken } from '../../const/function';
import { inject, observer } from 'mobx-react';
import store from '../../store';
import img1 from '../../assest/img_1.png';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import color from '@material-ui/core/colors/amber';
const Header = React.lazy(() => import('../Header'));
const Footer = React.lazy(() => import('../Footer'));

const useStyles = makeStyles(theme => ({

    txtColor: {
        color: 'grey',
    },
    dec: {
        marginBlockStart: '0',
        marginBlockEnd: '0',
    },
    title: {
        marginBlockEnd: '0.3em',
    },
    root: {
        maxWidth: 600,
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        padding: 'revert'
        // width:'100%'
    },
    contentBar: {
        width: '99%',
        margin: '1px',
        marginTop: '1px',
        marginLeft: '8px',
        marginRight: '12px!important',
        paddingTop: '16px',
        paddingRight: '2px',
        paddingBottom: '11px',
        backgroundColor: 'snow',
        height: '30px',
        borderRadius: '4px',
        color: '#000',
    },
    paddingClass: {
        padding: '0px!important',
    },
    imgClass: {
        margin: theme.spacing(1),
        height: '180px!important',
        width: '180px!important',
        overflow: 'hidden',
        display: 'block',
        cursor: 'pointer',
        // width: '100%',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    table: {
        minWidth: 700,
    },
    tabledesign: {
        // marginLeft: '61px',
        // marginRight: '6px',
    },
}))

//compare property component
function CompareProperty(propValues) {
    const [propData, setpropData] = useState([])
    const [proplist, setproplist] = useState([]);
    const [newprop, setnewprop] = useState([]);
    const [propCount, setpropCount] = useState(0);
    const [loading, setloading] = useState(true);
    const [PRank, setPRank] = useState([])
    const [BedRank, setBedRank] = useState([])
    const [BathRank, setBathRank] = useState([])
    const [PropRank, setPropRank] = useState([])
    const [RankWeights, setRankWeights] = useState([])
    const [CommonRankProperty, setCommonRankProperty] = useState([])
    const [emptyState, setEmptyState] = useState()
    const [propertyDataList, setpropertyDataList] = useState([])
    const [NetPays, setNetPays] = useState(0)
    var storePropValue;
    var data1 = []
    var globalPoropVal;

    // get property data and properties count
    useEffect(() => {
        // setpropData([])
        propValues.history.location.state && propValues.history.location.state.map((i, k) => {
            // var data = proplist
            var data = propertyDataList

            data.push(i)
            // setproplist(data)
            setpropertyDataList(data)
            // if (data.length === propValues.history.location.state && propValues.history.location.state.length) {
            const arrayUniqueByKey = [...new Map(propertyDataList.map(item =>
                [item[2], item])).values()];
            setproplist(arrayUniqueByKey)
            // console.log("102",arrayUniqueByKey.length)
            setpropCount(arrayUniqueByKey.length)
            // }


        })

        storePropValue = propValues.store.data.Properties
        // store.update("Properties", propValues.store.data.Properties)
        setpropCount(proplist.length)

        if (!store.data.User) {
            getUser().then(data => {
                store.update("User", data);
                //  profileData()
                if (data != undefined) {
                    if (data.province) {
                        store.update("Province", data.province)
                    }
                    if (data.properties) {
                        var properties = {}
                        var property = []
                        data && data.collaboration && data.collaboration.length > 0
                            && data.collaboration.map((i, j) => {
                                properties = {}
                                i.properties && i.properties.length > 0 && i.properties.map((j, k) => {
                                    properties = j
                                    property.push(properties)
                                })
                            })
                        store.update("Properties", data.properties.concat(property))
                    }
                }
            })
        }
    }, [propValues])

    //property count and call profileData function when change proplist and propValues
    useEffect(() => {
        // setpropData([])
        setpropCount(proplist.length)
        profileData()
    }, [propValues, proplist])

    //profileData handle properties data
    function profileData() {
        data1 = []
        var a = { propData }
        var b = { data1 }
        if (store.data && store.data.Properties.length > 0) {
            store.data && store.data.Properties.length > 0 && store.data.Properties.map((i, k) => {
                if (i._id) {
                    proplist.map((a, b) => {
                        if (i._id === a[0]) {
                            if (i.Mortgage.askingPrices != undefined || i.Mortgage.askingPrices != null) {
                                i.Property.Price = "$" + i.Mortgage.askingPrices.toLocaleString();
                            }
                            data1.push(i)
                        }

                    })
                }
            })
            setNetPays(store.data)
            setloading(false)
            var aVal = { data1 }
            if (aVal && aVal.propData && aVal.propData.length > 0) {
                // handlePropData(data1)
                setpropData(aVal.propData)
                setnewprop(aVal.propData)
                handlePropData(aVal.propData)
            } else {
                // handlePropData(data1)
                setpropData(data1)
                setnewprop(data1)
                handlePropData(data1)
            }

        }
        else {
            setpropData(a.propData)
            setnewprop(a.propData)

            // setloading(false)
            // handlePropData(data1)
            // setpropData(data1)
            // setnewprop(data1)
            handlePropData(a.propData)
            if (a.propData.length <= 0) {
                //get user detail
                getUser().then(data => {
                    store.update("User", data);
                    //  profileData()
                    if (data != undefined) {
                        // if (data.province) {
                        //     store.update("Province", data.province)
                        // }
                        if (data.properties) {
                            var properties = {}
                            var property = []
                            data && data.collaboration && data.collaboration.length > 0
                                && data.collaboration.map((i, j) => {
                                    properties = {}
                                    i.properties && i.properties.length > 0 && i.properties.map((j, k) => {
                                        properties = j
                                        property.push(properties)
                                    })
                                })
                            // UpdatedPropertyList = data.properties.concat(property)
                            if (proplist.length > 0) {
                                data.properties.concat(property).map((i, k) => {
                                    if (i._id) {
                                        // console.log("213 proplist", proplist)
                                        proplist.map((a, b) => {
                                            if (i._id === a[0]) {
                                                if (i.Mortgage.askingPrices != undefined || i.Mortgage.askingPrices != null) {
                                                    i.Property.Price = "$" + i.Mortgage.askingPrices.toLocaleString();
                                                }
                                                data1.push(i)
                                            }
                                            // console.log("205",objdata)
                                            // data1.push(objdata)
                                        })
                                    }
                                })
                            }
                            else {
                                var updatedlist;
                                propValues.history.location.state && propValues.history.location.state.map((i, k) => {
                                    var data = propertyDataList
                                    data.push(i)
                                    setpropertyDataList(data)
                                    const arrayUniqueByKey = [...new Map(propertyDataList.map(item =>
                                        [item[2], item])).values()];
                                    setproplist(arrayUniqueByKey)
                                    updatedlist = arrayUniqueByKey
                                    setpropCount(arrayUniqueByKey.length)
                                })
                                data.properties.concat(property).map((i, k) => {
                                    if (i._id) {
                                        updatedlist.map((a, b) => {
                                            if (i._id === a[0]) {
                                                if (i.Mortgage.askingPrices != undefined || i.Mortgage.askingPrices != null) {
                                                    i.Property.Price = "$" + i.Mortgage.askingPrices.toLocaleString();
                                                }
                                                data1.push(i)
                                            }
                                            // data1.push(objdata)
                                        })
                                    }
                                })
                            }
                            handlePropData(data1)
                            setpropData(data1)
                            setnewprop(data1)
                            // console.log("227", data1)
                            store.update("Properties", data.properties.concat(property))
                        }
                    }
                })
            }

        }

    }

    // const profileData = () => {
    //     var data1 = propData
    //     console.log("124", propData)
    //     if (propData.length <= 0) {
    //         console.log("126*", store.data.Properties, propData, propData.length)
    //         if (store.data && store.data.Properties.length > 0) {
    //             store.data && store.data.Properties.length > 0 && store.data.Properties.map((i, k) => {
    //                 if (i._id) {
    //                     proplist.map((a, b) => {
    //                         console.log("aa132**,", i._id, a)
    //                         if (i._id === a[0]) {
    //                             console.log("128**,", i, i.Property.Price, i.Mortgage.askingPrices)
    //                             if (i.Mortgage.askingPrices != undefined || i.Mortgage.askingPrices != null) {
    //                                 i.Property.Price = "$" + i.Mortgage.askingPrices.toLocaleString();
    //                             }
    //                             data1.push(i)
    //                         }

    //                     })
    //                 }
    //             })
    //         }
    //         else {
    //             var UpdatedPropertyList;
    //             getUser().then(data => {
    //                 store.update("User", data);
    //                 //  profileData()
    //                 if (data != undefined) {
    //                     // if (data.province) {
    //                     //     store.update("Province", data.province)
    //                     // }
    //                     console.log("data156", data)
    //                     if (data.properties) {
    //                         var properties = {}
    //                         var property = []
    //                         data && data.collaboration && data.collaboration.length > 0
    //                             && data.collaboration.map((i, j) => {
    //                                 properties = {}
    //                                 i.properties && i.properties.length > 0 && i.properties.map((j, k) => {
    //                                     properties = j
    //                                     property.push(properties)
    //                                 })
    //                             })
    //                             console.log("data.properties.concat(property)",data.properties.concat(property))
    //                         UpdatedPropertyList = data.properties.concat(property)
    //                         store.update("Properties", data.properties.concat(property))
    //                     }
    //                 }
    //             })
    //             console.log("data174", store, UpdatedPropertyList)

    //             // if (store.data && store.data.Properties.length > 0) { 
    //             window.store.data && window.store.data.Properties.length > 0 && window.store.data.Properties.map((i, k) => {
    //                 if (i._id) {
    //                     proplist.map((a, b) => {
    //                         console.log("aa182**,", i._id, a)
    //                         if (i._id === a[0]) {
    //                             console.log("182**,", i, i.Property.Price, i.Mortgage.askingPrices)
    //                             if (i.Mortgage.askingPrices != undefined || i.Mortgage.askingPrices != null) {
    //                                 i.Property.Price = "$" + i.Mortgage.askingPrices.toLocaleString();
    //                             }
    //                             data1.push(i)
    //                         }

    //                     })
    //                 }
    //             })
    //             // }
    //         }
    //     } else {
    //         console.log("141**", propData)
    //         propData.map((i, j) => {
    //             console.log("142", i)
    //             if (i.Mortgage.askingPrices != undefined || i.Mortgage.askingPrices != null) {
    //                 i.Property.Price = "$" + i.Mortgage.askingPrices.toLocaleString();
    //             }
    //             data1.push(i)
    //         })
    //     }
    //     setloading(false)
    //     console.log("152", data1)
    //     // handlePropData(data1)
    //     setpropData(data1)
    //     console.log("154", propData)
    //     handlePropData(propData)
    // }

    //handle property data
    const handlePropData = (propData) => {

        globalPoropVal = { propData }
        setnewprop(globalPoropVal.propData)
        if (globalPoropVal.propData.length > 0) {
            setloading(false)
            setEmptyState(globalPoropVal.propData)
            return (setnewprop(globalPoropVal.propData))
        } else {
            var as = { propData }
            return (setnewprop(as.propData))
        }
    }

    useEffect(() => {
        setEmptyState(newprop)
    }, [newprop, propData, globalPoropVal])

    useEffect(() => {
    }, [emptyState])

    //common rank of properties
    function CommonRankShow(a) {
        setCommonRankProperty(a)
    }

    const classes = useStyles();
    const history = useHistory()
    const getArryfitervalue = (arr) => {
        var as = { arr }
        return as.arr
    }
    return (
        <Suspense fallback={<div>loading...</div>}>
            <Container spacing={2} xs={12} sm={12} md={12} lg={12} xl={12} style={{ width: '1300px' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} style={{ backgroundColor: 'white' }} spacing={2}>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button variant="outlined" onClick={() => { history.push('/home') }} style={{ margin: "100px 0px 0px 0px", cursor: 'pointer' }} color="primary">Go to Property Board</Button>
                        <p style={{ marginLeft: '70px', fontSize: '3ch' }}>
                            Compare Properties</p></Grid>

                    {/* <Grid container xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '10px' }} spacing={2}>
                        <Grid xs={3} sm={3} md={3} lg={3} xl={3} item spacing={2}>
                            <PropertyMenu propCount={propCount} />
                        </Grid>
                        {newprop && newprop.map((list, k) => {
                            return (
                                newprop && newprop.length < 3 ?
                                    <Grid container item key={k} xs={4} sm={4} md={4} lg={4} xl={4} spacing={2}>
                                        <ImgDetail weblink={list}
                                            img={list.Property && list.Property.Photo.length > 0 && list.Property.Photo[0].HighResPath ? list.Property.Photo[0].HighResPath : ''}
                                            title={list.title} des={list.Property.Address.AddressText} />
                                    </Grid>
                                    :
                                    <Grid container item key={k} xs={3} sm={3} md={3} lg={3} xl={3} spacing={2}>
                                        <ImgDetail weblink={list}
                                            img={list.Property && list.Property.Photo.length > 0 && list.Property.Photo[0].HighResPath ? list.Property.Photo[0].HighResPath : ''}
                                            title={list.title} des={list.Property.Address.AddressText} />
                                    </Grid>
                            )
                        })}
                    </Grid>
                  */}
                    {loading && <div style={{
                        position: 'fixed',
                        zIndex: 20000,
                        height: '100%',
                        background: "#0000004D",
                        opacity: 1,
                        // backgroung:'rgba(0, 0, 0.2, 0.2)',
                        // hsla(240, 100%, 0%, 0.2)
                        width: "100%",
                        textAlign: "center",
                        paddingTop: "30vmin",
                        left: '8px'
                    }}>
                        <CircularProgress size={30} />
                    </div>}

                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.paddingClass}>
                        <ContentBar barName='Overall Rank' commonRank={CommonRankProperty} newprop={newprop} />
                    </Grid> */}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.tabledesign}>
                        <CustomizedTables newprop={newprop ? newprop : getArryfitervalue(newprop)} CommonRankShow={CommonRankShow} propCount={propCount} commonRank={CommonRankProperty} NetPays={NetPays} /></Grid>

                    <Grid container >
                        <Grid item xs={4} sm={4} md={5} lg={5} xl={4}></Grid>
                        <Grid item xs={8} sm={8} md={7} lg={7} xl={8}><Footer /></Grid>
                    </Grid>
                </Grid>
            </Container>
        </Suspense>
    )
}
export default inject("store")(observer(CompareProperty));

//Left side Property Label and count
const PropertyMenu = ({ propCount }) => {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <p className={classes.txtColor}>{propCount} Property</p>
            </Grid>
        </Grid>
    );
}

//Image Detail display img
const ImgDetail = ({ weblink, img, title, des }) => {
    const classes = useStyles();
    return (
        <GridList item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.root}  >
            <img
                onClick={() => window.open(weblink.Weblink, '_blank')}
                className={classes.imgClass}
                src={img}
                alt={"tutorialSteps[activeStep].label"}
            />
            <div style={{ width: '100%', textAlign: '-webkit-center', position: 'relative', height: 'auto!important' }}>
                <h4 className={classes.title}> {title} </h4>
                <p className={classes.dec}>{des} </p>
            </div>
        </GridList>
    );
}

//OverAll Rank OrderBar
const ContentBar = ({ barName, commonRank, newprop }) => {
    const [IndexArra, setIndexArra] = useState([])
    const classes = useStyles();
    var lastrank;

    useEffect(() => {
        a()
    }, [commonRank, newprop])
    var b = [];
    var rankcomprop = [];

    function a() {
        var comMainArr = [];
        var comObj = {};
        var comRnk = {};
        var mRanknew = []
        var newComRnk = {}
        var rnkCom = []
        if (commonRank.length === 0) {
            newprop.forEach((value, i) => {
                comObj = {}
                // comObj['value' + parseInt(i + 1)] = value && value.Mortgage && value.Mortgage.Scenarios[0] && value.Mortgage.Scenarios[0].totalMortgageRequired ? value.Mortgage.Scenarios[0].totalMortgageRequired : 0;
                comObj['rank' + parseInt(i + 1)] = 0;
                comMainArr.push(comObj)
            })

        }

        commonRank = commonRank.CommonRankProp === undefined ? commonRank : commonRank.CommonRankProp
        for (var i = 0; i < commonRank.length; i++) {
            var sameRankArr = [];
            var sameRankObj = {};
            if (mRanknew.length == 0) {
                sameRankObj = {};

                sameRankObj = commonRank[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < commonRank.length; j++) {
                    sameRankObj = {};
                    if (commonRank[i].commanRank === commonRank[j].commanRank) {
                        sameRankObj = commonRank[j]
                        sameRankArr.push(sameRankObj)
                    }

                }

                mRanknew.push(sameRankArr);
            } else if (mRanknew.find(m => m.find(n => n.propId == commonRank[i].propId)) == null) {
                sameRankObj = {};
                sameRankObj = commonRank[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < commonRank.length; j++) {
                    if (commonRank[i].commanRank === commonRank[j].commanRank) {
                        sameRankObj = {};
                        sameRankObj = commonRank[j]
                        sameRankArr.push(sameRankObj)
                    }

                }
                mRanknew.push(sameRankArr)
            }
        }

        mRanknew.map((i, j) => {
            comRnk = {}
            if (i.length > 1) {
                for (var k = 1; k <= i.length; k++) {
                    comRnk = {}
                    comRnk['rank'] = j + 1;
                    comRnk['propId'] = i[k - 1].propId
                    rnkCom.push(comRnk);
                }
            }
            if (i.length === 1) {
                comRnk['rank'] = rnkCom.length + 1;
                comRnk['propId'] = i[0].propId
                rnkCom.push(comRnk);

            }

        })

        if (rnkCom.length === newprop.length) {
            let commonArrayColour = ['green', 'orange', 'red']
            newprop.map((value, j) => {
                newComRnk = {}
                var commonRank = rnkCom.find(m => m.propId === value._id).rank
                newComRnk['rankcom'] = rnkCom.find(m => m.propId === value._id).rank
                if (commonArrayColour.length >= commonRank)
                    newComRnk['bgColor'] = commonArrayColour[commonRank - 1];
                else
                    newComRnk['bgColor'] = "inherit";
                comMainArr.push(newComRnk)
                setIndexArra(comMainArr)
            })
        }
    }

    return (
        // <Grid className={classes.paddingClass} container>
        <React.Fragment>
            {/* <Divider orientation="horizontal" variant="inset" /> */}
            {/* <div className={classes.contentBar}> */}
            {/* <span style={{ color: 'black', marginLeft: '20px', fontSize: '22px' }}>{barName}</span> */}
            {/* <React.Fragment> */}
            {/* <Table style={{
                    color: 'black',
                    height: '19px',
                    padding: '9px',
                    display: 'inline-block',
                    width: '19px',
                    borderRadius: '50%',
                    textAlign: 'center',
                    marginLeft: IndexArra.length < 3 ? '329px' : '263px',
                    // backgroundColor: i.bgColor
                }} key={"some Ids"} > */}
            {/* <TableRow> */}
            <StyledTableCell>{barName}</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell style={{ borderLeft: 'none' }}></StyledTableCell>
            {IndexArra.length > 0 ? IndexArra.map((i) => {
                return (
                    <React.Fragment>
                        <StyledTableCell>
                            <span
                                style={{
                                    textAlign: 'center',
                                    color: 'black',
                                    height: '19px',
                                    padding: '9px',
                                    display: 'inline-block',
                                    width: '19px',
                                    borderRadius: '50%',
                                    // textAlign: 'center',
                                    marginLeft: IndexArra.length < 3 ? '62px' : '68px',
                                    backgroundColor: i.bgColor
                                }}>{i.rankcom}</span>
                        </StyledTableCell>
                        <StyledTableCell style={{ borderLeft: 'none' }}></StyledTableCell></React.Fragment>)
                // </span>

            })
                : ""
            }
            {/* </TableRow> */}
            {/* </Table > */}
            {/* </React.Fragment> */}

            {/* </div> */}
        </React.Fragment>)
}

// <span style={{
//     color: 'black',
//     height: '19px',
//     padding: '9px',
//     display: 'inline-block',
//     width: '19px',
//     borderRadius: '50%',
//     textAlign: 'center',
//     marginLeft: IndexArra.length < 3 ? '329px' : '263px',
//     backgroundColor: i.bgColor
// }} key={"some Ids" + i}>


// {/* </div > */}
// </Grid >



const PropertyDetail = ({ Bedroom, Bathroom, Balcony, Ownership, Carparking, Facing, Basement, SizeInterior, SizeTotal }) => {

    const classes = useStyles();
    return (
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} className={classes.paddingClass}>

            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <p><span className={classes.txtColor}>Bedroom:</span> {Bedroom}</p>
                <p><span className={classes.txtColor}>Balcony:</span> {Balcony ? Balcony : "No"}</p>
                <p><span className={classes.txtColor}>Car parking:</span> {Carparking ? Carparking : "No"}</p>
                <p><span className={classes.txtColor}>Basement:</span> {Basement ? Basement : "No"}</p>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <p><span className={classes.txtColor}>Bathroom:</span> {Bathroom}</p>
                <p><span className={classes.txtColor}>Facing:</span> {Facing}</p>
                {/* <p><span className={classes.txtColor}>Interiro Area:</span> {SizeInterior ? SizeInterior : "N/A"}</p> */}
                <p><span className={classes.txtColor}>Area:</span> {SizeInterior ? SizeInterior : "N/A"}</p>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}> */}
            {/* <p><span className={classes.txtColor}>Total Land Area:</span> {SizeTotal ? SizeTotal : "N/A"}</p> */}
            <p style={{ marginTop: '-1px' }}><span className={classes.txtColor}>Ownership:</span>{Ownership ? Ownership : "No"}</p>
            {/* </Grid>  */}
        </Grid>
    )
}

// common input field
const InputField = ({ Value, onChange }) => {

    return (
        <TextField
            // value={Value === undefined || Value === '' ? 0 : (Value).toLocaleString()}
            value={Value}
            style={{ width: '100%' }}
            margin="dense"
            onChange={(e) => onChange(e.target.value)}
            type="number"
            onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3).replace(/[^0-9]/g, '')
            }}
        />
    )
}

// toggle button
const SwitchCheck = ({ checked, onChange }) => {
    return (
        <p style={{ margin: '0px' }}> L<Switch checked={checked} onChange={(e) => onChange(e.target.value)} />H</p>
    )
}

//style table cells 
const StyledTableCell = withStyles((theme) => ({
    root: {
        fontFamily: 'inherit !important',
        borderLeft: '1px solid #ddd',
        padding: '6px!important'
    },
    head: {

        // backgroundColor: theme.palette.common.black,
        backgroundColor: 'snow',
        // opacity: 0.5,
        // color: theme.palette.common.white,
        color: '#000',
        fontSize: '16px',
        textAlign: "center"
    },
    body: {
        fontSize: '16px',
        textAlign: "center"
    },
}))(TableCell);

//style table row
const StyledTableRow = withStyles((theme) => ({
    root: {
        // '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
        backgroundColor: 'ghostwhite',
        color: theme.palette.common.white,
        fontFamily: 'inherit !important'
        // },
    },
}))(TableRow);

//Table data function create data
function createData(id, attribute, inputf, switchtoggle, value) {
    return { id, attribute, inputf, switchtoggle, value };
}

//table customized
function CustomizedTables({ newprop, CommonRankShow, propCount, commonRank, NetPays }) {
    const classes = useStyles();
    const [priceweight, setpriceweight] = useState(0)
    const [mortgageweight, setmortgageweight] = useState(0)
    const [mortgagepayweight, setmortgagepayweight] = useState(0)
    const [proptaxweight, setproptaxweight] = useState(0)
    const [bedroomweight, setbedroomweight] = useState(0)
    const [bathroomweight, setbathroomweight] = useState(0)
    const [balconyweight, setbalconyweight] = useState(0)
    const [areaweight, setareaweight] = useState(0)
    const [distanceweight, setdistanceweight] = useState(0)
    const [distance2weight, setdistance2weight] = useState(0)
    const [otherLocationweight, setotherLocationweight] = useState(0)
    const [otherLocationName, setotherLocationName] = useState([])
    const [totalweight, settotalweight] = useState(0)
    const [affordabilityWeight, setaffordabilityWeight] = useState(0)
    const [togglePrice, settogglePrice] = useState(false)
    const [toggleMortgage, settoggleMortgage] = useState(false)
    const [toggleMortgagePay, settoggleMortgagePay] = useState(false)
    const [togglePropTax, settogglePropTax] = useState(false)
    const [toggleBed, settoggleBed] = useState(false)
    const [toggleBath, settoggleBath] = useState(false)
    const [toggleBalc, settoggleBalc] = useState(false)
    const [toggleArea, settoggleArea] = useState(false)
    const [toggleDistance, settoggleDistance] = useState(false)
    const [toggleDistance2, settoggleDistance2] = useState(false)
    const [toggleOtherLocation, settoggleOtherLocation] = useState(false)
    const [toggleAffordability, settoggleAffordability] = useState(false)
    const [PRank, setPRank] = useState([])
    const [BedRank, setBedRank] = useState([])
    const [BathRank, setBathRank] = useState([])
    const [MortgageRank, setMortgageRank] = useState([])
    const [MortgagePayRank, setMortgagePayRank] = useState([])
    const [PropertyTaxRank, setPropertyTaxRank] = useState([])
    const [BalconyRank, setBalconyRank] = useState([])
    const [AreaRank, setAreaRank] = useState([])
    const [AffordabilityRank, setAffordabilityRank] = useState([])
    const [DistanceRank, setDistanceRank] = useState([])
    const [DistanceRank2, setDistanceRank2] = useState([])
    const [OtherLocationRank, setOtherLocationRank] = useState([])
    const [CommonRankProp, setCommonRankProp] = useState([])
    const [CommonRankPrice, setCommonRankPrice] = useState([])
    const [CommonRankBath, setCommonRankBath] = useState([])
    const [CommonRankMortgage, setCommonRankMortgage] = useState([])
    const [CommonRankMortgagePay, setCommonRankMortgagePay] = useState([])
    const [CommonRankPropertyTax, setCommonRankPropertyTax] = useState([])
    const [CommonRankBed, setCommonRankBed] = useState([])
    const [CommonRankBalcony, setCommonRankBalcony] = useState([])
    const [CommonRankArea, setCommonRankArea] = useState([])
    const [CommonRankAffordability, setCommonRankAffordability] = useState([])
    const [CommonRankDistance, setCommonRankDistance] = useState([])
    const [CommonRankDistance2, setCommonRankDistance2] = useState([])
    const [CommonRankOtherLocation, setCommonRankOtherLocation] = useState([])
    const [netpay, setnetpay] = useState(0)
    const [monthlyexp, setmonthlyexp] = useState(0)
    // const [afford, setafford] = useState(0)
    var OtherLocationsName, bed, pri, bath, minPrice, maxBedroom, maxBathroom, mortgage, mortgageMM, mortgagepayMM, mortgagepay, propertytax, proptaxMM, afford, affordMM, balcony, balconyMM, distance, distanceMM, distance2, distance2MM, otherLocation, otherLocationMM, area, areaMM, maxKitchen, maxGarage, RankProperty, bedweights, bathweights, priceweights, balconyweights;

    //montly expenses and net pay
    useEffect(() => {
        setnetpay(NetPays.User && NetPays.User.monthlyExpense && NetPays.User.monthlyExpense.netPayPerMonth)
        // setnetpay(window.store.data.User && window.store.data.User.monthlyExpense && window.store.data.User.monthlyExpense.netPayPerMonth)
        setmonthlyexp(NetPays.User && NetPays.User.monthlyExpense && NetPays.User.monthlyExpense.totalMontlyExpense)

    }, [NetPays])

    useEffect(() => {
    }, [newprop])

    // set total weight 
    useEffect(() => {
        var setweights = (Number(priceweight) + Number(mortgageweight) + Number(mortgagepayweight) + Number(proptaxweight) + Number(affordabilityWeight)
            + Number(bedroomweight) + Number(bathroomweight) + Number(balconyweight) + Number(areaweight) + Number(distanceweight) + Number(distance2weight) + Number(otherLocationweight))
        settotalweight(setweights)
        if (setweights > 100) {
            sweetpopup()
        }
        else {
            if (Number(priceweight) > 0 && togglePrice === false || togglePrice === true) {
                priceRankWeigthChange(priceweight)
            }
            if (Number(bedroomweight) > 0 && toggleBed === false || toggleBed === true) {
                bedroomRankWeigthChange(bedroomweight)
            }
            if (Number(bathroomweight) > 0 && toggleBath === false || toggleBath === true) {
                bathroomRankWeigthChange(bathroomweight)
            }
            if (Number(mortgageweight) > 0 && toggleMortgage === false || toggleMortgage === true) {
                mortgageRankWeigthChange(mortgageweight)
            }
            if (Number(mortgagepayweight) > 0 && toggleMortgagePay === false || toggleMortgagePay === true) {
                mortgagePayRankWeigthChange(mortgagepayweight)
            }
            if (Number(proptaxweight) > 0 && togglePropTax === false || togglePropTax === true) {
                propertyTaxRankWeigthChange(proptaxweight)
            }
            if (Number(balconyweight) > 0 && toggleBalc === false || toggleBalc === true) {
                balconyRankWeigthChange(balconyweight)
            }
            if (Number(distanceweight) > 0 && toggleDistance === false || toggleDistance === true) {
                distanceRankWeigthChange(distanceweight)
            }
            if (Number(distance2weight) > 0 && toggleDistance2 === false || toggleDistance2 === true) {
                distance2RankWeigthChange(distance2weight)
            }
            if (Number(areaweight) > 0 && toggleArea === false || toggleArea === true) {
                areaRankWeigthChange(areaweight)
            }
            if (Number(otherLocationweight) > 0 && toggleOtherLocation === false || toggleOtherLocation === true) {
                otherDistanceRankWeigthChange(otherLocationweight)
            }
            if (Number(affordabilityWeight) > 0 && toggleAffordability === false || toggleAffordability === true) {
                affordabilityRankWeigthChange(affordabilityWeight)
            }

        }
    }, [priceweight, mortgageweight, mortgagepayweight, proptaxweight
        , bedroomweight, bathroomweight, balconyweight, areaweight, distanceweight,
        distance2weight, otherLocationweight, affordabilityWeight,
        togglePrice, toggleBed, toggleBath, toggleMortgage, toggleMortgagePay, togglePropTax, toggleBalc,
        toggleDistance, toggleDistance2, toggleArea, toggleOtherLocation, toggleAffordability])

    //Error Popup
    function sweetpopup() {
        return (
            swal({
                title: "Error!",
                text: "please enter suitable value sum of all weights is less than equal to 100 !",
                icon: "error",
            })
        )
    }

    //Table Row cells
    const TableRowCells = ({ newprop }) => {
        return newprop && newprop.length > 0 ?
            newprop.map((i, j) => {
                return (
                    <React.Fragment key={j + "aa"}>
                        < StyledTableCell style={{ textAlign: "center" }} value={i}> Value</StyledTableCell >
                        < StyledTableCell style={{ align: "left", width: '1%', borderLeft: 'none' }} value={i} > Rank</StyledTableCell >
                        {/* < StyledTableCell value={i} ><span>Value</span><span>Rank</span> </StyledTableCell > */}
                    </React.Fragment>
                )
            }) : []
    }

    //colour use for values according to rank order
    var ValueColour = ['green', 'orange', 'red']
    //Table Cells set rank values
    const TableCellValues = ({ row }) => {
        return row && row.value && row.value.length > 0 ?
            row.value.map((i, j) => {
                return (
                    <React.Fragment key={j + "aa"}>
                        <StyledTableCell
                            style={{
                                textAlign: "center", backgroundColor: ValueColour[i["rank" + parseInt(j + 1)] - 1]

                            }}
                            value={i["value" + parseInt(j + 1)]}>{i["value" + parseInt(j + 1)]}</StyledTableCell>
                        <StyledTableCell value={i["rank" + parseInt(j + 1)]}>{i["rank" + parseInt(j + 1)]}</StyledTableCell>
                    </React.Fragment>
                )
            }) : []
    }

    //Price Comparison
    var commonpricerankpush = [];
    var priceCompare = (newprop, rnkArr) => {
        var prices = [];
        var rankPrices = [];
        var price = {}
        var newPrice = {}
        // var newprice = {};
        var pR = {};
        var fval;
        var pRanknew = []



        if (rnkArr === undefined || (rnkArr && rnkArr.length === 0)) {
            newprop && newprop.forEach((value, i) => {
                price = {}
                price['value' + parseInt(i + 1)] = value.Property.Price ? value.Property.Price : 0;
                price['rank' + parseInt(i + 1)] = 0;
                prices.push(price)
            })

        }
        if (rnkArr != undefined) {
            for (var i = 0; i < rnkArr.length; i++) {
                var pRs = {};
                var sameRankArr = [];
                var sameRankObj = {};
                if (pRanknew.length == 0) {
                    sameRankObj = {};

                    sameRankObj = rnkArr[i];
                    sameRankArr.push(sameRankObj);
                    for (var j = i + 1; j < rnkArr.length; j++) {
                        sameRankObj = {};
                        if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                            sameRankObj = rnkArr[j]
                            sameRankArr.push(sameRankObj)
                        }
                    }
                    pRanknew.push(sameRankArr);

                } else if (pRanknew.find(m => m.find(n => n.propId == rnkArr[i].propId)) == null) {
                    sameRankObj = {};
                    sameRankObj = rnkArr[i];
                    sameRankArr.push(sameRankObj);
                    for (var j = i + 1; j < rnkArr.length; j++) {
                        if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                            sameRankObj = {};
                            sameRankObj = rnkArr[j]
                            sameRankArr.push(sameRankObj)
                        }
                    }
                    pRanknew.push(sameRankArr)
                }
            }
        }
        if (pRanknew.length > 0) {
            if (pRanknew[0].togPrice == false) {
                pRanknew.sort((a, b) => {
                    return a[0].PriceCalc - b[0].PriceCalc
                })
            }
            else {
                pRanknew.sort((a, b) => {
                    return b[0].PriceCalc - a[0].PriceCalc
                })
            }
        }

        //set rank and prop id of price
        pRanknew.map((i, j) => {
            price = {}
            if (i.length > 1) {
                for (var k = 1; k <= i.length; k++) {
                    price = {}
                    price['rank'] = j + 1;
                    price['propId'] = i[k - 1].propId
                    rankPrices.push(price);
                }
            }
            if (i.length === 1) {
                price['rank'] = rankPrices.length + 1;
                price['propId'] = i[0].propId
                rankPrices.push(price);

            }
        })
        if (rankPrices.length === newprop.length) {
            //set rank and value of price
            newprop.map((value, j) => {
                newPrice = {}

                newPrice['rank' + parseInt(j + 1)] = rankPrices.find(m => m.propId === value._id).rank
                newPrice['value' + parseInt(j + 1)] = value.Property.Price
                prices.push(newPrice)
            })
        }

        return prices
    }

    //Mortgage Comparison set value and rank
    var mortgageCompare = (newprop, rnkArr) => {
        var mortgages = [];
        var rankMortgages = [];
        var mortgage = {};
        var fval;
        var mRanknew = []
        var newMortgage = {}
        // set value and rank of newprop
        if (rnkArr.length === 0) {
            newprop.forEach((value, i) => {
                mortgage = {}
                mortgage['value' + parseInt(i + 1)] = value && value.Mortgage && value.Mortgage.Scenarios[0] && value.Mortgage.Scenarios[0].totalMortgageRequired ? value.Mortgage.Scenarios[0].totalMortgageRequired : 0;
                mortgage['rank' + parseInt(i + 1)] = 0;
                mortgages.push(mortgage)
            })
        }

        for (var i = 0; i < rnkArr.length; i++) {
            var sameRankArr = [];
            var sameRankObj = {};
            if (mRanknew.length == 0) {
                sameRankObj = {};

                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    sameRankObj = {};
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }

                mRanknew.push(sameRankArr);
            } else if (mRanknew.find(m => m.find(n => n.propId == rnkArr[i].propId)) == null) {
                sameRankObj = {};
                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = {};
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }
                mRanknew.push(sameRankArr)

            }
        }
        if (mRanknew.length > 0) {
            //according to toggle set higher to lower and lower to higher sort rank
            if (mRanknew[0].togMort == false) {
                mRanknew.sort((a, b) => {
                    return a[0].PriceCalc - b[0].PriceCalc
                })
            }
            else {
                mRanknew.sort((a, b) => {
                    return b[0].PriceCalc - a[0].PriceCalc
                })
            }
        }

        //set rank and prop id of mortgage
        mRanknew.map((i, j) => {
            mortgage = {}
            if (i.length > 1) {
                for (var k = 1; k <= i.length; k++) {
                    mortgage = {}
                    mortgage['rank'] = j + 1;
                    mortgage['propId'] = i[k - 1].propId
                    rankMortgages.push(mortgage);
                }
            }
            if (i.length === 1) {
                mortgage['rank'] = rankMortgages.length + 1;
                mortgage['propId'] = i[0].propId
                rankMortgages.push(mortgage);

            }

        })
        if (rankMortgages.length === newprop.length) {

            newprop.map((value, j) => {
                newMortgage = {}
                newMortgage['rank' + parseInt(j + 1)] = rankMortgages.find(m => m.propId === value._id).rank
                newMortgage['value' + parseInt(j + 1)] = value && value.Mortgage && value.Mortgage.Scenarios[0] && value.Mortgage.Scenarios[0].totalMortgageRequired ? value.Mortgage.Scenarios[0].totalMortgageRequired : 0;
                mortgages.push(newMortgage)
            })

        }
        return mortgages
    }

    //MortgagePayment Comparison
    var mortgagePaymentCompare = (newprop, rnkArr) => {
        var mortgagePayments = [];
        var mortgagePayment = {};
        var rankMortgagespay = [];
        var mortgagepay = {};
        var mRanknew = []
        var newMortgagepay = {}

        // rank length zero
        if (rnkArr.length === 0) {
            newprop.forEach((value, i) => {
                mortgagePayment = {}
                mortgagePayment['value' + parseInt(i + 1)] = value && value.Mortgage
                    && value.Mortgage.Scenarios[0] && value.Mortgage.Scenarios[0].totalMortgagePayment
                    ? value.Mortgage.Scenarios[0].totalMortgagePayment.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 }) : 0;
                mortgagePayment['rank' + parseInt(i + 1)] = 0;
                mortgagePayments.push(mortgagePayment)
            })
        }

        for (var i = 0; i < rnkArr.length; i++) {
            var sameRankArr = [];
            var sameRankObj = {};
            if (mRanknew.length == 0) {
                sameRankObj = {};

                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    sameRankObj = {};
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }
                }
                mRanknew.push(sameRankArr);
            } else if (mRanknew.find(m => m.find(n => n.propId == rnkArr[i].propId)) == null) {
                sameRankObj = {};
                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = {};
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }
                mRanknew.push(sameRankArr)

            }
        }
        if (mRanknew.length > 0) {
            if (mRanknew[0].togMortpay == false) {
                mRanknew.sort((a, b) => {
                    return a[0].PriceCalc - b[0].PriceCalc
                })
            }
            else {
                mRanknew.sort((a, b) => {
                    return b[0].PriceCalc - a[0].PriceCalc
                })
            }
        }


        mRanknew.map((i, j) => {
            mortgagepay = {}
            if (i.length > 1) {
                for (var k = 1; k <= i.length; k++) {
                    mortgagepay = {}
                    mortgagepay['rank'] = j + 1;
                    mortgagepay['propId'] = i[k - 1].propId
                    rankMortgagespay.push(mortgagepay);
                }
            }
            if (i.length === 1) {
                mortgagepay['rank'] = rankMortgagespay.length + 1;
                mortgagepay['propId'] = i[0].propId
                rankMortgagespay.push(mortgagepay);
            }

        })
        //set rank and value Mortgage payment
        if (rankMortgagespay.length === newprop.length) {
            newprop.map((value, j) => {
                newMortgagepay = {}
                newMortgagepay['rank' + parseInt(j + 1)] = rankMortgagespay.find(m => m.propId === value._id).rank
                newMortgagepay['value' + parseInt(j + 1)] = value && value.Mortgage
                    && value.Mortgage.Scenarios[0] && value.Mortgage.Scenarios[0].totalMortgagePayment
                    ? value.Mortgage.Scenarios[0].totalMortgagePayment.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 }) : 0;
                mortgagePayments.push(newMortgagepay)
            })
        }
        return mortgagePayments;
    }

    //Property Tax comparison
    var propertyTaxCompare = (newprop, rnkArr) => {
        var propertyTaxs = [];
        var propertyTax = {}
        var rankProperty = [];
        var property = {};
        var pRanknew = []
        var newProperty = {}
        if (rnkArr.length === 0) {
            newprop.forEach((value, i) => {
                propertyTax = {}
                propertyTax['value' + parseInt(i + 1)] = 0;
                propertyTax['rank' + parseInt(i + 1)] = 0;
                propertyTaxs.push(propertyTax)
            })
        }

        for (var i = 0; i < rnkArr.length; i++) {

            var sameRankArr = [];
            var sameRankObj = {};
            if (pRanknew.length == 0) {
                sameRankObj = {};

                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    sameRankObj = {};
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }
                pRanknew.push(sameRankArr);
            } else if (pRanknew.find(m => m.find(n => n.propId == rnkArr[i].propId)) == null) {
                sameRankObj = {};
                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = {};
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }
                pRanknew.push(sameRankArr)

            }
        }
        if (pRanknew.length > 0) {
            if (pRanknew[0].togprotax == false) {
                pRanknew.sort((a, b) => {
                    return a[0].PriceCalc - b[0].PriceCalc
                })
            }
            else {
                pRanknew.sort((a, b) => {
                    return b[0].PriceCalc - a[0].PriceCalc
                })
            }
        }


        pRanknew.map((i, j) => {
            property = {}
            if (i.length > 1) {
                for (var k = 1; k <= i.length; k++) {
                    property = {}
                    property['rank'] = j + 1;
                    property['propId'] = i[k - 1].propId
                    rankProperty.push(property);
                }

            }
            if (i.length === 1) {
                property['rank'] = rankProperty.length + 1;
                property['propId'] = i[0].propId
                rankProperty.push(property);
            }

        })

        if (rankProperty.length === newprop.length) {

            newprop.map((value, j) => {
                newProperty = {}
                newProperty['rank' + parseInt(j + 1)] = rankProperty.find(m => m.propId === value._id).rank
                newProperty['value' + parseInt(j + 1)] = 0;
                propertyTaxs.push(newProperty)
            })

        }
        return propertyTaxs;
    }

    //Bedroom comparison
    var bedroomsCompare = (newprop, rnkArr) => {
        var bedroomss = [];
        var bedrooms = {};
        var rankBedroom = [];
        var bedroom = {};
        var bRanknew = []
        var newBedroom = {}
        //bedroom values and rank set
        if (rnkArr.length === 0) {
            newprop.forEach((value, i) => {
                bedrooms = {}
                bedrooms['value' + parseInt(i + 1)] = value && value.Building && value.Building.Bedrooms ? value.Building.Bedrooms : 0;
                bedrooms['rank' + parseInt(i + 1)] = 0;
                bedroomss.push(bedrooms)
            })
        }

        for (var i = 0; i < rnkArr.length; i++) {
            var sameRankArr = [];
            var sameRankObj = {};
            if (bRanknew.length == 0) {
                sameRankObj = {};

                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    sameRankObj = {};
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }

                bRanknew.push(sameRankArr);
            } else if (bRanknew.find(m => m.find(n => n.propId == rnkArr[i].propId)) == null) {
                sameRankObj = {};
                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = {};
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }
                bRanknew.push(sameRankArr)
            }
        }

        //sort  higher and lower bedroom
        if (bRanknew.length > 0) {
            if (bRanknew[0].togBed == false) {
                bRanknew.sort((a, b) => {
                    return a[0].PriceCalc - b[0].PriceCalc
                })
            }
            else {
                bRanknew.sort((a, b) => {
                    return b[0].PriceCalc - a[0].PriceCalc
                })
            }
        }

        //set rank order by prop id
        bRanknew.map((i, j) => {
            bedroom = {}
            if (i.length > 1) {
                for (var k = 1; k <= i.length; k++) {
                    bedroom = {}
                    bedroom['rank'] = j + 1;
                    bedroom['propId'] = i[k - 1].propId
                    rankBedroom.push(bedroom);
                }
            }
            if (i.length === 1) {
                bedroom['rank'] = rankBedroom.length + 1;
                bedroom['propId'] = i[0].propId
                rankBedroom.push(bedroom);
            }

        })
        if (rankBedroom.length === newprop.length) {
            //set bedroom rank and value
            newprop.map((value, j) => {
                newBedroom = {}
                newBedroom['rank' + parseInt(j + 1)] = rankBedroom.find(m => m.propId === value._id).rank
                newBedroom['value' + parseInt(j + 1)] = value && value.Building && value.Building.Bedrooms ? value.Building.Bedrooms : 0;
                bedroomss.push(newBedroom)
            })

        }
        return bedroomss;
    }

    //Bathroom Comparison
    var bathroomsCompare = (newprop, rnkArr) => {
        var bathroomss = [];
        var bathrooms = {};
        var rankBathroom = [];
        var bathroom = {};
        var bRanknew = []
        var newBathroom = {}
        // newprop is list of properties
        if (rnkArr.length === 0) {
            //set bathroom value and rank
            newprop.forEach((value, i) => {
                bathrooms = {}
                bathrooms['value' + parseInt(i + 1)] = value && value.Building && value.Building.BathroomTotal ? value.Building.BathroomTotal : 0;
                bathrooms['rank' + parseInt(i + 1)] = 0;
                bathroomss.push(bathrooms)
            })
        }

        for (var i = 0; i < rnkArr.length; i++) {
            var sameRankArr = [];
            var sameRankObj = {};
            if (bRanknew.length == 0) {
                sameRankObj = {};

                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    sameRankObj = {};
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }

                bRanknew.push(sameRankArr);
            } else if (bRanknew.find(m => m.find(n => n.propId == rnkArr[i].propId)) == null) {
                sameRankObj = {};
                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = {};
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }
                bRanknew.push(sameRankArr)

            }
        }
        //sort bathroom higher and lower
        if (bRanknew.length > 0) {
            if (bRanknew[0].togBath == false) {
                bRanknew.sort((a, b) => {
                    return a[0].PriceCalc - b[0].PriceCalc
                })
            }
            else {
                bRanknew.sort((a, b) => {
                    return b[0].PriceCalc - a[0].PriceCalc
                })
            }
        }

        //set prop id and rank in rankBathroom
        bRanknew.map((i, j) => {
            bathroom = {}
            if (i.length > 1) {
                for (var k = 1; k <= i.length; k++) {
                    bathroom = {}
                    bathroom['rank'] = j + 1;
                    bathroom['propId'] = i[k - 1].propId
                    rankBathroom.push(bathroom);
                }

            }
            if (i.length === 1) {
                bathroom['rank'] = rankBathroom.length + 1;
                bathroom['propId'] = i[0].propId
                rankBathroom.push(bathroom);
            }

        })

        //rankBathroom length is equal to newprop length 
        if (rankBathroom.length === newprop.length) {
            //set bathroom rank and value
            newprop.map((value, j) => {
                newBathroom = {}
                newBathroom['rank' + parseInt(j + 1)] = rankBathroom.find(m => m.propId === value._id).rank
                newBathroom['value' + parseInt(j + 1)] = value && value.Building && value.Building.BathroomTotal ? value.Building.BathroomTotal : 0;
                bathroomss.push(newBathroom)
            })

        }

        //value and rank of property
        return bathroomss;
    }

    //Balcony Comparison
    var balconyCompare = (newprop, rnkArr) => {
        var balconyss = [];
        var balconys = {};

        var rankBalcony = [];
        var balcony = {};
        var bRanknew = []
        var newBalcony = {}
        if (rnkArr.length === 0) {
            newprop.forEach((value, i) => {
                balconys = {}
                balconys['value' + parseInt(i + 1)] = 0;
                balconys['rank' + parseInt(i + 1)] = 0;
                balconyss.push(balconys)
            })
        }

        for (var i = 0; i < rnkArr.length; i++) {
            var sameRankArr = [];
            var sameRankObj = {};
            if (bRanknew.length == 0) {
                sameRankObj = {};

                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    sameRankObj = {};
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }

                bRanknew.push(sameRankArr);
            } else if (bRanknew.find(m => m.find(n => n.propId == rnkArr[i].propId)) == null) {
                sameRankObj = {};
                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = {};
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }
                bRanknew.push(sameRankArr)

            }
        }
        if (bRanknew.length > 0) {
            if (bRanknew[0].togBal == false) {
                bRanknew.sort((a, b) => {
                    return a[0].PriceCalc - b[0].PriceCalc
                })
            }
            else {
                bRanknew.sort((a, b) => {
                    return b[0].PriceCalc - a[0].PriceCalc
                })
            }
        }

        //set rank order of property
        bRanknew.map((i, j) => {
            balcony = {}
            if (i.length > 1) {
                for (var k = 1; k <= i.length; k++) {
                    balcony = {}
                    balcony['rank'] = j + 1;
                    balcony['propId'] = i[k - 1].propId
                    rankBalcony.push(balcony);
                }
            }
            if (i.length === 1) {
                balcony['rank'] = rankBalcony.length + 1;
                balcony['propId'] = i[0].propId
                rankBalcony.push(balcony);
            }
        })

        if (rankBalcony.length === newprop.length) {

            newprop.map((value, j) => {
                newBalcony = {}
                newBalcony['rank' + parseInt(j + 1)] = rankBalcony.find(m => m.propId === value._id).rank
                newBalcony['value' + parseInt(j + 1)] = 0;
                balconyss.push(newBalcony)
            })
        }
        return balconyss;
    }

    //Affordability Comparison
    var affordabilityCompare = (newprop, rnkArr) => {

        // setnetpay(window.store.data.User && window.store.data.User.monthlyExpense && window.store.data.User.monthlyExpense.netPayPerMonth)
        // setmonthlyexp(window.store.data.User && window.store.data.User.monthlyExpense && window.store.data.User.monthlyExpense.totalMontlyExpense)

        var affordbilities = [];
        var rankAffordbilities = [];
        var Affordbility = {}
        var newAffordbility = {}
        // var newprice = {};
        var aR = {};
        var fval;
        var aRanknew = []



        if (rnkArr === undefined || (rnkArr && rnkArr.length === 0)) {
            newprop && newprop.forEach((value, i) => {

                var mortgagepp = value && value.Mortgage
                    && value.Mortgage.Scenarios[0] && value.Mortgage.Scenarios[0].totalMortgagePayment
                    ? value.Mortgage.Scenarios[0].totalMortgagePayment.toLocaleString(undefined, { minimumFractionDigits: 2 }) : 0;
                mortgagepp = mortgagepp ? mortgagepp.replace(',', '') : 0
                var affordRate = netpay - (monthlyexp + parseFloat(mortgagepp))
                Affordbility = {}
                Affordbility['value' + parseInt(i + 1)] = affordRate ? affordRate.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 }) : 0;
                Affordbility['rank' + parseInt(i + 1)] = 0;
                affordbilities.push(Affordbility)
            })

        }
        if (rnkArr != undefined) {
            for (var i = 0; i < rnkArr.length; i++) {
                var pRs = {};
                var sameRankArr = [];
                var sameRankObj = {};
                if (aRanknew.length == 0) {
                    sameRankObj = {};

                    sameRankObj = rnkArr[i];
                    sameRankArr.push(sameRankObj);
                    for (var j = i + 1; j < rnkArr.length; j++) {
                        sameRankObj = {};
                        if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                            sameRankObj = rnkArr[j]
                            sameRankArr.push(sameRankObj)
                        }
                    }
                    aRanknew.push(sameRankArr);

                } else if (aRanknew.find(m => m.find(n => n.propId == rnkArr[i].propId)) == null) {
                    sameRankObj = {};
                    sameRankObj = rnkArr[i];
                    sameRankArr.push(sameRankObj);
                    for (var j = i + 1; j < rnkArr.length; j++) {
                        if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                            sameRankObj = {};
                            sameRankObj = rnkArr[j]
                            sameRankArr.push(sameRankObj)
                        }
                    }
                    aRanknew.push(sameRankArr)
                }
            }
        }
        if (aRanknew.length > 0) {
            if (aRanknew[0].togAffordpay == false) {
                aRanknew.sort((a, b) => {
                    return a[0].PriceCalc - b[0].PriceCalc
                })
            }
            else {
                //sort rankNew higher to lower
                aRanknew.sort((a, b) => {
                    return b[0].PriceCalc - a[0].PriceCalc
                })
            }
        }

        aRanknew.map((i, j) => {
            Affordbility = {}
            if (i.length > 1) {
                for (var k = 1; k <= i.length; k++) {
                    Affordbility = {}
                    Affordbility['rank'] = j + 1;
                    Affordbility['propId'] = i[k - 1].propId
                    rankAffordbilities.push(Affordbility);
                }
            }
            if (i.length === 1) {
                Affordbility['rank'] = rankAffordbilities.length + 1;
                Affordbility['propId'] = i[0].propId
                rankAffordbilities.push(Affordbility);

            }
        })
        if (rankAffordbilities.length === newprop.length) {

            newprop.map((value, j) => {
                newAffordbility = {}
                var mortgagepp = value && value.Mortgage
                    && value.Mortgage.Scenarios[0] && value.Mortgage.Scenarios[0].totalMortgagePayment
                    ? value.Mortgage.Scenarios[0].totalMortgagePayment.toLocaleString(undefined, { minimumFractionDigits: 2 }) : 0;
                mortgagepp = mortgagepp ? mortgagepp.replace(',', '') : 0
                var affordRate = netpay - (monthlyexp + parseFloat(mortgagepp))
                newAffordbility['rank' + parseInt(j + 1)] = rankAffordbilities.find(m => m.propId === value._id).rank
                newAffordbility['value' + parseInt(j + 1)] = affordRate ? affordRate.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 }) : 0
                affordbilities.push(newAffordbility)

            })
        }

        return affordbilities
    }

    //Area Comparison
    var areaCompare = (newprop, rnkArr) => {
        var areass = [];
        var areas = {};
        var rankArea = [];
        var area = {};
        var bRanknew = []
        var newArea = {}
        //rnkArr is RankOrder List and newprop is Property list
        if (rnkArr.length === 0) {
            newprop.forEach((value, i) => {
                areas = {}
                areas['value' + parseInt(i + 1)] = value && value.Building && value.Building.SizeInterior === undefined ? 0 : value && value.Building && value.Building.SizeInterior ? value.Building.SizeInterior : 0;
                areas['rank' + parseInt(i + 1)] = 0;
                areass.push(areas)
            })
        }

        for (var i = 0; i < rnkArr.length; i++) {
            var sameRankArr = [];
            var sameRankObj = {};
            if (bRanknew.length == 0) {
                sameRankObj = {};

                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    sameRankObj = {};
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }
                }

                bRanknew.push(sameRankArr);
            } else if (bRanknew.find(m => m.find(n => n.propId == rnkArr[i].propId)) == null) {
                sameRankObj = {};
                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = {};
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }
                bRanknew.push(sameRankArr)

            }
        }
        if (bRanknew.length > 0) {
            //area toggle 
            if (bRanknew[0].togarea == false) {
                //lower to higher
                bRanknew.sort((a, b) => {
                    return a[0].PriceCalc - b[0].PriceCalc
                })
            }
            else {
                //higher to lower
                bRanknew.sort((a, b) => {
                    return b[0].PriceCalc - a[0].PriceCalc
                })
            }
        }


        bRanknew.map((i, j) => {
            area = {}
            if (i.length > 1) {
                for (var k = 1; k <= i.length; k++) {
                    area = {}
                    area['rank'] = j + 1;
                    area['propId'] = i[k - 1].propId
                    rankArea.push(area);
                }

            }
            if (i.length === 1) {
                area['rank'] = rankArea.length + 1;
                area['propId'] = i[0].propId
                rankArea.push(area);
            }
        })
        //set rank order
        if (rankArea.length === newprop.length) {
            newprop.map((value, j) => {
                newArea = {}
                newArea['rank' + parseInt(j + 1)] = rankArea.find(m => m.propId === value._id).rank
                newArea['value' + parseInt(j + 1)] = value && value.Building && value.Building.SizeInterior === undefined ? 0 : value && value.Building && value.Building.SizeInterior ? value.Building.SizeInterior : 0;
                areass.push(newArea)
            })

        }
        return areass;
    }

    //Work Distance comparison
    var distanceCompare = (newprop, rnkArr) => {
        // console.log("newprop and rnkArr", newprop, rnkArr)
        var distancess = [];
        var distances = {};
        var rankDistance = [];
        var distance = {};
        var dRanknew = []
        var newDistance = {}
        //set rank order
        if (rnkArr.length === 0) {
            newprop.forEach((value, i) => {
                distances = {}
                distances['value' + parseInt(i + 1)] = value && value.Mortgage && value.Mortgage.DistanceMatrix && value.Mortgage.DistanceMatrix.WorkLocation === undefined ? 0 : value && value.Mortgage && value.Mortgage.DistanceMatrix && value.Mortgage.DistanceMatrix.WorkLocation ? value.Mortgage.DistanceMatrix.WorkLocation : 0 + " Km";
                distances['rank' + parseInt(i + 1)] = 0;
                distancess.push(distances)
            })
        }

        for (var i = 0; i < rnkArr.length; i++) {
            var sameRankArr = [];
            var sameRankObj = {};
            if (dRanknew.length == 0) {
                sameRankObj = {};
                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    sameRankObj = {};
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }
                }

                dRanknew.push(sameRankArr);
            } else if (dRanknew.find(m => m.find(n => n.propId == rnkArr[i].propId)) == null) {
                sameRankObj = {};
                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = {};
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }
                dRanknew.push(sameRankArr)

            }
        }
        if (dRanknew.length > 0) {
            if (dRanknew[0].togdist == false) {
                dRanknew.sort((a, b) => {
                    return a[0].PriceCalc - b[0].PriceCalc
                })
            }
            else {
                dRanknew.sort((a, b) => {
                    return b[0].PriceCalc - a[0].PriceCalc
                })
            }
        }


        dRanknew.map((i, j) => {
            distance = {}
            if (i.length > 1) {
                for (var k = 1; k <= i.length; k++) {
                    distance = {}
                    distance['rank'] = j + 1;
                    distance['propId'] = i[k - 1].propId
                    rankDistance.push(distance);
                }
            }
            if (i.length === 1) {
                distance['rank'] = rankDistance.length + 1;
                distance['propId'] = i[0].propId
                rankDistance.push(distance);

            }

        })

        // set rank and value of property
        if (rankDistance.length === newprop.length) {
            newprop.map((value, j) => {
                newDistance = {}
                newDistance['rank' + parseInt(j + 1)] = rankDistance.find(m => m.propId === value._id).rank
                newDistance['value' + parseInt(j + 1)] = value && value.Mortgage && value.Mortgage.DistanceMatrix && value.Mortgage.DistanceMatrix.WorkLocation === undefined ? 0 : value && value.Mortgage && value.Mortgage.DistanceMatrix && value.Mortgage.DistanceMatrix.WorkLocation ? value.Mortgage.DistanceMatrix.WorkLocation : 0 + " Km";
                distancess.push(newDistance)
            })

        }
        return distancess;
    }

    //School Distance comparison2
    var distance2Compare = (newprop, rnkArr) => {
        var distance2s = [];
        var distance2 = {};
        var rankDistance = [];
        var distance = {};
        var dRanknew = []
        var newDistance = {}
        if (rnkArr.length === 0) {
            newprop.forEach((value, i) => {
                distance2 = {}
                distance2['value' + parseInt(i + 1)] = value && value.Mortgage && value.Mortgage.DistanceMatrix && value.Mortgage.DistanceMatrix.SchoolLocation && value.Mortgage.DistanceMatrix.SchoolLocation === undefined ? 0 : value && value.Mortgage && value.Mortgage.DistanceMatrix && value.Mortgage.DistanceMatrix.SchoolLocation ? value.Mortgage.DistanceMatrix.SchoolLocation : 0 + " Km";
                distance2['rank' + parseInt(i + 1)] = 0;
                distance2s.push(distance2)
            })
        }

        for (var i = 0; i < rnkArr.length; i++) {
            var sameRankArr = [];
            var sameRankObj = {};
            if (dRanknew.length == 0) {
                sameRankObj = {};

                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    sameRankObj = {};
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }

                dRanknew.push(sameRankArr);
            } else if (dRanknew.find(m => m.find(n => n.propId == rnkArr[i].propId)) == null) {
                sameRankObj = {};
                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {

                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = {};
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }
                dRanknew.push(sameRankArr)

            }
        }
        if (dRanknew.length > 0) {
            if (dRanknew[0].togdist2 == false) {
                dRanknew.sort((a, b) => {
                    return a[0].PriceCalc - b[0].PriceCalc
                })
            }
            else {
                dRanknew.sort((a, b) => {
                    return b[0].PriceCalc - a[0].PriceCalc
                })
            }
        }


        dRanknew.map((i, j) => {
            distance = {}
            if (i.length > 1) {
                for (var k = 1; k <= i.length; k++) {
                    distance = {}
                    distance['rank'] = j + 1;
                    distance['propId'] = i[k - 1].propId
                    rankDistance.push(distance);
                }

            }
            if (i.length === 1) {
                distance['rank'] = rankDistance.length + 1;
                distance['propId'] = i[0].propId
                rankDistance.push(distance);
            }
        })

        if (rankDistance.length === newprop.length) {
            //set value and rank order of property 
            newprop.map((value, j) => {
                newDistance = {}
                newDistance['rank' + parseInt(j + 1)] = rankDistance.find(m => m.propId === value._id).rank
                newDistance['value' + parseInt(j + 1)] = value && value.Mortgage && value.Mortgage.DistanceMatrix && value.Mortgage.DistanceMatrix.SchoolLocation && value.Mortgage.DistanceMatrix.SchoolLocation === undefined ? 0 : value && value.Mortgage && value.Mortgage.DistanceMatrix && value.Mortgage.DistanceMatrix.SchoolLocation ? value.Mortgage.DistanceMatrix.SchoolLocation : 0 + " Km";
                distance2s.push(newDistance)
            })

        }
        return distance2s;
    }

    //Other Distance comparison
    var locationNames = [];
    var otherLocationCompare = (newprop, rnkArr) => {
        // console.log("newprop, rnkArr 1561", newprop, rnkArr)
        var otherLocations = [];
        var otherLocation = {};
        var rankDistance = [];
        var distance = {};
        var dRanknew = []
        var newDistance = {}
        var locationName;

        if (rnkArr.length === 0) {
            // console.log("1568 other loation newprop", newprop)
            newprop.forEach((value, i) => {
                // console.log("1575 value", value)
                OtherLocationsName = value
                if (value && value.Mortgage && value.Mortgage.DistanceMatrix && value.Mortgage.DistanceMatrix.OtherLocation && value.Mortgage.DistanceMatrix.OtherLocation.length > 0) {
                    // value.Mortgage.DistanceMatrix.OtherLocation.forEach((values, j) => {
                    // for (var j = 0; j < value.Mortgage.DistanceMatrix.OtherLocation.length; j++) {
                    otherLocation = {}
                    otherLocation['value' + parseInt(i + 1)] = value && value.Mortgage.DistanceMatrix
                        && value.Mortgage.DistanceMatrix.OtherLocation === undefined ? 0 : value && value.Mortgage.DistanceMatrix.OtherLocation ?
                        value.Mortgage.DistanceMatrix.OtherLocation : 0 + " Km";
                    otherLocation['rank' + parseInt(i + 1)] = 0;
                    otherLocations.push(otherLocation)
                    // }
                    // console.log("1885",otherLocations)
                }
                //  otherLocation = {}
                // otherLocation['value' + parseInt(i + 1)] = value && value.otherLocationsDistance 
                // && value.otherLocationsDistance === undefined ? 0 : value && value.otherLocationsDistance 
                // && value.otherLocationsDistance[0].distance;
                // otherLocation['rank' + parseInt(i + 1)] = 0;
                // otherLocations.push(otherLocation)
                // console.log("1592 other loation newprop", otherLocations)
            })
        }

        for (var i = 0; i < rnkArr.length; i++) {
            var sameRankArr = [];
            var sameRankObj = {};
            if (dRanknew.length == 0) {
                sameRankObj = {};

                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {
                    sameRankObj = {};
                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }

                dRanknew.push(sameRankArr);
            } else if (dRanknew.find(m => m.find(n => n.propId == rnkArr[i].propId)) == null) {
                sameRankObj = {};
                sameRankObj = rnkArr[i];
                sameRankArr.push(sameRankObj);
                for (var j = i + 1; j < rnkArr.length; j++) {

                    if (rnkArr[i].PriceCalc === rnkArr[j].PriceCalc) {
                        sameRankObj = {};
                        sameRankObj = rnkArr[j]
                        sameRankArr.push(sameRankObj)
                    }

                }
                dRanknew.push(sameRankArr)

            }
        }
        if (dRanknew.length > 0) {
            if (dRanknew[0].togotherLocation == false) {
                dRanknew.sort((a, b) => {
                    return a[0].PriceCalc - b[0].PriceCalc
                })
            }
            else {
                dRanknew.sort((a, b) => {
                    return b[0].PriceCalc - a[0].PriceCalc
                })
            }
        }

        //set rank and property id
        dRanknew.map((i, j) => {
            otherLocation = {}
            if (i.length > 1) {
                for (var k = 1; k <= i.length; k++) {
                    otherLocation = {}
                    otherLocation['rank'] = j + 1;
                    otherLocation['propId'] = i[k - 1].propId
                    rankDistance.push(otherLocation);
                }

            }
            if (i.length === 1) {
                otherLocation['rank'] = rankDistance.length + 1;
                otherLocation['propId'] = i[0].propId
                rankDistance.push(otherLocation);
            }
        })

        if (rankDistance.length === newprop.length) {
            // set rank and value of propertie
            newprop.map((value, j) => {
                newDistance = {}
                newDistance['rank' + parseInt(j + 1)] = rankDistance.find(m => m.propId === value._id).rank
                newDistance['value' + parseInt(j + 1)] = value && value.Mortgage &&
                    value.Mortgage.DistanceMatrix && value.Mortgage.DistanceMatrix.OtherLocation === undefined ? 0 :
                    value && value.Mortgage.DistanceMatrix.OtherLocation ? value.Mortgage.DistanceMatrix.OtherLocation : 0 + " Km";
                otherLocations.push(newDistance)
            })

        }
        return otherLocations;
    }

    //Price Rank Order change weight of price
    const priceRankWeigthChange = (e) => {
        var newPropArr = [];
        newprop.forEach((value, i) => {
            newPropArr.push(value);
        })
        var aa = newPropArr.sort(function (a, b) {
            var aPrice = parseFloat(a && a.Property && a.Property.Price && a.Property.Price ? a.Property.Price.replace('$', '').replace(/,/g, '').trim() : 0);
            var bPrice = parseFloat(b && b.Property && b.Property.Price && b.Property.Price ? b.Property.Price.replace('$', '').replace(/,/g, '').trim() : 0);
            if (togglePrice === false) {
                return aPrice - bPrice;
            } else {
                return bPrice - aPrice;
            }

        });

        if (aa[0]) {
            minPrice = aa && aa[0].Property && aa[0].Property.Price
        }
        var data = []
        for (var i = 0; i < newprop.length; i++) {
            if (priceweight && togglePrice === false) {
                var propsId = newprop[i]._id
                var pricec = newprop[i].Property.Price
                pricec = parseFloat(pricec.split("$")[1].replace(/,/g, ''))
                var minpp = parseFloat(minPrice ? minPrice.split("$")[1].replace(/,/g, '') : 0)
                if (minpp && pricec && priceweight) {
                    pri = ((minpp / pricec) * priceweight)
                }
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = pri;
                obj.togPrice = togglePrice;
                data.push(obj);
                data = data.sort((a, b) => {
                    return b.PriceCalc - a.PriceCalc
                })
                setPRank(data)
                if (data.length === newprop.length) {
                    var pricechk = {}
                    pricechk.pricechks = true
                    pricechk.priceweight = priceweight
                    calculateCommonRank(data, pricechk)
                }
            } else if (priceweight && togglePrice === true) {
                var propsId = newprop[i]._id
                var pricec = newprop[i].Property.Price
                pricec = parseFloat(pricec.split("$")[1].replace(/,/g, ''))
                var minpp = parseFloat(minPrice ? minPrice.split("$")[1].replace(/,/g, '') : 0)
                if (minpp && pricec && priceweight) {
                    pri = ((pricec / minpp) * priceweight)
                }
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = pri;
                obj.togPrice = togglePrice;
                data.push(obj);
                data = data.sort((a, b) => {
                    return a.PriceCalc - b.PriceCalc
                })
                setPRank(data)
                if (data.length === newprop.length) {
                    setPRank(data)
                    var pricechk = {}
                    pricechk.pricechks = true
                    pricechk.priceweight = priceweight
                    calculateCommonRank(data, pricechk)
                }
            }

        }
    }

    //Mortgae Rank Order change weight of mortgage
    const mortgageRankWeigthChange = (e) => {
        var newPropArr = [];
        newprop.forEach((value, i) => {
            newPropArr.push(value);
        })
        //acc to toggle higher to lower and lower to higher sort
        var aa = newPropArr.sort(function (a, b) {
            var aMortgage = a.Mortgage && a.Mortgage.Scenarios[0] && a.Mortgage.Scenarios[0].totalMortgageRequired ? a.Mortgage.Scenarios[0].totalMortgageRequired : 0;
            var bMortgage = b.Mortgage && b.Mortgage.Scenarios[0] && b.Mortgage.Scenarios[0].totalMortgageRequired ? b.Mortgage.Scenarios[0].totalMortgageRequired : 0;
            if (toggleMortgage === false) {
                return aMortgage - bMortgage;
            } else {
                return bMortgage - aMortgage;
            }

        });
        if (aa[0]) {
            mortgageMM = aa && aa[0].Mortgage.Scenarios[0] && aa[0].Mortgage.Scenarios[0].totalMortgageRequired
                ? aa[0].Mortgage.Scenarios[0].totalMortgageRequired : 0
        }

        var data = []
        for (var i = 0; i < newprop.length; i++) {
            if (mortgageweight && toggleMortgage === false) {
                var propsId = newprop[i]._id
                var mortgagepri = newprop[i].Mortgage && newprop[i].Mortgage.Scenarios[0]
                    && newprop[i].Mortgage.Scenarios[0].totalMortgageRequired
                    ? newprop[i].Mortgage.Scenarios[0].totalMortgageRequired : 0

                mortgageMM = mortgageMM ? mortgageMM : 0
                if (mortgagepri === 0) {
                    mortgagepri = "0"
                }
                if (mortgageMM && mortgagepri && mortgageweight) {
                    mortgage = ((mortgageMM / mortgagepri) * parseInt(mortgageweight))
                }
                mortgage = mortgage === undefined ? 0 : mortgage
                // let s = Number.isNaN(mortgage)
                // mortgage = s === true ? 0 : mortgage
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = mortgage;
                obj.togMort = toggleMortgage;
                data.push(obj);
                data = data.sort((a, b) => {
                    return b.PriceCalc - a.PriceCalc
                })
                setMortgageRank(data)
                if (data.length === newprop.length) {
                    var mortchk = {}
                    mortchk.mortchks = true
                    mortchk.mortgageweight = mortgageweight
                    calculateCommonRank(data, mortchk)
                }

            } else if (mortgageweight && toggleMortgage === true) {
                var propsId = newprop[i]._id
                var mortgagepri = newprop[i].Mortgage && newprop[i].Mortgage.Scenarios[0]
                    && newprop[i].Mortgage.Scenarios[0].totalMortgageRequired
                    ? newprop[i].Mortgage.Scenarios[0].totalMortgageRequired : 0

                mortgageMM = mortgageMM ? mortgageMM : 0
                // console.log("20733", mortgageMM)
                if (mortgagepri === 0) {
                    mortgagepri = "0"
                }
                if (mortgageMM && mortgagepri && mortgageweight) {
                    mortgage = ((mortgagepri / mortgageMM) * parseInt(mortgageweight))
                }
                mortgage = mortgage === undefined ? 0 : mortgage
                // let s = Number.isNaN(mortgage)
                // mortgage = s === true ? 0 : mortgage
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = mortgage;
                obj.togMort = toggleMortgage;
                data.push(obj);
                data = data.sort((a, b) => {
                    return a.PriceCalc - b.PriceCalc
                })
                setMortgageRank(data)
                if (data.length === newprop.length) {
                    var mortchk = {}
                    mortchk.mortchks = true
                    mortchk.mortgageweight = mortgageweight
                    calculateCommonRank(data, mortchk)
                }
            }

        }
    }

    //MortgagePayment Rank Order change weight of MortgagePayment
    const mortgagePayRankWeigthChange = (e) => {
        var newPropArr = [];
        // newPropArr is list of properties
        newprop.forEach((value, i) => {
            newPropArr.push(value);
        })
        var aa = newPropArr.sort(function (a, b) {
            var aMortgagePay = a.Mortgage && a.Mortgage.Scenarios[0] && a.Mortgage.Scenarios[0].totalMortgagePayment ? a.Mortgage.Scenarios[0].totalMortgagePayment : 0;
            var bMortgagePay = b.Mortgage && b.Mortgage.Scenarios[0] && b.Mortgage.Scenarios[0].totalMortgagePayment ? b.Mortgage.Scenarios[0].totalMortgagePayment : 0
            //according to toggle change values
            if (toggleMortgagePay === false) {
                return aMortgagePay - bMortgagePay;
            } else {
                return bMortgagePay - aMortgagePay;
            }

        });
        if (aa[0]) {
            mortgagepayMM = aa && aa[0].Mortgage.Scenarios[0] && aa[0].Mortgage.Scenarios[0].totalMortgagePayment
                ? aa[0].Mortgage.Scenarios[0].totalMortgagePayment : 0
        }

        var data = []
        for (var i = 0; i < newprop.length; i++) {
            if (mortgagepayweight && toggleMortgagePay === false) {
                var propsId = newprop[i]._id
                var mortgagepaypri = newprop[i].Mortgage && newprop[i].Mortgage && newprop[i].Mortgage.Scenarios[0]
                    && newprop[i].Mortgage.Scenarios[0].totalMortgagePayment
                    ? newprop[i].Mortgage.Scenarios[0].totalMortgagePayment : 0

                mortgagepayMM = mortgagepayMM ? mortgagepayMM : 0
                if (mortgagepaypri === 0) {
                    mortgagepaypri = "0"
                }
                if (mortgagepayMM && mortgagepaypri && mortgagepayweight) {
                    mortgagepay = ((mortgagepayMM / mortgagepaypri) * parseInt(mortgagepayweight))
                }
                mortgagepay = mortgagepay === undefined ? 0 : mortgagepay
                let s = Number.isNaN(mortgagepay)
                mortgagepay = s === true ? 0 : mortgagepay
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = mortgagepay;
                obj.togMortpay = toggleMortgagePay;
                data.push(obj);
                data = data.sort((a, b) => {
                    return b.PriceCalc - a.PriceCalc
                })
                setMortgagePayRank(data)
                if (data.length === newprop.length) {
                    var mortpaychk = {}
                    mortpaychk.mortppaychks = true
                    calculateCommonRank(data, mortpaychk)
                }
            } else if (mortgagepayweight && toggleMortgagePay === true) {
                var propsId = newprop[i]._id
                var mortgagepaypri = newprop[i].Mortgage && newprop[i].Mortgage.Scenarios[0]
                    && newprop[i].Mortgage.Scenarios[0].totalMortgagePayment
                    ? newprop[i].Mortgage.Scenarios[0].totalMortgagePayment : 0
                mortgagepayMM = mortgagepayMM ? mortgagepayMM : 0
                if (mortgagepaypri === 0) {
                    mortgagepaypri = "0"
                }
                if (mortgagepayMM && mortgagepaypri && mortgagepayweight) {
                    mortgagepay = ((mortgagepaypri / mortgagepayMM) * parseInt(mortgagepayweight))
                }
                mortgagepay = mortgagepay === undefined ? 0 : mortgagepay
                let s = Number.isNaN(mortgagepay)
                mortgagepay = s === true ? 0 : mortgagepay
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = mortgagepay;
                obj.togMortpay = toggleMortgagePay;
                data.push(obj);
                data = data.sort((a, b) => {
                    return a.PriceCalc - b.PriceCalc
                })
                setMortgagePayRank(data)
                if (data.length === newprop.length) {
                    var mortpaychk = {}
                    mortpaychk.mortppaychks = true
                    calculateCommonRank(data, mortpaychk)
                }
            }

        }
    }

    //Property tax Rank Order change weight of PropertyTax
    const propertyTaxRankWeigthChange = (e) => {
        var newPropArr = [];
        newprop.forEach((value, i) => {
            newPropArr.push(value);
        })
        var aa = newPropArr.sort(function (a, b) {
            var aPropTax = 0;
            var bPropTax = 0;
            //accroding toggle value
            if (togglePropTax === false) {
                return aPropTax - bPropTax;
            } else {
                return bPropTax - aPropTax;
            }

        });
        if (aa[0]) {
            proptaxMM = aa && aa[0]
        }

        var data = []
        for (var i = 0; i < newprop.length; i++) {
            if (proptaxweight && togglePropTax === false) {
                var propsId = newprop[i]._id
                var proppri = 0

                proptaxMM = proptaxMM ? proptaxMM : 0
                if (proptaxweight && proppri && proptaxweight) {
                    propertytax = ((proptaxMM / proppri) * proptaxweight)
                }
                propertytax = propertytax === undefined ? 0 : propertytax
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = propertytax;
                obj.togprotax = togglePropTax;
                data.push(obj);
                //higher to lower
                data = data.sort((a, b) => {
                    return b.PriceCalc - a.PriceCalc
                })
                setPropertyTaxRank(data)
                if (data.length === newprop.length) {
                    var proptaxchk = {}
                    proptaxchk.proptaxchks = true
                    proptaxchk.proptaxweight = proptaxweight
                    calculateCommonRank(data, proptaxchk)
                }
            } else if (proptaxweight && togglePropTax === true) {
                var propsId = newprop[i]._id
                var proptaxpri = 0

                proptaxMM = proptaxMM ? proptaxMM : 0
                if (proptaxMM && proptaxpri && proptaxweight) {
                    propertytax = ((proptaxpri / proptaxMM) * proptaxweight)
                }
                propertytax = propertytax === undefined ? 0 : propertytax
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = propertytax;
                obj.togprotax = togglePropTax;
                data.push(obj);
                data = data.sort((a, b) => {
                    return a.PriceCalc - b.PriceCalc
                })
                setPropertyTaxRank(data)
                if (data.length === newprop.length) {
                    var proptaxchk = {}
                    proptaxchk.proptaxchks = true
                    proptaxchk.proptaxweight = proptaxweight
                    calculateCommonRank(data, proptaxchk)
                }
            }

        }
    }

    //Bedroom  Rank Order change weight of Bedroom 
    const bedroomRankWeigthChange = (e) => {
        var newPropArr = [];
        newprop.forEach((value, i) => {
            newPropArr.push(value);
        })
        var aa = newPropArr.sort(function (a, b) {
            var aBed = parseFloat(a && a.Building && a.Building.Bedrooms && a.Building.Bedrooms ? a.Building.Bedrooms : 0);
            var bBed = parseFloat(b && b.Building && b.Building.Bedrooms && b.Building.Bedrooms ? b.Building.Bedrooms : 0);
            // according to toggle 
            if (toggleBed === false) {
                return aBed - bBed;
            }
            else {
                return bBed - aBed;
            }
        });
        if (aa[0]) {
            var z = aa && aa[0].Building && aa[0].Building.Bedrooms ? aa[0].Building.Bedrooms : 0
            if (z && z.length > 1) {
                splitfun(z)
                maxBedroom = splitfun(z)
            }
            else {
                maxBedroom = z
            }
        }

        var data = []
        for (var i = 0; i < newprop.length; i++) {
            var Bedroomss = newprop[i].Building && newprop[i].Building.Bedrooms
                && newprop[i].Building.Bedrooms ? newprop[i].Building.Bedrooms : 0
            if (bedroomweight && toggleBed === true) {
                if (Bedroomss && Bedroomss.length > 1) {
                    var propsId = newprop[i]._id
                    Bedroomss = splitfun(Bedroomss)
                    bed = ((Bedroomss / maxBedroom) * parseInt(bedroomweight))
                    bed = bed === undefined ? 0 : bed
                    let s = Number.isNaN(bed)
                    bed = s === true ? 0 : bed
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = bed;
                    obj.togBed = toggleBed;
                    data.push(obj);
                    data = data.sort((a, b) => {
                        return a.PriceCalc - b.PriceCalc
                    })
                    setBedRank(data)
                    if (data.length === newprop.length) {
                        var bedchk = {}
                        bedchk.bedchks = true
                        bedchk.bedroomweight = bedroomweight
                        calculateCommonRank(data, bedchk)
                    }
                }
                else {
                    bed = ((Bedroomss / maxBedroom) * parseInt(bedroomweight))
                    bed = bed === undefined ? 0 : bed
                    let s = Number.isNaN(bed)
                    bed = s === true ? 0 : bed
                    var propsId = newprop[i]._id
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = bed;
                    obj.togBed = toggleBed;
                    data.push(obj);
                    // sort lower to higher
                    data = data.sort((a, b) => {
                        return a.PriceCalc - b.PriceCalc
                    })
                    setBedRank(data)
                    if (data.length === newprop.length) {
                        var bedchk = {}
                        bedchk.bedchks = true
                        bedchk.bedroomweight = bedroomweight
                        calculateCommonRank(data, bedchk)
                    }
                }
            }
            else if (bedroomweight && toggleBed === false) {
                if (Bedroomss && Bedroomss.length > 1) {
                    var propsId = newprop[i]._id
                    Bedroomss = splitfun(Bedroomss)
                    bed = ((maxBedroom / Bedroomss) * parseInt(bedroomweight))
                    bed = bed === undefined ? 0 : bed
                    let s = Number.isNaN(bed)
                    bed = s === true ? 0 : bed
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = bed;
                    obj.togBed = toggleBed;
                    data.push(obj);
                    // higher to lower sort
                    data = data.sort((a, b) => {
                        return b.PriceCalc - a.PriceCalc
                    })
                    setBedRank(data)
                    if (data.length === newprop.length) {
                        var bedchk = {}
                        bedchk.bedchks = true
                        bedchk.bedroomweight = bedroomweight
                        calculateCommonRank(data, bedchk)
                    }

                }
                else {
                    var propsId = newprop[i]._id
                    bed = ((maxBedroom / Bedroomss) * parseInt(bedroomweight))
                    bed = bed === undefined ? 0 : bed
                    let s = Number.isNaN(bed)
                    bed = s === true ? 0 : bed
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = bed;
                    obj.togBed = toggleBed;
                    data.push(obj);
                    //higher to lower sort
                    data = data.sort((a, b) => {
                        return b.PriceCalc - a.PriceCalc
                    })
                    setBedRank(data)
                    if (data.length === newprop.length) {
                        var bedchk = {}
                        bedchk.bedchks = true
                        bedchk.bedroomweight = bedroomweight
                        calculateCommonRank(data, bedchk)
                    }
                }
            }

        }
    }

    //Bathroom Rank Order change weight of bathroom
    const bathroomRankWeigthChange = (e) => {
        var newPropArr = [];
        newprop.forEach((value, i) => {
            newPropArr.push(value);
        })
        var aa = newPropArr.sort(function (a, b) {
            var aBathroom = parseFloat(a && a.Building && a.Building.BathroomTotal ? a.Building.BathroomTotal : 0);
            var bBathroom = parseFloat(b && b.Building && b.Building.BathroomTotal ? b.Building.BathroomTotal : 0);
            //toggle of bedroom
            if (toggleBed === false) {
                return aBathroom - bBathroom;
            }
            else {
                return bBathroom - aBathroom;
            }

        });
        if (aa[0]) {
            var z = aa && aa[0].Building && aa[0].Building.BathroomTotal ? aa[0].Building.BathroomTotal : 0
            if (z && z.length > 1) {
                splitfun(z)
                maxBathroom = splitfun(z)
            } else {
                maxBathroom = z
            }

        }

        var data = []
        for (var i = 0; i < newprop.length; i++) {
            var BathroomTotals = newprop[i].Building && newprop[i].Building.BathroomTotal
                ? newprop[i].Building.BathroomTotal : 0
            if (bathroomweight && toggleBath === true) {
                if (BathroomTotals && BathroomTotals.length > 1) {
                    var propsId = newprop[i]._id
                    BathroomTotals = splitfun(BathroomTotals)
                    BathroomTotals = splitfun(BathroomTotals)
                    bath = ((BathroomTotals / maxBathroom) * parseInt(bathroomweight))
                    bath = bath === undefined ? 0 : bath
                    let s = Number.isNaN(bath)
                    bath = s === true ? 0 : bath
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = bath;
                    obj.togBath = toggleBath;
                    data.push(obj);
                    data = data.sort((a, b) => {
                        return a.PriceCalc - b.PriceCalc
                    })
                    setBathRank(data)
                    if (data.length === newprop.length) {
                        var bathchk = {}
                        bathchk.bathchks = true
                        bathchk.bathroomweight = bathroomweight
                        calculateCommonRank(data, bathchk)

                    }

                } else {
                    var propsId = newprop[i]._id
                    bath = ((BathroomTotals / maxBathroom) * parseInt(bathroomweight))
                    bath = bath === undefined ? 0 : bath
                    let s = Number.isNaN(bath)
                    bath = s === true ? 0 : bath
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = bath;
                    obj.togBath = toggleBath;
                    data.push(obj);
                    //lower to higher
                    data = data.sort((a, b) => {
                        return a.PriceCalc - b.PriceCalc
                    })
                    setBathRank(data)
                    if (data.length === newprop.length) {
                        var bathchk = {}
                        bathchk.bathchks = true
                        bathchk.bathroomweight = bathroomweight
                        calculateCommonRank(data, bathchk)
                    }
                }
            }
            else if (bathroomweight && toggleBath === false) {
                if (BathroomTotals && BathroomTotals.length > 1) {
                    var propsId = newprop[i]._id
                    BathroomTotals = splitfun(BathroomTotals)
                    BathroomTotals = splitfun(BathroomTotals)
                    bath = ((maxBathroom / BathroomTotals) * parseInt(bathroomweight))
                    bath = bath === undefined ? 0 : bath
                    let s = Number.isNaN(bath)
                    bath = s === true ? 0 : bath
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = bath;
                    obj.togBath = toggleBath;
                    data.push(obj);
                    // higher to lower
                    data = data.sort((a, b) => {
                        return b.PriceCalc - a.PriceCalc
                    })
                    setBathRank(data)
                    if (data.length === newprop.length) {
                        var bathchk = {}
                        bathchk.bathchks = true
                        bathchk.bathroomweight = bathroomweight
                        calculateCommonRank(data, bathchk)
                    }

                } else {
                    var propsId = newprop[i]._id
                    bath = ((maxBathroom / BathroomTotals) * parseInt(bathroomweight))
                    bath = bath === undefined ? 0 : bath
                    let s = Number.isNaN(bath)
                    bath = s === true ? 0 : bath
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = bath;
                    obj.togBath = toggleBath;
                    data.push(obj);
                    data = data.sort((a, b) => {
                        return b.PriceCalc - a.PriceCalc
                    })
                    setBathRank(data)
                    if (data.length === newprop.length) {
                        var bathchk = {}
                        bathchk.bathchks = true
                        bathchk.bathroomweight = bathroomweight
                        calculateCommonRank(data, bathchk)
                    }
                }
            }

        }
    }

    //Balcony Rank Order change weight of balcony
    const balconyRankWeigthChange = (e) => {
        var newPropArr = [];
        newprop.forEach((value, i) => {
            newPropArr.push(value);
        })

        //properties sort according to toggle
        var aa = newPropArr.sort(function (a, b) {
            var aBalcony = 0;
            var bBalcony = 0;
            if (toggleBalc === false) {
                return aBalcony - bBalcony;
            } else {
                return bBalcony - aBalcony;
            }

        });
        if (aa[0]) {
            balconyMM = aa && aa[0]
        }

        var data = []

        // set lower is the best and higher is the best 
        for (var i = 0; i < newprop.length; i++) {
            if (balconyweight && toggleBalc === false) {
                var propsId = newprop[i]._id
                var balc = 0

                balconyMM = balconyMM ? balconyMM : 0
                if (balconyMM && balc && balconyweight) {
                    balcony = ((balconyMM / balc) * balconyweight)
                }
                balcony = balcony === undefined ? 0 : balcony
                let s = Number.isNaN(balcony)
                balcony = s === true ? 0 : balcony
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = balcony;
                obj.togBal = toggleBalc;
                data.push(obj);
                data = data.sort((a, b) => {
                    return b.PriceCalc - a.PriceCalc
                })
                setBalconyRank(data)
                if (data.length === newprop.length) {
                    var balcchk = {}
                    balcchk.balcchks = true
                    balcchk.balconyweight = balconyweight
                    calculateCommonRank(data, balcchk)
                }
            }
            else if (balconyweight && toggleBalc === true) {
                var propsId = newprop[i]._id
                var balcony = 0

                balconyMM = balconyMM ? balconyMM : 0
                //    lower is the best
                if (balconyMM && balcony && balconyweight) {
                    balcony = ((balcony / balconyMM) * balconyweight)
                }
                balcony = balcony === undefined ? 0 : balcony
                let s = Number.isNaN(balcony)
                balcony = s === true ? 0 : balcony
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = balcony;
                obj.togBal = toggleBalc;
                data.push(obj);
                //lower to higher sort
                data = data.sort((a, b) => {
                    return a.PriceCalc - b.PriceCalc
                })
                //set balocny rank
                setBalconyRank(data)
                if (data.length === newprop.length) {
                    var balcchk = {}
                    balcchk.balcchks = true
                    balcchk.balconyweight = balconyweight
                    calculateCommonRank(data, balcchk)
                }
            }

        }
    }
    // Affordability Rank Order change weight of affordability
    const affordabilityRankWeigthChange = (e) => {
        var newPropArr = [];
        newprop.forEach((value, i) => {
            newPropArr.push(value);
        })
        var aa = newPropArr.sort(function (a, b) {


            // mortgagepp = mortgagepp ? mortgagepp.replace(',', '') : 0
            //   console.log("1617", mortgagepp)
            //    var affordRate = netpay - (monthlyexp + parseFloat(mortgagepp))

            var aMortgagePay = a.Mortgage && a.Mortgage.Scenarios[0] && a.Mortgage.Scenarios[0].totalMortgagePayment ? a.Mortgage.Scenarios[0].totalMortgagePayment : 0;
            var bMortgagePay = b.Mortgage && b.Mortgage.Scenarios[0] && b.Mortgage.Scenarios[0].totalMortgagePayment ? b.Mortgage.Scenarios[0].totalMortgagePayment : 0
            var affordRateA = netpay - (monthlyexp + parseFloat(aMortgagePay))
            var affordRateB = netpay - (monthlyexp + parseFloat(bMortgagePay))
            if (toggleAffordability === false) {
                return affordRateA - affordRateB;
            } else {
                return affordRateB - affordRateA;
            }

        });
        // if (aa[0]) {
        //     minPrice = aa && aa[0].Property && aa[0].Property.Price
        // }

        if (aa[0]) {
            affordMM = aa && aa[0].Mortgage.Scenarios[0] && aa[0].Mortgage.Scenarios[0].totalMortgagePayment
                ? aa[0].Mortgage.Scenarios[0].totalMortgagePayment : 0
            affordMM = netpay - (monthlyexp + parseFloat(affordMM))
        }
        var data = []
        for (var i = 0; i < newprop.length; i++) {
            if (affordabilityWeight && toggleAffordability === false) {
                var propsId = newprop[i]._id

                var affordpri = newprop[i].Mortgage && newprop[i].Mortgage && newprop[i].Mortgage.Scenarios[0]
                    && newprop[i].Mortgage.Scenarios[0].totalMortgagePayment
                    ? newprop[i].Mortgage.Scenarios[0].totalMortgagePayment : 0
                affordpri = netpay - (monthlyexp + parseFloat(affordpri))

                affordMM = affordMM ? affordMM : 0
                if (affordpri === 0) {
                    affordpri = "0"
                }

                if (affordMM && affordpri && affordabilityWeight) {

                    if (affordMM >= 0 || affordpri >= 0) {
                        afford = ((affordMM / affordpri) * parseInt(affordabilityWeight))
                    } else {
                        afford = ((affordpri / affordMM) * parseInt(affordabilityWeight))
                    }

                }
                // if (affordMM && affordpri && affordabilityWeight) {
                //     // afford = ((affordMM / affordpri) * parseInt(affordabilityWeight))
                //     afford = ((affordpri / affordMM) * parseInt(affordabilityWeight))
                // }
                afford = afford === undefined ? 0 : afford
                let s = Number.isNaN(afford)
                afford = s === true ? 0 : afford

                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = afford;
                obj.togAffordpay = toggleAffordability;
                data.push(obj);
                data = data.sort((a, b) => {
                    return b.PriceCalc - a.PriceCalc
                })
                setAffordabilityRank(data)
                if (data.length === newprop.length) {
                    var affordchk = {}
                    affordchk.affordschks = true
                    affordchk.affordweight = affordabilityWeight
                    calculateCommonRank(data, affordchk)
                }
            } else if (affordabilityWeight && toggleAffordability === true) {
                var propsId = newprop[i]._id
                var affordpaypri = newprop[i].Mortgage && newprop[i].Mortgage.Scenarios[0]
                    && newprop[i].Mortgage.Scenarios[0].totalMortgagePayment
                    ? newprop[i].Mortgage.Scenarios[0].totalMortgagePayment : 0
                affordMM = affordMM ? affordMM : 0

                affordpaypri = netpay - (monthlyexp + parseFloat(affordpaypri))

                if (affordpaypri === 0) {
                    affordpaypri = "0"
                }
                // if (affordMM && affordpaypri && affordabilityWeight) {
                //     //  afford = ((affordpaypri / affordMM) * parseInt(affordabilityWeight))
                //     afford = ((affordMM / affordpaypri) * parseInt(affordabilityWeight))
                // }

                if (affordMM && affordpaypri && affordabilityWeight) {

                    if (affordMM >= 0 || affordpaypri >= 0) {
                        afford = ((affordpaypri / affordMM) * parseInt(affordabilityWeight))
                    } else {
                        afford = ((affordMM / affordpaypri) * parseInt(affordabilityWeight))
                    }

                }

                afford = afford === undefined ? 0 : afford
                let s = Number.isNaN(afford)
                afford = s === true ? 0 : afford
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = afford;
                obj.togAffordpay = toggleAffordability;
                data.push(obj);
                data = data.sort((a, b) => {
                    return a.PriceCalc - b.PriceCalc
                })
                setAffordabilityRank(data)
                if (data.length === newprop.length) {
                    var affordchk = {}
                    affordchk.affordschks = true
                    affordchk.affordweight = affordabilityWeight
                    calculateCommonRank(data, affordchk)
                }
            }

        }
    }

    //Work Distance Rank Order change weight of distance rank weight
    const distanceRankWeigthChange = (e) => {
        var newPropArr = [];
        newprop.forEach((value, i) => {
            newPropArr.push(value);
        })
        var aa = newPropArr.sort(function (a, b) {
            var aDistance = parseFloat(a.Mortgage && a.Mortgage.DistanceMatrix && a.Mortgage.DistanceMatrix.WorkLocation ? a.Mortgage.DistanceMatrix.WorkLocation.split("Km") : 0);
            var bDistance = parseFloat(b.Mortgage && b.Mortgage.DistanceMatrix && b.Mortgage.DistanceMatrix.WorkLocation ? b.Mortgage.DistanceMatrix.WorkLocation.split("Km") : 0);
            if (toggleDistance === false) {
                return aDistance - bDistance;
            } else {
                return bDistance - aDistance;
            }

        });
        if (aa[0]) {
            distanceMM = aa && aa[0].Mortgage && aa[0].Mortgage.DistanceMatrix && aa[0].Mortgage.DistanceMatrix.WorkLocation ? aa[0].Mortgage.DistanceMatrix.WorkLocation.split("Km") : 0
        }

        var data = []
        for (var i = 0; i < newprop.length; i++) {

            if (distanceweight && toggleDistance === false) {
                var propsId = newprop[i]._id
                var distances = newprop[i].Mortgage && newprop[i].Mortgage.DistanceMatrix && newprop[i].Mortgage.DistanceMatrix.WorkLocation ? newprop[i].Mortgage.DistanceMatrix.WorkLocation : 0
                distances = parseFloat(distances ? distances.split("Km") : 0)
                distanceMM = distanceMM ? distanceMM : 0
                if (distanceMM && distances && distanceweight) {
                    distance = ((parseInt(distanceMM) / distances) * parseInt(distanceweight))
                }
                distance = distance === undefined ? 0 : distance
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = distance;
                obj.togdist = toggleDistance;
                data.push(obj);
                data = data.sort((a, b) => {
                    return b.PriceCalc - a.PriceCalc
                })
                setDistanceRank(data)
                if (data.length === newprop.length) {
                    var distchk = {}
                    distchk.distchks = true
                    distchk.distanceweight = distanceweight
                    calculateCommonRank(data, distchk)
                }
            }

            else if (distanceweight && toggleDistance === true) {
                var propsId = newprop[i]._id
                var distances = newprop[i].Mortgage && newprop[i].Mortgage.DistanceMatrix && newprop[i].Mortgage.DistanceMatrix.WorkLocation ? newprop[i].Mortgage.DistanceMatrix.WorkLocation : 0
                distances = parseFloat(distances ? distances.split("Km") : 0)
                distanceMM = distanceMM ? distanceMM : 0
                if (distanceMM && distances && distanceweight) {
                    distance = ((distances / parseInt(distanceMM)) * parseInt(distanceweight))
                }
                distance = distance === undefined ? 0 : distance
                // console.log("work distance", distance)
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = distance;
                obj.togdist = toggleDistance;
                data.push(obj);
                data = data.sort((a, b) => {
                    return a.PriceCalc - b.PriceCalc
                })
                setDistanceRank(data)
                if (data.length === newprop.length) {
                    var distchk = {}
                    distchk.distchks = true
                    distchk.distanceweight = distanceweight
                    calculateCommonRank(data, distchk)
                }
            }

        }
    }

    //School Distance2 Rank Order change weight of school distance 2
    const distance2RankWeigthChange = (e) => {
        var newPropArr = [];
        newprop.forEach((value, i) => {
            newPropArr.push(value);
        })

        var aa = newPropArr.sort(function (a, b) {
            // console.log("2437 aaa**", a, b)
            var aDistance2 = parseFloat(a.Mortgage && a.Mortgage.DistanceMatrix && a.Mortgage.DistanceMatrix.SchoolLocation ? a.Mortgage.DistanceMatrix.SchoolLocation.split("Km") : 0);
            var bDistance2 = parseFloat(b.Mortgage && b.Mortgage.DistanceMatrix && b.Mortgage.DistanceMatrix.SchoolLocation ? b.Mortgage.DistanceMatrix.SchoolLocation.split("Km") : 0);
            if (toggleDistance2 === false) {
                return aDistance2 - bDistance2;
            } else {
                return bDistance2 - aDistance2;
            }

        });

        if (aa[0]) {
            distance2MM = aa && aa[0].Mortgage && aa[0].Mortgage.DistanceMatrix && aa[0].Mortgage.DistanceMatrix.SchoolLocation ? aa[0].Mortgage.DistanceMatrix.SchoolLocation.split("Km") : 0
        }

        var data = []
        for (var i = 0; i < newprop.length; i++) {
            if (distance2weight && toggleDistance2 === false) {
                var propsId = newprop[i]._id
                var distances = newprop[i].Mortgage.DistanceMatrix && newprop[i].Mortgage.DistanceMatrix.SchoolLocation && newprop[i].Mortgage.DistanceMatrix.SchoolLocation ? newprop[i].Mortgage.DistanceMatrix.SchoolLocation : 0
                distances = parseFloat(distances ? distances.split("Km") : 0)
                distance2MM = distance2MM ? distance2MM : 0
                if (distance2MM && distances && distance2weight) {
                    distance2 = ((parseInt(distance2MM) / distances) * parseInt(distance2weight))
                }
                distance2 = distance2 === undefined ? 0 : distance2
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = distance2;
                obj.togdist2 = toggleDistance2;
                data.push(obj);
                data = data.sort((a, b) => {
                    return b.PriceCalc - a.PriceCalc
                })
                setDistanceRank2(data)
                if (data.length === newprop.length) {
                    var dist2chk = {}
                    dist2chk.dist2chks = true
                    dist2chk.distance2weight = distance2weight
                    calculateCommonRank(data, dist2chk)
                }
            }
            else if (distance2weight && toggleDistance2 === true) {
                var propsId = newprop[i]._id
                var distances = newprop[i].Mortgage.DistanceMatrix && newprop[i].Mortgage.DistanceMatrix.SchoolLocation && newprop[i].Mortgage.DistanceMatrix.SchoolLocation ? newprop[i].Mortgage.DistanceMatrix.SchoolLocation : 0
                distances = parseFloat(distances ? distances.split("Km") : 0)
                distance2MM = distance2MM ? distance2MM : 0
                if (distance2MM && distances && distance2weight) {
                    distance2 = ((distances / parseInt(distance2MM)) * parseInt(distance2weight))
                }
                distance2 = distance2 === undefined ? 0 : distance2
                // console.log("school distance2", distance2)
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = distance2;
                obj.togdist2 = toggleDistance2;
                data.push(obj);
                data = data.sort((a, b) => {
                    return a.PriceCalc - b.PriceCalc
                })
                setDistanceRank2(data)
                if (data.length === newprop.length) {
                    var dist2chk = {}
                    dist2chk.dist2chks = true
                    dist2chk.distance2weight = distance2weight
                    calculateCommonRank(data, dist2chk)
                }
            }
        }
    }

    //Other Distance Rank Order change weight of other distance
    const otherDistanceRankWeigthChange = (e) => {
        var newPropArr = [];
        newprop.forEach((value, i) => {
            newPropArr.push(value);
        })
        var aa = newPropArr.sort(function (a, b) {
            var aOtherLocation = parseFloat(a.Mortgage && a.Mortgage.DistanceMatrix && a.Mortgage.DistanceMatrix.OtherLocation ? a.Mortgage.DistanceMatrix.OtherLocation.split("Km") : 0);
            var bOtherLocation = parseFloat(b.Mortgage && b.Mortgage.DistanceMatrix && b.Mortgage.DistanceMatrix.OtherLocation ? b.Mortgage.DistanceMatrix.OtherLocation.split("Km") : 0);
            if (toggleOtherLocation === false) {
                return aOtherLocation - bOtherLocation;
            } else {
                return bOtherLocation - aOtherLocation;
            }

        });
        if (aa[0]) {
            otherLocationMM = aa && aa[0].Mortgage.DistanceMatrix && aa[0].Mortgage.DistanceMatrix.OtherLocation ? aa[0].Mortgage.DistanceMatrix.OtherLocation.split("Km") : 0
            otherLocationMM = otherLocationMM ? otherLocationMM[0] : 0
        }

        var data = []
        for (var i = 0; i < newprop.length; i++) {
            if (otherLocationweight && toggleOtherLocation === false) {
                var propsId = newprop[i]._id
                var otherLocations = newprop[i].Mortgage && newprop[i].Mortgage.DistanceMatrix && newprop[i].Mortgage.DistanceMatrix.OtherLocation ? newprop[i].Mortgage.DistanceMatrix.OtherLocation : 0
                otherLocations = parseFloat(otherLocations ? otherLocations.split("Km") : 0)
                if (otherLocationMM && otherLocations && otherLocationweight) {
                    otherLocation = ((parseInt(otherLocationMM) / otherLocations) * parseInt(otherLocationweight))
                }
                otherLocation = otherLocation === undefined ? 0 : otherLocation

                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = otherLocation;
                obj.togotherLocation = toggleOtherLocation;
                data.push(obj);
                // higher to lower
                data = data.sort((a, b) => {
                    return b.PriceCalc - a.PriceCalc
                })
                setOtherLocationRank(data)
                if (data.length === newprop.length) {
                    var otherLocationchk = {}
                    otherLocationchk.otherLocationchks = true
                    otherLocationchk.otherLocationweight = otherLocationweight
                    calculateCommonRank(data, otherLocationchk)
                }
            } else if (otherLocationweight && toggleOtherLocation === true) {
                var propsId = newprop[i]._id
                var otherLocations = newprop[i].Mortgage && newprop[i].Mortgage.DistanceMatrix && newprop[i].Mortgage.DistanceMatrix.OtherLocation ? newprop[i].Mortgage.DistanceMatrix.OtherLocation : 0
                otherLocations = parseFloat(otherLocations ? otherLocations.split("Km") : 0)
                if (otherLocationMM && otherLocations && otherLocationweight) {
                    otherLocation = ((otherLocations / parseInt(otherLocationMM)) * parseInt(otherLocationweight))
                }
                otherLocation = otherLocation === undefined ? 0 : otherLocation
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = otherLocation;
                obj.togotherLocation = toggleOtherLocation;
                data.push(obj);
                //lower to higher
                data = data.sort((a, b) => {
                    return a.PriceCalc - b.PriceCalc
                })
                setOtherLocationRank(data)
                if (data.length === newprop.length) {
                    var otherLocationchk = {}
                    otherLocationchk.otherLocationchks = true
                    otherLocationchk.otherLocationweight = otherLocationweight
                    calculateCommonRank(data, otherLocationchk)
                }
            }
        }
    }


    // Area Rank Order change weight of area
    const areaRankWeigthChange = (e) => {
        var newPropArr = [];
        newprop.forEach((value, i) => {
            newPropArr.push(value);
        })
        var aa = newPropArr.sort(function (a, b) {
            var aArea = parseFloat(a.Building && a.Building.SizeInterior ? a.Building.SizeInterior.split("sqft") : 0);
            var bArea = parseFloat(b.Building && b.Building.SizeInterior ? b.Building.SizeInterior.split("sqft") : 0);
            //according to toggle
            if (toggleArea === false) {
                return aArea - bArea;
            } else {
                return bArea - aArea;
            }

        });
        if (aa[0]) {
            areaMM = aa && aa[0].Building && aa[0].Building.SizeInterior ? aa[0].Building.SizeInterior.split("sqft") : 0
            areaMM = areaMM ? areaMM[0] : 0
        }

        var data = []
        for (var i = 0; i < newprop.length; i++) {
            if (areaweight && toggleArea === false) {
                var propsId = newprop[i]._id
                var areas = newprop[i].Building && newprop[i].Building.SizeInterior ? newprop[i].Building.SizeInterior : 0
                areas = parseFloat(areas ? areas.split("sqft") : 0)
                if (areas === 0) {
                    areas = "0"
                }
                if (areaMM && areas && areaweight) {
                    area = ((parseInt(areaMM) / areas) * parseInt(areaweight))
                }
                area = area === undefined ? 0 : area
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = area;
                obj.togarea = toggleArea;
                data.push(obj);
                data = data.sort((a, b) => {
                    return b.PriceCalc - a.PriceCalc
                })
                setAreaRank(data)
                if (data.length === newprop.length) {
                    var areachk = {}
                    areachk.areachks = true
                    areachk.areaweight = areaweight
                    calculateCommonRank(data, areachk)
                }
            } else if (areaweight && toggleArea === true) {
                var propsId = newprop[i]._id
                var areaa = newprop[i].Building && newprop[i].Building.SizeInterior ? newprop[i].Building.SizeInterior : 0
                areaa = parseFloat(areaa ? areaa.split("sqft") : 0)
                if (areaa === 0) {
                    areaa = "0"
                }
                if (areaMM && areaa && areaweight) {
                    area = ((areaa / parseInt(areaMM)) * parseInt(areaweight))
                }
                area = area === undefined ? 0 : area
                var obj = {};
                obj.propId = propsId;
                obj.PriceCalc = area;
                obj.togarea = toggleArea;
                data.push(obj);
                data = data.sort((a, b) => {
                    return a.PriceCalc - b.PriceCalc
                })
                setAreaRank(data)
                if (data.length === newprop.length) {
                    var areachk = {}
                    areachk.areachks = true
                    areachk.areaweight = areaweight
                    calculateCommonRank(data, areachk)
                }
            }

        }
    }

    //OverAll Rank Order 
    var sortedCommonRank;
    const calculateCommonRank = (data, allchks) => {
        var commonRankArr = [];

        //price over all rank
        if (allchks.pricechks !== undefined && allchks.pricechks === true) {
            setPRank(data)
            var minPrice;
            var dataP = []
            var newPropArr = [];
            newprop.forEach((value, i) => {
                newPropArr.push(value);
            })

            var aa = newPropArr.sort(function (a, b) {
                var aPrice = parseFloat(a && a.Property && a.Property.Price && a.Property.Price ? a.Property.Price.replace('$', '').replace(/,/g, '').trim() : 0);
                var bPrice = parseFloat(b && b.Property && b.Property.Price && b.Property.Price ? b.Property.Price.replace('$', '').replace(/,/g, '').trim() : 0);
                return aPrice - bPrice;

            });
            if (aa[0]) {
                minPrice = aa && aa[0].Property && aa[0].Property.Price
            }

            for (var i = 0; i < newprop.length; i++) {
                if (allchks.priceweight) {
                    var priceweight = allchks.priceweight
                    var propsId = newprop[i]._id
                    var pricec = newprop[i].Property.Price
                    pricec = parseFloat(pricec.split("$")[1].replace(/,/g, ''))
                    var minpp = parseFloat(minPrice ? minPrice.split("$")[1].replace(/,/g, '') : 0)
                    if (minpp && pricec && priceweight) {
                        pri = ((minpp / pricec) * priceweight)
                    }
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = pri;
                    dataP.push(obj);
                    dataP = dataP.sort((a, b) => {
                        return b.PriceCalc - a.PriceCalc
                    })
                    setCommonRankPrice(dataP)
                }
            }
        }

        //bath over all rank
        if (allchks.bathchks !== undefined && allchks.bathchks === true) {
            setBathRank(data)
            var newPropArr = [];
            newprop.forEach((value, i) => {
                newPropArr.push(value);
            })

            //higher to lower
            var aa = newPropArr.sort(function (a, b) {
                var aBathroom = parseFloat(a && a.Building && a.Building.BathroomTotal ? a.Building.BathroomTotal : 0);
                var bBathroom = parseFloat(b && b.Building && b.Building.BathroomTotal ? b.Building.BathroomTotal : 0);
                return bBathroom - aBathroom;
            });

            if (aa[0]) {
                var z = aa && aa[0].Building && aa[0].Building.BathroomTotal ? aa[0].Building.BathroomTotal : 0
                if (z && z.length > 1) {
                    splitfun(z)
                    maxBathroom = splitfun(z)
                } else {
                    maxBathroom = z
                }
            }

            var dataB = []
            for (var i = 0; i < newprop.length; i++) {
                var BathroomTotals = newprop[i].Building && newprop[i].Building.BathroomTotal
                    ? newprop[i].Building.BathroomTotal : 0
                if (allchks.bathroomweight) {
                    var bathroomweight = allchks.bathroomweight
                    if (BathroomTotals && BathroomTotals.length > 1) {
                        var propsId = newprop[i]._id
                        BathroomTotals = splitfun(BathroomTotals)
                        BathroomTotals = splitfun(BathroomTotals)
                        bath = ((BathroomTotals / maxBathroom) * parseInt(bathroomweight))
                        bath = bath === undefined ? 0 : bath
                        var obj = {};
                        obj.propId = propsId;
                        obj.PriceCalc = bath;
                        obj.togBath = toggleBath;
                        dataB.push(obj);
                        dataB = dataB.sort((a, b) => {
                            return a.PriceCalc - b.PriceCalc
                        })
                        setCommonRankBath(dataB)
                    } else {
                        var propsId = newprop[i]._id
                        bath = ((BathroomTotals / maxBathroom) * parseInt(bathroomweight))
                        bath = bath === undefined ? 0 : bath
                        var obj = {};
                        obj.propId = propsId;
                        obj.PriceCalc = bath;
                        obj.togBath = toggleBath;
                        dataB.push(obj);
                        dataB = dataB.sort((a, b) => {
                            return a.PriceCalc - b.PriceCalc
                        })
                        setCommonRankBath(dataB)
                    }
                }
            }
        }

        //Mortgage overall rank
        if (allchks.mortchks !== undefined && allchks.mortchks === true) {
            setMortgageRank(data)

            var newPropArr = [];
            newprop.forEach((value, i) => {
                newPropArr.push(value);
            })

            //lower ot higher
            var aa = newPropArr.sort(function (a, b) {
                var aMortgage = a.Mortgage && a.Mortgage.Scenarios[0] && a.Mortgage.Scenarios[0].totalMortgageRequired ? a.Mortgage.Scenarios[0].totalMortgageRequired : 0;
                var bMortgage = b.Mortgage && b.Mortgage.Scenarios[0] && b.Mortgage.Scenarios[0].totalMortgageRequired ? b.Mortgage.Scenarios[0].totalMortgageRequired : 0;
                return aMortgage - bMortgage;
            });

            if (aa[0]) {
                mortgageMM = aa && aa[0].Mortgage.Scenarios[0] && aa[0].Mortgage.Scenarios[0].totalMortgageRequired
                    ? aa[0].Mortgage.Scenarios[0].totalMortgageRequired : 0
            }

            var dataM = []
            for (var i = 0; i < newprop.length; i++) {
                //Mortgage loser is the best
                if (allchks.mortgageweight) {
                    var mortgageweight = allchks.mortgageweight
                    var propsId = newprop[i]._id
                    var mortgagepri = newprop[i].Mortgage && newprop[i].Mortgage.Scenarios[0]
                        && newprop[i].Mortgage.Scenarios[0].totalMortgageRequired
                        ? newprop[i].Mortgage.Scenarios[0].totalMortgageRequired : 0

                    mortgageMM = mortgageMM ? mortgageMM : 0
                    if (mortgageMM && mortgagepri && mortgageweight) {
                        mortgage = ((mortgageMM / mortgagepri) * parseInt(mortgageweight))
                    }
                    mortgage = mortgage === undefined ? 0 : mortgage

                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = mortgage;
                    obj.togMort = toggleMortgage;
                    dataM.push(obj);
                    dataM = dataM.sort((a, b) => {
                        return b.PriceCalc - a.PriceCalc
                    })
                    //set common rank
                    setCommonRankMortgage(dataM)

                }
            }
        }

        //Mortgage payment overall rank
        if (allchks.mortpaychks !== undefined && allchks.mortpaychks === true) {
            setMortgagePayRank(data)

            var newPropArr = [];
            newprop.forEach((value, i) => {
                newPropArr.push(value);
            })

            //sort properties by mortgage value
            var aa = newPropArr.sort(function (a, b) {
                var aMortgagePay = a.Mortgage && a.Mortgage.Scenarios[0] && a.Mortgage.Scenarios[0].totalMortgagePayment ? a.Mortgage.Scenarios[0].totalMortgagePayment : 0;
                var bMortgagePay = b.Mortgage && b.Mortgage.Scenarios[0] && b.Mortgage.Scenarios[0].totalMortgagePayment ? b.Mortgage.Scenarios[0].totalMortgagePayment : 0
                return aMortgagePay - bMortgagePay;
            });
            if (aa[0]) {
                mortgagepayMM = aa && aa[0].Mortgage.Scenarios[0] && aa[0].Mortgage.Scenarios[0].totalMortgagePayment
                    ? aa[0].Mortgage.Scenarios[0].totalMortgagePayment : 0
            }

            var dataM = []
            for (var i = 0; i < newprop.length; i++) {
                if (allchks.mortgagepayweight) {
                    var mortgagepayweight = allchks.mortgagepayweight
                    var propsId = newprop[i]._id

                    var mortgagepaypri = newprop[i].Mortgage && newprop[i].Mortgage && newprop[i].Mortgage.Scenarios[0]
                        && newprop[i].Mortgage.Scenarios[0].totalMortgagePayment
                        ? newprop[i].Mortgage.Scenarios[0].totalMortgagePayment : 0

                    mortgagepayMM = mortgagepayMM ? mortgagepayMM : 0

                    if (mortgagepayMM && mortgagepaypri && mortgagepayweight) {
                        mortgagepay = ((mortgagepayMM / mortgagepaypri) * parseInt(mortgagepayweight))
                    }
                    mortgagepay = mortgagepay === undefined ? 0 : mortgagepay
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = mortgagepay;
                    obj.togMortpay = toggleMortgagePay;
                    dataM.push(obj);
                    //higher to lower
                    dataM = dataM.sort((a, b) => {
                        return b.PriceCalc - a.PriceCalc
                    })
                    //set common rank
                    setCommonRankMortgagePay(dataM)

                }
            }
        }

        //Propertytax overall rank
        if (allchks.proptaxchks !== undefined && allchks.proptaxchks === true) {
            setPropertyTaxRank(data)

            var newPropArr = [];
            newprop.forEach((value, i) => {
                newPropArr.push(value);
            })
            // newPropArr is properties array
            var aa = newPropArr.sort(function (a, b) {
                var aPropTax = 0;
                var bPropTax = 0;
                return aPropTax - bPropTax;
            });

            if (aa[0]) {
                proptaxMM = aa && aa[0] ? aa[0] : 0
            }

            var dataP = []
            for (var i = 0; i < newprop.length; i++) {
                if (allchks.proptaxweight) {
                    var proptaxweight = allchks.proptaxweight
                    var propsId = newprop[i]._id
                    var proppri = 0

                    proptaxMM = proptaxMM ? proptaxMM : 0
                    //higher is the best
                    if (proptaxweight && proppri && proptaxweight) {
                        propertytax = ((proptaxMM / proppri) * proptaxweight)
                    }
                    propertytax = propertytax === undefined ? 0 : propertytax
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = propertytax;
                    obj.togprotax = togglePropTax;
                    dataP.push(obj);
                    //sort obj 
                    dataP = dataP.sort((a, b) => {
                        return b.PriceCalc - a.PriceCalc
                    })
                    //set common rank of property
                    setCommonRankPropertyTax(dataP)
                }
            }
        }

        //Bedroom Overall rank
        if (allchks.bedchks !== undefined && allchks.bedchks === true) {
            setBedRank(data)

            var newPropArr = [];
            newprop.forEach((value, i) => {
                newPropArr.push(value);
            })

            //newPropArr(properties) sort
            var aa = newPropArr.sort(function (a, b) {
                var aBed = parseFloat(a && a.Building && a.Building.Bedrooms && a.Building.Bedrooms ? a.Building.Bedrooms : 0);
                var bBed = parseFloat(b && b.Building && b.Building.Bedrooms && b.Building.Bedrooms ? b.Building.Bedrooms : 0);
                return bBed - aBed;
            });

            if (aa[0]) {
                var z = aa && aa[0].Building && aa[0].Building.Bedrooms ? aa[0].Building.Bedrooms : 0
                if (z && z.length > 1) {
                    splitfun(z)
                    maxBedroom = splitfun(z)
                }
                else {
                    maxBedroom = z
                }
            }

            var dataB = []
            for (var i = 0; i < newprop.length; i++) {
                var Bedroomss = newprop[i].Building && newprop[i].Building.Bedrooms
                    && newprop[i].Building.Bedrooms ? newprop[i].Building.Bedrooms : 0
                //check value in allchks bedroomweight is exist then in to the if case
                if (allchks.bedroomweight) {
                    if (Bedroomss && Bedroomss.length > 1) {
                        var propsId = newprop[i]._id
                        Bedroomss = splitfun(Bedroomss)
                        //higher is the best
                        bed = ((Bedroomss / maxBedroom) * parseInt(bedroomweight))
                        bed = bed === undefined ? 0 : bed
                        var obj = {};
                        obj.propId = propsId;
                        obj.PriceCalc = bed;
                        obj.togBed = toggleBed;
                        dataB.push(obj);
                        dataB = dataB.sort((a, b) => {
                            return a.PriceCalc - b.PriceCalc
                        })
                        //common rank of bedroom
                        setCommonRankBed(dataB)

                    }
                    else {
                        bed = ((Bedroomss / maxBedroom) * parseInt(bedroomweight))
                        bed = bed === undefined ? 0 : bed
                        var propsId = newprop[i]._id
                        var obj = {};
                        obj.propId = propsId;
                        obj.PriceCalc = bed;
                        obj.togBed = toggleBed;
                        dataB.push(obj);
                        dataB = dataB.sort((a, b) => {
                            return a.PriceCalc - b.PriceCalc
                        })
                        setCommonRankBed(dataB)
                    }
                }
            }
        }

        //Balcony overall rank
        if (allchks.balcchks !== undefined && allchks.balcchks === true) {
            setBalconyRank(data)
            var newPropArr = [];
            newprop.forEach((value, i) => {
                newPropArr.push(value);
            })
            var aa = newPropArr.sort(function (a, b) {
                var aBalcony = 0;
                var bBalcony = 0;
                return bBalcony - aBalcony;
            });
            if (aa[0]) {
                balconyMM = aa && aa[0] ? aa[0] : 0
            }

            var dataB = []
            for (var i = 0; i < newprop.length; i++) {
                if (allchks.balconyweight) {
                    var balconyweight = allchks.balconyweight
                    var propsId = newprop[i]._id
                    var balcony = 0
                    balconyMM = balconyMM ? balconyMM : 0
                    //lower is the best
                    if (balconyMM && balcony && balconyweight) {
                        balcony = ((balcony / balconyMM) * balconyweight)
                    }
                    balcony = balcony === undefined ? 0 : balcony
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = balcony;
                    obj.togBal = toggleBalc;
                    dataB.push(obj);
                    //sort dataB lower to higher
                    dataB = dataB.sort((a, b) => {
                        return a.PriceCalc - b.PriceCalc
                    })
                    setCommonRankBalcony(dataB)
                }
            }
        }

        // Affordability overall rank
        if (allchks.affordchk !== undefined && allchks.affordchk === true) {
            setAffordabilityRank(data)

            var newPropArr = [];
            newprop.forEach((value, i) => {
                newPropArr.push(value);
            })

            //affordability lower to higher
            var aa = newPropArr.sort(function (a, b) {
                var aaffordPay = a.Mortgage && a.Mortgage.Scenarios[0] && a.Mortgage.Scenarios[0].totalMortgagePayment ? a.Mortgage.Scenarios[0].totalMortgagePayment : 0;
                var baffordPay = b.Mortgage && b.Mortgage.Scenarios[0] && b.Mortgage.Scenarios[0].totalMortgagePayment ? b.Mortgage.Scenarios[0].totalMortgagePayment : 0
                var affordRateA = netpay - (monthlyexp + parseFloat(aaffordPay))
                var affordRateB = netpay - (monthlyexp + parseFloat(baffordPay))
                return affordRateA - affordRateB;
            });

            if (aa[0]) {
                affordMM = aa && aa[0].Mortgage.Scenarios[0] && aa[0].Mortgage.Scenarios[0].totalMortgagePayment
                    ? aa[0].Mortgage.Scenarios[0].totalMortgagePayment : 0
                affordMM = netpay - (monthlyexp + parseFloat(affordMM))
            }

            var dataM = []
            for (var i = 0; i < newprop.length; i++) {
                if (allchks.affordweight) {
                    var affordweight = allchks.affordweight
                    var propsId = newprop[i]._id

                    var affordpaypri = newprop[i].Mortgage && newprop[i].Mortgage && newprop[i].Mortgage.Scenarios[0]
                        && newprop[i].Mortgage.Scenarios[0].totalMortgagePayment
                        ? newprop[i].Mortgage.Scenarios[0].totalMortgagePayment : 0

                    affordpaypri = netpay - (monthlyexp + parseFloat(affordpaypri))

                    affordMM = affordMM ? affordMM : 0

                    //higher is the best
                    if (affordMM && affordpaypri && affordweight) {
                        afford = ((affordMM / affordpaypri) * parseInt(affordweight))
                    }
                    afford = afford === undefined ? 0 : afford
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = afford;
                    obj.togAffordpay = toggleAffordability;
                    dataM.push(obj);
                    dataM = dataM.sort((a, b) => {
                        return b.PriceCalc - a.PriceCalc
                    })
                    setCommonRankAffordability(dataM)
                }
            }
        }

        //Area overall rank
        if (allchks.areachks !== undefined && allchks.areachks === true) {
            setAreaRank(data)

            var newPropArr = [];
            newprop.forEach((value, i) => {
                newPropArr.push(value);
            })

            //higher to lower
            var aa = newPropArr.sort(function (a, b) {
                var aArea = parseFloat(a.Building && a.Building.SizeInterior ? a.Building.SizeInterior.split('sqft') : 0);
                var bArea = parseFloat(b.Building && b.Building.SizeInterior ? b.Building.SizeInterior.split('sqft') : 0);
                return bArea - aArea;
            });

            if (aa[0]) {
                areaMM = aa && aa[0].Building && aa[0].Building.SizeInterior ? aa[0].Building.SizeInterior.split("sqft") : 0
                areaMM = areaMM ? areaMM[0] : 0
            }

            var dataA = []
            for (var i = 0; i < newprop.length; i++) {
                if (allchks.areaweight) {
                    var areaweight = allchks.areaweight
                    var propsId = newprop[i]._id
                    var areaa = newprop[i].Building && newprop[i].Building.SizeInterior ? newprop[i].Building.SizeInterior : 0
                    areaa = parseFloat(areaa ? areaa.split("sqft") : 0)
                    if (areaMM && areaa && areaweight) {
                        area = ((areaa / parseInt(areaMM)) * parseInt(areaweight))
                    }
                    area = area === undefined ? 0 : area
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = area;
                    obj.togarea = toggleArea;
                    dataA.push(obj);
                    dataA = dataA.sort((a, b) => {
                        return a.PriceCalc - b.PriceCalc
                    })
                    setCommonRankArea(dataA)
                }
            }
        }

        //Work distance overall rank 
        if (allchks.distchks !== undefined && allchks.distchks === true) {
            setDistanceRank(data)
            var newPropArr = [];
            newprop.forEach((value, i) => {
                newPropArr.push(value);
            })

            //lower to higher
            var aa = newPropArr.sort(function (a, b) {
                var aDistance = parseFloat(a.Mortgage && a.Mortgage.DistanceMatrix && a.Mortgage.DistanceMatrix.WorkLocation && a.Mortgage.DistanceMatrix.WorkLocation ? a.Mortgage.DistanceMatrix.WorkLocation.split('Km') : 0);
                var bDistance = parseFloat(b.Mortgage && b.Mortgage.DistanceMatrix && b.Mortgage.DistanceMatrix.WorkLocation && b.Mortgage.DistanceMatrix.WorkLocation ? b.Mortgage.DistanceMatrix.WorkLocation.split('Km') : 0);
                return aDistance - bDistance;
            });
            if (aa[0]) {
                distanceMM = aa && aa[0].Mortgage && aa[0].Mortgage.DistanceMatrix && aa[0].Mortgage.DistanceMatrix.WorkLocation && aa[0].Mortgage.DistanceMatrix.WorkLocation ? aa[0].Mortgage.DistanceMatrix.WorkLocation.split("Km") : 0
                distanceMM = distanceMM ? distanceMM[0] : 0
            }

            var dataD = []
            for (var i = 0; i < newprop.length; i++) {
                if (allchks.distanceweight) {
                    var distanceweight = allchks.distanceweight
                    var propsId = newprop[i]._id
                    var distances = newprop[i].Mortgage && newprop[i].Mortgage.DistanceMatrix && newprop[i].Mortgage.DistanceMatrix.WorkLocation && newprop[i].Mortgage.DistanceMatrix.WorkLocation ? newprop[i].Mortgage.DistanceMatrix.WorkLocation : 0
                    distances = parseFloat(distances ? distances.split("Km") : 0)

                    distanceMM = distanceMM ? distanceMM : 0
                    //higher is the best
                    if (distanceMM && distances && distanceweight) {
                        distance = ((parseInt(distanceMM) / distances) * parseInt(distanceweight))
                    }
                    distance = distance === undefined ? 0 : distance
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = distance;
                    obj.togdist = toggleDistance;
                    dataD.push(obj);
                    dataD = dataD.sort((a, b) => {
                        return b.PriceCalc - a.PriceCalc
                    })
                    setCommonRankDistance(dataD)
                }
            }
        }

        //School distance overall rank
        if (allchks.dist2chks !== undefined && allchks.dist2chks === true) {
            setDistanceRank2(data)

            var newPropArr = [];
            newprop.forEach((value, i) => {
                newPropArr.push(value);
            })

            var aa = newPropArr.sort(function (a, b) {
                // console.log("overall rank", a, b)
                var aDistance2 = parseFloat(a.Mortgage && a.Mortgage.DistanceMatrix && a.Mortgage.DistanceMatrix.SchoolLocation ? a.Mortgage.DistanceMatrix.SchoolLocation.split('Km') : 0);
                var bDistance2 = parseFloat(b.Mortgage && b.Mortgage.DistanceMatrix && b.Mortgage.DistanceMatrix.SchoolLocation ? b.Mortgage.DistanceMatrix.SchoolLocation.split('Km') : 0);
                return aDistance2 - bDistance2;
            });

            if (aa[0]) {
                // console.log("3119", aa[0])
                distance2MM = aa && aa[0].Mortgage && aa[0].Mortgage.DistanceMatrix && aa[0].Mortgage.DistanceMatrix.SchoolLocation ? aa[0].Mortgage.DistanceMatrix.SchoolLocation.split("Km") : 0
                distance2MM = distance2MM ? distance2MM[0] : 0
            }

            var dataD = []
            for (var i = 0; i < newprop.length; i++) {
                if (allchks.distance2weight) {
                    var distance2weight = allchks.distance2weight
                    var propsId = newprop[i]._id
                    var distances = newprop[i].Mortgage && newprop[i].Mortgage.DistanceMatrix && newprop[i].Mortgage.DistanceMatrix.SchoolLocation ? newprop[i].Mortgage.DistanceMatrix.SchoolLocation : 0
                    distances = parseFloat(distances ? distances.split("Km") : 0)

                    distance2MM = distance2MM ? distance2MM : 0
                    if (distance2MM && distances && distance2weight) {
                        distance2 = ((parseInt(distance2MM) / distances) * parseInt(distance2weight))
                    }
                    distance2 = distance2 === undefined ? 0 : distance2
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = distance2;
                    obj.togdist2 = toggleDistance2;
                    dataD.push(obj);
                    dataD = dataD.sort((a, b) => {
                        return b.PriceCalc - a.PriceCalc
                    })
                    setCommonRankDistance2(dataD)
                }
            }
        }

        //Other distance overall rank
        if (allchks.otherLocationchks !== undefined && allchks.otherLocationchks === true) {
            setOtherLocationRank(data)

            var newPropArr = [];
            newprop.forEach((value, i) => {
                newPropArr.push(value);
            })

            var aa = newPropArr.sort(function (a, b) {
                var aOtherLocation = parseFloat(a.Mortgage && a.Mortgage.DistanceMatrix && a.Mortgage.DistanceMatrix.OtherLocation ? a.Mortgage.DistanceMatrix.OtherLocation.split('Km') : 0);
                var bOtherLocation = parseFloat(b.Mortgage && b.Mortgage.DistanceMatrix && b.Mortgage.DistanceMatrix.OtherLocation ? b.Mortgage.DistanceMatrix.OtherLocation.split('Km') : 0);
                return aOtherLocation - bOtherLocation;
            });

            if (aa[0]) {
                otherLocationMM = aa && aa[0].Mortgage && aa[0].Mortgage.DistanceMatrix && aa[0].Mortgage.DistanceMatrix.OtherLocation ? aa[0].Mortgage.DistanceMatrix.OtherLocation.split("Km") : 0
                otherLocationMM = otherLocationMM ? otherLocationMM[0] : 0
            }

            var dataD = []
            for (var i = 0; i < newprop.length; i++) {
                if (allchks.otherLocationweight) {
                    var otherLocationweight = allchks.otherLocationweight
                    var propsId = newprop[i]._id
                    var otherLocationss = newprop[i].Mortgage && newprop[i].Mortgage.DistanceMatrix && newprop[i].Mortgage.DistanceMatrix.OtherLocation ? newprop[i].Mortgage.DistanceMatrix.OtherLocation : 0
                    otherLocationss = parseFloat(otherLocationss ? otherLocationss.split("Km") : 0)

                    otherLocationMM = otherLocationMM ? otherLocationMM : 0
                    if (otherLocationMM && otherLocationss && otherLocationweight) {
                        otherLocation = ((parseInt(otherLocationMM) / otherLocationss) * parseInt(otherLocationweight))
                    }
                    otherLocation = otherLocation === undefined ? 0 : otherLocation
                    var obj = {};
                    obj.propId = propsId;
                    obj.PriceCalc = otherLocation;
                    obj.togotherLocation = toggleOtherLocation;
                    dataD.push(obj);
                    dataD = dataD.sort((a, b) => {
                        return b.PriceCalc - a.PriceCalc
                    })
                    setCommonRankOtherLocation(dataD)
                }
            }
        }

        //Overall rank order
        newprop.forEach((value, i) => {
            var objCommonRank = {};
            objCommonRank.propId = value._id;
            var priceRank = CommonRankPrice && CommonRankPrice.length > 0 ? CommonRankPrice.find(m => m.propId == value._id).PriceCalc : 0
            var mortRank = CommonRankMortgage && CommonRankMortgage.length > 0 ? CommonRankMortgage.find(m => m.propId == value._id).PriceCalc : 0
            var mortpayRank = CommonRankMortgagePay && CommonRankMortgagePay.length > 0 ? CommonRankMortgagePay.find(m => m.propId == value._id).PriceCalc : 0
            var affordRank = CommonRankAffordability && CommonRankAffordability.length > 0 ? CommonRankAffordability.find(m => m.propId == value._id).PriceCalc : 0
            var proptaxRank = CommonRankPropertyTax && CommonRankPropertyTax.length > 0 ? CommonRankPropertyTax.find(m => m.propId == value._id).PriceCalc : 0
            var bedRank = CommonRankBed && CommonRankBed.length > 0 ? CommonRankBed.find(m => m.propId == value._id).PriceCalc : 0
            var bathRank = CommonRankBath && CommonRankBath.length > 0 ? CommonRankBath.find(m => m.propId == value._id).PriceCalc : 0
            var balRank = CommonRankBalcony && CommonRankBalcony.length > 0 ? CommonRankBalcony.find(m => m.propId == value._id).PriceCalc : 0
            var areaRank = CommonRankArea && CommonRankArea.length > 0 ? CommonRankArea.find(m => m.propId == value._id).PriceCalc : 0
            var distRank = CommonRankDistance && CommonRankDistance.length > 0 ? CommonRankDistance.find(m => m.propId == value._id).PriceCalc : 0
            // DistanceRank && DistanceRank.length > 0 ? DistanceRank.find(m => m.propId == value._id).PriceCalc : 0;
            var dist2Rank = CommonRankDistance2 && CommonRankDistance2.length > 0 ? CommonRankDistance2.find(m => m.propId == value._id).PriceCalc : 0
            // DistanceRank2 && DistanceRank2.length > 0 ? DistanceRank2.find(m => m.propId == value._id).PriceCalc : 0;
            var otherlocationRank = CommonRankOtherLocation && CommonRankOtherLocation.length > 0 ? CommonRankOtherLocation.find(m => m.propId == value._id).PriceCalc : 0
            var total = priceRank + mortRank + mortpayRank + affordRank + proptaxRank + bedRank + bathRank
                + balRank + areaRank + distRank + dist2Rank + otherlocationRank;
            objCommonRank.commanRank = total;
            commonRankArr.push(objCommonRank);
        })
        //higher to lower
        sortedCommonRank = commonRankArr.sort((a, b) => {
            return b.commanRank - a.commanRank
        })
        var a = sortedCommonRank
        setCommonRankProp(a)
    }

    //show common rank order
    useEffect(() => {
        CommonRankShow({ CommonRankProp })
    }, [CommonRankProp, sortedCommonRank])

    //split function use to split "+" symbol
    function splitfun(a) {
        if (a.length > -1) {
            var s = a && a.split("+")
            var as = (parseInt(s[0]) + parseInt(s[1]))
            return as;
        }
    }


    //set other location name
    useEffect(() => {
        if (newprop != undefined && newprop.length > 0) {
            if (newprop[0].otherLocationsDistance && newprop[0].otherLocationsDistance.length > 0) {
                newprop[0].otherLocationsDistance.forEach((value, k) => {
                    locationNames.push(value.name);
                })
            }
        }
        setotherLocationName(locationNames)
        var Name = OtherLocationsName
    }, [OtherLocationsName, newprop])

    //Table Data set in table
    var rows = [
        createData(0, 'Price', <InputField Value={priceweight} onChange={setpriceweight} />, <SwitchCheck checked={togglePrice} onChange={() => settogglePrice(!togglePrice)} />, priceCompare(newprop, PRank)),
        createData(1, 'Mortgage', <InputField Value={mortgageweight} onChange={setmortgageweight} />, <SwitchCheck checked={toggleMortgage} onChange={() => settoggleMortgage(!toggleMortgage)} />, mortgageCompare(newprop, MortgageRank)),
        createData(2, 'Mortgage Payment', <InputField Value={mortgagepayweight} onChange={setmortgagepayweight} />, <SwitchCheck checked={toggleMortgagePay} onChange={() => settoggleMortgagePay(!toggleMortgagePay)} />, mortgagePaymentCompare(newprop, MortgagePayRank)),
        // createData(3, 'Property Tax', <InputField Value={proptaxweight} onChange={setproptaxweight} />, <SwitchCheck checked={togglePropTax} onChange={() => settogglePropTax(!togglePropTax)} />, propertyTaxCompare(newprop, PropertyTaxRank)),
        createData(3, 'Affordability', <InputField Value={affordabilityWeight} onChange={setaffordabilityWeight} />, <SwitchCheck checked={toggleAffordability} onChange={() => settoggleAffordability(!toggleAffordability)} />, affordabilityCompare(newprop, AffordabilityRank)),
        createData(4, 'Bedrooms', <InputField Value={bedroomweight} onChange={setbedroomweight} />, <SwitchCheck checked={toggleBed} onChange={() => settoggleBed(!toggleBed)} />, bedroomsCompare(newprop, BedRank)),
        createData(5, 'Bathrooms', <InputField Value={bathroomweight} onChange={setbathroomweight} />, <SwitchCheck checked={toggleBath} onChange={() => settoggleBath(!toggleBath)} />, bathroomsCompare(newprop, BathRank)),
        createData(6, 'Balcony', <InputField Value={balconyweight} onChange={setbalconyweight} />, <SwitchCheck checked={toggleBalc} onChange={() => settoggleBalc(!toggleBalc)} />, balconyCompare(newprop, BalconyRank)),
        createData(7, 'Area', <InputField Value={areaweight} onChange={setareaweight} />, <SwitchCheck checked={toggleArea} onChange={() => settoggleArea(!toggleArea)} />, areaCompare(newprop, AreaRank)),
        createData(8, 'Work Distance', <InputField Value={distanceweight} onChange={setdistanceweight} />, <SwitchCheck checked={toggleDistance} onChange={() => settoggleDistance(!toggleDistance)} />, distanceCompare(newprop, DistanceRank)),
        createData(9, 'School Distance', <InputField Value={distance2weight} onChange={setdistance2weight} />, <SwitchCheck checked={toggleDistance2} onChange={() => settoggleDistance2(!toggleDistance2)} />, distance2Compare(newprop, DistanceRank2)),
        // createData(10, otherLocationName, <InputField Value={otherLocationweight} onChange={setotherLocationweight} />, <SwitchCheck checked={toggleOtherLocation} onChange={() => settoggleOtherLocation(!toggleOtherLocation)} />, otherLocationCompare(newprop, OtherLocationRank)),
        createData(10, "Other Distance", <InputField Value={otherLocationweight} onChange={setotherLocationweight} />, <SwitchCheck checked={toggleOtherLocation} onChange={() => settoggleOtherLocation(!toggleOtherLocation)} />, otherLocationCompare(newprop, OtherLocationRank)),
    ];

    //use for other location
    var otherLocationStartingIndex = rows.length;
    otherLocationName.forEach((value, i) => {
        rows.push(createData(otherLocationStartingIndex + i, value, <InputField Value={otherLocationweight} onChange={setotherLocationweight} />, <SwitchCheck checked={toggleOtherLocation} onChange={() => settoggleOtherLocation(!toggleOtherLocation)} />, otherLocationCompare(newprop, OtherLocationRank)))
    })

    return (
        <TableContainer component={Paper} >
            <Table className={classes.table} aria-label="customized table" id="tableCompare">
                <TableHead>
                    <TableRow style={{ width: '100%' }}>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell><PropertyMenu propCount={propCount} /></StyledTableCell>
                        <StyledTableCell style={{ borderLeft: 'none' }}></StyledTableCell>
                        {newprop && newprop.map((list, k) => {
                            return (
                                <React.Fragment key={k + "aa"}>

                                    <StyledTableCell>
                                        <ImgDetail weblink={list}
                                            img={list.Property && list.Property.Photo.length > 0 && list.Property.Photo[0].HighResPath ? list.Property.Photo[0].HighResPath : ''}
                                            title={list.title} des={list.Property.Address.AddressText} />
                                    </StyledTableCell>
                                    <StyledTableCell style={{ borderLeft: 'none' }}></StyledTableCell>
                                </React.Fragment>
                            )

                        })}
                    </TableRow>
                    {/* overall rank order */}
                    <TableRow><ContentBar barName='Overall Rank' commonRank={commonRank} newprop={newprop} /></TableRow>
                    <TableRow style={{ width: '100%' }}>
                        <StyledTableCell style={{ width: '0.01%' }}>Attributes</StyledTableCell>
                        <StyledTableCell style={{ width: '0.01%' }} >Importance</StyledTableCell>
                        <StyledTableCell style={{ width: '9%' }} >Sort</StyledTableCell>
                        <TableRowCells newprop={newprop} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.attribute}>
                            <StyledTableCell scope="row">
                                {row.attribute}
                            </StyledTableCell>
                            <StyledTableCell scope="row">
                                {row.inputf}
                            </StyledTableCell>
                            <StyledTableCell scope="row">
                                {row.switchtoggle}
                            </StyledTableCell>
                            <TableCellValues row={row} />
                        </StyledTableRow>
                    ))}
                    <StyledTableRow>
                        <StyledTableCell>
                            Total
                        </StyledTableCell>
                        <StyledTableCell>
                            {(Number(priceweight) + Number(mortgageweight) + Number(mortgagepayweight) + Number(proptaxweight)
                                + Number(bedroomweight) + Number(bathroomweight) + Number(balconyweight) + Number(areaweight) + Number(distanceweight) + Number(distance2weight) + Number(otherLocationweight))}
                        </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}