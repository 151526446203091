import React, { useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Paper } from "@material-ui/core";
import logo from '../../assest/logo.png';
// import logo from '../../assest/logo.png';
// var editPropValNext, editPropValBack;

//flyout header
function Header({ buttons, activeStep, maxSteps, handleBack, handleNext, Logout }) {
  const [IsboxShadow, setIsboxShadow] = useState(false)

  // useEffect(() => {
  //   editPropValNext = localStorage.getItem("editPropNext")
  //   editPropValBack = parseInt(localStorage.getItem("editPropBack"))
  //   console.log("13131313*****", editPropValBack)
  // }, [localStorage.getItem("editPropNext"), localStorage.getItem("editPropBack")])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [])
  
  //change a design of paper
  function handleScroll(event) {
    if (window.scrollY > 20) {
      setIsboxShadow(true)
    }
    else {
      setIsboxShadow(false)
    }
  }
  //KeyboardArrowLeft and KeyboardArrowRight flyout arrow button 
  return (
    <Paper variant="elevation" id="aiLogout"
      style={{
        width: '100%',
        position: 'fixed',
        height: '80px',
        background: '#fff',
        zIndex: 6000,
        boxShadow: IsboxShadow ? "2px -4px 30px 0px" : "none"
      }}
    >

      <a href="https://www.wyzeguide.com/" target="_blank"><img src={logo} alt="logo" 
      style={{ padding: '0px 0px 0px 10px', height: '80px' }} /></a>
      { window.store.data.Properties && window.store.data.Properties.length > 0 ? <span style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        margin: "auto",
        fontSize: "15px",
        fontWeight: 600,
        padding: "28px",
        paddingLeft: "50px",
      }}><a href="https://www.wyzeguide.com/home" target="_blank">Property Board</a></span> : ''}
      {
        buttons && <div style={{ float: "right", marginTop: '25px', marginRight: "10px" }}>
          <span style={{ cursor: 'pointer' }} onClick={Logout}> Logout  </span>
          <Button size="large" onClick={handleBack} disabled={activeStep === 0}
            style={{
              minWidth: 0,
              padding: "4px",
              color: activeStep !== 0 && "#f02834"
            }}>
            {<KeyboardArrowLeft style={{ fontSize: '40px', left: '-470px', top: '137px', position: 'absolute' }} />}
          </Button>
          <Button size="large" onClick={handleNext} disabled={activeStep === maxSteps - 1}
            style={{
              minWidth: 0,
              padding: "4px",
              color: activeStep !== maxSteps - 1 && "#f02834"
            }}>
            {<KeyboardArrowRight style={{ fontSize: '40px', top: '137px', marginLeft: '4px', position: 'absolute' }} />}
          </Button>
        </div>
      }
    </Paper >
  )
}

export default React.memo(Header)
// inject("store")(observer(Home))