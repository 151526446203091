import React, { Suspense, useEffect, useState } from 'react'
import { TextField, Grid, Icon, Container, CardMedia, Button } from '@material-ui/core';
import logo from '../../assest/logo.png';
import AppleBtn from '../../assest/AppleBtn.png';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import GooglePlayBtn from '../../assest/GooglePlayBtn.png';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { NavLink, Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Remax from '../../assest/Remax.png';
import CentrisCa from '../../assest/CentrisCa.png';
import Century21 from '../../assest/Century21.png';
import RealtorCa from '../../assest/RealtorCa.png';
import RoyalPage from '../../assest/RoyalPage.png';
import WorkImg1 from '../../assest/WorkImg1.png';

// import AddToBrowser from '../../assest/AddToBrowser.gif';
// import RealtorSites from '../../assest/RealtorSites.png';
// import AddToFavorite from '../../assest/AddToFavorite.gif';
// import MortgageCalculator from '../../assest/MortgageCalculator.png';
// import DashboardNew from '../../assest/DashboardNew.png';

// import AddToBrowser from '../../assest/corousalAssets/Add_To_Chrome_Trim.gif';
// import RealtorSites from '../../assest/corousalAssets/RealtorSites.png';
// import AddToFavorite from '../../assest/corousalAssets/Add_To_Fav_Trim.gif';
// import MortgageCalculator from '../../assest/corousalAssets/Mortgage.png';
// import DashboardNew from '../../assest/corousalAssets/PropertyBoard.png';

import AddToBrowser from '../../assest/corousalAssets/addDasshh.gif';
import RealtorSites from '../../assest/corousalAssets/realtorsitess.png';
import AddToFavorite from '../../assest/corousalAssets/addFav.gif';
import MortgageCalculator from '../../assest/corousalAssets/Mortgage-Calculatorrr.png';
import DashboardNew from '../../assest/corousalAssets/PropertyBoarddd.png';

import WorkImg2 from '../../assest/WorkImg2.png';
import WorkImg3 from '../../assest/WorkImg3.png';
import WorkImg4 from '../../assest/WorkImg4.png';
import WorkImg5 from '../../assest/WorkImg5.png';
import WorkImg6 from '../../assest/WorkImg6.png';
import test1 from '../../assest/test1.png';
import test2 from '../../assest/test2.png';
import Paper from '@material-ui/core/Paper';
import BankofMontreal from '../../assest/BankofMontreal.png';
import CibcBank from '../../assest/CibcBank.png';
import Nationalbank from '../../assest/Nationalbank.png';
import RbcBank from '../../assest/RbcBank.png';
import Scotiabank from '../../assest/Scotiabank.png';
import TdBank from '../../assest/TdBank.png';
import SimpleImageSlider from "react-simple-image-slider";
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import Avatar from '@material-ui/core/Avatar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import OurTestimonialsImg from '../../assest/OurTestimonialsImg.png';
import bgsilder from '../../assest/bg.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Fragment } from 'react';

// import HorizontalSlider from 'react-horizontal-slider'

const useStyles = makeStyles(theme => ({
  flyout: {
    marginTop: '5px',
    maxWidth: '1250px',
    margin: '0 auto',
  },
  login: {
    float: 'right',
    width: 'auto',
    textTransform: 'none',
    backgroundColor: "red",
    color: 'white'
  },
  btn: {
    marginTop: '12px', color: 'red', display: 'inline-block',
    marginLeft: '10px', marginRight: '10px',
  },
  icon: {
    marginTop: '4%',
    maxWidth: '1250px',
    margin: '0 auto',
    textAlign: 'center'
  },
  icon2: {
    marginTop: '4%',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    backgroundImage: "url(" + bgsilder + ")",
    backgroundSize: "cover"
  },
  imgClass: {
    width: '100%'
  },
  dec: {
    marginBlockStart: '0',
    marginBlockEnd: '0',
    opacity: 0.5
  },
  title: {
    marginBlockEnd: '0.3em',
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 340,
  },
  rootpaper: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(27),
      height: theme.spacing(29),
    },
  },
  rootpaperBank: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(22),
      height: theme.spacing(25),
    },
  },
  rootFreqQuestion: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  logoImg: {
    maxWidth: '80%',
    margin: '0 auto'
  },
}));

const styles = {
  media: {
    position: 'relative'
  },
  overlay: {
    color: 'black'
  },
  exploreBtn: {
    backgroundColor: 'white',
    float: 'left',
    clear: 'both',
    width: '210px',
    height: '70px',
    fontSize: '19px',
    fontWeight: '600',
  }
}

function Landing({ }) {
  const [tokens, setToken] = useState("")
  const classes = useStyles();
  const history = useHistory()

  useEffect(() => {
    if (localStorage.getItem("token")) {
      console.log("token", localStorage.getItem("token"))
      // window.location.pathname = '/home'
      setToken(localStorage.getItem("token"))
    }
  }, [])

  //extension link open into new tab 
  function AddChromeFunc() {
    window.open('https://chrome.google.com/webstore/detail/wyzeguide/adiajnagkpifaapdjaolgfbjlnefoeke')
    // window.href = 'https://chrome.google.com/webstore/detail/wyzeguide/adiajnagkpifaapdjaolgfbjlnefoeke';
    // window.location.pathname='https://chrome.google.com/webstore/detail/wyzeguide/adiajnagkpifaapdjaolgfbjlnefoeke';
  }
  return (
    <Suspense fallback={<div>loading...</div>}>
      <Container maxWidth="" style={{ padding: '0px' }}>

        <Grid container item maxWidth="sm" style={{ marginTop: '5px' }} justify="space-between" className={classes.flyout}>
          <Grid item xs={6} sm={4} md={2} lg={2} xl={1} spacing={6} className="landingLogo" style={{ padding: '0px' }}>
            <Icon>
              <img src={logo} style={{ width: '200px' }} />
            </Icon>
          </Grid>
          <Grid item xs={5} sm={7} md={8} lg={8} xl={9} spacing={2}
            style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '0px', }}>
            <Button className='WyzeGuideBtn' variant="contained" onClick={() => AddChromeFunc()} >
              Add WyzeGuide</Button>
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2} xl={2} className='loginRegisterLink'
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {
              tokens ?
                <NavLink to='/home' style={{ fontWeight: 'bold', color: 'black' }}>Property Board</NavLink>
                :
                <>
                  <NavLink to='/login' style={{ fontWeight: 'bold', color: 'black' }}>Login</NavLink>
                  <NavLink to='/register' style={{ color: 'black', fontWeight: 'bold', marginLeft: '10px' }}>
                    Register
                  </NavLink>
                </>
            }

          </Grid>
        </Grid>
        <CardMedia className='mainImage' style={styles.media}>
          <Grid container justify="" className={classes.flyout}>
            <Grid item style={styles.overlay} className="overlayLanding" xs={12} sm={12} md={8} >
              <h1 className="textSize"> A New Way To<span style={{ color: 'red' }}> Find </span> <br /> your <span style={{ color: 'red' }}>Dream Home</span>
              </h1>
              <p className='btnExplore'>An AI powered hub that makes finding your dream <br />home simple and easy.</p>

              <Button class='chromeBtn' onClick={() => AddChromeFunc()}>Add to Chrome It's Free</Button>
              {/* <p className='GoogleAppleBtn'>
                <span><img src={AppleBtn} className='imgGoogleApplebtn' /></span>
                <span className='GooglePlayBtn'><img src={GooglePlayBtn} className='imgGoogleApplebtn' /></span>
              </p> */}
            </Grid>
          </Grid>
        </CardMedia>
        <Grid container className="corousalOuter">
          {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h1 className="landingTextSize">How it <span style={{ color: 'red' }}>works</span></h1>
          </Grid> */}
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item >
            <WorkSlider />
          </Grid>
        </Grid>
        <Grid container className={classes.icon}>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <div className="pTagCanadianRealtors">
              <p>Add the WyzeGuide browser extension. Click on any of the sites below to find your dream home.</p>
            </div>
            <h1 className="landingTextSize2">Top Canadian <span style={{ color: 'red' }}>Realtors</span></h1>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item className="relator_bank">
            <RealtorsCard />
          </Grid>
        </Grid>
        <Grid container className={classes.icon}>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
          <div className="pTagCanadianRealtors">
            <p>Apply the latest rates in the mortgage calculator</p>
              {/* <p>One stop to access mortgage rates from  top banks in Canada</p> */}
            </div>
            <h1 className="landingTextSize2">Top Canadian <span style={{ color: 'red' }}>Banks   </span></h1>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item className="canadian_bank">
            <BankCard />
          </Grid>
        </Grid>

        <Grid container className={classes.icon}>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <h1 className="landingTextSize">Frequently asked <span style={{ color: 'red' }}> Questions </span></h1>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} container
          // className="canadian_bank"
          >
            <FrequentlyQuestion />
          </Grid>
        </Grid>

        <Grid container className={classes.icon2}>
          {/* <img src={OurTestimonialsImg}> */}
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
            <h1 className="landingTextSize">Our <span style={{ color: 'red' }}>Testimonials </span></h1>
            {/* <div className="pTagCanadianRealtors"> <p>One stop to access mortgage rates from  top banks in Canada</p>
                        </div> */}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} container item className="testimonial">
            <Sliders />
          </Grid>
          {/* </img> */}
        </Grid>

        <p style={{ textAlign: 'center', marginTop: '50px' }}>&copy; Copyright 2010-2021 Narus Technologics Inc.</p>
      </Container >
    </Suspense>
  )
}

export default Landing;

//FAQ question
const FrequentlyQuestion = () => {
  const classes = useStyles();
  var heartSymbol = { a: "&#9829;" }
  const QuestionData = [
    { Question: 'Is it free?', Answer: 'Yes, WyzeGuide is free to use by home buyers.' },
    { Question: 'How up to date are the mortgage rates:', Answer: 'Mortgage rates are updated nightly' },
    { Question: 'Are you sharing my information?', Answer: 'WyzeGuide does not share information to 3rd parties. You have the option to share information through the Share button.' },
    { Question: 'How accurate is the land transfer tax?', Answer: 'The land transfer tax is updated periodically. Therefore, there is a chance the latest land transfer rates are not reflected in the app. You are advised to confirm with your real estate agent on the expected land transfer tax and not rely solely on WyzeGuide as a sole authority on taxes, costs or listing information.' },
    { Question: "What happens if the house I’ve favorited has been sold?", Answer: 'Once you click on a property on your Property Board, a new tab will be opened in the browser. If the property has been sold or is off the market, the property will not be shown on the real estate site.' },
    { Question: 'How do I rank properties?', Answer: 'Select two or three properties and a Compare button will appear on the top right of the page. Select the Compare button and you the properties will be displayed on a comparison page. You can allocate a total of 100 points across the different attributes of each property. Allocate these points based on the relative importance of the attributes. Toggle H or L based on whether the attribute is better if the value is high or low. For example, higher number of bedrooms is better and having a lower mortgage payment is better.' },
    { Question: 'How do you I share a property.', Answer: 'Sharing a property is done at the category level. All properties in the shared category will be made available to the person you are sharing with In the Property Board, select a category you would like to share and then select invite. Enter the email address of the person you would like to share the category with. An invite to share will be sent. There are two modes of sharing (a) View and (b) Edit. View will allow the other person to only view the properties that you add into the category. Edit will allow the other person to edit the financial calculations of each property or remove properties from that category. Once you have sent out the share invite, the person you are sharing with will receive an email. They must accept your invite to see the shared category. They will then need to log onto their Property Board and your shared category will appear in their category list. It will appear as “Your First Name” – “Category Name”. For example, Dianne wants to share all the houses in the category Investment with her realtor Brian. On Dianne’s Property Board the category will appear as Investment. On Brian’s it will appear as Dianne-Investment.' },
    { Question: 'Can I remove properties?', Answer: <span>Yes. On the Property Board click on the <span> &#8285;</span> and select Delete.</span> },
    {
      Question: 'How do I favorite properties?', Answer: <span>Install the WyzeGuide extension and then visit the list of supported real estate sites. Once you are on an individual property listing page, the WyzeGuide fly-out will appear on the right side of the panel. Click on the bookmark
        {/* <span style={{ color: 'red' }}> <BookmarkIcon /> </span>  */}
        <span><img style={{ width: '17px', height: '19px', margin: '0px 5px' }} src="https://i.imgur.com/NXEXy2P.png" /></span>
        to favorite and the property will automatically saved to your Property Board.</span>
    },
    { Question: 'How do I update the mortgage terms?', Answer: <span>There are two ways to do this. The first is on the Property Board. Select <span> &#8285;</span> and select Edit. On the edit page, you can update the mortgage terms. Secondly, you can update the terms in the WyzeGuide extension. In the fly-out, use the &lt; &gt; [arrow buttons] and navigate to the property you are want to update the terms on. Scroll down to Mortgage Amount and update the terms.</span> },
    // { Question: 'Is there a mobile app?', Answer: '' },
    // { Question: 'How up to data are the listings?', Answer: '' },
    // { Question: 'How does the ranking works?', Answer: '' },
    // { Question: 'Is my information secure?', Answer: '' },
    // { Question: 'Glossary of common mortgage related terms.', Answer: '' },
    // <span style="font-size:500%;color:red;">&hearts;</span>
  ]
  return (
    <div >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container spacing={2} style={{ margin: '0px' }}>
        {QuestionData && QuestionData.map((i, j) => (
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} spacing={2} className="faq">
            <Accordion style={{ backgroundColor: '#eee' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{i.Question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {i.Answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

//Website vards
const RealtorsCard = () => {
  const classes = useStyles();
  const RealtorData = [
    // { img: CentrisCa, link: 'https://www.centris.ca' },
    // { img: Century21, link: 'https://www.c21.ca' },
    { img: Remax, link: 'https://www.remax.ca' },
    { img: RoyalPage, link: 'https://www.royallepage.ca' },
    { img: RealtorCa, link: 'https://www.realtor.ca' }
  ]
  // className='rootPaperRealtorCard' className={classes.rootpaper}
  // console.log("RealtorDataRealtorData 208", RealtorData)
  return (
    <div className={classes.rootpaper}>
      {RealtorData && RealtorData.map((i, j) => (
        <CardActionArea href={i.link} className='rootPaperRealtorCard'>
          <Paper elevation={3} style={{ height: '230px' }}> <img src={i.img} />
          </Paper></CardActionArea>
      ))}
    </div>
  );
}

//Bank cards
const BankCard = () => {
  const classes = useStyles();
  const RealtorData = [
    { img: TdBank, title: 'TD Bank' },
    { img: RbcBank, title: 'RBC Bank' },
    { img: BankofMontreal, title: 'BMO Bank' },
    { img: Scotiabank, title: 'Scotia Bank' },
    { img: CibcBank, title: 'CIBC Bank' },
    { img: Nationalbank, title: 'National Bank' }
  ]
  // console.log("RealtorDataRealtorData 208", RealtorData)
  return (
    <div className={classes.rootpaperBank}>
      {RealtorData && RealtorData.map((i, j) => (
        <Card className='rootPaperBankCard'>
          <CardActionArea >
            <Paper elevation={3} ><img src={i.img} /> </Paper>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2" style={{ opacity: 0.5 }}>{i.title}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </div>
  );
}

// How its work
const WorkSlider = () => {
  const [ImgVal, setImgVal] = useState(0)
  const [seconds, setSeconds] = useState(0);

  const classes = useStyles();
  const slides = [
    { id: 1, title: 'Install WyzeGuide on your browser', description: '' },
    {
      id: 2, title: 'Visit these leading Canadian real estate sites', description:
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <a href='https://www.realtor.ca/' style={{ marginRight: '15px' }}><Paper elevation={3} style={{ maxWidth: '90px', height: '50px', display: 'flex', alignItems: 'center' }}> <img className={classes.logoImg} src={RealtorCa} /></Paper></a> <br />
          <a href='https://www.remax.ca/' style={{ marginRight: '15px' }}><Paper elevation={3} style={{ maxWidth: '90px', height: '50px', display: 'flex', alignItems: 'center' }}> <img className={classes.logoImg} src={Remax} /></Paper></a> <br />
          <a href="https://www.royallepage.ca/en/" style={{ marginRight: '15px' }}><Paper elevation={3} style={{ maxWidth: '90px', height: '50px', display: 'flex', alignItems: 'center' }}> <img className={classes.logoImg} src={RoyalPage} /></Paper></a>
        </div>
    },
    { id: 3, title: 'Find and favorite a property you like', description: <><p>WyzeGuide’s favourite button  <img width="18px" src='https://i.imgur.com/suVUvnO.png'></img> <img width="18px" src='https://i.imgur.com/NXEXy2P.png'></img>  will automatically appear on the right edge of the browser</p></> },
    { id: 4, title: "Calculate your mortgage payment with WyzeGuide's mortgage calculator.", description: 'WyzeGuide has the latest mortage rates.  In the fly-out or Property Board add your down payment, select a mortgage rate from top banks, calculate your mortgage payments and closing costs' },
    { id: 5, title: 'Visit your WyzeGuide Property Board to share and rank properties', description: <p>Review all your saved properties, share with family and friends, compare, rank and decide which property is best for you.<br />Answer these questions by completing your profile:<ul style={{ paddingLeft: '20px', margin: '0' }}><li>Is my dream home affordable? </li><li> How far is my dream home from important locations?</li></ul></p> },
    // { title: 'Decide', description: 'Wyzeguide, an artificial intelligence bot will help rank order your properties and make the difficult decision simpler and easier' }
  ];

  //List of images
  const ImgSlider = [
    { img: AddToBrowser },
    { img: RealtorSites },
    { img: AddToFavorite },
    { img: MortgageCalculator },
    { img: DashboardNew },
    // { img: WorkImg6 }
  ]
  const [ImageSliders, setImageSliders] = useState(ImgSlider);

  //change img in how its work 
  const setImgValfun = (e) => {

    setImageSliders(ImgSlider)
    setImgVal(e)
    setSeconds(e + 1);
    
  }
  

  //set time to change img 
  useEffect(() => {
    const interval = setInterval(() => {
      setImgVal(seconds)
      setSeconds(seconds => seconds + 1);
    }, 5800);
    if (seconds > 4) {
      setSeconds(0)
    }
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <Grid xs={12} sm={12} md={12} lg={12} xl={12} container item spacing={2} style={{ display: 'flex', justifyContent: 'normal' }}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <h1 className="landingTextSize">How it <span style={{ color: 'red' }}>works</span></h1>
      </Grid>
      <Grid className="corousalBG" xs={12} sm={7} md={7} lg={7} xl={7} container item>
        {ImageSliders && ImageSliders.length > 0 && ImageSliders.map((slide, index) => <div key={index} className="corousalInner">
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} item style={{maxHeight: '610px', objectFit: 'cover'}}>
            <img src={slide.img} alt="Avatar"
              style={{
                // width: '100%',
                width: '100%',
                display: ImgVal === index ? '' : 'none'
                //  borderRadius: '50px', backgroundColor: 'burlywood',
                // borderRadius: '50%', height: '150px'
              }}
              id={index}
            />
          </Grid>
        </div>)}
      </Grid>
      <Grid xs={12} sm={5} md={5} lg={5} xl={5} container item spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="paddingArea">
          {slides.map((slide, index) => <div key={index} style={{ direction: 'ltr' }}>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
              <div className="slider_work" style={{
                cursor: 'pointer',
                opacity: 1
                // opacity: ImgVal === index ? 1 : 1
              }}
                onClick={() => setImgValfun(index)}
                defaultValue={index} id={index}><strong style={{ opacity: ImgVal === index ? 1 : 0.7, color: 'red', float: 'left', fontSize: '16px' }}>{slide.id}. {slide.title}</strong>
                <br />
                <p> {slide.description}</p></div>
            </Grid>
          </div>)}
        </Grid>
      </Grid>
    </Grid>
  )
}

const AutoplaySlider = withAutoplay(AwesomeSlider);

//Testimonials
const Sliders = () => {
  var slide;
  const slides = [
    slide = [{ img: test1, title: 'First item', description: 'What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book it has?' },
    { img: test2, title: 'Second item', description: 'Lorem ipsum ard dummy text ever since the ard dummy text ever since the ard dummy text ever since the ard dummy text ever since the ' }],
    slide = [{ img: test1, title: 'Three item', description: 'What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book it has?' },
    { img: test2, title: 'Four item', description: 'Lorem ipsum ard dummy text ever since the ard dummy text ever since the ard dummy text ever since the ard dummy text ever since the ' }],
    slide = [{ img: test1, title: 'Five item', description: 'What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book it has?' },
    { img: test2, title: 'Six item', description: 'Lorem ipsum ard dummy text ever since the ard dummy text ever since the ard dummy text ever since the ' }]
  ];
  return (
    <AutoplaySlider
      play={true}
      cancelOnInteraction={false} // should stop playing on user interaction
      interval={6000}
    >
      {slides.map((slide, index) => <div key={index} style={{ objectFit: 'contain' }}>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} container justify="center" style={{ objectFit: 'contain' }}>
          {slide.map((i, j) => <React.Fragment>
            <Grid container item xs={12} sm={5} md={5} lg={5} xl={5} spacing={2} style={{ objectFit: 'contain' }}>
              <Grid xs={12} sm={4} md={4} lg={4} xl={4} item spacing={2}>
                <img src={i.img} alt="Avatar" style={{
                  maxWidth: '90%', borderRadius: '50px', backgroundColor: 'burlywood',
                  borderRadius: '50%',
                }} />

              </Grid>
              <Grid xs={12} sm={8} md={8} lg={8} xl={8} item spacing={2}>
                <div><strong style={{ color: 'red', float: 'left' }}>  {i.title}</strong></div>
                <div>
                  <p style={{ opacity: 0.5, float: 'left' }}>{"10 Auguest 2020"}</p><br /><br />
                  <p> {i.description}</p>
                </div>
              </Grid>
            </Grid>
          </React.Fragment>)}
        </Grid>
      </div>)}
      {/* <div data-src="/path/to/image-0.png" />
            <div data-src="/path/to/image-1.png" />
            <div data-src="/path/to/image-2.jpg" /> */}
    </AutoplaySlider>
  );
}


const ASlider = () => {
  var slide;
  const slides = [
    slide = [{ img: CentrisCa, title: 'First item', description: 'What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book it has?' },
    { img: CentrisCa, title: 'Second item', description: 'Lorem ipsum' }],
    slide = [{ img: CentrisCa, title: 'Three item', description: 'What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book it has?' },
    { img: CentrisCa, title: 'Four item', description: 'Lorem ipsum' }],
    slide = [{ img: CentrisCa, title: 'Five item', description: 'What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book it has?' },
    { img: CentrisCa, title: 'Six item', description: 'Lorem ipsum' }]
  ];
  return (
    <Slider >
      {slides.map((slide, index) => <div key={index}>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} container spacing={5} >
          {slide.map((i, j) => <React.Fragment>
            {console.log("445 ii", i)}
            <Grid container xs={6} sm={6} md={6} lg={6} xl={6} >
              <Grid xs={4} sm={4} md={4} lg={4} xl={4} item  >
                <img src={CentrisCa} alt="Avatar" style={{
                  width: '150px', borderRadius: '50px', backgroundColor: 'burlywood',
                  borderRadius: '50%', height: '150px'
                }} />

              </Grid>
              <Grid xs={8} sm={8} md={8} lg={8} xl={8} item  >
                <div><strong style={{ color: 'red', float: 'left' }}>  {i.title}</strong></div>
                <br />
                <div>
                  <p style={{ opacity: 0.5, float: 'left' }}>{"10 Auguest 2020"}</p><br /><br />
                  <p> {i.description}</p>
                </div>

              </Grid>
              {/* </section> */}
            </Grid>
          </React.Fragment>)}
        </Grid>
      </div>)}
    </Slider>
  )

}