import React, { useEffect, useState } from 'react';
import { getAccessToken } from '../../const/function';
import Axios from 'axios';
import { Api } from '../../const';
import {
    Button, Grid, CircularProgress, Container
} from '@material-ui/core';
import swal from 'sweetalert';
import logo from '../../assest/alogo.png';

// import history from 'history';

//token verification
function TokenVerification() {
    const [btnToken, setbtnToken] = useState(false)
    const [loading, setloading] = useState(false)
    const [tokenData, settokenData] = useState('')
    var tokenverify;

    //get link verifyLink
    useEffect(() => {
        var a = window.location.search
        a = a.split('?code=')
        a = a[1]
      //  console.log("21", a)
        if (a) {
            VerifyLink(a)
        }
    }, [])

    //verify link  and call api of verification link
    function VerifyLink(a) {
        setloading(true)
        if (localStorage.getItem('token')) {
            var head = {
                headers: {
                    "x-accesstoken": localStorage.getItem('token'),
                    "x-invitetoken": a
                }
            }
            return Axios.post(Api.baseUrl + "/api/Collaboration/verifyInvitationLink", null, head)
                .then(res => {
                    if (res.data.result.message === "InvalidToken") {
                        setloading(false)
                        return getAccessToken().then(res => res ? VerifyLink() : '')
                    }
                    else {
                        if (res.data.result.message === "NotAuthorized") {
                            //  window.location.pathname = '/home'
                            setbtnToken(true)
                            tokenverify = res
                            settokenData(tokenverify)
                            setloading(false)
                        }
                        else {
                            setbtnToken(true)
                            tokenverify = res.data.result.data
                            settokenData(tokenverify)
                            setloading(false)
                        }
                        setloading(false)
                        return res.data.result;
                    }
                }).catch(err => {
                    setloading(false)
                })
        } else {
            setloading(false)
            window.location.pathname = '/login'
        }
    }

    //accept invitation by invited user
    function AcceptInvite() {
        setloading(true)
        if (tokenData && tokenData.data && tokenData.data.result && tokenData.data.result.message === "NotAuthorized") {
            window.location.pathname = '/home'
        } else {

            var head = {
                headers: {
                    "x-accesstoken": localStorage.getItem('token')
                }
            };

            var body = {
                "collaborationId": tokenData.collaborationId
            }

            return Axios.post(Api.baseUrl + '/api/Collaboration/accept', body, head)
                .then(res => {
                    if (res.data.result.message === "AcceptedSuccessFully") {
                        swal({
                            title: "Success!",
                            text: res.data.result.message,
                            icon: "success",
                            buttons: {
                                confirm: {
                                    value: window.location.pathname = '/home',
                                },
                            },
                        })
                    }
                    setloading(false)
                }).catch(err => {
                    setloading(false)
                    console.log(err)
                })
        }
    }

    return (
        <Container style={{ backgroundColor: '#FFFFFF', marginTop: '10px' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <img style={{ width: "35%", marginTop: '20px' }} src={logo}></img>
                <h1>Yes, I'm excited to see what you have found for me!</h1>
                {/* <h1 style={{
                marginLeft: '34%',
                marginTop: '8%'
            }}>Collaboration token verification</h1> */}
                {loading && <div style={{
                    position: 'fixed',
                    zIndex: 20000,
                    height: '100%',
                    background: "#ffffff94",
                    opacity: 1,
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "30vmin",
                    left: '8px',
                    marginTop: '0px'
                }}>
                    <CircularProgress size={30} />
                </div>}
                {btnToken ? <Button
                    style={{
                        border: '1px solid red',
                        backgroundColor: 'red',
                        color: 'white',
                        left: '46%',
                        marginTop: '12%'
                    }}
                    onClick={AcceptInvite}
                >Show Me</Button> :
                    <Button style={{
                        border: '1px solid red',
                        backgroundColor: 'red',
                        color: 'white',
                        left: '46%',
                        marginTop: '12%'
                    }} disabled>Show Me</Button>
                }
                <p style={{ textAlign: 'center' }}><b style={{ paddingTop: '35px' }}>Click on the button to accept and go to your Property Board</b></p>

            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={4} xl={4}> */}
            <p style={{ textAlign: 'center', paddingTop: '75px' }}>&copy; Copyright 2010-2021 Narus Technologics Inc.</p>
            {/* </Grid> */}
        </Container>
    )
}

export default TokenVerification;