import React, { Suspense, useEffect, useState } from "react";
import CoursalSlider from "../imageSlider";
import {
  FormControlLabel,
  Grid,
  Icon,
  Container,
  Checkbox,
  Avatar,
  Link,
  Button,
  Menu,
  MenuItem,
  Modal,
  Input,
  Select,
  CircularProgress,
  Radio,
  FormControl,
  RadioGroup,
  FormLabel,
  Switch,
  FormGroup,
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import home from "../../assest/home.png";
import cross from "../../assest/cross.png";
import img1 from "../../assest/img_1.png";
import budget from "../../assest/budget.png";
import profile from "../../assest/profile_pic.png";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import tex from "../../assest/tex.png";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Slider from "@material-ui/core/Slider";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {
  getCat,
  getUser,
  getMortgage,
  updatePropertyCommentCategory,
  getAccessToken,
  postCategory,
  deleteCategory,
  sortProperties,
  deleteProperty,
  inviteMails,
  getInviteMails,
  getCollaborationInvitations,
  updateCollaborationInvitation,
  deleteInvitation,
} from "../../const/function";
import { getDistanceBetweenAddress } from "../../const/location";
import { inject, observer } from "mobx-react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";
const Header = React.lazy(() => import("../Header"));
const Footer = React.lazy(() => import("../Footer"));

//CSS
const useStyles = makeStyles((theme) => ({
  flyout: {
    marginTop: "5px",
    maxWidth: "1250px",
    margin: "0 auto",
  },
  login: {
    float: "right",
    width: "auto",
  },
  btn: {
    marginTop: "12px",
    color: "green",
    // display: 'inline-block',
    top: "99px",
    right: "417px",
    position: "fixed",
    marginLeft: "10px",
    // marginRight: '10px',
    border: "solid",
    // margin: theme.spacing(2),
  },
  icon: {
    marginTop: "4%",
    maxWidth: "1250px",
    margin: "0 auto",
    textAlign: "center",
  },
  fontTitle: {
    // marginTop: '1%',
    // maxWidth: '1170px',
    maxWidth: "100%",
    margin: "0 auto",
    textAlign: "center",
    paddingTop: "5px",
  },
  imgClass: {
    margin: theme.spacing(1),
    height: 180,
    overflow: "hidden",
    display: "block",
    width: "100%",
    cursor: "pointer",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  dec: {
    marginBlockStart: "0",
    marginBlockEnd: "0",
    fontSize: "13px",
    // opacity: 0.5
  },
  title: {
    marginBlockEnd: "0.3em",
  },
  menuItem: {
    top: "66px",
    right: "110px !important",
  },
  imgMenu: {
    color: "red",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(0),
    },
    top: "60px",
  },
  root1: {
    // minWidth: '125px'
    marginTop: "36px",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabStyle: {
    minWidth: "130px",
    backgroundColor: "white",
    color: "black",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    top: "20%!important",
    left: "25%!important",
    position: "absolute",
    width: 300,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: "400px",
    overflowY: "scroll",
    minHeight: "200px",
    bottom: "auto",
  },
  texterror: {
    color: "red",
  },

  invitePopup: {
    top: "49%!important",
    left: "49%!important",
    position: "absolute",
    minWidth: "450px !important",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  inviteCross: {
    height: "13px",
    width: "13px",
    position: "absolute",
    top: "37px",
    /* float: right; */
    right: "16px",
  },
  inviteListRoot: {
    width: "100%",
    // maxWidth: 400,
    marginTop: "-13px",
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    border: "solid 1px lightgrey",
    padding: "2px",
  },
  inviteInput: {
    width: "400px",
    height: "45px",
    borderColor: "lightgrey",
    borderRadius: "2px",
    border: "1px solid lightgrey",
  },
  sliderRoot: {
    width: 300 + theme.spacing(3) * 2,
  },
  sliderMargin: {
    height: theme.spacing(3),
  },
  rootImgDes: {
    // flexGrow: 1,
    margin: theme.spacing(1),
  },
  subHead: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#f02834",
    margin: 0,
  },
  propName: {
    margin: theme.spacing(1),
    fontSize: "19px",
    fontWeight: "bold",
  },
  imgDes: {
    marginBlockStart: "0em",
    marginBlockEnd: "0em",
    fontSize: "13px",
  },
  box: {
    height: "10px",
    width: "10px",
  },
  h: {
    "&::before": {
      content: "some content",
      display: "block",
      height: 60,
      marginTop: -60,
    },
  },
  boxDesign: {
    width: "0px",
    height: "0px",
    backgroundColor: "red",
    color: "white",
    margin: "1px",
    padding: "3px",
    border: "1px solid red",
    float: "left",
  },
  imgCheckBox: {
    float: "right!important",
    position: "relative",
    top: "47px",
  },
  imgchkBox: {
    borderRadius: "5px",
    backgroundColor: "white",
    height: "5px",
    width: "6px",
    right: "8px",
    top: "-10px",
  },
  compareBtn: {
    backgroundColor: "red",
    padding: "7px",
    color: "white",
    marginTop: "20px",
    float: "right",
    right: "126px",
  },
  updateImgMenu: {
    opacity: "1",
    transform: "none",
    maxHeight: "130px",
    width: "20ch",
    transition:
      "opacity 217ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 145ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    top: "393px",
    left: "55%",
    transformOrigin: "-16px 75.7969px",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const styles = {
  media: {
    height: 0,
    paddingTop: "38%", // 16:9
    position: "relative",
  },
  overlay: {
    position: "absolute",
    left: "6%",
    color: "black",
    top: "32%",
  },
};

// Home page main fun
function Home({ data, store }) {
  const classes = useStyles();
  const history = useHistory();
  const [Building, setBuilding] = useState("");
  const [loading, setloading] = useState(false);
  const [btnDisable, setbtnDisable] = useState(true);
  const [count, setcount] = useState(0);
  const [propValues, setpropValues] = useState([]);
  const [catNamList, setcatNamList] = useState("");
  const [updatedCatList, setupdatedCatList] = useState("");
  const [CategoryFilterStateData, setCategoryFilterStateData] = useState([]);

  // if store is empty then update store
  useEffect(() => {
    if (!store.data.Mortgage) {
      getMortgage().then((data) => store.update("Mortgage", data));
    }
    if (!store.data.User) {
      getUser().then((data) => {
        // data.userProfilePic = localStorage.getItem("userProfilePic") || ''
        store.update("User", data);
        profileData(data);
        if (data != undefined) {
          if (data.province) {
            store.update("Province", data.province);
          }
          if (data.properties) {
            setloading(true);
            var properties = {};
            var property = [];
            var categories = {};
            var category = [];
            data &&
              data.collaboration &&
              data.collaboration.length > 0 &&
              data.collaboration.map((i, j) => {
                categories = {};
                properties = {};
                i.properties &&
                  i.properties.length > 0 &&
                  i.properties.map((j, k) => {
                    properties = j;
                    property.push(properties);
                  });
              });
            store.update("Properties", data.properties.concat(property));
            // console.log("324 store update properties", data.properties, property)
            setloading(false);
          } else {
            var propertys = [];
            data &&
              data.collaboration &&
              data.collaboration.length > 0 &&
              data.collaboration.map((i, j) => {
                var propertiess = {};
                i.properties &&
                  i.properties.length > 0 &&
                  i.properties.map((l, k) => {
                    propertiess = l;
                    // console.log("336 properties", propertiess, l)
                    propertys.push(propertiess);
                  });
                // property.push(properties)
              });
            // console.log("342 propertys",propertys )
            store.update("Properties", propertys);
          }
        }
      });
    }

    //get access token
    if (!store.data.getAccessToken) {
      getAccessToken().then((data) => store.update("GenerateToken", data));
    }
  }, []);

  // set profile data
  function profileData(data) {
    setloading(true);
    // console.log("profile data 341", data, store)
    store.data.Properties &&
      store.data.Properties.map((i, k) => {
        setBuilding(i);
        setloading(false);
        return;
      });
    if (!store.data.Properties) {
      setloading(false);
    }
  }

  // call profile data function to set store for data management
  useEffect(() => {
    profileData();
  }, [window.store]);

  useEffect(() => {
    console.log("72");
  }, [window.store]);

  // img checkbox handling
  function handleImgCheck(a) {
    // console.log("374", a)
    // == a.id   == null
    // let as = propValues.find(m => m.find(n => n[0] == a.id)) == null
    // let list = propValues
    // let lists;
    // if (propValues.length > 0) {
    //     lists = propValues.filter((i) => i[0] != a.id)
    // }
    if (a.check === false) {
      //  list = propValues.filter((i) => i[0] != a.id)
      // console.log("375", lists)
      // if (propValues.length < 3) {
      // propValues = list
      setcount(count + 1);
      let list = propValues;
      list.push([a.id, a.check, a.index]);
      setpropValues(list);
      if (count > 0) {
        setbtnDisable(false);
      }
      // if (count > 3) {
      //     setbtnDisable(true)
      // }
      // }
    } else {
      setcount(count - 1);
      propValues.map((i, k) => {
        if (i[2] === a.index) {
          propValues.splice(k, 1);
        }
      });
      if (count <= 2) {
        setbtnDisable(true);
      }
      // if (count > 3) {
      //     setbtnDisable(true)
      // }
    }
    // console.log("402**", propValues, count)
  }

  //handle loading in case of categroies set
  function handleloadingset(a) {
    setloading(a);
    if (
      window.store &&
      window.store.data &&
      window.store.data.Cat &&
      window.store.data.Cat.length > 0
    ) {
      store.update("Cat", window.store.data.Cat);
      setloading(false);
    } else {
      setloading(false);
    }
  }

  useEffect(() => {
    // console.log("418", count)
  }, [count]);

  //categorty name list
  function handleCatNameList(a) {
    if (count > 0) {
      if (
        window.store.data &&
        window.store.data.Properties &&
        store.data.Properties.length <= 2
      ) {
        setcount(1);
      }
    }
    if (a.NewList) {
      if (a.NewList.length === 0) {
        setcatNamList(a.NewList);
      } else {
        var flags = [],
          output = [],
          l = a.NewList.length,
          i;
        for (i = 0; i < l; i++) {
          if (flags[a.NewList[i]]) continue;
          flags[a.NewList[i]] = true;
          output.push(a.NewList[i]);
          setcatNamList(output);
        }
      }
    } else {
      if (a.catName.length === 0) {
        setcatNamList(a.catName);
      } else {
        var flags = [],
          output = [],
          l = a.catName.length,
          i;
        for (i = 0; i < l; i++) {
          if (flags[a.catName[i]]) continue;
          flags[a.catName[i]] = true;
          output.push(a.catName[i]);
          setcatNamList(output);
        }
      }
    }
  }

  // selected categories values
  function slectedCateValues(cl) {
    setupdatedCatList(cl);
  }

  //category filter data
  function CategoryFilterData(values) {
    setCategoryFilterStateData(values);
  }
  return (
    <div style={{ backgroundColor: "white" }}>
      <Container>
        <Suspense fallback={<div>loading...</div>}>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Header />
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={3}
            xl={3}
            className={classes.fontTitle}
            spacing={2}
          >
            <h3 style={{ textAlign: "left", margin: "100px 10px 0px" }}>
              {" "}
              FAVORITE PROPERTY
            </h3>
          </Grid>
          {count > 1 ? (
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={3}
              xl={3}
              className="compareBtnTop"
              spacing={2}
            >
              <Button
                color="secondary"
                variant="contained"
                style={{
                  backgroundColor: "red",
                  padding: "7px",
                  color: "white",
                }}
                disabled={btnDisable}
                onClick={() => {
                  history.push("/comparisonProperties", propValues);
                }}
              >
                Compare {count}
              </Button>
            </Grid>
          ) : (
            ""
          )}
          <Grid
            container
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.fontTitle}
            spacing={2}
          >
            <Grid
              xs={12}
              sm={12}
              md={4}
              lg={3}
              xl={3}
              style={{ textAlign: "left" }}
              item
            >
              <SimpleTabs
                loadingset={handleloadingset}
                slectedCateValues={slectedCateValues}
                catNameList={handleCatNameList}
                store={store}
                CategoryFilterData={CategoryFilterData}
              />
            </Grid>

            <Grid
              xs={12}
              sm={12}
              md={8}
              lg={9}
              xl={9}
              container
              spacing={2}
              style={{ textAlign: "left" }}
            >
              <Grid container xs={12} className={classes.fontTitle} spacing={2}>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  spacing={2}
                  style={{ marginTop: "-23px", height: "38px" }}
                >
                  {catNamList &&
                    catNamList.map((i, k) => {
                      return (
                        <span
                          style={{
                            marginLeft: "10px",
                            float: "left",
                            fontSize: "19px",
                            padding: "0px",
                            marginTop: "9px",
                            marginRight: "4px",
                          }}
                        >
                          {i[0] === "" ? "Properties" : i[0]}
                        </span>
                      );
                    })}
                </Grid>
                {catNamList.length > 0 ? (
                  window.store.data.Properties &&
                  window.store.data.Properties.length > 0 ? (
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      className="btn"
                      style={{ textAlign: "right" }}
                      spacing={2}
                    >
                      <Button color="secondary">
                        <InvitePopUp updatedCatList={updatedCatList} />
                      </Button>
                    </Grid>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </Grid>

              {loading && (
                <div
                  style={{
                    position: "fixed",
                    zIndex: 20000,
                    height: "100%",
                    background: "#ffffff94",
                    opacity: 1,
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "30vmin",
                    left: "8px",
                    marginTop: "0px",
                  }}
                >
                  <CircularProgress size={30} />
                </div>
              )}
              <Grid
                container
                item
                xs={10}
                sm={10}
                md={10}
                lg={10}
                xl={10}
                spacing={4}
              >
                {loading ? (
                  loading
                ) : window.store.data.Properties &&
                  window.store.data.Properties.length > 0 ? (
                  window.store.data.Properties &&
                  window.store.data.Properties.length > -1 &&
                  window.store.data.Properties.map((i, k) => {
                    if (i != undefined) {
                      return (
                        <CardSmall
                          img={i}
                          title="3 BHK 1620 Sq-ft Flat for Sale"
                          des="Los Angles,USA"
                          // size={12}
                          store={store.data.User}
                          // mortgageValue={i}
                          commentss={i.Comment}
                          id={i._id}
                          index={k}
                          key={"propice" + k}
                          Building={i}
                          propValues={propValues}
                          imgCheck={handleImgCheck}
                          loadingset={handleloadingset}
                          HanleCategoryFilterData={CategoryFilterStateData}
                        />
                      );
                    }
                  })
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    justify="space-between"
                    style={{ padding: "0px 0 0 10px", marginTop: "20px" }}
                  >
                    <CoursalSlider />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid>
            {count > 1 ? (
              <Button
                color="secondary"
                variant="contained"
                // href='/comparisonProperties'
                disabled={btnDisable}
                onClick={() => {
                  history.push("/comparisonProperties", propValues);
                }}
                className={classes.compareBtn}
              >
                Compare {count}
              </Button>
            ) : (
              ""
            )}
          </Grid>
          <Grid container>
            <Grid xs={12} sm={3} md={4} lg={4} xl={4} item></Grid>
            <Grid xs={12} sm={9} md={8} lg={8} xl={8} item>
              <Footer />
            </Grid>
          </Grid>
        </Suspense>
      </Container>
    </div>
  );
}

export default inject("store")(observer(Home));

//filter component
const Filter = ({
  index,
  size,
  title,
  checked,
  icon,
  id,
  sort,
  handleCatId,
  store,
  FilterTabVal,
  FilterCatVal,
}) => {
  const [check, setcheck] = useState(false);
  // const [catValList, setcatValList] = useState([])

  //set data on change of select filter
  const handleChange = () => {
    setcheck(!check);
    //set id check value sort index and title
    handleCatId({ id, check, sort, index, title });
    // handleCatList(id, check, sort, index, title)
  };

  useEffect(() => {
    if (FilterTabVal == 0) {
      // FilterCatVal
      FilterCatVal &&
        FilterCatVal.length > 0 &&
        FilterCatVal.map((i, j) => {
          if (i.check == check && i.index == index && i.id == id) {
            setcheck(!check);
          }
        });
      // setcheck(!check)
    }
    // console.log("FilterTabVal,FilterCatVal,541 id, check, sort, index, title ,size", FilterTabVal, FilterCatVal, id, check, sort, index, title, size, icon, store)
  }, []);

  // Delete Categories
  const deleteCategories = (a, store) => {
    return swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this category!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCategory(a).then((res) => {
          if (res.message != undefined) {
            swal("Poof! Your category has been deleted", {
              icon: res.message === "FailToDelete" ? "error" : "success",
            });
            getUser().then((res) => {
              if (res.properties != undefined) {
                store.update("Properties", res.properties);
              }
              getCat().then((data) => {
                // console.log("642", data)
                store.update("Cat", data);
                handleChange();
              });
            });
          }
          handleChange();
        });
      } else {
        swal("Your property is safe!");
      }
    });
  };

  return (
    <Grid xs={size} item>
      <FormControlLabel
        control={
          <Checkbox
            checked={check}
            onClick={handleChange}
            value={check}
            id={index}
          />
        }
        label={title}
      />
      <Icon>
        <img
          style={{
            // marginLeft: '75px',
            height: "14px",
            float: "right",
            marginTop: "13px",
          }}
          src={icon}
          onClick={() => deleteCategories(id, store)}
        />
      </Icon>
    </Grid>
  );
};

//show img cards and img detail or property detail on home page
const CardSmall = ({
  img,
  size,
  title,
  des,
  Building,
  index,
  id,
  imgCheck,
  propValues,
  commentss,
  loadingset,
  store,
  HanleCategoryFilterData,
}) => {
  const [check, setCheck] = useState(false);
  const [loading, setloading] = useState(false);
  const [checkDisable, setCheckDisable] = useState(true);
  const [commentRole, setcommentRole] = useState(true);
  const [comments, setComments] = useState("");
  const [categoryFilterData, setcategoryFilterData] = useState([]);
  var area =
    Building && Building.Building && Building.Building.SizeInterior
      ? Building.Building.SizeInterior
      : "";
  var Bedrooms =
    Building && Building.Building && Building.Building.Bedrooms
      ? Building.Building.Bedrooms
      : "";
  const classes = useStyles();

  //set category filter data
  useEffect(() => {
    setcategoryFilterData(HanleCategoryFilterData);
    window.store.update("changePropCategoryHandle", HanleCategoryFilterData);
  }, [HanleCategoryFilterData]);

  useEffect(() => {
    //property comment function
    commentFun();
  }, [commentss]);

  //set property comments
  function commentFun() {
    setComments(commentss ? commentss : "");
  }

  //checkbox handle change set check value and imgCheck set
  function handleChange(e) {
    // if (propValues.length >= 3) {
    //     setCheckDisable(false)
    // }
    // console.log("696", check)
    setCheck(!check);
    imgCheck({ id, check, index });
  }

  //Comment section
  function saveComments() {
    // setloading(true)
    loadingset(true);
    //update comments
    updatePropertyCommentCategory(id, "", comments).then((res) => {
      if (res.message === "UpdatedSuccess") {
        setComments(res.data.Comment);
        loadingset(false);
      }
      loadingset(false);
    });
  }

  //role access of invited user
  function RoleAccess() {
    if (
      window.store &&
      window.store.data &&
      window.store.data.Cat &&
      window.store.data.Cat.length > 0 &&
      window.store.data.Cat.find(
        (x) =>
          x.UserId === Building.UserId &&
          x._id === Building.Category &&
          x.invitedUserRole === "Viewer"
      ) != null
    ) {
      swal({
        title: "It's invited property.",
        text: "You have only Reading access!",
        icon: "warning",
      });
    } else {
      setcommentRole(false);
    }
  }

  //properties Values set check and img check
  useEffect(() => {
    propValues.map((i, j) => {
      if (i[0] == id && i[1] == false) {
        setCheck(!check);
        imgCheck({ id, check, index });
      }
    });
  }, [propValues]);

  return (
    <Grid item xs={10} sm={10} md={6} lg={6} xl={6} >
      <span className={classes.imgCheckBox}>
        <Checkbox
          // value="checked"
          className={classes.imgchkBox}
          disabled={!checkDisable}
          checked={check}
          onClick={checkDisable === true ? handleChange : {}}
          value={id}
          id={index}
        />
      </span>
      <img
        onClick={() => window.open(img.Weblink, "_blank")}
        className={classes.imgClass}
        src={
          img.Property &&
          img.Property.Photo.length > 0 &&
          img.Property.Photo[0].HighResPath
        }
        alt={"tutorialSteps[activeStep].label"}
      />
      <div style={{ textAlign: "left", position: "relative" }}>
        <h5 className={classes.title}>
          {" "}
          {Bedrooms + " BHK " + area + " Flat for Sale"}{" "}
        </h5>
        <span style={{ position: "absolute", top: "-13px", right: "-17px" }}>
          <ImgMenu
            propId={Building._id}
            Building={Building}
            store={store}
            HanleCategoryFilterData={categoryFilterData}
          />
        </span>
        <p className={classes.dec}>{img.Property.Address.AddressText} </p>
      </div>

      {/* comment input box */}
      <InputBase
        onClick={RoleAccess}
        // onFocus={RoleAccess()}
        className={classes.margin}
        fullWidth
        multiline
        rowsMax={4}
        value={comments}
        onChange={(e) => setComments(e.target.value)}
        onBlur={commentRole === true ? "" : saveComments}
        readOnly={commentRole === true ? commentRole : ""}
        placeholder="Click here Add notes..."
        inputProps={{ "aria-label": "naked" }}
      />
      {/* img description component */}
      <ImgDescription Building={Building} store={store} index={index} />
    </Grid>
  );
};

//tab panel of sort by and filter by
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// Filter by and Sort by block
const SimpleTabs = ({
  loadingset,
  catNameList,
  store,
  slectedCateValues,
  CategoryFilterData,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [catList, setCatList] = useState("");
  const [catValue, setCatValue] = useState([]);
  const [check, setCheck] = useState(false);
  const [sort, setSort] = useState("");
  const [index, setIndex] = useState("");
  const [values, setValues] = useState("MinPrice");
  const [valueRadio, setValueRadio] = useState("");
  const [budgetValues, setbudgetValues] = useState(0);
  const [category, setCategory] = useState("");
  const [catName, setcatName] = useState([]);
  const [isPrice, setIsPrice] = useState(false);
  const [isBedroom, setIsBedroom] = useState(false);
  const [isArea, setIsArea] = useState(false);
  const [isPriceDis, setIsPriceDis] = useState(false);
  const [isBedroomDis, setIsBedroomDis] = useState(false);
  const [isAreaDis, setIsAreaDis] = useState(false);
  const [FilterTabVal, setFilterTabVal] = useState(3);
  const [FilterCatVal, setFilterCatVal] = useState([]);
  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      loadingset(false);
    }
    setFilterTabVal(newValue);
    setValue(newValue);
  };

  //handle categories and get categories update categories store
  function catHandles(a) {
    setCatList(window.store.data.Cat);
    if (
      (a.catRes === "" && window.store.data.Cat == null) ||
      window.store.data.Cat == ""
    ) {
      getCat().then((res) => {
        store.update("Cat", res);
        setCatList(store.data.Cat);
      });
    } else {
      store.update("Cat", store.data.Cat);
      setCatList(store.data.Cat);
    }
  }

  useEffect(() => {
    // console.log("7999 store changePropCategoryHandle", store)
  }, [store]);

  useEffect(() => {
    if (category) {
      loadingset(true);
      setCheck(category.check);
      setSort(category.sort);
      setIndex(category.index);

      // Array.prototype.contains = function (v) {
      //     console.log("894", v)
      //     for (var i = 0; i < this.length; i++) {
      //         console.log("896**", i, [i], v, v[1])
      //         if (i === v[1]) return true;
      //     }
      //     return false;
      // };

      // Array.prototype.unique = function () {
      //     var arr = [];
      //     for (var i = 0; i < this.length; i++) {
      //         console.log("903", i)
      //         if (!arr.contains(this[i])) {
      //             arr.push(this[i]);
      //         }
      //     }
      //     return arr;
      // }

      var catvalArr = catValue;
      var NewList;
      var list;
      if (catValue && catValue.length > 0) {
        if (category.check == true) {
          NewList = catValue.filter((i) => i[1] != category.index);
          setCatValue(NewList);
        } else {
          list = catValue.filter((i) => i[1] != category.index);
          list.push([category.id, category.index]);
          setCatValue(list);

          // var uniques = catValue.unique(); // result = [1,3,4,2,8]
          // setCatValue(uniques)
        }
      } else {
        catvalArr.push([category.id, category.index]);
        setCatValue(catvalArr);
      }

      // if (category.check === true) {
      //     console.log("842**", catValue, category)
      //     // let filterlist =
      //     // catValue.filter((i) => i[1] != category.index)
      //     catValue.map((i, k) => {
      //         if (i[1] == category.index) {
      //             catValue.splice(k, 1)
      //         }
      //     })

      //     // for (var i = 0; i < catValue.length; i++) {
      //     //     console.log("catcatValue[i]",catValue, catValue[i])
      //     //     if (catValue[i]) {
      //     //         var a = catValue[i]

      //     //         if (a[1] === category.index) {
      //     //             console.log("catcatValue[i]", i, a, a[1], category.index)
      //     //             catValue.splice(i, 1);
      //     //         }

      //     //     }

      //     // }
      //     // setCatValue(filterlist)
      //     console.log("848**", catValue)

      // } else {
      //     console.log("849", category.check, category, catName, JSON.stringify(catValue), catValue.length)
      //     var b = catValue
      //     console.log("851", JSON.stringify(catValue), b)
      //     if (JSON.stringify(catValue) == [] || JSON.stringify(catValue) == null || catValue.length == 0) {
      //         console.log("853", catValue)
      //         b.push([category.id, category.index]);
      //         setCatValue(b)
      //     } else {

      //         // catValue.map((i, j) => {
      //         console.log("859", category.id)
      //         // if (i[0] != category.id) {
      //         b.push([category.id, category.index])
      //         setCatValue(b)
      //         // }
      //         console.log("864", catValue)
      //         // })
      //         var uniques = catValue.unique(); // result = [1,3,4,2,8]
      //         // setCatValue(uniques)
      //         console.log("unique913", uniques);
      //     }

      // }

      // else {
      //     console.log("849", category.check, category, catName, catValue, catValue.length)
      //     let b = catValue
      //     console.log("851", b, catValue)
      //     if (catValue.length === 0) {
      //         console.log("852", catValue, catValue.length)
      //         b.push([category.id, category.index])
      //         console.log("bbbbb", b)
      //         setCatValue(b)
      //     } else {
      //         catValue.map((i, j) => {
      //             console.log("857", i)
      //             if (i[0] != category.id) {
      //                 b.push([category.id, category.index])
      //                 setCatValue(b)
      //             }
      //         })
      //     }

      // }

      slectedCateValues({ catValue });
      CategoryFilterData({ category });
      if (NewList) {
        catvalArr = NewList;
      } else {
        catvalArr = list ? list : catvalArr;
      }
      //sort properties with budget , categories and by filter
      sortProperties(catvalArr, values, parseInt(budgetValues)).then((res) => {
        if (res.message === "InternalServerError") {
          //getUser api get uer detail
          getUser().then((res) => {
            window.store.update("Properties", res.properties);
            // store.update("Properties", res.data)
            loadingset(false);
          });
        } else if (res && res.data) {
          store.update("Properties", res.data);
          loadingset(false);
        }
        loadingset(false);
      });
    } else {
      //set filter values according to radio button
      if (valueRadio == "Price") {
        if (isPrice) {
          setValues("MaxPrice");
        } else {
          setValues("MinPrice");
        }
      } else if (valueRadio == "Bedroom") {
        if (isBedroom) {
          setValues("MaxBedrooms");
        } else {
          setValues("MinBedrooms");
        }
      } else if (valueRadio == "Area") {
        if (isArea) {
          setValues("MaxInteriorArea");
        } else {
          setValues("MinInteriorArea");
        }
      }
      sortProperties(catValue, values, budgetValues).then((res) => {
        if (res === undefined) {
          store.update("Properties", "");
          loadingset(false);
        } else {
          store.update("Properties", res.data);
          loadingset(false);
        }
        loadingset(false);
      });
    }
  }, [budgetValues, values, category, valueRadio, store]);

  //handle categories of filter categories value
  function handleCatIds(a) {
    var filtervalArr = FilterCatVal;
    if (FilterCatVal && FilterCatVal.length > 0) {
      if (a.check == true) {
        var NewList = FilterCatVal.filter((i) => i.id != a.id);
        setFilterCatVal(NewList);
      } else {
        filtervalArr.push(a);
        setFilterCatVal(filtervalArr);
      }
    } else {
      filtervalArr.push(a);
      setFilterCatVal(filtervalArr);
    }

    var catNarr = catName;
    if (catName && catName.length > 0) {
      if (a.check == true) {
        let NewList = catName.filter((i) => i[0] != a.title);
        setcatName(NewList);
        catNameList({ NewList, check });
      } else {
        catNarr.push([a.title, a.index]);
        setcatName(catNarr);
        catNameList({ catName, check });
      }
    } else {
      catNarr.push([a.title, a.index]);
      setcatName(catNarr);
      catNameList({ catName, check });
    }

    // if (catName.length > 0) {
    //     catName.map((u, v) => {
    //         console.log("934", u, a)
    //         if (u[0] !== a.title) {
    //             if (a.check === true) {
    //                 // console.log("833 true check", a.check)
    //                 catName.map((i, k) => {
    //                     if (i[1] === a.index) {
    //                         catName.splice(k, 1)
    //                     }
    //                 })
    //             }
    //             else {
    //                 console.log("944 a check false case", catName, a.check, a)
    //                 catName.map((i, j) => {
    //                     if (i[0] !== a.title) {
    //                         console.log("1029 a check false case", i, catName, a.check, a)
    //                         var b = catName
    //                         b.push([a.title, a.index])
    //                         setcatName(b)
    //                     }
    //                 })

    //             }
    //             catNameList({ catName, check })
    //         }
    //         else {
    //             if (a.check === true) {
    //                 console.log("953 true check", a.check)
    //                 catName.map((i, k) => {
    //                     // console.log("868 i a.index", i[1], a.index)
    //                     if (i[1] === a.index) {
    //                         catName.splice(k, 1)
    //                     }
    //                 })
    //             }
    //             else {
    //                 console.log("962 a check false case", catName, a.check, a)
    //                 var b = catName
    //                 //      catName.filter(x=>x[0]!==a.title){
    //                 b.push([a.title, a.index])
    //                 setcatName(b)
    //                 //  }
    //                 // var flags = [], output = [], l = b.length, i;
    //                 // for (i = 0; i < l; i++) {
    //                 //     if (flags[b[i]]) continue;
    //                 //     flags[b[i]] = true;
    //                 //      output.push(b[i]);
    //                 //     setcatName(output)
    //                 // }
    //                 //    console.log(output);
    //             }
    //             catNameList({ catName, check })
    //         }
    //     })
    // } else {
    //     console.log("981else 848", a)
    //     if (a.check === true) {
    //         // console.log("849 a check", a.check)
    //         catName.map((i, k) => {
    //             if (i[1] === a.index) {
    //                 catName.splice(k, 1)
    //             }
    //             // console.log("854 a check", catName)
    //         })
    //     } else {
    //         console.log("991 a check else case", catName, a.check, a)
    //         var b = catName
    //         b.push([a.title, a.index])
    //         setcatName(b)
    //     }
    //     catNameList({ catName, check })
    //     // console.log("849 a check", catName, check)
    // }
    setCategory(a);
    loadingset(true);

    setCatList(store.data.Cat);
  }

  //handle radio button click of sort by
  const handleRadio = (event) => {
    setValueRadio(event.target.value);
    if (event.target.value == "Price") {
      // setValues("MinPrice")
      setIsPriceDis(false);
      setIsAreaDis(true);
      setIsBedroomDis(true);
    } else if (event.target.value == "Area") {
      // setValues("MinInteriorArea")
      setIsPriceDis(true);
      setIsAreaDis(false);
      setIsBedroomDis(true);
    } else if (event.target.value == "Bedroom") {
      // setValues("MinBedrooms")
      setIsPriceDis(true);
      setIsAreaDis(true);
      setIsBedroomDis(false);
    }
    loadingset(true);
  };

  //budget scroll handle
  const handlebudgetScroll = (a) => {
    setbudgetValues(a.budgetValue);
    loadingset(true);
  };

  //when change toggle in sort by
  const onChangeToggle = (event) => {
    if (!isPriceDis) {
      setIsPrice(!isPrice);
      if (isPrice === false) {
        setValues("MaxPrice");
      } else {
        setValues("MinPrice");
      }
    } else if (!isAreaDis) {
      setIsArea(!isArea);
      if (isArea === false) {
        setValues("MaxInteriorArea");
      } else {
        setValues("MinInteriorArea");
      }
    } else if (!isBedroomDis) {
      setIsBedroom(!isBedroom);
      if (isBedroom === false) {
        setValues("MaxBedrooms");
      } else {
        setValues("MinBedrooms");
      }
    }
  };

  //toggle minimun and maximun sort by
  const SwitchCheck = ({ checked, onChange, isDisabled }) => {
    return (
      <p>
        {" "}
        Min
        <Switch
          checked={checked}
          disabled={isDisabled}
          onChange={(e) => onChange(e.target.value)}
        />
        Max
      </p>
    );
  };
  return (
    <div className={classes.root1}>
      <AppBar
        position="static"
        style={{ backgroundColor: "white" }}
        className="tabSize"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          className="tabStyle"
        >
          <Tab
            label="Filter by"
            {...a11yProps(0)}
            className={classes.tabStyle}
          />
          <Tab label="Sort by" {...a11yProps(1)} className={classes.tabStyle} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} style={{ marginTop: "-23px" }}>
        <p>
          <Icon>
            <img src={home} />
          </Icon>{" "}
          Category
        </p>
        <section>
          {catList &&
            catList.map((i, k) => {
              return (
                i && (
                  <Filter
                    index={k}
                    title={i.CategoryName}
                    id={i._id}
                    handleCatId={handleCatIds}
                    FilterTabVal={FilterTabVal}
                    FilterCatVal={FilterCatVal}
                  />
                )
              );
            })}
          <CategoryPopUp catHandle={catHandles} store={store} />
          <CustomizedSlider
            text="Your Budget"
            icons={budget}
            stepDiff={500000}
            maxVal={3000000}
            defaultVal={0}
            budgetScrolls={handlebudgetScroll}
          />
          {/* <CustomizedSlider text="Property Tax" icons={tex} stepDiffs={5000} maxVals={30000} defaultVal={0} budgetScrolls={handlebudgetScroll} /> */}
        </section>
      </TabPanel>
      <TabPanel value={value} index={1} style={{ marginTop: "-23px" }}>
        <h4 style={{ color: "red" }}>Sort Property</h4>
        <section>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={valueRadio}
              onChange={handleRadio}
            >
              <FormControlLabel
                value="Price"
                control={<Radio />}
                label="Price"
              />
              <span style={{ marginLeft: "117px", marginTop: "-55px" }}>
                <SwitchCheck
                  checked={isPrice}
                  isDisabled={isPriceDis}
                  onChange={onChangeToggle}
                />
              </span>
              <FormControlLabel
                value="Bedroom"
                control={<Radio />}
                label="Bedroom"
              />
              <span style={{ marginLeft: "117px", marginTop: "-55px" }}>
                <SwitchCheck
                  checked={isBedroom}
                  isDisabled={isBedroomDis}
                  onChange={onChangeToggle}
                />
              </span>
              <FormControlLabel value="Area" control={<Radio />} label="Area" />
              <span style={{ marginLeft: "117px", marginTop: "-55px" }}>
                <SwitchCheck
                  checked={isArea}
                  isDisabled={isAreaDis}
                  onChange={onChangeToggle}
                />
              </span>
            </RadioGroup>
          </FormControl>
        </section>
      </TabPanel>
    </div>
  );
};

//modal random set
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

//get modal invitation popup
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

// Add Category popup
const CategoryPopUp = ({ catHandle, store }) => {
  const [open, setOpen] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [InputErr, setInputErr] = useState(false);
  const [InputEmpty, setInputEmpty] = useState(false);
  const [ServerError, setServerError] = useState("");
  const [catRes, setCatRes] = useState("");
  const [catListss, setCatListss] = useState([]);
  const [loading, setloading] = useState(false);

  //add remove category popup
  const handleOpen = () => {
    setCatListss(store.data.Cat);
    setOpen(true);
  };

  //get data from filter component
  const handleCatIdss = (a) => {
    if (a.check == false) {
      setCatRes(store.data.Cat);
    }
    store.update("Cat", store.data.Cat);
    setOpen(a.check);

    setCatListss(store.data.Cat);
  };

  //close modal of add/remove popup
  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  useEffect(() => {
    catHandle({ catRes });
  }, [catRes]);

  //save new category
  const handleChange = () => {
    setInputEmpty(false); //input field of category popup is set null
    setInputErr(false); //input field erro in category popup
    if (categoryName.trim() === "") {
      setInputEmpty(true);
      return false;
    }
    if (categoryName.trim().length <= 3) {
      setInputErr(true);
      return false;
    }
    if (categoryName.length >= 30) {
      setInputErr(true);
      return false;
    }
    setloading(true);
    //call api to save new category
    return postCategory(categoryName).then((res) => {
      if (res) {
        if (res.message) {
          setServerError(res.message);
          setloading(false);
        } else {
          setOpen(false);
          setCategoryName("");
          getUser().then((data) => {
            store.update("User", data);
            setInputErr(false);
            setServerError("");
            getCat().then((data) => {
              store.update("Cat", data);
              setInputErr(false);
              setServerError("");
              setCatRes(data);
              setCatListss(data);
              setOpen(false);
              setloading(false);
            });
          });
          setServerError("");
        }
      }
    });
  };

  return (
    <div>
      <Link
        variant="contained"
        style={{ color: "dodgerblue", cursor: "pointer" }}
        color="primary"
        onClick={handleOpen}
      >
        Add/Remove Category
      </Link>
      {loading && (
        <div
          style={{
            position: "fixed",
            zIndex: 20000,
            height: "100%",
            opacity: 1,
            width: "100%",
            background: "#ffffff94",
            textAlign: "center",
            paddingTop: "30vmin",
            left: "8px",
            marginTop: "-444px",
          }}
        >
          <CircularProgress size={30} />
        </div>
      )}
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div className={classes.paper}>
          <p>Add Category</p>
          <span onClick={handleClose}>
            <Icon>
              <img src={cross} className={classes.inviteCross} />
            </Icon>
          </span>
          <Input
            className={classes.input}
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          ></Input>
          {InputErr && (
            <Typography className={classes.texterror}>
              Invalid Category name{" "}
            </Typography>
          )}
          {InputEmpty && (
            <Typography className={classes.texterror}>
              Please Enter Category name{" "}
            </Typography>
          )}
          {ServerError && (
            <Typography className={classes.texterror}>
              {ServerError}{" "}
            </Typography>
          )}
          {catListss &&
            catListss.map((i, k) => {
              return (
                i && (
                  <Filter
                    key={"addCategoryKey" + k}
                    title={i.CategoryName}
                    id={i._id}
                    icon={cross}
                    store={store}
                    handleCatId={handleCatIdss}
                  />
                )
              );
            })}
          <Button
            style={{ backgroundColor: "red", color: "white", float: "right" }}
            onClick={handleChange}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};
//Slider design
const PrettoSlider = withStyles({
  root: {
    color: "red",
    height: 8,
    padding: "15px 0",
    width: "58%!important",
  },
  thumb: {
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
    height: 28,
    width: 28,
    backgroundColor: "#fff",
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 11px)",
    top: -18,
    "& *": {
      background: "transparent",
      color: "grey",
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  trackFalse: {
    borderTop: "20%",
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundColor: "grey",
    opacity: 0.2,
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 0,
    backgroundColor: "currentColor",
  },
  PrivateValueLabel: {
    lineHeight: "2.4!important",
  },
})(Slider);

// show value in slider
function valuetext(value) {
  return value;
}

//budget property slider
const CustomizedSlider = ({
  text,
  icons,
  maxVal,
  defaultVal,
  budgetScrolls,
  stepDiff,
  stepDiffs,
  maxVals,
}) => {
  const [budgetValue, setBudgetValue] = useState(0);
  const classes = useStyles();

  //change slider when call a function then set budget value
  const abc = (e) => {
    if (maxVals || stepDiffs) {
    } else {
      setBudgetValue(e.target.textContent);
    }
  };

  //budget slider scroll
  useEffect(() => {
    budgetScrolls({ budgetValue });
  }, [budgetValue]);

  return (
    <div className={classes.sliderRoot}>
      <div className={classes.sliderMargin} />
      <Typography id="discrete-slider" gutterBottom>
        <Icon>
          <img src={icons} />
        </Icon>{" "}
        {text}
      </Typography>
      <PrettoSlider
        defaultValue={defaultVal}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="on"
        step={stepDiff ? stepDiff : stepDiffs}
        marks
        min={0}
        max={maxVal ? maxVal : maxVals}
        onChangeCommitted={(e) => {
          abc(e);
        }}
      />
    </div>
  );
};

// Property Menu Options
const ImgMenu = (propId, Building, store, HanleCategoryFilterData) => {
  const classes = useStyles();
  const history = useHistory();
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const [catLists, setCatLists] = useState("");
  const [anchorEll, setAnchorEll] = useState(null);
  const [changeCateBool, setchangeCateBool] = useState(false);
  const [loading, setloading] = useState(false);
  const [catValueState, setcatValueState] = useState([]);
  const open = Boolean(anchorEl);
  const opens = Boolean(anchorEll);

  useEffect(() => {
    // console.log("1340HanleCategoryFilterData", HanleCategoryFilterData, window.store)
  }, [HanleCategoryFilterData]);

  // open property three dot menuProperty Options
  const handleClick = (event) => {
    var AnchorValue;
    setAnchorEl(event.currentTarget);
    AnchorValue = event.currentTarget;
    setAnchorEl(null);
    //check user have a role to access
    if (
      window.store &&
      window.store.data &&
      window.store.data.Cat &&
      window.store.data.Cat.length > 0 &&
      window.store.data.Cat.find(
        (x) =>
          x.UserId === propId.Building.UserId &&
          x._id === propId.Building.Category &&
          x.invitedUserRole === "Viewer"
      ) != null
    ) {
      swal({
        title: "It's invited property.",
        text: "You have only Viewer access!",
        icon: "warning",
      });
    } else if (
      window.store &&
      window.store.data &&
      window.store.data.Cat &&
      window.store.data.Cat.length > 0 &&
      window.store.data.Cat.find(
        (x) =>
          x.UserId === propId.Building.UserId &&
          x._id === propId.Building.Category &&
          x.invitedUserRole === ""
      ) != null
    ) {
      setAnchorEl(AnchorValue);
    } else if (
      window.store &&
      window.store.data &&
      window.store.data.Cat &&
      window.store.data.Cat.length > 0 &&
      window.store.data.Cat.find(
        (x) =>
          x.UserId === propId.Building.UserId &&
          propId.Building.Category === undefined &&
          x.invitedUserRole === ""
      ) != null
    ) {
      setAnchorEl(AnchorValue);
    } else {
      setchangeCateBool(true);
      setAnchorEl(AnchorValue);
    }
  };

  //change category in menu list
  const handleClicks = (event) => {
    setAnchorEll(event.currentTarget);
  };

  //set category data in state CatLists
  useEffect(() => {
    if (
      window.store &&
      window.store.data &&
      window.store.data.Cat &&
      window.store.data.Cat.length > 0
    ) {
      setCatLists(window.store.data.Cat);
    }
  }, []);

  // Property Delete function
  function handleDelete(id, store) {
    return swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this property!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteProperty(id).then((res) => {
          setloading(true);
          if (res.message) {
            swal("Poof! Your property has been deleted", {
              icon: res.message === "FailToDelete" ? "error" : "success",
            });
            setAnchorEl(null);
            //call getUser api update latest api in property store
            getUser().then((res) => {
              if (res.properties) {
                window.store.update("Properties", res.properties);
              } else {
                window.store.update("Properties", "");
              }
            });
            setloading(false);
          }
        });
      } else {
        swal("Your property is safe!");
        setAnchorEl(null);
        setloading(false);
      }
      // setloading(false)
    });
  }

  //Update Category of property
  function handleUpdateCategory(id, CategoryId) {
    setloading(true);
    updatePropertyCommentCategory(id.propId, CategoryId).then((res) => {
      if (res.message === "UpdatedSuccess") {
        setAnchorEl(null);
        setAnchorEll(null);
        setloading(false);
        swal({
          title: "Category Updated",
          text: res.message,
          icon: "success",
        });
        if (
          window.store &&
          window.store.data.changePropCategoryHandle &&
          window.store.data.changePropCategoryHandle.category
        ) {
          var categoryList =
            window.store.data.changePropCategoryHandle.category;
          if (categoryList) {
            // loadingset(true)
            // setCheck(category.check)
            // setSort(category.sort)
            // setIndex(category.index)

            if (categoryList.check === true) {
              catValueState.map((i, k) => {
                if (i[1] === categoryList.index) {
                  catValueState.splice(k, 1);
                }
              });
            } else {
              var b = catValueState;
              b.push([categoryList.id, categoryList.index]);
              setcatValueState(b);
            }
            var values = "MinPrice";
            var budgetValues = 0;
            // slectedCateValues({ catValue })
            // CategoryFilterData({ category })

            //sort properties list
            sortProperties(catValueState, values, budgetValues).then((res) => {
              if (res.message === "InternalServerError") {
                getUser().then((res) => {
                  window.store.update("Properties", res.properties);
                  // store.update("Properties", res.data)
                  // loadingset(false)
                });
              } else if (res && res.data) {
                window.store.update("Properties", res.data);
                // loadingset(false)
              }
              // loadingset(false)
            });
          }
        }
        // getCat().then(data => {
        //     console.log("window.store.data 1349",data)
        //     window.store.update("Cat", data)
        // })

        // getUser().then(res => {
        //     // console.log("window.store.data 1349",res)
        //     //     window.store.update("Cat", data)
        //     if (res.properties) {
        //         window.store.update("Properties", res.properties)
        //     }
        // })

        // window.store.update("changePropCategoryHandle", res)
      }
      setloading(false);
    });
  }

  //close property menu which is open click on three dots
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEll(null);
  };

  //edit property and open a detail property page
  function edithandle(propId) {
    var a = propId.propId;
    history.push("/detailProperty?id=" + a);
  }
  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            zIndex: 20000,
            height: "100%",
            opacity: 1,
            width: "100%",
            background: "#ffffff94",
            textAlign: "center",
            paddingTop: "38vmin",
            left: "8px",
            marginTop: "-230px",
          }}
        >
          <CircularProgress size={30} />
        </div>
      )}
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            // maxHeight: ITEM_HEIGHT * 2.5,
            width: "19ch",
          },
        }}
      >
        <MenuItem
          onClick={() => edithandle(propId)}
          style={{ color: "red", padding: "0px", marginLeft: "18px" }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => handleDelete(propId)}
          style={{ color: "red", padding: "0px", marginLeft: "18px" }}
        >
          Delete
        </MenuItem>
        <MenuItem
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClicks}
          style={{
            color: "red",
            padding: "0px",
            marginLeft: "18px",
            display: changeCateBool === true ? "none" : "",
          }}
        >
          Change Category
        </MenuItem>

        <Menu
          id="long-menu"
          anchorEl={anchorEll}
          keepMounted
          open={opens}
          onClose={handleClose}
          style={{ display: anchorEll === null ? "none" : "" }}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {window.store.data.Cat &&
            window.store.data.Cat.length > 0 &&
            window.store.data.Cat.map((option, k) =>
              propId.Building.Category != option._id ? (
                <MenuItem
                  key={"option" + k}
                  selected={option.CategoryName === "abcd"}
                  onClick={() => handleUpdateCategory(propId, option._id)}
                >
                  {option.CategoryName}
                </MenuItem>
              ) : window.store.data.Cat.length > 1 ? (
                ""
              ) : (
                <MenuItem onClick={handleClose}>No Data</MenuItem>
              )
            )}
        </Menu>
      </Menu>
    </div>
  );
};

//other location detail
const OtherLocationDetail = ({ Title, QuantityOrPrice, Color, index }) => {
  const classes = useStyles();
  return (
    <div>
      {QuantityOrPrice &&
        QuantityOrPrice.length > 0 &&
        QuantityOrPrice.map((i, j) => {
          return (
            <div>
              <span>
                <IconDiamond color={Color} />
              </span>
              <p className={classes.imgDes}> {i.name} </p>
              <span>
                <b>{i.distance}</b>
              </span>
            </div>
          );
        })}
    </div>
  );
};

//img description detail
const ImgDescriptionDetail = ({ Title, QuantityOrPrice, Color, index }) => {
  const classes = useStyles();
  return (
    <div>
      <span>
        <IconDiamond color={Color} />
      </span>
      <p className={classes.imgDes}> {Title} </p>
      <span>
        <b style={{ fontSize: "13px" }}>
          {QuantityOrPrice
            ? Title === "Affordability"
              ? "$" + QuantityOrPrice
              : QuantityOrPrice
            : "$" + 0}
        </b>
      </span>
    </div>
  );
};

//use in img description detail component for show colour ful square on dashboard
const IconDiamond = ({ color }) => {
  return (
    <span
      style={{
        width: "0px",
        height: "0px",
        backgroundColor: color,
        color: "white",
        margin: "4px",
        padding: "5px",
        border: "1px solid",
        float: "left",
        top: "2px",
        right: "2px",
        position: " relative",
        borderColor: color,
        borderRadius: "3px",
      }}
    >
      .
    </span>
  );
};

// call in card small component use for show the detail of image
const ImgDescription = (Building, affordabilitys, store, index) => {
  const [Build, setBuild] = useState(0);
  const [afford, setafford] = useState(0);
  const [netpay, setnetpay] = useState(0);
  const [monthlyexp, setmonthlyexp] = useState(0);
  const [mortgagep, setmortgagep] = useState(0);
  const [workDistance, setworkDistance] = useState("0 Km");
  const [schoolLocation, setschoolLocation] = useState("0 Km");
  const [otherLocation, setotherLocation] = useState([]);
  var otherLocationArrays = [];

  //set states with property detail
  useEffect(() => {
    var mortgagepp =
      Building.Building.Mortgage &&
      Building.Building.Mortgage.Scenarios[0] &&
      Building.Building.Mortgage.Scenarios[0].totalMortgagePayment
        ? Building.Building.Mortgage.Scenarios[0].totalMortgagePayment
        : 0;
    setmortgagep(mortgagepp);
    setnetpay(
      window.store.data.User &&
        window.store.data.User.monthlyExpense &&
        window.store.data.User.monthlyExpense.netPayPerMonth
    );
    setmonthlyexp(
      window.store.data.User &&
        window.store.data.User.monthlyExpense &&
        window.store.data.User.monthlyExpense.totalMontlyExpense
    );
    setafford(netpay - (monthlyexp + mortgagep));
  }, [store, Build, Building, netpay, monthlyexp]);

  //set locations initial
  useEffect(() => {
    setschoolLocation(
      Building.Building.Mortgage &&
        Building.Building.Mortgage.DistanceMatrix &&
        Building.Building.Mortgage.DistanceMatrix.SchoolLocation
        ? Building.Building.Mortgage.DistanceMatrix.SchoolLocation
        : 0 + " Km"
    );
    setworkDistance(
      Building.Building.Mortgage &&
        Building.Building.Mortgage.DistanceMatrix &&
        Building.Building.Mortgage.DistanceMatrix.WorkLocation
        ? Building.Building.Mortgage.DistanceMatrix.WorkLocation
        : 0 + " Km"
    );
    setotherLocation(
      Building.Building.Mortgage &&
        Building.Building.Mortgage.DistanceMatrix &&
        Building.Building.Mortgage.DistanceMatrix.OtherLocation
        ? Building.Building.Mortgage.DistanceMatrix.OtherLocation
        : 0 + " Km"
    );
    // console.log("1614***", Building)
    setBuild(
      Building.Building.Mortgage.Scenarios[0] === undefined
        ? 0
        : Building.Building.Mortgage.Scenarios[0]
    );
  }, [Building]);

  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <ImgDescriptionDetail
          Title={"Price"}
          QuantityOrPrice={
            Building.Building.Mortgage.askingPrices
              ? Building.Building.Mortgage.askingPrices.toLocaleString()
              : Building.Building.Property.Price
          }
          Color={afford > 0 ? "green" : "red"}
          index={index}
        />
      </Grid>
      <Grid item xs={4}>
        <ImgDescriptionDetail
          Title={"Mortgage"}
          QuantityOrPrice={
            Build.totalMortgageRequired
              ? Build.totalMortgageRequired.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0
          }
          Color={afford > 0 ? "green" : "red"}
          index={index}
        />
      </Grid>
      <Grid item xs={4}>
        <ImgDescriptionDetail
          Title={"Property Tax"}
          QuantityOrPrice={"$0"}
          Color="blue"
          index={index}
        />
      </Grid>
      <Grid item xs={8}>
        <ImgDescriptionDetail
          Title={"Mortgage Payment"}
          QuantityOrPrice={
            Build.totalMortgagePayment
              ? Build.totalMortgagePayment.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0
          }
          Color={afford > 0 ? "green" : "red"}
          index={index}
        />
      </Grid>
      <Grid item xs={4}>
        <ImgDescriptionDetail
          Title={"Affordability"}
          QuantityOrPrice={
            afford
              ? afford.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0
          }
          Color={afford > 0 ? "green" : "red"}
          index={index}
        />
      </Grid>
      <Grid item xs={4}>
        <ImgDescriptionDetail
          Title={"Bedroom"}
          QuantityOrPrice={
            Building &&
            Building.Building &&
            Building.Building.Building &&
            Building.Building.Building.Bedrooms
              ? Building.Building.Building.Bedrooms
              : 0
          }
          Color="Purple"
          index={index}
        />
      </Grid>
      <Grid item xs={4}>
        <ImgDescriptionDetail
          Title={"Bathroom"}
          QuantityOrPrice={
            Building &&
            Building.Building &&
            Building.Building.Building &&
            Building.Building.Building.BathroomTotal
              ? Building.Building.Building.BathroomTotal
              : 0
          }
          Color="pink"
          index={index}
        />
      </Grid>
      <Grid item xs={4}>
        <ImgDescriptionDetail
          Title={"Balcony"}
          QuantityOrPrice={"no"}
          Color="black"
          index={index}
        />
      </Grid>
      <Grid item xs={6}>
        <ImgDescriptionDetail
          Title={"Area"}
          QuantityOrPrice={
            Building.Building.Building &&
            Building.Building.Building.SizeInterior
              ? Building.Building.Building.SizeInterior
              : "N/A"
          }
          Color="brown"
          index={index}
        />
      </Grid>
      <Grid item xs={6}>
        <ImgDescriptionDetail
          Title={" Work Location"}
          QuantityOrPrice={workDistance}
          Color="orange"
          index={index}
        />
      </Grid>
      <Grid item xs={6}>
        <ImgDescriptionDetail
          Title={"School Location"}
          QuantityOrPrice={schoolLocation}
          Color="orange"
          index={index}
        />
      </Grid>
      <Grid item xs={6}>
        <ImgDescriptionDetail
          Title={"Other Location"}
          QuantityOrPrice={otherLocation}
          Color="orange"
          index={index}
        />
        {/* < OtherLocationDetail Title={"Other Location"} QuantityOrPrice={otherLocation} Color="orange" index={index} /> */}
      </Grid>
    </Grid>
  );
};

//invitation popup
const InvitePopUp = ({ updatedCatList }) => {
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [valuea, setValue] = React.useState(false);
  const [btnchk, setbtnchk] = React.useState(true);
  const [emails, setemails] = useState([]);
  const [getApi, setGetApi] = useState(false);
  const [loading, setloading] = useState(false);
  const [inviteListArr, setinviteListArr] = useState(false);

  //get invited mails
  useEffect(() => {
    if (getApi === true) {
      setloading(true);
      getInviteMails().then((res) => {
        if (res != undefined) {
          var inviteListObjs = [];
          res &&
            res.data.map((i, j) => {
              updatedCatList.catValue.map((m, n) => {
                if (i.collaborateCategory === m[0]) {
                  var inviteListObja = i;
                  inviteListObjs.push(inviteListObja);
                }
              });
            });
          setinviteListArr(inviteListObjs);
          setloading(false);
        }
        setloading(false);
        setGetApi(false);
      });
    }
  }, [getApi]);

  //Open true show invite button on dashboard
  const handleOpen = () => {
    setOpen(true);
  };

  //close invited mail popup
  const handleClose = () => {
    setOpen(false);
    setbtnchk(false);
  };

  //edit setting button in invitation popup use for show list of invitations
  const handleInvite = () => {
    setbtnchk(true);
  };

  // handle input field of email in invitation and multiple emails
  const handleEmail = (e) => {
    if (e.length === 0) {
      setemails(e);
      setbtnchk(true);
    } else {
      setemails(e);
      setbtnchk(false);
    }
  };

  //send invitation to new user
  var collabCat;
  const sendInvitation = () => {
    setloading(true);
    if (valuea != "") {
      setbtnchk(true);
    }
    updatedCatList.catValue.map((i, k) => {
      collabCat = i[0];
    });
    var a = {};
    var emailbody = {};
    var bodyArr = [];
    emails.map((i, j) => {
      updatedCatList.catValue.map((j, k) => {
        emailbody = {};
        emailbody = {
          invitedEmail: i,
          invitedUserId: "",
          invitedUserRole: "Viewer",
          collaborateCategory: j[0],
        };
        a = {};
        a = emailbody;
        bodyArr.push(a);
      });
    });
    inviteMails(bodyArr).then((res) => {
      if (res && res.message != undefined) {
        setGetApi(true);
        swal({
          title: "Invitation Sent",
          // text: res.message,
          icon: "success",
        });
        setemails([]);
        setloading(false);
        handleClose();
      } else {
        setloading(false);
      }
      setloading(false);
    });
  };

  const classes = useStyles();
  return (
    <div>
      <span
        variant="contained"
        style={{ color: "green", cursor: "pointer" }}
        color="primary"
        onClick={handleOpen}
      >
        {<AddIcon style={{ color: "green", marginTop: "-2px" }} />}
        <p
          style={{
            float: "right",
            display: "inline-block",
            position: "relative",
            top: "-14px",
            height: "0px",
            background: "none!important",
          }}
        >
          invite
        </p>
      </span>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.invitePopup}>
          <p
            style={{
              fontSize: "26px",
              marginTop: "7px",
            }}
          >
            Sharing Settings
          </p>
          <span onClick={handleClose}>
            <Icon>
              <img src={cross} className={classes.inviteCross} />
            </Icon>
          </span>
          <Grid xs={12}>
            <p style={{ color: "grey" }}>Invite with emails</p>
          </Grid>
          <Grid xs={12}>
            <ReactMultiEmail
              placeholder="email"
              style={{ width: "480px !important" }}
              email={emails}
              value={emails}
              onChange={(e) => handleEmail(e)}
              validateEmail={(email) => {
                return isEmail(email); // return boolean
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
            <br />
          </Grid>
          {loading && (
            <div
              style={{
                position: "fixed",
                zIndex: 20000,
                height: "12%",
                opacity: 1,
                width: "100%",
                background: "#ffffff94",
                textAlign: "center",
                left: "0px",
              }}
            >
              <CircularProgress size={30} />
            </div>
          )}

          <Grid container style={{ marginTop: "7px" }} spacing={2} xs={12}>
            <Grid xs={12} item>
              {
                <Link
                  style={{ color: "red" }}
                  onClick={() => setValue(!valuea)}
                >
                  {" "}
                  <SettingsOutlinedIcon />
                  <span onClick={handleInvite}>
                    {" "}
                    {
                      <Link
                        style={{ color: "black", cursor: "pointer" }}
                        onClick={() => setValue(!valuea)}
                      >
                        Edit Settings
                      </Link>
                    }
                  </span>
                </Link>
              }
            </Grid>
            <Grid xs={12} item>
              {valuea && (
                <InviteList
                  emails={emails}
                  inviteListArrs={inviteListArr}
                  updatedCatList={updatedCatList}
                  getApi={getApi}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginTop: "25px", textAlign: "right" }}
            spacing={2}
            xs={12}
          >
            <Grid xs={10} item>
              <Button
                style={{ backgroundColor: "lightgrey", color: "black" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid xs={2} item>
              <Button
                disabled={btnchk ? btnchk : false}
                style={{ backgroundColor: "red", color: "white" }}
                onClick={sendInvitation}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

//show list of invitation click on Edit setting button this a component of inviteList
const InviteList = ({ emails, updatedCatList, getApi, inviteListArrs }) => {
  const [inviteList, setinviteList] = useState([]);
  const [loading, setloading] = useState(false);
  const [deleteApi, setDeleteApi] = useState(false);
  var inviteListObj = {};
  var inviteListArr = [];

  //get invitation list
  useEffect(() => {
    setloading(true);
    getInviteMails().then((res) => {
      if (res != undefined) {
        res &&
          res.data.map((i, j) => {
            updatedCatList.catValue.map((m, n) => {
              if (i.collaborateCategory === m[0]) {
                inviteListObj = i;
                inviteListArr.push(inviteListObj);
              }
            });
          });
        setinviteList(inviteListArr);
        setloading(false);
      }
      setloading(false);
    });
  }, []);

  useEffect(() => {
    setinviteList(inviteListArrs);
  }, [inviteListArrs]);

  useEffect(() => {
    if (getApi === true || deleteApi === true) {
      setloading(true);
      getInviteMails().then((res) => {
        if (res != undefined) {
          res &&
            res.data.map((i, j) => {
              updatedCatList.catValue.map((m, n) => {
                if (i.collaborateCategory === m[0]) {
                  inviteListObj = i;
                  inviteListArr.push(inviteListObj);
                }
              });
            });
          setinviteList(inviteListArr);
          setloading(false);
        }
        setloading(false);
        setDeleteApi(false);
      });
    }
  }, [getApi, deleteApi]);

  const classes = useStyles();
  const [state, setState] = React.useState({ age: "", name: "hai" });
  const handleChange = (event) => {
    const name = event.target.name;
    setState({ ...state, [name]: event.target.value });
  };

  //change invitation role of invited user
  function changeinvitedUserRole(Role, User) {
    User.invitedUserRole = Role;
    var body = {};
    var a = [];
    var updateInviteBody;
    updateInviteBody = {
      invitedEmail: User.invitedEmail,
      invitedUserId: User.invitedUserId,
      invitedUserRole: Role,
      collaborateCategory: User.collaborateCategory,
    };
    a.push(updateInviteBody);
    body = a;
    setloading(true);
    updateCollaborationInvitation(User._id, body).then((res) => {
      if (res.message === "CollaborationUpdated") {
        swal({
          title: "Sharing Updated",
          //  text: res.message,
          icon: "success",
        });
        setloading(false);
      } else if (res.message === "UserNotAcceptedInvitationYet") {
        swal({
          title: "Sharing Updated",
          //  text: res.message,
          icon: "warning",
        });
        setloading(false);
      } else {
        swal({
          title: "Sharing Updated",
          //  text: res.message,
          icon: "error",
        });
        setloading(false);
      }
      setloading(false);
    });
  }

  //delete invitation in list
  function deleteInvitedUser(e, collaborateCategory) {
    setloading(true);
    deleteInvitation(collaborateCategory).then((res) => {
      if (res.message === "DeleteCollaboration") {
        swal({
          title: "Delete invitation",
          text: res.message,
          icon: "success",
        });
        setDeleteApi(true);
        setloading(false);
      } else {
        swal({
          title: "Delete invitation",
          text: res.message,
          icon: "error",
        });
        setloading(false);
      }
      setloading(false);
    });
  }

  return (
    <Grid className="inviteScroll">
      <p style={{ color: "grey" }}>Who has access</p>
      {loading && (
        <div
          style={{
            position: "fixed",
            zIndex: 20000,
            height: "12%",
            opacity: 1,
            width: "100%",
            background: "#ffffff94",
            textAlign: "center",
            left: "0px",
          }}
        >
          <CircularProgress size={30} />
        </div>
      )}
      <table dense className={classes.inviteListRoot}>
        {inviteList.length > 0 &&
          inviteList.map((i, value) => {
            const labelId = `checkbox-list-secondary-label-${value}`;
            return (
              <tr key={value} button style={{ marginLeft: "-24px" }}>
                <td style={{ maxWidth: "50px" }}>
                  <Avatar alt={`Avatar n°${value + 1}`} src={""} />
                </td>
                <td
                  style={{ minWidth: "190px", marginLeft: "-7px" }}
                  id={labelId}
                  primary={i.invitedEmail}
                >
                  {i.invitedEmail}
                </td>
                <td id={labelId} primary={i.category}>
                  {i.category}
                </td>
                <td>
                  <Select
                    style={{ cursor: "pointer", left: "10px" }}
                    defaultValue={
                      i.invitedUserRole ? i.invitedUserRole : "Viewer"
                    }
                    inputProps={{ name: "name", id: "uncontrolled-native" }}
                    onChange={(e) => changeinvitedUserRole(e.target.value, i)}
                  >
                    <MenuItem style={{ cursor: "pointer" }} value={"Viewer"}>
                      {<VisibilityIcon />}
                    </MenuItem>
                    <MenuItem style={{ cursor: "pointer" }} value={"Editor"}>
                      <EditIcon />
                    </MenuItem>
                  </Select>
                </td>
                <td>
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon
                      onClick={(e) => deleteInvitedUser(e.target.value, i._id)}
                    />
                  </IconButton>
                </td>
              </tr>
            );
          })}
      </table>
    </Grid>
  );
};
