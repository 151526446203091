import Axios from "axios"
import { Api } from "."

//Call api's

//get user detail
async function getUser(token) {
  return Axios.get(Api.baseUrl + "/api/AIBotUserProfile", {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  })
    .then(res => {
      // console.log("res profile", res)
      if (res.data.message === "InvalidToken") {
        return getAccessToken().then(res => res ? getUser(res) : '');
      } else {
        if (res && res.data && res.data.result) {
          let uname = res.data.result.firstName + " " + res.data.result.lastName
          localStorage.setItem("userName", uname);
        }
        return res.data.result
      }
    })
    .catch(err => {
      console.log(err)
      // reject(err)
    })
}


//get category detail
var catArr = []

var categoriess = {}
var catobj = {}
async function getCat(token) {
  catArr = []
  // console.log("Cat 31")
  return Axios.get(Api.baseUrl + "/api/Category", {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  })
    .then(async (res) => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? getCat(res) : '')
      }
      else {
        var CatList = res.data.result.data
        if (CatList != undefined && CatList.length > 0) {
          //get collaboration data
          await getCollaborationInvitations().then(res => {
            categoriess = {}
            res && res.data && res.data.length > 0 && res.data.map((i, j) => {
              categoriess = {}
              if (catArr.find(x => x._id == i.collaborateCategory) == null) {
                categoriess = {
                  CategoryName: i.firstName + "-" + i.category,
                  UserId: i.userid,
                  firstName: i.firstName,
                  _id: i.collaborateCategory,
                  IsAccepted: i.IsAccepted,
                  invitedEmail: i.invitedEmail,
                  invitedUserId: i.invitedUserId,
                  invitedUserRole: i.invitedUserRole,
                  properties: i.properties,
                }
                catArr.push(categoriess)
              }

            })
            if (CatList.length > 0) {
              catobj = {}
              CatList.map((i, k) => {
                if (catArr.find(x => x._id == i._id) == null) {
                  catobj = {}
                  catobj = {
                    CategoryName: i.CategoryName,
                    UserId: i.UserId,
                    _id: i._id,
                    IsAccepted: '',
                    invitedEmail: '',
                    invitedUserId: '',
                    invitedUserRole: '',
                    properties: '',
                  }
                  catArr = [...catArr, catobj]
                }
              })
            }
          })
            .catch(err => {
              console.log("line90", err);
            })
        }
        else {
          await getCollaborationInvitations().then(res => {
            categoriess = {}
            res.data && res.data.length > 0 && res.data.map((i, j) => {
              categoriess = {}
              categoriess = {
                CategoryName: i.firstName + "-" + i.category,
                UserId: i.userid,
                _id: i.collaborateCategory,
                IsAccepted: i.IsAccepted,
                invitedEmail: i.invitedEmail,
                invitedUserId: i.invitedUserId,
                invitedUserRole: i.invitedUserRole,
                properties: i.properties,
              }
              catArr.push(categoriess)
            })
          })
        }
        if (catArr.length >= 1) {
          return catArr
        }
        else {
          return res.data.result.data
        }
      }

    })
    .catch(err => {
      console.log(err)
      // reject(err)
    })
}

// get mortgage data call mortgage api
async function getMortgage(token) {
  return Axios.get(Api.baseUrl + "/api/Mortgage", {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  })
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? getMortgage(res) : '')
      }
      else {
        return res.data.result.data
      }
    })
    .catch(err => {
      console.log(err)
      // reject(err)
    })
}

// get accressToken call token api
async function getAccessToken() {
  return Axios.post(Api.baseUrl + "/api/AIBotGenerateToken", {}, {
    headers: {
      "x_refreshToken": localStorage.getItem('refreshToken')
    }
  })
    .then(res => {
      if (res) {
        localStorage.setItem("token", res.data.result.accessToken)
        return res.data.result.accessToken
      }
    })
    .catch(err => {
      console.log(err)
      // reject(err)
    })
}

//update Property comment api
async function updatePropertyCommentCategory(id, CategoryId, comments, token) {
  var body = {
    categoryId: CategoryId,
    comment: comments ? comments : ''
  }
  var head = {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  }

  return Axios.post(Api.baseUrl + "/api/Property/" + id, body, head)
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? updatePropertyCommentCategory() : '')
      }
      else {
        // updateMotegage(id, head)
        return res.data.result;
      }
      // updateMotegage(id, head)
    }).catch(err => {
      console.log(err)

    })
}


// add new category
async function postCategory(name, token) {
  return Axios.post(Api.baseUrl + "/api/Category", {
    name
  }, {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  })
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? postCategory() : '')
      }
      else {
        return res.data.result
      }

    })

    .catch(err => {
      console.log(err)
    })
}

// delete category
async function deleteCategory(a, token) {
  // https://84e4d37ffb7e.ngrok.io/  Api.baseUrl +
  return Axios.delete(Api.baseUrl + "/api/Category/" + a, {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  })
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? deleteCategory(a) : '')
      }
      else {
        return res.data.result
      }
    })
    .catch(err => {
      console.log(err)
      // reject(err)
    })
}

//delete properties
async function deleteProperty(a, token) {
  return Axios.delete(Api.baseUrl + "/api/Property/" + a.propId, {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  })
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? deleteProperty(a) : '')
      }
      else {
        return res.data.result
      }
    })
    .catch(err => {
      console.log(err)
    })
}

// sort properties use in case of filter and sort by and budget
async function sortProperties(catValue, values, budgetValues, token) {
  var categories = [];
  var sorts = values && values !== 'undefined' ? values : 'MinPrice'
  for (let i = 0; i < catValue.length; i++) {
    if (categories.indexOf(catValue[i][0]) < 0)
      categories.push(catValue[i][0]);
  }
  var body = {
    "filter": {
      "categories": categories,
      "priceRange": parseInt(budgetValues)
    }
  }

  return Axios.post(Api.baseUrl + "/api/Property/sorting/" + sorts, body, {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  })
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? sortProperties() : '')
      }
      else {
        return res.data.result
      }
    })
    .catch(err => {
      console.log(err)
    })
}

// save monthly expenses
async function monthlyExpenses(body, token) {
  var head = {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  }
  return Axios.post(Api.baseUrl + "/api/User/Manage/MonthyExpense/", body, head)
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? monthlyExpenses() : '')
      }
      else {
        return res.data.result;
      }
    }).catch(err => {
      console.log(err)
    })
}

// edit Profile api
async function editProfile(id, body, token) {
  var head = {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  }

  return Axios.post(Api.baseUrl + "/api/User/" + id, body, head)
    .then(res => {
      if (res.data.message === "InvalidToken") {
        return getAccessToken().then(res => res ? editProfile() : '');
      } else {
        return res.data.result
      }
    })
    .catch(err => {
      console.log(err)
      // reject(err)
    })
}

// send invitation to users and new users
async function inviteMails(body, token) {
  var head = {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  };
  return Axios.post(Api.baseUrl + "/api/Collaboration", body, head)
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? inviteMails() : '')
      }
      else {
        return res.data.result;
      }

    }).catch(err => {
      console.log(err)
    })
}


//get invitationMails list
async function getInviteMails(token) {

  var head = {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  }
  return Axios.get(Api.baseUrl + "/api/Collaboration", head)
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? getInviteMails() : '')
      }
      else {
        return res.data.result;
      }
    }).catch(err => {
      console.log(err)

    })
}

// get CollaborationInvitations
async function getCollaborationInvitations(token) {

  var head = {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  }

  return Axios.get(Api.baseUrl + "/api/Collaboration/invitations", head)
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? getCollaborationInvitations() : '')
      }
      else {
        return res.data.result;
      }

    }).catch(err => {
      console.log(err)

    })
}

// update Collaboration Invitation
async function updateCollaborationInvitation(id, body, token) {
  var head = {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  }
  return Axios.post(Api.baseUrl + "/api/Collaboration/update/" + id, body, head)
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? updateCollaborationInvitation() : '')
      }
      else {
        return res.data.result;
      }
    }).catch(err => {
      console.log(err)

    })
}

// delete invitations
async function deleteInvitation(id, token) {
  // console.log("deleteinvitation 418", id)
  // var head = {
  //   headers: {
  //     "x-accesstoken": token || localStorage.getItem('token')
  //   }
  // }
  return Axios.delete(Api.baseUrl + "/api/Collaboration/" + id, {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  })
    .then(res => {
      // console.log("res deleteinvitation 430", res)
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? deleteInvitation() : '')
      }
      else {
        return res.data.result;
      }
    }).catch(err => {
      console.log(err)

    })
}

// Mortgage Rate add new bank
async function MortgageRate(body, token) {
  // console.log("450 body", body)
  var head = {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  };
  return Axios.post(Api.baseUrl + "/api/MortgageRate", body, head)
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? MortgageRate() : '')
      }
      else {
        return res.data.result;
      }

    }).catch(err => {
      console.log(err)
    })
}

// get all mortgage rate detail
async function GetAllMortgageRate(token) {
  var head = {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  };
  return Axios.get(Api.baseUrl + "/api/MortgageRate", head)
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? GetAllMortgageRate() : '')
      }
      else {
        return res.data.result;
      }

    }).catch(err => {
      console.log(err)
    })
}

// delete  mortgae rate
async function DeleteMortgageRate(id, token) {
  var head = {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  };
  return Axios.delete(Api.baseUrl + "/api/MortgageRate/" + id, head)
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? DeleteMortgageRate() : '')
      }
      else {
        return res.data.result;
      }

    }).catch(err => {
      console.log(err)
    })
}

// get mortgage rate detail by id
async function GetByIdMortgageRate(id, token) {
  var head = {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  };
  return Axios.get(Api.baseUrl + "/api/MortgageRate/" + id, head)
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? GetByIdMortgageRate() : '')
      }
      else {
        return res.data.result;
      }

    }).catch(err => {
      console.log(err)
    })
}

// update mortgage detail
async function UpdateMortgageRate(id, body, token) {
  var head = {
    headers: {
      "x-accesstoken": token || localStorage.getItem('token')
    }
  };
  return Axios.post(Api.baseUrl + "/api/MortgageRate/" + id, body, head)
    .then(res => {
      if (res.data.result.message === "InvalidToken") {
        return getAccessToken().then(res => res ? UpdateMortgageRate() : '')
      }
      else {
        return res.data.result;
      }

    }).catch(err => {
      console.log(err)
    })
}



export {
  getUser, getMortgage, getCat, getAccessToken, editProfile, postCategory, deleteCategory, deleteProperty,
  sortProperties, updatePropertyCommentCategory, monthlyExpenses, inviteMails, getInviteMails,
  getCollaborationInvitations, updateCollaborationInvitation, deleteInvitation, MortgageRate,
  GetAllMortgageRate, DeleteMortgageRate, GetByIdMortgageRate, UpdateMortgageRate
}