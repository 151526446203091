import React, { Suspense, useEffect, useState } from 'react'
import {
  Grid, Container, TextField, Button, ExpansionPanel, ExpansionPanelSummary, Typography,
  ExpansionPanelDetails, CircularProgress
} from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import img1 from '../../assest/img_1.png';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { getUser, getAccessToken, getMortgage, monthlyExpenses } from '../../const/function';
import { useHistory } from "react-router-dom";
import store from '../../store';
import { Select, MenuItem, Switch } from '@material-ui/core';
import { provincial_ltt, municipal_ltt, ltt_rebate } from '../../const/motgage';
import { Api } from '../../const';
import Axios from 'axios';
import {
  getDistanceBetweenAddress
} from '../../const/location';
const Header = React.lazy(() => import('../Header'));
const Footer = React.lazy(() => import('../Footer'));

//page style
const useStyles = makeStyles(theme => ({
  flyout: {
    marginTop: '5px',
    maxWidth: '1250px',
    margin: '0 auto',
  },
  login: {
    float: 'right',
    width: 'auto'
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  root1: {
    '& > *': {
      // margin: theme.spacing(),
      width: '100%',
    },
  },
  propDetailBox: {
    backgroundColor: 'white',
    padding: '10px',
    width: '100%',
    margin: '32px',
    marginBottom: '18px',
    height: '100%',
    marginTop: '1px'
  },
  labelMargin: {
    marginTop: '16px',
    marginLeft: '0px'
  },
  summary: {
    height: '0px',
    margin: '0px',
    paddingRight: '10px',
    paddingLeft: '10px'
  },
  details: {
    width: '100%',
    paddingRight: '0px',
    paddingLeft: '0px'
  },
  subHead: {
    fontSize: '1.2375rem',
    padding: '10px',
    // fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#f02834',
    margin: 0,
  },
  panelMargin: {
    margin: '10px'
  },
  img: {
    margin: theme.spacing(1),
    height: 260,
    overflow: 'hidden',
    display: 'block',
    cursor: 'pointer',
    width: '100%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
}));

//Main function of edit property detail page
function PropertyDetail() {
  const history = useHistory()
  const [ExpandedAdditional, setExpandedAdditional] = useState(false)
  const [ExpandedMortgageAmount, setExpandedMortgageAmount] = useState(false)
  const [ExpandedMonthlyExpense, setExpandedMonthlyExpense] = useState(false)
  const [loading, setloading] = useState(false);
  const [dataexpense, setdataexpense] = useState('');
  const [propid, setpropid] = useState('');
  const [detailProp, setdetailProp] = useState([]);
  const [Asking, setAsking] = useState(0)
  const [Asking2, setAsking2] = useState(0)
  const [Expected, setExpected] = useState(0)
  const [Down, setDown] = useState(0)
  const [Percent, setPercent] = useState(0)
  const [Down2, setDown2] = useState(0)
  const [Percent2, setPercent2] = useState(20)
  const [UserData, setUserData] = useState('')
  const [Insurance, setInsurance] = useState(0)
  const [Insurance2, setInsurance2] = useState(0)
  const [MortgagePayment, setMortgagePayment] = useState(0)
  const [MortgagePayment2, setMortgagePayment2] = useState(0)
  const [Needed2, setNeeded2] = useState(0)
  const [Needed, setNeeded] = useState(0)
  const [Affordabilitys, setAffordabilitys] = useState(0)
  const [Affordabilitys2, setAffordabilitys2] = useState(0)
  const [BiCalTotal, setBiCalTotal] = useState(0)
  const [BiCalInterest, setBiCalInterest] = useState(0)
  const [BiCalLoanterm, setBiCalLoanterm] = useState(0)
  const [BiCalMonthlyPaymentAmt, setBiCalMonthlyPaymentAmt] = useState(0)
  const [BiCalbiweeklypayment, setBiCalbiweeklypayment] = useState(0)
  const [BiCalAccerlateBiWeeklypayment, setBiCalAccerlateBiWeeklypayment] = useState(0)
  const [BiCalInterestMonthlypayment, setBiCalInterestMonthlypayment] = useState(0)
  const [BiCalInterestBiWeeklypayment, setBiCalInterestBiWeeklypayment] = useState(0)
  const [BiCalInterestAccerealteBiWeeklypayment, setBiCalInterestAccerealteBiWeeklypayment] = useState(0)
  const [TotalMotage, setTotalMotage] = useState(0)
  const [TotalMotage2, setTotalMotage2] = useState(0)
  const [TotalAdditionalCost, setTotalAdditionalCost] = useState(0)
  const [TotalAdditionalCost2, setTotalAdditionalCost2] = useState(0)
  const [AddressTextporp, setAddressTextporp] = useState('')
  const [Frequency, setFrequency] = useState(0)
  const [Frequency2, setFrequency2] = useState(0)
  const [BankDetails, setBankDetails] = useState('')
  const [BankDetail2, setBankDetail2] = useState('')
  const [ClosingBool, setClosingBool] = useState(false)
  const [Rate, setRate] = useState(0)
  const [PropertyTax, setPropertyTax] = useState(0)
  const [toggleMortgageValue, setToggleMortgageValue] = useState(0)
  const [LawyerFee, setLawyerFee] = useState(0)
  const [TitleInsurance, setTitleInsurance] = useState(0)
  const [HomeInsurance, setHomeInsurance] = useState(0)
  const [EstoppelCertificate, setEstoppelCertificate] = useState(0)
  const [Appraisal, setAppraisal] = useState(0)
  const [MortgageInsuranceDown, setMortgageInsuranceDown] = useState(0)
  const [AmortizationBiWeekly, setAmortizationBiWeekly] = useState(0)
  const [Landinfo, setLandinfo] = useState('')
  const [OtherLocation, setOtherLocation] = useState('')
  const [workDistance, setworkDistance] = useState('')
  const [schoolLocation, setschoolLocation] = useState('')
  const [LocationAddressText, setLocationAddressText] = useState('')
  const [otherNameLocation, setotherNameLocation] = useState('')
  const [saveMonthlyExpense, setsaveMonthlyExpense] = useState(false)
  const MortgageCalculator = require('mortgage-calculator-p')('ca');

  //use query to get url of tab
  function useQuery() {
    return new URLSearchParams(window.location.search);
  }

  let query = useQuery();

  //get property id by query
  useEffect(() => {
    setloading(true)
    setpropid(query.get("id"))
  }, [query])

  //Get Mortgage detail and user detail when store is empty
  useEffect(() => {
    setloading(true)
    if (!store.data.Mortgage) {
      getMortgage().then(data => store.update("Mortgage", data))
    }
    if (!store.data.User) {
      getUser().then(data => {
        store.update("User", data);
        if (data != undefined) {
          if (data.province) {
            store.update("Province", data.province)
          }
          if (data.properties) {
            store.update("Properties", data.properties)
          }
        }
      })
    }

    if (!store.data.getAccessToken) {
      getAccessToken().then(data => store.update("GenerateToken", data))
    }
    propertyData()
  }, [propid, query])

  //set property data into state
  const propertyData = () => {
    setloading(true)
    store.data.Properties.map((i, k) => {
      if (i._id === query.get("id")) {
        if (detailProp.length == 0) {
          var b = detailProp || []
          b.push(i)
          setdetailProp(b)
        }
        //  else {
        //   setdetailProp(i)
        // }

      }
      // setloading(false)
    })
    if (detailProp && detailProp.length > 0) {
      detailProp && detailProp.length > 0 && detailProp.map((i, j) => {
        // setloading(true)
        setdetailProp(i)
        MortgageCalc(i)
        setLocationAddressText(i && i.Property && i.Property.Address.AddressText ? i.Property.Address.AddressText : '')
        // setOtherLocation(i.otherLocationsDistance && i.otherLocationsDistance ? i.otherLocationsDistance : '')
        // setloading(false)
      })
    }
    else {
      // detailProp && detailProp.length > 0 && detailProp.map((i, j) => {
      // setloading(true)
      setdetailProp(detailProp)
      MortgageCalc(detailProp)
      setLocationAddressText(detailProp && detailProp.Property && detailProp.Property.Address.AddressText ? detailProp.Property.Address.AddressText : '')
      // setOtherLocation(detailProp.otherLocationsDistance && detailProp.otherLocationsDistance ? detailProp.otherLocationsDistance : '')
      // setloading(false)
      // })   
    }

    // setloading(false)
  }

  //when property page shoe empty and doesn't show data on page then this function is call
  function emptyCase() {
    setloading(true)
    store.data.Properties.map((i, k) => {
      if (i._id === query.get("id")) {
        var b = detailProp
        b.push(i)
        setdetailProp(b)
      }
    })
    detailProp && detailProp.map((i, j) => {
      setdetailProp(i)
      MortgageCalc(i)
      setLocationAddressText(i && i.Property && i.Property.Address.AddressText ? i.Property.Address.AddressText : '')
      // setOtherLocation(i.otherLocationsDistance && i.otherLocationsDistance ? i.otherLocationsDistance : '')
      // setloading(false)
    })
    // setloading(false)
  }

  //use for get expenses
  useEffect(() => {
    // setloading(true)
    getExpenses();
  }, [])

  //set locations and Excepted and asking price
  useEffect(() => {
    var address = LocationAddressText
    var a = detailProp.Mortgage && detailProp.Mortgage.askingPrices ? detailProp.Mortgage.askingPrices : detailProp.Property && detailProp.Property.Price
    var b = detailProp.Mortgage && detailProp.Mortgage.askingPrices1 ? detailProp.Mortgage.askingPrices1 : a

    if (detailProp && detailProp.Property && detailProp.Property.Price == a) {
      setloading(true)
      // var expectPrice = detailProp.Property && detailProp.Property.Price ? detailProp.Property.Price : 0
      setExpected(a ? a.split("$")[1] : a)
      setAsking(a ? a.split("$")[1] : a)
      setAsking2(b ? b.split("$")[1] : b)
      setloading(false)
    }
    else {
      if (a) {
        setloading(true)
        setExpected(a.toLocaleString())
        setAsking(a.toLocaleString())
        setAsking2(b.toLocaleString())
      }
      setloading(false)
    }
    if (detailProp && detailProp.Mortgage && detailProp.Mortgage.DistanceMatrix) {
      setOtherLocation(detailProp.Mortgage.DistanceMatrix && detailProp.Mortgage.DistanceMatrix.OtherLocation ? detailProp.Mortgage.DistanceMatrix.OtherLocation : 0 + " Km")
      setschoolLocation(detailProp.Mortgage.DistanceMatrix && detailProp.Mortgage.DistanceMatrix.SchoolLocation ? detailProp.Mortgage.DistanceMatrix.SchoolLocation : 0 + " Km")
      setworkDistance(detailProp.Mortgage.DistanceMatrix && detailProp.Mortgage.DistanceMatrix.WorkLocation ? detailProp.Mortgage.DistanceMatrix.WorkLocation : 0 + " Km")
    } else {
      setOtherLocation(0 + " Km")
      setschoolLocation(0 + " Km")
      setworkDistance(0 + " Km")

    }
    // if (window.store.data.User && window.store.data.User.workLocation) {
    //   if (!window.store.data.Properties.workLocationDistance) {
    //     let originLocation = window.store.data.User.workLocation;
    //     let destinationLocation = address;
    //     getDistanceBetweenAddress(originLocation, destinationLocation)
    //       .then(res => {
    //         if (res.status === 200) {
    //           var distance = res.data.rows[0].elements[0].distance.value;
    //           var distanceInMiles = parseFloat(distance * 0.000621371).toFixed(2);
    //           setworkDistance(distanceInMiles + ' Miles');
    //         }
    //         else {
    //           setworkDistance('0 Miles');
    //         }
    //       })
    //       .catch(err => {
    //         setworkDistance('0 Miles');
    //       })
    //   }
    //   else {
    //     setworkDistance(window.store.data.Properties.workLocationDistance);
    //   }
    // }
    // else {
    //   setworkDistance('0 Miles')
    // }

    // if (window.store.data.User && window.store.data.User.schoolLocation) {
    //   if (!window.store.data.Properties.schoolLocationDistance) {
    //     let originLocation = window.store.data.User.schoolLocation;
    //     let destinationLocation = address;
    //     getDistanceBetweenAddress(originLocation, destinationLocation)
    //       .then(res => {
    //         if (res.status === 200) {
    //           var distance = res.data.rows[0].elements[0].distance.value;
    //           var distanceInMiles = parseFloat(distance * 0.000621371).toFixed(2);
    //           setschoolLocation(distanceInMiles + ' Miles');
    //         }
    //         else {
    //           setschoolLocation('0 Miles');
    //         }
    //       })
    //       .catch(err => {
    //         setschoolLocation('0 Miles');
    //       })
    //   }
    //   else {
    //     setschoolLocation(window.store.data.Properties.schoolLocationDistance);
    //   }
    // }
    // else {
    //   setschoolLocation('0 Miles')
    // }

    // //Other Location fun
    // var otherLocationArray = []
    // if (window.store.data.User && window.store.data.User.otherLocations && window.store.data.User.otherLocations.length > 0) {
    //   if (window.store.data.Properties.otherLocationsDistance
    //     && window.store.data.User.otherLocations.length !== window.store.data.Properties.otherLocationsDistance.length) {
    //     OtherLocationfun()
    //   }
    //   else {
    //     if (window.store.data.Properties && window.store.data.Properties.otherLocationsDistance === window.store.data.User.otherLocations.length) {
    //       setOtherLocation(window.store.data.Properties.otherLocationsDistance)
    //     }
    //     else {
    //       OtherLocationfun()
    //     }
    //   }
    // }
    // else {
    //   setOtherLocation(otherLocationArray);
    // }

  }, [LocationAddressText])

  //Other Location
  // function OtherLocationfun() {
  //   var otherLocationArray = []
  //   var otherLocationArrays = []
  //   window.store.data.User.otherLocations.forEach((value, i) => {
  //     let objOtherLocation = {};
  //     let originLocation = value.location;
  //     let destinationLocation = LocationAddressText;
  //     getDistanceBetweenAddress(originLocation, destinationLocation)
  //       .then(res => {
  //         if (res.status === 200) {
  //           var distance = res.data.rows[0].elements[0].distance.value;
  //           var distanceInMiles = parseFloat(distance * 0.000621371).toFixed(2);
  //           objOtherLocation.name = value.name + "'s Location";
  //           objOtherLocation.distance = distanceInMiles + " Miles";
  //           otherLocationArray.push(objOtherLocation);
  //           otherLocationArrays = otherLocationArray
  //           i++;
  //           setotherNameLocation(otherLocationArrays);
  //         }
  //         else {
  //           objOtherLocation.name = value.name + "'s Location";
  //           objOtherLocation.distance = "0 Miles";
  //           otherLocationArray.push(objOtherLocation);
  //           otherLocationArrays = otherLocationArray
  //           i++;
  //           setotherNameLocation(otherLocationArrays);
  //         }
  //       })
  //       .catch(err => {
  //         objOtherLocation.name = value.name + "'s Location";
  //         objOtherLocation.distance = "0 Miles";
  //         otherLocationArray.push(objOtherLocation);
  //         otherLocationArrays = otherLocationArray
  //         i++
  //         setotherNameLocation(otherLocationArrays);
  //       })
  //   })
  // }

  //get Expenses

  //get expenses call this function 
  const getExpenses = () => {
    // setloading(true);
    if (!store.data.User) {
      getUser().then(res => {
        if (res && res.monthlyExpense != undefined) {
          setdataexpense(res.monthlyExpense)
        }
        emptyCase()
        // setloading(false);
      })
    }
    else {
      // setloading(true)
      setdataexpense(store.data.User.monthlyExpense)
      emptyCase()
      // setloading(false);
    }
  }

  //set toggle for show hide or collapse the Mortgage Amount,Additional and monthly expenses
  const classes = useStyles();
  const toggleAdditionalCost = () => setExpandedAdditional(!ExpandedAdditional);
  const toggleMortgageAmount = () => setExpandedMortgageAmount(!ExpandedMortgageAmount);
  const toggleMonthlyExpense = () => setExpandedMonthlyExpense(!ExpandedMonthlyExpense);

  //handle total Amount and set states
  function handleTotalAmount(a) {
    var totalAdditionalCost = a.TotalAmount
    setTotalAdditionalCost(totalAdditionalCost.toFixed(2))
    setLawyerFee(a.LawyerFees)
    setTitleInsurance(a.TitleInsurance)
    setHomeInsurance(a.HomeInsurance)
    setEstoppelCertificate(a.EstoppelCertificate)
    setAppraisal(a.Appraisal)
    setMortgageInsuranceDown(a.InsuranceDown)
  }

  //set bank detail data get from child
  function setBankDetails2(q) {
    setMortgagePayment2(q.EMI2);
    setFrequency2(q.Frequency2);
  }

  //toggle mortgage and total amount 
  function handleToggleMortgageTotalAmount(d) {
    setMortgagePayment(d.EMI)
    setAmortizationBiWeekly(d.AmortizationPeriod)
    setBiCalLoanterm(d.AmortizationPeriod)
    setClosingBool(d.Closing)
    setRate(d.RateType)
    setBiCalInterest(d.Rate)
    setPropertyTax(d.Rate)
    setToggleMortgageValue(d.TotalClosingCost)
    setFrequency(d.Frequency)
  }

  //handle total expenses and affordability
  function handleTotalExpenses(a) {
    setloading(false)
    var totalexpense = a && a.totalMontlyExpense ? a.totalMontlyExpense : a.totalMontlyExpense
    var netpaymentmonth = a && a.netPayPerMonth ? a.netPayPerMonth : a.netPayPerMonth

    if (parseInt(Frequency) === 52) {
      var ab = totalexpense + parseFloat(a && (a.MortgagePayment * 4));
      var afffordbility = netpaymentmonth - ab
      setAffordabilitys(afffordbility)
    }
    else if (parseInt(Frequency) === 26) {
      var ab = totalexpense + parseFloat(a && (a.MortgagePayment * 2));
      var afffordbility = netpaymentmonth - ab
      setAffordabilitys(afffordbility)
    }
    else if (parseInt(Frequency) === 12) {
      var ab = totalexpense + parseFloat(a && a.MortgagePayment)
      var afffordbility = netpaymentmonth - ab
      setAffordabilitys(afffordbility)
    }

    if (parseInt(Frequency2) === 52) {
      let mortgagepayment = parseFloat(a && (a.MortgagePayment2 * 4))
      var b = totalexpense + mortgagepayment
      var afffordbility2 = netpaymentmonth - b
      setAffordabilitys2(afffordbility2)
    }
    if (parseInt(Frequency2) === 26) {
      var b = totalexpense + parseFloat(a && (a.MortgagePayment2 * 2))
      var afffordbility2 = netpaymentmonth - b
      setAffordabilitys2(afffordbility2)
    }
    else if (parseInt(Frequency2) === 12) {
      var b = totalexpense + parseFloat(a && a.MortgagePayment2)
      var afffordbility2 = netpaymentmonth - b
      setAffordabilitys2(afffordbility2)
    }

  }

  //set affordability and total expenses
  useEffect(() => {
    var totalexpense = dataexpense && dataexpense.totalMontlyExpense ? dataexpense.totalMontlyExpense : ''
    var netpaymentmonth = dataexpense && dataexpense.netPayPerMonth ? dataexpense.netPayPerMonth : ''


    if (parseInt(Frequency) === 52) {
      var mortPay = MortgagePayment * 4;

      var a = totalexpense + parseFloat(mortPay)
      var afffordbility = netpaymentmonth - a
      setAffordabilitys(afffordbility)
    }
    else if (parseInt(Frequency) === 26) {
      var a = totalexpense + parseFloat(MortgagePayment * 2)
      var afffordbility = netpaymentmonth - a
      setAffordabilitys(afffordbility)
    }
    else if (parseInt(Frequency) === 12) {
      var a = totalexpense + parseFloat(MortgagePayment)
      var afffordbility = netpaymentmonth - a
      setAffordabilitys(afffordbility)
    }

    if (parseInt(Frequency2) === 52) {

      var b = totalexpense + parseFloat(MortgagePayment2 * 4)
      var afffordbility2 = netpaymentmonth - b
      setAffordabilitys2(afffordbility2)
    }
    else if (parseInt(Frequency2) === 26) {
      var b = totalexpense + parseFloat(MortgagePayment2 * 2)
      var afffordbility2 = netpaymentmonth - b
      setAffordabilitys2(afffordbility2)
    }
    else if (parseInt(Frequency2) === 12) {
      var b = totalexpense + parseFloat(MortgagePayment2)
      var afffordbility2 = netpaymentmonth - b
      setAffordabilitys2(afffordbility2)
    }

  }, [dataexpense])

  //Mortgage Calculator set text and expenses
  function MortgageCalc(detailProp) {
    setloading(true)
    setAddressTextporp(detailProp && detailProp.Property && detailProp.Property.Address.AddressText)
    var a = detailProp.Mortgage && detailProp.Mortgage.askingPrices ? detailProp.Mortgage.askingPrices : detailProp.Property && detailProp.Property.Price
    var b = detailProp.Mortgage && detailProp.Mortgage.askingPrices1 ? detailProp.Mortgage.askingPrices1 : a

    if (detailProp && detailProp.Property && detailProp.Property.Price == a) {
      setloading(true)
      // setExpected(a ? a.split("$")[1] : a)
      // setAsking(a ? a.split("$")[1] : a)
      // setAsking2(b ? b.split("$")[1] : b)
      setloading(false)
    }
    else {
      if (a) {
        setloading(true)
        // setExpected(a.toLocaleString())
        // setAsking(a.toLocaleString())
        // setAsking2(b.toLocaleString())
      }
      setloading(false)
    }

    if (!store.data.User) {
      setloading(true)
      getUser().then(res => {
        if (res.monthlyExpense) {
          setdataexpense(res.monthlyExpense)
        }
      })
      // setloading(false)
    } else {
      setdataexpense(store.data.User.monthlyExpense)
      // setloading(false)
    }

    // setloading(false)
  }

  //call calculator , calculator Percentage,calcultor percentage
  useEffect(() => {
    setclau()
    setclauPer()
    setclauPer2()
  }, [Asking, Asking2, Expected])

  //set needed value and other detail of mortgage calcutor
  useEffect(() => {
    if (detailProp && detailProp.Mortgage && detailProp.Mortgage.Scenarios.length > 0 && detailProp.Mortgage.Scenarios[0].downPaymentPercent) {
      let per = detailProp.Mortgage.Scenarios[0].downPaymentPercent
      var aAsking = Asking ? Asking : 0
      aAsking = aAsking ? parseFloat(aAsking.replace(/,/g, "")) : 0
      let neededVal = aAsking - ((aAsking * per) / 100)
      setNeeded(neededVal)
    }

    var downpercent2 = detailProp && detailProp.Mortgage && detailProp.Mortgage.Scenarios.length > 0 && detailProp.Mortgage.Scenarios[1] && detailProp.Mortgage.Scenarios[1].downPaymentPercent ? detailProp.Mortgage.Scenarios[1].downPaymentPercent : 5
    setPercent2(downpercent2)
    setDown2(detailProp && detailProp.Mortgage && detailProp.Mortgage.Scenarios.length > 0 && detailProp.Mortgage.Scenarios[1] && detailProp.Mortgage.Scenarios[1].downPayment ? detailProp.Mortgage.Scenarios[1].downPayment : Down2)
    setDown(detailProp && detailProp.Mortgage && detailProp.Mortgage.Scenarios.length > 0 && detailProp.Mortgage.Scenarios[0].downPayment ? detailProp.Mortgage.Scenarios[0].downPayment : Down)
    setPercent(detailProp && detailProp.Mortgage && detailProp.Mortgage.Scenarios.length > 0 && detailProp.Mortgage.Scenarios[0].downPaymentPercent ? detailProp.Mortgage.Scenarios[0].downPaymentPercent : Percent)
    setPercent2(detailProp && detailProp.Mortgage && detailProp.Mortgage.Scenarios.length > 0 && detailProp.Mortgage.Scenarios[1] && detailProp.Mortgage.Scenarios[1].downPaymentPercent ? detailProp.Mortgage.Scenarios[1].downPaymentPercent : 5)
    setInsurance(detailProp && detailProp.Mortgage && detailProp.Mortgage.Scenarios.length > 0 && detailProp.Mortgage.Scenarios[0].mortgageInsurance ? detailProp.Mortgage.Scenarios[0].mortgageInsurance : Insurance)
    setInsurance2(detailProp && detailProp.Mortgage && detailProp.Mortgage.Scenarios.length > 0 && detailProp.Mortgage.Scenarios[1] && detailProp.Mortgage.Scenarios[1].mortgageInsurance ? detailProp.Mortgage.Scenarios[1].mortgageInsurance : Insurance)
  }, [detailProp, Asking])

  //total mortgage and BicalcultorTotal and insurance set
  useEffect(() => {
    setTotalMotage((parseFloat(Needed) + getInsurance(Needed, Percent)))
    setBiCalTotal((parseFloat(Needed) + getInsurance(Needed, Percent)))
    setInsurance(getInsurance(Needed, Percent))
  }, [Needed, Percent])

  useEffect(() => {
    ScenarioCalculator()
  }, [Needed2])

  //calculate scenario  and set values of insurance and total mortgage value for second scenario
  function ScenarioCalculator() {
    setInsurance2(getInsurance(Needed2, Percent2))
    setTotalMotage2((parseFloat(Needed2) + getInsurance(Needed2, Percent2)))
  }

  // set calculator in case of Percentage
  useEffect(() => {
    setclauForPer2()
  }, [Asking, Asking2])

  // set calculator in case of econd scenario Percentage
  function setclauForPer2() {
    var askPricefor;
    if (detailProp.length > 0) {
      if (detailProp[0]) {
        askPricefor = detailProp && detailProp[0] && detailProp[0].Property.Price
          && detailProp[0].Mortgage.askingPrices
          ? detailProp[0].Mortgage.askingPrices ? Asking
            : detailProp[0].Property.Price : detailProp[0].Property.Price
      } else {
        askPricefor = detailProp && detailProp.Property.Price
          && detailProp.Mortgage.askingPrices
          ? detailProp.Mortgage.askingPrices ? Asking
            : detailProp.Property.Price : detailProp.Property.Price
      }
    }

    // var askPricefor = detailProp && detailProp[0] && detailProp[0].Property.Price
    //  && detailProp[0].Mortgage.askingPrices 
    // ? detailProp[0].Mortgage.askingPrices ? Asking
    //  : detailProp[0].Property.Price : detailProp[0].Property.Price
    // askPricefor = detailProp[0].Mortgage && detailProp[0].Mortgage.askingPrices ? detailProp[0].Mortgage.askingPrices : askPricefor

    if (askPricefor != 0) {
      // askPricefor = askPricefor ? askPricefor.split("$")[1] : askPricefor
    }
    // askPricefor = askPricefor ? askPricefor.replace(/,/g, "") : Asking
    // askPricefor = parseFloat(askPricefor)
    var aAsking2 = Asking2 ? Asking2 : Asking
    aAsking2 = aAsking2 ? aAsking2.replace(/,/g, "") : 0
    var NewAsking = Asking ? Asking.replace(/,/g, "") : Asking
    var downpayment = (aAsking2 * Percent2) / 100;
    if (Percent2.length <= 0 || Percent2 === "NaN" || Percent2 === "Na") {
      setDown2(0)
      setPercent2(0)
      setNeeded2(aAsking2)
    } else {
      if (!Down2 || Down2 == 0) {
        setDown2(downpayment)
      }
      setNeeded2(aAsking2 - ((aAsking2 * Percent2) / 100))
    }
  }

  //Calculate percent for Scenario2
  function setclauPer2(e) {
    var aAsking2 = Asking2 ? Asking2 : 0
    aAsking2 = aAsking2 ? aAsking2.replace(/,/g, "") : 0
    if (e) {
      var downpayment = (aAsking2 * e.target.value) / 100;
      if (e.target.value.length <= 0 || e.target.value === "NaN" || e.target.value === "Na") {
        setDown2(0)
        setPercent2(0)
        setNeeded2(aAsking2)
      } else {
        if (!Down2 || Down2 == 0) {
          setDown2(downpayment)
        }
        setNeeded2(aAsking2 - ((aAsking2 * e.target.value) / 100))
      }
    } else {
      var downpayment = (aAsking2 * Percent2) / 100;
      if (Percent2.length <= 0 || Percent2 === "NaN" || Percent2 === "Na") {
        setDown2(0)
        setPercent2(0)
        setNeeded2(aAsking2)
      } else {
        if (!Down2 || Down2 == 0) {
          setDown2(downpayment)
        }
        setNeeded2(aAsking2 - ((aAsking2 * Percent2) / 100))
      }
    }
  }

  //Downpayment for Scenario2
  function setDownPer2(e) {
    var aAsking2 = Asking2 ? Asking2 : 0
    aAsking2 = aAsking2 ? aAsking2.replace(/,/g, "") : 0
    var a = e.target.value
    a = a.replace(/,/g, "")
    if ((a).length <= 0 || a === "NaN" || a === "Na") {
      setDown2(0)
      setPercent2(0)
      setNeeded2(aAsking2)
    } else {
      var per = ((100 * a) / aAsking2)
      setPercent2(per.toFixed(2))
      setNeeded2(aAsking2 - ((aAsking2 * per.toFixed(2)) / 100))
    }
  }

  //initially set data
  function setclau(e) {
    if (e) {
      if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
        e.target.value = 0
      }
      var val1 = e.target.value
      var val2 = val1.replace(/,/g, "")
      var downpayment = (val2 * Percent) / 100;
      if ((downpayment).length <= 0 || downpayment === "NaN" || downpayment === "Na" || downpayment === 0) {
        setDown(0)
        setPercent(0)
        setNeeded(0)
      } else {
        setDown(downpayment)
        var aa = val2 - ((val2 * Percent) / 100)
        setNeeded(aa)
        setBiCalTotal(val2 - ((val2 * Percent) / 100))
      }

    }
    else {
      var aAsking = Asking ? Asking : 0
      aAsking = aAsking ? parseFloat(aAsking.replace(/,/g, "")) : 0
      setDown((aAsking * Percent) / 100)
      // setNeeded(aAsking - ((aAsking * Percent) / 100))
      var aa = aAsking - ((aAsking * Percent) / 100)
      setNeeded(aa)
      setBiCalTotal(aAsking - ((aAsking * Percent) / 100))
    }
  }

  //calculate percent 
  function setclauPer(e) {
    var aAsk = Asking ? Asking : 0
    aAsk = aAsk ? aAsk.replace(/,/g, "") : 0
    if (e) {
      var downpayment = (aAsk * e.target.value) / 100;
      if (e.target.value.length <= 0 || e.target.value === "NaN" || e.target.value === "Na") {
        setDown(0)
        setPercent(0)
        setNeeded(aAsk)
        setBiCalTotal(aAsk)
      } else {
        setDown(downpayment)
        setNeeded(aAsk - ((aAsk * e.target.value) / 100))
        setBiCalTotal(aAsk - ((aAsk * e.target.value) / 100))
      }
    }
    else {
      var downpayment = (aAsk * Percent) / 100;
      if (Percent.length <= 0 || Percent === "NaN" || Percent === "Na") {
        setDown(0)
        setPercent(0)
        setNeeded(aAsk)
      } else {
        setDown(downpayment)
        setNeeded(aAsk - ((aAsk * Percent) / 100))
      }
    }
  }

  //calculate percentage
  function setDownPer(e) {
    var aAsking = Asking ? Asking : 0
    aAsking = aAsking ? aAsking.replace(/,/g, "") : 0
    var a = e.target.value
    a = a.replace(/,/g, "")
    if (parseFloat(aAsking) === 0) {
      setDown(0)
      setPercent(0)
      setNeeded(aAsking)
      setBiCalTotal(aAsking)
    } else {
      if (a.length <= 0 || a === "NaN" || a === "Na") {
        setDown(0)
        setPercent(0)
        setNeeded(aAsking)
        setBiCalTotal(aAsking)
      } else {
        var per = ((100 * a) / aAsking)
        setPercent(per.toFixed(2))
        setNeeded(aAsking - ((aAsking * per.toFixed(2)) / 100))
        setBiCalTotal(aAsking - ((aAsking * per.toFixed(2)) / 100))
      }
    }

  }

  //BiWeekly Calculator
  function BiWeeklyCalculator() {
    const principal = BiCalTotal;
    const annual_rate = BiCalInterest;  // in percentage
    const amortization_period = BiCalLoanterm;  // in years

    if (annual_rate > 0) {
      const mc = new MortgageCalculator(principal, annual_rate, amortization_period);
      var mcpayment = mc.show_all_periodic_payment
      setBiCalInterestMonthlypayment(mcpayment.monthly)
      setBiCalInterestBiWeeklypayment(mcpayment.biWeekly)
      setBiCalInterestAccerealteBiWeeklypayment(mcpayment.acceleratedBiWeekly)
    }
    else {
      setBiCalInterestMonthlypayment(0)
      setBiCalInterestBiWeeklypayment(0)
      setBiCalInterestAccerealteBiWeeklypayment(0)
    }

    setBiCalMonthlyPaymentAmt(principal / (BiCalLoanterm * 12))
    setBiCalbiweeklypayment(principal / (BiCalLoanterm * (52 / 2)))
    setBiCalAccerlateBiWeeklypayment(principal / (BiCalLoanterm * 12 * 2))
  }

  //Reset BiWeekly Calculator
  function ResetCal() {
    setBiCalTotal(TotalMotage)
    setBiCalInterest(PropertyTax)
    setBiCalLoanterm(AmortizationBiWeekly)
    setBiCalMonthlyPaymentAmt(0)
    setBiCalbiweeklypayment(0)
    setBiCalAccerlateBiWeeklypayment(0)
    setBiCalInterestMonthlypayment(0)
    setBiCalInterestBiWeeklypayment(0)
    setBiCalInterestAccerealteBiWeeklypayment(0)
  }

  //Update Mortgage Data
  function UpdateMortgage() {
    var mortgageRequired = ClosingBool === true ? toggleMortgageValue.toFixed(2) : TotalMotage.toFixed(2)
    var as1 = Asking ? Asking.replace(/,/g, '') : Asking
    as1 = parseFloat(as1)
    var as2 = Asking2 ? Asking2.replace(/,/g, '') : Asking2
    as2 = parseFloat(as2)
    var body = {
      "bankName": BankDetails.Bank,
      "bankName1": BankDetail2.Bank2,
      "askingPrices": as1,
      "askingPrices1": as2,
      "totalMotage1": TotalMotage,
      "totalMotage2": TotalMotage2,
      "Scenarios": [
        {
          "scenarioName": "Scenario 1",
          "downPayment": Down,
          "downPaymentPercent": Percent,
          "mortgageInsurance": Insurance,
          "totalMortgageRequired": mortgageRequired,
          "amortizationPeriod": BankDetails.AmortizationPeriod,
          "morgageType": BankDetails.RateType,
          "mortgageRate": BankDetails.Rate,
          "totalMortgagePayment": MortgagePayment,
          "mortgageTerm": BankDetails.Term,
          "mortgageFrequency": BankDetails.Frequency
        },
        {
          "scenarioName": "Scenario 2",
          "downPayment": Down2,
          "downPaymentPercent": Percent2,
          "mortgageInsurance": Insurance2,
          "totalMortgageRequired": mortgageRequired,
          "amortizationPeriod": BankDetail2.AmortizationPeriod2,
          "morgageType": BankDetail2.RateType2,
          "mortgageRate": BankDetail2.Rate2,
          "totalMortgagePayment": MortgagePayment2,
          "mortgageTerm": BankDetail2.Term2,
          "mortgageFrequency": BankDetail2.Frequency2
        }
      ],
      "landTranferTax": {
        "provincial": Landinfo.Province,
        "municipal": Landinfo.City,
        "isFirstProperty": Landinfo.Closing ? true : false
      },
      "lawyerFee": LawyerFee,
      "titleInsuranceFee": TitleInsurance,
      "estoppelCertificateFee": EstoppelCertificate,
      "homeInspectionFee": HomeInsurance,
      "appraisalFee": Appraisal,
      "additionalCosts": TotalAdditionalCost,
      "totalCost": TotalAdditionalCost,
      "isClosingCost": BankDetails.Closing ? true : false,
      "closingCostPayment": toggleMortgageValue,
      DistanceMatrix: {
        "SchoolLocation": schoolLocation,
        "WorkLocation": workDistance,
        "OtherLocation": OtherLocation,
      }
    }
    var head = {
      headers: {
        "x-accesstoken": localStorage.getItem('token')
      }
    }
    setloading(true)
    setsaveMonthlyExpense(true)
    Axios.post(Api.baseUrl + "/api/Property/Mortgage/" + propid, body, head)
      .then(res => {
        if (res.data.result.message === "InvalidToken") {
          return getAccessToken().then(res => res ? UpdateMortgage(propid, head) : '')
        }
        else {
          getUser().then(data => {
            store.update("User", data)
            setloading(false)
          }).catch(error => {
            setloading(false)
          })
        }
      })
      .catch(err => {
        setloading(false)
      })
  }
  return (
    <Suspense fallback={<div>loading...</div>}>
      <Container >
        <Grid style={{ backgroundColor: 'white', margin: '87px', padding: '0px' }} >
          <Suspense fallback={<div>loading...</div>}><Header /></Suspense></Grid>
        <Button variant="outlined" onClick={() => { history.push('/home') }} style={{ margin: "15px", marginLeft: "0px" }} color="primary">Go to Property Board</Button>
        <Grid container style={{ backgroundColor: 'ghostWhite', width: '100%' }}>
          {loading && <div style={{
            position: 'fixed',
            zIndex: 20000,
            height: '100%',
            opacity: 1,
            width: "100%",
            background: "#ffffff94",
            textAlign: "center",
            paddingTop: "30vmin",
            left: '8px',
            marginTop: '0px'
          }}>
            <CircularProgress size={30} />
          </div>}
          <Grid container item className={classes.propDetailBox} spacing={2}>
            <Grid xs={6} item ><img
              className={classes.img}
              onClick={() => window.open(detailProp && detailProp.Property && detailProp.Weblink, '_blank')}
              src={detailProp && detailProp.Property && detailProp.Property.Photo.length > 0 && detailProp.Property.Photo[0].HighResPath}
              alt={"tutorialSteps[activeStep].label"}
            /></Grid>
            <Grid xs={6} container item style={{ marginBlockStart: '-10px' }}>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <p >{detailProp && detailProp.Property && detailProp.Property.Address.AddressText}</p>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
                <p >Bedroom : <span>{detailProp && detailProp.Building && detailProp.Building.Bedrooms}</span></p>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
                <p >Bathroom : <span>{detailProp && detailProp.Building && detailProp.Building.BathroomTotal}</span></p>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
                <p >Balcony : <span>N/A</span></p>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
                <p >CarParking : <span>N/A</span></p>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
                <p >Facing : <span>N/A</span></p>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
                <p >Area : <span>{detailProp && detailProp.Land && detailProp.Land.SizeTotal}</span></p>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <p >OwnerShip : <span>{detailProp && detailProp.Property && detailProp.Property.OwnershipType}</span></p>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
                <p >Distance To Work : <span>{detailProp && detailProp.Mortgage && detailProp.Mortgage.DistanceMatrix && detailProp.Mortgage.DistanceMatrix.WorkLocation ? detailProp.Mortgage.DistanceMatrix.WorkLocation : workDistance}</span></p>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
                <p >Distance To School : <span>{detailProp && detailProp.Mortgage && detailProp.Mortgage.DistanceMatrix && detailProp.Mortgage.DistanceMatrix.SchoolLocation ? detailProp.Mortgage.DistanceMatrix.SchoolLocation : schoolLocation}</span></p>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <p >Other Location : <span>{detailProp && detailProp.Mortgage && detailProp.Mortgage.DistanceMatrix && detailProp.Mortgage.DistanceMatrix.OtherLocation ? detailProp.Mortgage.DistanceMatrix.OtherLocation : OtherLocation}</span></p>
                {/* <OtherLocationCom OtherLocationss={OtherLocation ? OtherLocation : otherNameLocation} /> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid container item className={classes.propDetailBox} spacing={2}>
            <Grid xs={12} sm={12} md={12} lg={6} xl={6} container item
              style={{ height: '630px', overflowY: 'scroll', overflowX: 'hidden' }}
            >
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <ExpansionPanel >
                  <Typography className={classes.subHead}>Mortgage Calculator<span style={{ marginLeft: '30px', color: "black" }}>
                  </span></Typography>
                </ExpansionPanel>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} container item spacing={2} >
                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item >
                  Asking Price
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
                  <TextField fullWidth margin="dense"
                    value={Expected ? Expected : 0}
                    onChange={e => {
                      var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                      val = parseFloat(val);
                      var formatVal = val.toLocaleString();
                      setExpected(formatVal != "NaN" ? formatVal : 0)
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
                  <Button variant="contained"
                    style={{ backgroundColor: 'red', color: 'white', margin: '11px' }}
                    onClick={ScenarioCalculator}>Calculate</Button>
                </Grid>


                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item></Grid>
                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
                  Scenario 1
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
                  Scenario 2
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item className={classes.labelMargin}>
                  Expected Purchase Price
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
                  <TextField fullWidth margin="dense"
                    value={Asking ? Asking : 0}
                    onChange={e => {
                      var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                      val = parseFloat(val);
                      var formatVal = val.toLocaleString();
                      setAsking(formatVal != "NaN" ? formatVal : 0)
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
                  <TextField fullWidth margin="dense"
                    value={Asking2 ? Asking2 : 0}
                    onChange={e => {
                      var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                      val = parseFloat(val);
                      var formatVal = val.toLocaleString();
                      setAsking2(formatVal != "NaN" ? formatVal : 0)
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={4}>
                  <p> Down payment  </p>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    value={Down ? (Down).toLocaleString() : 0}
                    onChange={e => {
                      var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                      val = parseFloat(val);
                      var formatVal = val.toLocaleString();
                      setDown(formatVal)
                      setDownPer(e)
                    }}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    value={Down2 ? (Down2).toLocaleString() : 0}
                    onChange={e => {
                      var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                      val = parseFloat(val);
                      setDown2(val)
                      setDownPer2(e)
                    }}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>

                <Grid item xs={4}><p>Percentage</p></Grid>
                <Grid item xs={4}>
                  <TextField
                    value={Percent ? Percent : 0}
                    onChange={e => {
                      setPercent(e.target.value === '' ? 0 : Number(e.target.value))
                      setclauPer(e)
                    }}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    value={Percent2 ? Percent2 : 0}
                    onChange={e => {
                      setPercent2(e.target.value === '' ? 0 : Number(e.target.value))
                      setclauPer2(e)
                    }}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item className={classes.labelMargin}>
                  Mortgage Needed
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>
                  <p>{Needed ? Needed.toLocaleString(undefined, { minimumFractionDigits: 2 }) : 0}</p>

                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} xl={4} item>

                  <p>${Needed2 ? Needed2.toLocaleString(undefined, { minimumFractionDigits: 2 }) : 0}</p>
                </Grid>
                <ExpansionPanel >
                  <ExpansionPanelSummary
                    className={classes.summary}
                    expandIcon={ExpandedAdditional ? <RemoveIcon style={{ color: "#f02834" }} /> : <AddIcon style={{ color: "#f02834" }} />}
                    aria-controls="panel1a-content"
                    onClick={toggleAdditionalCost}
                  >
                    <Typography className={classes.subHead}> Additional Costs
                      <span style={{ marginLeft: '53px', color: "black" }}>
                      </span>
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <AdditionalCost
                      dataforSet={detailProp}
                      setLandinfo={setLandinfo}
                      store={store}
                      Insurance={Insurance}
                      Insurance2={Insurance2}
                      askPrice={Asking}
                      DownPaymentPercentage={Percent}
                      DownPaymentPercentage2={Percent2}
                      ownershipTypes={detailProp && detailProp.Property && detailProp.Property.OwnershipType}
                      addressTexts={AddressTextporp}
                      onSelectTotalAmount={handleTotalAmount}
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel >
                  <ExpansionPanelSummary
                    className={classes.summary}
                    expandIcon={ExpandedMortgageAmount ? <RemoveIcon style={{ color: "#f02834" }} /> : <AddIcon style={{ color: "#f02834" }} />}
                    aria-controls="panel1a-content"
                    onClick={toggleMortgageAmount}
                  >
                    <Typography className={classes.subHead}>Mortgage Payment  </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <MortgageAmount
                      TotalMotage={TotalMotage}
                      dataforSet={detailProp}
                      TotalMotage2={TotalMotage2}
                      setBankDetails={setBankDetails}
                      setBankDetails2={setBankDetails2}
                      setBankDetail2={setBankDetail2}
                      store={store}
                      Insurance={Insurance}
                      Insurance2={Insurance2}
                      NeededValue={Needed}
                      NeededValue2={Needed2}
                      DownPayment2={Down2}
                      DownPayment={Down}
                      DownPaymentPercentage={Percent}
                      DownPaymentPercentage2={Percent2}
                      ExpectedPayment={Expected}
                      TotalAdditionCost={setTotalAdditionalCost}
                      onSelectToggleMortgageAmount={handleToggleMortgageTotalAmount}
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel >
                  <ExpansionPanelSummary
                    className={classes.summary}
                    expandIcon={ExpandedMonthlyExpense ? <RemoveIcon style={{ color: "#f02834" }} /> : <AddIcon style={{ color: "#f02834" }} />}
                    aria-controls="panel1a-content"
                    onClick={toggleMonthlyExpense}
                  >
                    <Typography className={classes.subHead}>Budget Calculator
                      <span style={{ marginLeft: '42px', color: "black" }}>
                      </span></Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <MonthlyExpenseCalculator
                      dataexpense={dataexpense}
                      MortgagePayment={MortgagePayment}
                      MortgagePayment2={MortgagePayment2}
                      TotalExpenses={handleTotalExpenses}
                      saveMonthlyExpensebool={saveMonthlyExpense}
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <Grid xs={12} sm={12} md={12} lg={6} xl={6} item   >
                  {Affordabilitys > 0 || Affordabilitys2 > 0 ? <h4 style={{ color: 'green' }}>Affordability</h4>
                    : <h4 style={{ color: 'red' }}>Affordability</h4>}
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={3} xl={3} item >
                  <h4>${Affordabilitys ? Affordabilitys.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 }) : 0}</h4>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={3} xl={3} item >
                  <h4>${Affordabilitys2 ? Affordabilitys2.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 }) : 0}</h4>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                  <Button variant="contained"
                    style={{ backgroundColor: 'red', color: 'white' }}
                    onClick={UpdateMortgage}>Save</Button>
                </Grid>
              </Grid>

            </Grid>


            <Grid xs={12} sm={12} md={12} lg={6} xl={6} container item
              style={{ height: '630px', overflowY: 'scroll', overflowX: 'hidden' }}
            >
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <ExpansionPanel >
                  <Typography className={classes.subHead}>Biweekly Calculator<span style={{ marginLeft: '30px', color: "black" }}>
                  </span></Typography>
                </ExpansionPanel>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} container item spacing={2} >
                <Grid xs={12} sm={12} md={12} lg={6} xl={6} item className={classes.labelMargin}>
                  Mortgage Needed
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
                  <TextField fullWidth margin="dense"
                    value={BiCalTotal === undefined || BiCalTotal === '' || BiCalTotal === "NaN" ? 0 : (BiCalTotal).toLocaleString()}
                    onChange={e => {
                      var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                      val = parseFloat(val);
                      setBiCalTotal(val != "NaN" ? val : 0)
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={6} xl={6} item className={classes.labelMargin}>
                  Interest Rate(Anually)
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
                  <TextField fullWidth margin="dense"
                    value={BiCalInterest === undefined || BiCalInterest === '' || BiCalInterest === "NaN" ? 0 : (BiCalInterest).toLocaleString()}
                    onChange={e => {

                      var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                      val = parseFloat(val);
                      setBiCalInterest(val != "NaN" ? val : 0)
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={6} xl={6} item className={classes.labelMargin}>
                  Amortization Period
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
                  <TextField fullWidth margin="dense"
                    value={BiCalLoanterm === undefined || BiCalLoanterm === '' || BiCalLoanterm === "NaN" ? 0 : (BiCalLoanterm).toLocaleString()}
                    onChange={e => {
                      var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                      val = parseFloat(val);
                      setBiCalLoanterm(val ? val : 0)
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" />,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
                  <Button variant="contained"
                    style={{ backgroundColor: 'red', color: 'white', margin: '11px' }}
                    onClick={BiWeeklyCalculator}>Calculate</Button>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
                  <Button variant="contained"
                    style={{ backgroundColor: 'grey', color: 'white', margin: '11px' }}
                    onClick={ResetCal}>Reset</Button>
                </Grid>

                <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                  Monthly
                  <h4 style={{ marginBlockStart: "0.5em", marginBlockEnd: 0 }} >${BiCalMonthlyPaymentAmt.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 })}</h4 >
                </Grid>
                <Grid xs={5} sm={5} md={5} lg={6} xl={6} item>
                  {/* Interest with Monthly Payment
                  <h4 style={{ marginBlockStart: "0.5em", marginBlockEnd: 0 }} >${BiCalInterestMonthlypayment.toLocaleString(undefined, { minimumFractionDigits: 2 })}</h4 > */}
                </Grid>

                <Grid xs={5} sm={5} md={5} lg={6} xl={6} item>
                  Weekly
                  <h4 style={{ marginBlockStart: "0.5em", marginBlockEnd: 0 }} >${BiCalbiweeklypayment.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 })}</h4 >
                </Grid>
                <Grid xs={5} sm={5} md={5} lg={6} xl={6} item>
                  {/* Interest with Bi-weekly Payment
                  <h4 style={{ marginBlockStart: "0.5em", marginBlockEnd: 0 }} >${BiCalInterestBiWeeklypayment.toLocaleString(undefined, { minimumFractionDigits: 2 })}</h4 > */}
                </Grid>

                <Grid xs={5} sm={5} md={5} lg={6} xl={6} item>
                  Bi-weekly
                  <h4 style={{ marginBlockStart: "0.5em", marginBlockEnd: 0 }} >${BiCalAccerlateBiWeeklypayment.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 })}</h4 >
                </Grid>
                <Grid xs={5} sm={5} md={5} lg={6} xl={6} item>
                  {/* Interest with Accelerated Bi-weekly Payment
                  <h4 style={{ marginBlockStart: "0.5em", marginBlockEnd: 0 }} >${BiCalInterestAccerealteBiWeeklypayment.toLocaleString(undefined, { minimumFractionDigits: 2 })}</h4 > */}
                </Grid>

              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={3} md={4} lg={5} xl={5}></Grid>
            <Grid item xs={12} sm={9} md={8} lg={7} xl={7} >
              <Suspense fallback={<div>loading...</div>}><Footer /></Suspense></Grid>
          </Grid>
        </Grid>
      </Container>
    </Suspense >
  )
}
export default inject('store')(observer(PropertyDetail));

//Other Locatopn Component
const OtherLocationCom = (OtherLocationss) => {
  return (
    OtherLocationss && OtherLocationss.OtherLocationss && OtherLocationss.OtherLocationss.length > 0
    && OtherLocationss.OtherLocationss.map((i, j) => {
      // <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
      return <p > {i.name}: <span>{i.distance}</span></p>
      // </Grid>
    })
  )
}
//Insurance function
function getInsurance(Needed, d) {
  if (9.99 >= d) {
    return (Needed * 4) / 100
  }
  if (14.99 >= d) {
    return (Needed * 3.1) / 100
  }
  if (19.99 >= d) {
    return (Needed * 2.8) / 100
  }
  else {
    return 0
  }
}

//AdditionalCost Component
const AdditionalCost = ({ Insurance, Insurance2, addressTexts, store, askPrice, setLandinfo, dataforSet,
  onSelectTotalAmount, DownPaymentPercentage, DownPaymentPercentage2, ownershipTypes }) => {

  const [Province, setProvince] = useState("")
  const [City, setCity] = useState("")
  const [Provincial, setProvincial] = useState(0)
  const [Rebate, setRebate] = useState(0)
  const [Municipal, setMunicipal] = useState(0)
  const [LawyerFees, setLawyerFees] = useState(0)
  const [Closing, setClosing] = useState(false)
  const [TitleInsurance, setTitleInsurance] = useState(0)
  const [HomeInsurance, setHomeInsurance] = useState(0)
  const [EstoppelCertificate, setEstoppelCertificate] = useState(0)
  const [Appraisal, setAppraisal] = useState(0)
  const [TotalAmount, setTotalAmount] = useState(0)
  const [TotalAmount2, setTotalAmount2] = useState(0)
  const [ToggleRebateRate, setToggleRebateRate] = useState(0)
  const [InsuranceDown, setInsuranceDown] = useState(0)
  const [InsuranceDown2, setInsuranceDown2] = useState(0)

  const useStyles1 = makeStyles(theme => ({
    flyout: {
      width: "480px",
      marginTop: '50px'
    },
    select: {
      width: '100%',
    },
    root: {
      // flexGrow: 1,
      margin: theme.spacing(1),
    },
    comment: {
      margin: theme.spacing(1)
    },
    img: {
      margin: theme.spacing(1),
      height: 180,
      overflow: 'hidden',
      display: 'block',
      cursor: 'pointer',
      width: '100%',
    },
    subHead: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightRegular,
      color: '#f02834',
      margin: 0,
    },
    propName: {
      margin: theme.spacing(1),
      fontSize: '19px',
      fontWeight: "bold"
    },
    Panels: {
      width: '500px'
    },
    summary: {
      margin: '0px',
      paddingRight: '10px',
      paddingLeft: '10px'
    },
    details: {
      width: '100%',
      paddingRight: '0px',
      paddingLeft: '0px'
    },
    icon: {
      fill: "#f02834",
    }
  }));

  const classes = useStyles1()
  useEffect(() => {
    {
      onChangeProvince(addressTexts)
    }
  }, [addressTexts, dataforSet])

  useEffect(() => {
    {
      onChangeMunciple(addressTexts)
    }
  }, [Province])


  //Province
  function onChangeProvince(addressTexts) {
    var str = addressTexts && addressTexts.toLowerCase();
    store.data.Province && store.data.Province.length > 0 && store.data.Province.map((i, k) => {
      var pp = i.province.toLowerCase()
      var a = str.search(pp)
      if (a > -1) {
        return (setProvince(i.code), onChangeMunciple(addressTexts))
      }
    })
  }

  //Munciple
  function onChangeMunciple(addressTexts) {
    var str = addressTexts && addressTexts.toLowerCase();
    store.data.Province && store.data.Province.length > 0 && store.data.Province.map((i, k) => {
      if (i.code === Province) {
        return i.cities.map(j => {
          var jj = j.slug.toLowerCase()
          var citiess = str.search(jj)
          if (citiess > -1) {
            return (setCity(j.slug))
          }
        })
      }
    })
  }

  //set rebate munciple and provincial in case of change a state
  useEffect(() => {
    var askPrices = askPrice ? askPrice.replace(/,/g, '') : 0
    if (Province) {
      setProvincial(provincial_ltt(Province, askPrices))
    }
    if (Province && City) {
      setRebate(ltt_rebate(Province, City, "fthb", askPrices, ""))
    }

    if (City) {
      setMunicipal(municipal_ltt(Province, City, askPrices))
    }
    setLandinfo({ Province, City, Closing: Closing ? true : false })
  }, [Province, City, Closing, Provincial, Municipal, askPrice])

  //get data in dataforSet then set data in province ,city ,lawyer,titleInsuance,home insurnace ,appraisal and estoppel certification
  useEffect(() => {
    setProvince(dataforSet && dataforSet.Mortgage && dataforSet.Mortgage.landTranferTax && dataforSet.Mortgage.landTranferTax.provincial ? dataforSet.Mortgage.landTranferTax.provincial : Province)
    setCity(dataforSet && dataforSet.Mortgage && dataforSet.Mortgage.landTranferTax && dataforSet.Mortgage.landTranferTax.municipal ? dataforSet.Mortgage.landTranferTax.municipal : City)
    setLawyerFees(dataforSet && dataforSet.Mortgage && dataforSet.Mortgage.lawyerFee ? dataforSet.Mortgage.lawyerFee : LawyerFees)
    setTitleInsurance(dataforSet && dataforSet.Mortgage && dataforSet.Mortgage.titleInsuranceFee ? dataforSet.Mortgage.titleInsuranceFee : TitleInsurance)
    setHomeInsurance(dataforSet && dataforSet.Mortgage && dataforSet.Mortgage.homeInspectionFee ? dataforSet.Mortgage.homeInspectionFee : HomeInsurance)
    setAppraisal(dataforSet && dataforSet.Mortgage && dataforSet.Mortgage.appraisalFee ? dataforSet.Mortgage.appraisalFee : Appraisal)
    setEstoppelCertificate(dataforSet && dataforSet.Mortgage && dataforSet.Mortgage.estoppelCertificateFee ? dataforSet.Mortgage.estoppelCertificateFee : EstoppelCertificate)
  }, [dataforSet])

  //set insurnace when change DownPaymentPercentage and insurnce
  useEffect(() => {
    if (DownPaymentPercentage < 21) {
      setInsuranceDown(Insurance.toFixed(2))
    } else {
      setInsuranceDown(0)
    }
  }, [DownPaymentPercentage, Insurance])

  //set insurnace case two when change DownPaymentPercentage2 and insurnce2
  useEffect(() => {
    if (DownPaymentPercentage2 < 21) {
      setInsuranceDown2(Insurance2.toFixed(2))
    } else {
      setInsuranceDown2(0)
    }
  }, [Insurance2, DownPaymentPercentage2])


  //set setEstoppelCertificate according to ownershipTypes conditions
  useEffect(() => {
    if (ownershipTypes === "Condominium/Strata" || ownershipTypes == "Condominium" || ownershipTypes == "Strata") {
      setEstoppelCertificate(dataforSet.Mortgage.estoppelCertificateFee ? dataforSet.Mortgage.estoppelCertificateFee : 0)
    }
    else {
      setEstoppelCertificate(0)
    }
  }, [])

  //toggle use for rebate
  useEffect(() => {
    if (Closing === true) {
      setToggleRebateRate(Rebate);
    }
    else {
      setToggleRebateRate(0);
    }
  }, [Closing])

  //total amount
  useEffect(() => {
    setTotalAmount(Number(LawyerFees) + Number(TitleInsurance) +
      Number(EstoppelCertificate) + Number(HomeInsurance) + Number(Appraisal) + Number(Provincial) + Number(Municipal)
      + Number(InsuranceDown))
    // + Number(ToggleRebateRate)
  }, [LawyerFees, TitleInsurance, EstoppelCertificate, HomeInsurance, Appraisal, Provincial, Municipal,
    InsuranceDown, ToggleRebateRate])

  //set total amount 
  useEffect(() => {
    setTotalAmount2(Number(LawyerFees) + Number(TitleInsurance) +
      Number(EstoppelCertificate) + Number(HomeInsurance) + Number(Appraisal) + Number(Provincial) + Number(Municipal)
      + Number(InsuranceDown2))
    //  + Number(ToggleRebateRate)
  }, [LawyerFees, TitleInsurance, EstoppelCertificate, HomeInsurance, Appraisal, Provincial, Municipal,
    InsuranceDown2, ToggleRebateRate])

  //move data form this component to Parent
  useEffect(() => {
    onSelectTotalAmount({ TotalAmount, LawyerFees, TitleInsurance, EstoppelCertificate, HomeInsurance, Appraisal, InsuranceDown, InsuranceDown2 });
  }, [TotalAmount, LawyerFees, TitleInsurance, EstoppelCertificate, HomeInsurance, Appraisal, InsuranceDown, InsuranceDown2])

  return (
    <div>
      <Grid container spacing={1} >
        <Grid item xs={6}>
          <p>   <Switch checked={Closing} onChange={(e, c) => { setClosing(c) }} />   First time home buyer ?   </p>
        </Grid>

        <Grid item xs={3}>
          <Select
            className={classes.select}
            value={Province}
            margin="dense"
            variant="outlined"
            onChange={e => setProvince(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {
              store.data.Province && store.data.Province.length > 0 && store.data.Province.map((i, k) => {
                return <MenuItem key={"Province" + k} value={i.code}>{i.province}</MenuItem>
              })
            }
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select
            className={classes.select}
            value={City}
            margin="dense"
            variant="outlined"
            onChange={e => setCity(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {
              store.data.Province && store.data.Province.length > 0 && store.data.Province.map((i, k) => {
                if (i.code === Province) {
                  return i.cities.map(j => <MenuItem key={"city" + j.id} value={j.slug}>{j.name}</MenuItem>)
                }
              })
            }
          </Select>
        </Grid>
        {Closing &&
          <React.Fragment>
            <Grid item xs={5}>
              <p>Rebate Rate</p>
            </Grid>
            <Grid item xs={4}>
              <p><b>${Rebate.toLocaleString()}</b> </p>
            </Grid>
          </React.Fragment>
        }
        <Grid item xs={5}>
          <p>Land Transfer Tax</p>
        </Grid>
        <Grid item xs={3}>
          <p>Provincial Rate <br />${Provincial ? Provincial.toLocaleString() : 0}</p>
        </Grid>
        <Grid item xs={3}>
          <p>Municipal Rate<br />${Municipal ? Municipal.toLocaleString() : 0}</p>
        </Grid>
        <Grid item xs={5}>
          <p> Lawyer Fees</p>
        </Grid>
        <Grid item xs={4}>
          <InputField Value={LawyerFees} onChange={setLawyerFees} />
        </Grid>
        <Grid item xs={5}>
          <p> Title Insurance Fee</p>
        </Grid>
        <Grid item xs={4}>
          <InputField Value={TitleInsurance} onChange={setTitleInsurance} />
        </Grid>
        <Grid item xs={5}>
          <p>Estoppel Certificate Fee</p>
        </Grid>
        <Grid item xs={4}>
          <InputField Value={EstoppelCertificate} onChange={setEstoppelCertificate} />
        </Grid>
        <Grid item xs={5}>
          <p>Home Inspection Fees</p>
        </Grid>
        <Grid item xs={4}>
          <InputField Value={HomeInsurance} onChange={setHomeInsurance} />
        </Grid>
        <Grid item xs={5}>
          <p> Appraisal Fees</p>
        </Grid>
        <Grid item xs={4}>
          <InputField Value={Appraisal} onChange={setAppraisal} />
        </Grid>
        <Grid item xs={5}>
          <p> Mortgage Insurance</p>
        </Grid>
        <Grid item xs={3}>
          <p>${InsuranceDown ? InsuranceDown.toLocaleString(undefined, { minimumFractionDigits: 2 }) : 0}</p>
        </Grid>
        <Grid item xs={3}>
          <p>${InsuranceDown2 ? InsuranceDown2.toLocaleString(undefined, { minimumFractionDigits: 2 }) : 0}</p>
        </Grid>
        <Grid item xs={5}>
          <p>Closing Cost</p>
        </Grid>
        <Grid item xs={3}>
          <p>${TotalAmount ? TotalAmount.toLocaleString(undefined, { minimumFractionDigits: 2 }) : 0}</p>
        </Grid>
        <Grid item xs={3}>
          <p>${TotalAmount2 ? TotalAmount2.toLocaleString(undefined, { minimumFractionDigits: 2 }) : 0}</p>
        </Grid>
      </Grid>
    </div>
  )
}

// AdditionalCost Component input
const InputField = ({ Value, onChange }) => {
  return (
    <TextField
      value={Value === undefined || Value === '' ? 0 : (Value).toLocaleString()}
      fullWidth
      margin="dense"
      onChange={(e) => {
        var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
        val = parseFloat(val);
        onChange(val)
      }}
      variant="outlined"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
    />
  )
}

//Mortgage Amount
const MortgageAmount = ({ store, TotalMotage, TotalMotage2, dataforSet, setBankDetails, setBankDetails2, setBankDetail2,
  ExpectedPayment, NeededValue, NeededValue2,
  DownPayment, DownPayment2, Insurance, Insurance2, DownPaymentPercentage, DownPaymentPercentage2,
  TotalAdditionCost,
  onSelectToggleMortgageAmount }) => {
  const classes = useStyles2()
  const [RateType, setRateType] = useState("Fixed")
  const [OpenCloseRateType, setOpenCloseRateType] = useState("Open")
  const [Term, setTerm] = useState("")
  const [Bank, setBank] = useState("5e70c2e30ada0763803423c7")
  const [EMI, setEMI] = useState(0)
  const [Rate, setRate] = useState(0)
  const [Frequency, setFrequency] = useState(12)
  const [Closing, setClosing] = useState(false)
  const [TotalClosingCost, setTotalClosingCost] = useState(0)
  const [AmortizationPeriod, setAmortizationPeriod] = useState(25)
  const [RateType2, setRateType2] = useState("Fixed")
  const [OpenCloseRateType2, setOpenCloseRateType2] = useState("Open")
  const [Term2, setTerm2] = useState("")
  const [Bank2, setBank2] = useState("5e70c2e30ada0763803423c7")
  const [EMI2, setEMI2] = useState(0)
  const [Rate2, setRate2] = useState(0)
  const [Frequency2, setFrequency2] = useState(12)
  const [Closing2, setClosing2] = useState(false)
  const [TotalClosingCost2, setTotalClosingCost2] = useState(0)
  const [AmortizationPeriod2, setAmortizationPeriod2] = useState(25)
  const [TermArray, setTermArray] = useState([])
  const [TermArray2, setTermArray2] = useState([])
  const MortgageCalculator = require('mortgage-calculator-p')('ca');

  var termArrayList = []
  var termArrayList2 = []

  useEffect(() => {
    if (store.data.Mortgage) {
    }
    onmouseenter()
  }, [store, dataforSet])

  //total Closing cost
  useEffect(() => {
    var formatVal = ExpectedPayment ? ExpectedPayment.toString() : 0;
    var val = formatVal ? formatVal.replace(/,/g, '') : 0;
    var a = DownPayment - TotalClosingCost
    var b = val * 0.20
    if (a < b) {
      setTotalClosingCost(0)
    }
    else {
      setTotalClosingCost((TotalAdditionCost - Insurance) + NeededValue)
    }
    var a2 = DownPayment2 - TotalClosingCost
    var b2 = val * 0.20
    if (a2 < b2) {
      setTotalClosingCost2(0)
    }
    else {
      setTotalClosingCost2((TotalAdditionCost - Insurance2) + NeededValue2)
    }
  }, [Insurance, Insurance2, TotalAdditionCost, DownPaymentPercentage, DownPayment, DownPaymentPercentage2, DownPayment2])

  //use for set sacnerio one and two values set 
  useEffect(() => {
    setEMI(dataforSet.Mortgage && dataforSet.Mortgage.Scenarios && dataforSet.Mortgage.Scenarios.length > 0 && dataforSet.Mortgage.Scenarios[0].totalMortgagePayment ? dataforSet.Mortgage.Scenarios[0].totalMortgagePayment : EMI)
    setRate(dataforSet.Mortgage && dataforSet.Mortgage.Scenarios && dataforSet.Mortgage.Scenarios.length > 0 && dataforSet.Mortgage.Scenarios[0].mortgageRate ? dataforSet.Mortgage.Scenarios[0].mortgageRate : Rate)
    setEMI2(dataforSet.Mortgage && dataforSet.Mortgage.Scenarios && dataforSet.Mortgage.Scenarios.length > 0 && dataforSet.Mortgage.Scenarios[1] && dataforSet.Mortgage.Scenarios[1].totalMortgagePayment ? dataforSet.Mortgage.Scenarios[1].totalMortgagePayment ? dataforSet.Mortgage.Scenarios[0].totalMortgagePayment : EMI : EMI)
    setRate2(dataforSet.Mortgage && dataforSet.Mortgage.Scenarios && dataforSet.Mortgage.Scenarios.length > 0 && dataforSet.Mortgage.Scenarios[1] && dataforSet.Mortgage.Scenarios[1].mortgageRate ? dataforSet.Mortgage.Scenarios[1].mortgageRate : Rate)
    setTotalClosingCost(dataforSet.Mortgage && dataforSet.Mortgage.closingCostPayment && dataforSet.Mortgage.closingCostPayment ? dataforSet.Mortgage.closingCostPayment : TotalClosingCost)

    var forRate = dataforSet.Mortgage && dataforSet.Mortgage.Scenarios.length > 0 &&
      dataforSet.Mortgage.Scenarios[0] && dataforSet.Mortgage.Scenarios[0].mortgageTerm ?
      JSON.parse(dataforSet.Mortgage.Scenarios[0].mortgageTerm) : ''
    setTerm(forRate.Term)
    var forRate2 = dataforSet.Mortgage && dataforSet.Mortgage.Scenarios.length > 0
      && dataforSet.Mortgage.Scenarios[1] && dataforSet.Mortgage.Scenarios[1].mortgageTerm ?
      JSON.parse(dataforSet.Mortgage.Scenarios[1].mortgageTerm) : ''
    setTerm2(forRate2.Term ? forRate2.Term : forRate.Term)
  }, [dataforSet])

  //convert into json
  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  //according to toggle set values into onSelectedMortgageAmount pass to parent
  useEffect(() => {
    if (Closing === true) {
      onSelectToggleMortgageAmount({ TotalClosingCost, TotalClosingCost2, EMI, Rate, RateType, Frequency, Term, Closing, AmortizationPeriod });
    }
    else {
      onSelectToggleMortgageAmount({ TotalClosingCost: 0, TotalClosingCost2: 0, EMI, Rate, RateType, Frequency, Term, Closing, AmortizationPeriod });
    }
  }, [Closing, TotalClosingCost, EMI, Rate, AmortizationPeriod])

  // set EMI payment
  useEffect(() => {
    var forRate = isJson(Term) ? JSON.parse(Term) : Term
    if (TotalMotage && RateType && Term && Bank && Frequency && OpenCloseRateType) {
      var rate = forRate.SpecialOffer ? forRate.SpecialOffer : forRate.APR
      setRate(rate)
      var emi = (TotalMotage * (rate / 100)) / Frequency
      const principal = TotalMotage;
      const annual_rate = rate;  // in percentage
      const amortization_period = AmortizationPeriod;  // in years
      const mc = new MortgageCalculator(principal, annual_rate, amortization_period);
      var mcpayment = mc.show_all_periodic_payment
      SetEMIPayment(Frequency, mcpayment)
    }

    //move to parent bank detail
    setBankDetails({
      OpenCloseRateType,
      RateType,
      Term,
      Bank,
      EMI,
      Frequency,
      Closing,
      AmortizationPeriod,
      Rate: Term ? forRate.SpecialOffer ? forRate.SpecialOffer : forRate.APR : ""
    })
  }, [TotalMotage, RateType, Term, Bank, Frequency, Closing, OpenCloseRateType, AmortizationPeriod])

  //set Mortgage Payment Scenario1
  function SetEMIPayment(Frequency, mcpayment) {
    // console.log("1748",Frequency, mcpayment)
    if (Frequency == 12) {
      setEMI(mcpayment.monthly)
    } else if (Frequency == 52) {
      setEMI(mcpayment.weekly)
    }
    else if (Frequency == 26) {
      setEMI(mcpayment.biWeekly)
    }
    else {
      setEMI(mcpayment.monthly)
    }
  }

  //set bank detail of scenario2 and set values bankDetail2
  useEffect(() => {
    var forRate = isJson(Term2) ? JSON.parse(Term2) : Term2
    if (TotalMotage2 && RateType2 && Term2 && Bank2 && Frequency2 && OpenCloseRateType2) {
      var rate = forRate.SpecialOffer ? forRate.SpecialOffer : forRate.APR
      setRate2(rate)
      var emi = (TotalMotage2 * (rate / 100)) / Frequency2
      const principal = TotalMotage2;
      const annual_rate = rate;  // in percentage
      const amortization_period = AmortizationPeriod2;  // in years
      const mc = new MortgageCalculator(principal, annual_rate, amortization_period);
      var mcpayment = mc.show_all_periodic_payment
      SetEMIPayment2(Frequency2, mcpayment)
    }
    setBankDetail2({
      OpenCloseRateType2,
      RateType2,
      Term2,
      Bank2,
      EMI2,
      Frequency2,
      Closing2,
      AmortizationPeriod2,
      Rate2: Term2 ? forRate.SpecialOffer ? forRate.SpecialOffer : forRate.APR : ""
    }, [TotalMotage2, RateType2, EMI2, Term2, Bank2, Frequency2, Closing2, OpenCloseRateType2, AmortizationPeriod2])

    setBankDetails2({
      OpenCloseRateType2,
      RateType2,
      Term2,
      Bank2,
      EMI2,
      Frequency2,
      Closing2,
      AmortizationPeriod2,
      Rate2: Term2 ? forRate.SpecialOffer ? forRate.SpecialOffer : forRate.APR : ""
    })
  }, [TotalMotage2, RateType2, EMI2, Term2, Bank2, Frequency2, Closing2, OpenCloseRateType2, AmortizationPeriod2])

  //set mortgage payment Scenario2
  function SetEMIPayment2(Frequency2, mcpayment) {
    // console.log("1815 Frequency2", Frequency2)
    if (Frequency2 == 12) {
      setEMI2(mcpayment.monthly)
    } else if (Frequency2 == 52) {
      setEMI2(mcpayment.weekly)
    }
    else if (Frequency2 == 26) {
      setEMI2(mcpayment.biWeekly)
    }
    else {
      setEMI2(mcpayment.monthly)
    }
  }

  //change store and dataTable data then call a function 
  function onmouseenter() {
    if (dataforSet.Mortgage && dataforSet.Mortgage.bankName) {
      var forRate = dataforSet.Mortgage.Scenarios.length > 0 &&
        dataforSet.Mortgage.Scenarios[0].mortgageTerm ?
        dataforSet.Mortgage.Scenarios[0].mortgageTerm : ''
      setBank(dataforSet.Mortgage.bankName)
      var d = dataforSet.Mortgage.Scenarios[0].mortgageTerm
      forRate = forRate ? JSON.parse(forRate) : ''
      setTerm(forRate.Term)
      setAmortizationPeriod(dataforSet.Mortgage && dataforSet.Mortgage.Scenarios[0] && dataforSet.Mortgage.Scenarios[0].amortizationPeriod ? dataforSet.Mortgage.Scenarios[0].amortizationPeriod : AmortizationPeriod)
      setRateType(dataforSet.Mortgage.Scenarios[0].morgageType)
      setFrequency(dataforSet.Mortgage.Scenarios[0].mortgageFrequency)
      setClosing(dataforSet.Mortgage.isClosingCost ? true : false)
      setOpenCloseRateType(forRate.OpenClose)


      var forRate2 = dataforSet.Mortgage.Scenarios.length > 0 && dataforSet.Mortgage.Scenarios[1]
        && dataforSet.Mortgage.Scenarios[1].mortgageTerm ?
        dataforSet.Mortgage.Scenarios[1].mortgageTerm :
        dataforSet.Mortgage.Scenarios[0].mortgageTerm || ""
      forRate2 = forRate2 ? JSON.parse(forRate2) : ''
      setBank2(dataforSet.Mortgage.bankName1 ? dataforSet.Mortgage.bankName1 : dataforSet.Mortgage.bankName)
      var d = dataforSet.Mortgage && dataforSet.Mortgage.Scenarios[1]
        && dataforSet.Mortgage.Scenarios[1].mortgageTerm ? dataforSet.Mortgage.Scenarios[1].mortgageTerm
        : dataforSet.Mortgage.Scenarios[0].mortgageTerm
      // d = JSON.parse(d)
      // console.log("1875",forRate2,forRate)
      setTerm2(forRate2.Term ? forRate2.Term : forRate.Term)
      setAmortizationPeriod2(dataforSet.Mortgage && dataforSet.Mortgage.Scenarios[1] && dataforSet.Mortgage.Scenarios[1].amortizationPeriod ? dataforSet.Mortgage.Scenarios[1].amortizationPeriod : AmortizationPeriod)
      setRateType2(dataforSet.Mortgage && dataforSet.Mortgage.Scenarios[1] && dataforSet.Mortgage.Scenarios[1].morgageType ? dataforSet.Mortgage.Scenarios[1].morgageType : dataforSet.Mortgage.Scenarios[0].morgageType)
      setFrequency2(dataforSet.Mortgage && dataforSet.Mortgage.Scenarios[1] && dataforSet.Mortgage.Scenarios[1].mortgageFrequency ? dataforSet.Mortgage.Scenarios[1].mortgageFrequency : dataforSet.Mortgage.Scenarios[0].mortgageFrequency)
      setClosing2(dataforSet.Mortgage.isClosingCost ? true : false)
      setOpenCloseRateType2(forRate2.OpenClose ? forRate2.OpenClose : forRate.OpenClose)
    }

  }

  //set Term when change Bank, RateType, OpenCloseRateType, dataforSet
  useEffect(() => {
    store.data.Mortgage && store.data.Mortgage.length > 0 && store.data.Mortgage.map((b) => {
      if (b._id === Bank) {
        return b.MortageDetails.map((i, k) => {
          if (i.Type === RateType && i.OpenClose === OpenCloseRateType) {
            termArrayList.push(i);
            if (Term == i.Term) {
              return (
                setTerm(JSON.stringify(i))
              )
            }

          }
          setTermArray(termArrayList);
        })
      }
    })
  }, [Bank, RateType, OpenCloseRateType, dataforSet])

  //set Term2 when change Bank2, RateType2, OpenCloseRateType2, dataforSet
  useEffect(() => {
    store.data.Mortgage && store.data.Mortgage.length > 0 && store.data.Mortgage.map((b) => {
      if (b._id === Bank2) {
        return b.MortageDetails.map((i, k) => {
          if (i.Type === RateType2 && i.OpenClose === OpenCloseRateType2) {
            termArrayList2.push(i);
            if (Term2 == i.Term) {
              return (
                setTerm2(JSON.stringify(i))
              )
            }
          }
          setTermArray2(termArrayList2);
        })
      }
    })
  }, [Bank2, RateType2, OpenCloseRateType2, dataforSet])

  return (
    <div>
      <p>
        <Switch checked={Closing} onChange={() => setClosing(!Closing)} /> Do you want to add the closing costs to the mortgage?
      </p>
      <div className="widget" data-widget="calc-payment" data-ltt="only" data-lang="en"></div>
      <Grid container spacing={1} >
        {/*  {Closing &&
          <React.Fragment>
            <Grid item xs={5}>
              <p>Closing cost payment</p>
            </Grid>
            <Grid item xs={4}>

              {TotalClosingCost != 0 ?
                <p> ${TotalClosingCost.toLocaleString(undefined, { minimumFractionDigits: 2 })} </p>
                :
                <p>"Sorry, you cannot add closing costs to the mortgage.
                   Otherwise, you will be required to get mortgage insurance"</p>
              }

            </Grid>
          </React.Fragment>
        }
        */}
        <Grid item xs={5}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <p> Mortgage Rate Type</p>
        </Grid>

        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={RateType}
            margin="dense"
            variant="outlined"
            onChange={e => setRateType(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            <MenuItem value={"Fixed"}>Fixed</MenuItem>
            <MenuItem value={"Variable"}>Variable</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={RateType2}
            margin="dense"
            variant="outlined"
            onChange={e => setRateType2(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            <MenuItem value={"Fixed"}>Fixed</MenuItem>
            <MenuItem value={"Variable"}>Variable</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={OpenCloseRateType}
            margin="dense"
            variant="outlined"
            onChange={e => setOpenCloseRateType(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            <MenuItem value={"Open"}>Open</MenuItem>
            <MenuItem value={"Closed"}>Closed</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={OpenCloseRateType2}
            margin="dense"
            variant="outlined"
            onChange={e => setOpenCloseRateType2(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            <MenuItem value={"Open"}>Open</MenuItem>
            <MenuItem value={"Closed"}>Closed</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}>
          Mortgage Provider
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={Bank}
            margin="dense"
            variant="outlined"
            onChange={e => setBank(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {
              store.data.Mortgage && store.data.Mortgage.map((i, k) => {
                return <MenuItem key={"key bk term" + k + i._id} value={i._id}>{i.Bank} </MenuItem>
              })
            }
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={Bank2}
            margin="dense"
            variant="outlined"
            onChange={e => setBank2(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {
              store.data.Mortgage && store.data.Mortgage.map((i, k) => {
                return <MenuItem key={"key bk term" + k + i._id} value={i._id}>{i.Bank} </MenuItem>
              })
            }
          </Select>
        </Grid>
        <Grid item xs={4}>
          <p> Term </p>
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={Term}
            margin="dense"
            variant="outlined"
            onChange={e => { setTerm(e.target.value) }}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {TermArray && TermArray.length > 0 ? TermArray.map((i, j) =>
              (i.Type === RateType && i.OpenClose === OpenCloseRateType)
              && <MenuItem key={"key term" + j} selected={Term == parseInt(i.Term)} value={JSON.stringify(i)}>{parseInt(i.Term)} year {i.OpenClose}</MenuItem>
            ) :
              <MenuItem selected="Not Available" value="Not Available">Not Available</MenuItem>
            }
            {/* {store.data.Mortgage && store.data.Mortgage.length > 0 && store.data.Mortgage.map((b) => {
              if (b._id === Bank) {
                return b.MortageDetails.map((i, k) => {
                  if (i.Type === RateType && i.OpenClose === OpenCloseRateType) {
                    return <MenuItem selected={Term == parseInt(i.Term)} key={"key term" + k} value={JSON.stringify(i)}>{parseInt(i.Term)} year {i.OpenClose}</MenuItem>
                  }

                })
              }

            })} */}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={Term2}
            margin="dense"
            defaultValue={Term2}
            variant="outlined"
            onChange={e => { setTerm2(e.target.value) }}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {TermArray2 && TermArray2.length > 0 ? TermArray2.map((i, j) =>
              (i.Type === RateType2 && i.OpenClose === OpenCloseRateType2)
              && <MenuItem key={"key term" + j} selected={Term2 == parseInt(i.Term)} value={JSON.stringify(i)}>{parseInt(i.Term)} year {i.OpenClose}</MenuItem>
            ) :
              <MenuItem selected="Not Available" value="Not Available">Not Available</MenuItem>
            }

            {/* {store.data.Mortgage && store.data.Mortgage.length > 0 && store.data.Mortgage.map((b) => {
              var NewData = 0;
              if (b._id === Bank2) {
                return b.MortageDetails.map((i, k) => {
                  if (i.Type === RateType2 && i.OpenClose === OpenCloseRateType2) {
                    NewData = i.Type
                    return <MenuItem key={"key term" + k} value={JSON.stringify(i)}>{parseInt(i.Term)} year {i.OpenClose}</MenuItem>
                  }
                })
              }
            })} */}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <p>Amortization Period</p>
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={AmortizationPeriod}
            defaultValue={25}
            margin="dense"
            variant="outlined"
            onChange={e => setAmortizationPeriod(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {
              [0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].map((i) => {
                return (<MenuItem value={i}>{i} Year</MenuItem>)
              })
            }
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={AmortizationPeriod2}
            defaultValue={25}
            margin="dense"
            variant="outlined"
            onChange={e => setAmortizationPeriod2(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {
              [0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30].map((i) => {
                return (<MenuItem value={i}>{i} Year</MenuItem>)
              })
            }
          </Select>
        </Grid>
        <Grid item xs={4}>
          <p>Frequency</p>
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={Frequency}
            margin="dense"
            variant="outlined"
            onChange={e => setFrequency(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            <MenuItem value={12}>Monthly</MenuItem>
            <MenuItem value={52}>Weekly</MenuItem>
            <MenuItem value={26}>Bi-Weekly</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select
            className={classes.select}
            value={Frequency2}
            margin="dense"
            variant="outlined"
            onChange={e => setFrequency2(e.target.value)}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            <MenuItem value={12}>Monthly</MenuItem>
            <MenuItem value={52}>Weekly</MenuItem>
            <MenuItem value={26}>Bi-Weekly</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}>
          <p>Interest Rate</p>
        </Grid>
        <Grid item xs={4}>
          <p> {Rate}% </p>
        </Grid>
        <Grid item xs={4}>
          <p> {Rate2}% </p>
        </Grid>
        <Grid item xs={4}>
          <p> Mortgage Payment</p>
        </Grid>
        <Grid item xs={4}>
          <p>$ {EMI ? EMI.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 }) : "Select banks "} </p>
        </Grid>
        <Grid item xs={4}>
          <p>$ {EMI2 ? EMI2.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 }) : "Select banks "} </p>
        </Grid>
      </Grid>
    </div>
  )
}


const useStyles2 = makeStyles(theme => ({
  select: {
    width: '100%',
  },
  icon: {
    fill: "#f02834",
  }
}));

//Monthly Expense Calculator
const MonthlyExpenseCalculator = ({ dataexpense, MortgagePayment, MortgagePayment2, TotalExpenses, saveMonthlyExpensebool }) => {

  //Mothly Expenses Calculator page css
  const useStyles = makeStyles(theme => ({
    flyout: {
      marginTop: '5px',
      maxWidth: '1250px',
      margin: '0 auto',
    },
    login: {
      float: 'right',
      width: 'auto'
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
        // width: '37ch',
      },
    },
    root1: {
      '& > *': {
        // margin: theme.spacing(),
        width: '100%',
      },
    },
    box: {
      backgroundColor: 'white',
      padding: '10px',
      width: '50%',
      /* left: 22px; */
      margin: '32px',
      marginBottom: '18px',
      height: '100%',
      marginTop: '1px'
    },
    labelMargin: {
      marginTop: '16px',
      marginLeft: '0px'
    },
  }));
  const classes = useStyles();
  const [netPayPerMonth, setnetPayPerMonth] = useState(0);
  const [loansAndDebtPayments, setloansAndDebtPayments] = useState(0);
  const [homeExpenses, sethomeExpenses] = useState(0);
  const [propertyInsurance, setpropertyInsurance] = useState(0);
  const [dailyLiving, setdailyLiving] = useState(0);
  const [entertainment, setentertainment] = useState(0);
  const [saving, setsaving] = useState(0);
  const [health, sethealth] = useState(0);
  const [Transportation, setTransportation] = useState(0);
  const [charityOrGift, setcharityOrGift] = useState(0);
  const [other, setother] = useState(0);
  const [totalMontlyExpense, settotalMontlyExpense] = useState(0);

  // useEffect(() => {
  //   setExpenseData(dataexpense)
  // }, [dataexpense, MortgagePayment, MortgagePayment2])

  //setExpenseData when get prop from parent components
  useEffect(() => {
    setExpenseData(dataexpense)
  }, [dataexpense])

  //Totalexpenses value
  useEffect(() => {
    TotalExpenses({ totalMontlyExpense, netPayPerMonth, MortgagePayment, MortgagePayment2 })
  }, [totalMontlyExpense, netPayPerMonth, MortgagePayment, MortgagePayment2])
  function setExpenseData(dataexpense) {
    if (dataexpense && dataexpense != undefined)
      setnetPayPerMonth(dataexpense && dataexpense.netPayPerMonth != undefined ? dataexpense.netPayPerMonth : 0)
    setloansAndDebtPayments(dataexpense && dataexpense.loansAndDebtPayments != undefined ? dataexpense.loansAndDebtPayments : 0)
    sethomeExpenses(dataexpense && dataexpense.homeExpenses != undefined ? dataexpense.homeExpenses : 0)
    setpropertyInsurance(dataexpense && dataexpense.propertyInsurance != undefined ? dataexpense.propertyInsurance : 0)
    setdailyLiving(dataexpense && dataexpense.dailyLiving != undefined ? dataexpense.dailyLiving : 0)
    setentertainment(dataexpense && dataexpense.entertainment != undefined ? dataexpense.entertainment : 0)
    setsaving(dataexpense && dataexpense.saving != undefined ? dataexpense.saving : 0)
    sethealth(dataexpense && dataexpense.health != undefined ? dataexpense.health : 0)
    setTransportation(dataexpense && dataexpense.Transportation != undefined ? dataexpense.Transportation : 0)
    setcharityOrGift(dataexpense && dataexpense.charityOrGift != undefined ? dataexpense.charityOrGift : 0)
    setother(dataexpense && dataexpense.other != undefined ? dataexpense.other : 0)
    settotalMontlyExpense(dataexpense && dataexpense.totalMontlyExpense != undefined ? dataexpense.totalMontlyExpense : 0)
  }

  //set total monthly expenses
  useEffect(() => {
    var a = (loansAndDebtPayments + homeExpenses + propertyInsurance + dailyLiving + entertainment + saving
      + health + Transportation + charityOrGift + other)
    settotalMontlyExpense(a)
  }, [loansAndDebtPayments, homeExpenses, propertyInsurance, dailyLiving, entertainment, saving
    , health, Transportation, charityOrGift, other])

  //save monthly expenses
  useEffect(() => {
    if (saveMonthlyExpensebool == true) {
      saveMonthlyExpense()
    }

  }, [saveMonthlyExpensebool])



  function saveMonthlyExpense() {
    var body = {
      "monthlyExpense": {
        "netPayPerMonth": netPayPerMonth,
        "loansAndDebtPayments": loansAndDebtPayments,
        "homeExpenses": homeExpenses,
        "propertyInsurance": propertyInsurance,
        "dailyLiving": dailyLiving,
        "entertainment": entertainment,
        "saving": saving,
        "health": health,
        "Transportation": Transportation,
        "charityOrGift": charityOrGift,
        "other": other,
        "totalMontlyExpense": totalMontlyExpense
      }
    }
    // setloading(true)
    monthlyExpenses(body).then(res => {
      if (res.message === "MonthlyExpensiveUpdated") {
        // setloading(false)
      }
      // setloading(false)
    })
  }

  return (
    <Grid container>
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.labelMargin}>  <label >Net Pay Per Month</label> </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
        <TextField fullWidth margin="dense"
          value={netPayPerMonth === undefined || netPayPerMonth === '' ? 0 : (netPayPerMonth).toLocaleString()}
          onChange={(e) => {
            // console.log("2056 e", e.target.value)
            var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
            // console.log("2058 val", val)
            val = parseFloat(val)
            // .toFixed(2)
            // console.log("2060 val2060", val)
            setnetPayPerMonth(val)
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.labelMargin}>  <label >Mortgage Payment</label> </Grid>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className={classes.labelMargin}>
        ${MortgagePayment ? MortgagePayment.toLocaleString(undefined, { maximumfractiondigits: 2 }) : 0}
      </Grid>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className={classes.labelMargin}>
        ${MortgagePayment2 ? MortgagePayment2.toLocaleString(undefined, { maximumfractiondigits: 2 }) : 0}
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.labelMargin}>  <label >Property Tax</label> </Grid>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        $0
      </Grid>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3} >
        $0
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.labelMargin}>  <label >Loans and Debt Payments</label> </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
        <TextField fullWidth margin="dense"
          value={loansAndDebtPayments === undefined || loansAndDebtPayments === '' ? 0 : (loansAndDebtPayments).toLocaleString()}
          onChange={(e) => {
            var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
            val = parseFloat(val)
            // .toFixed(2)
            setloansAndDebtPayments(val)
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.labelMargin}>  <label >Home Expenses</label> </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
        <TextField fullWidth margin="dense"
          value={homeExpenses === undefined || homeExpenses === '' ? 0 : (homeExpenses).toLocaleString()}
          onChange={(e) => {
            var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
            val = parseFloat(val)
            // .toFixed(2)
            sethomeExpenses(val)
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.labelMargin}>  <label >Property Insurance</label> </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
        <TextField fullWidth margin="dense"
          value={propertyInsurance === undefined || propertyInsurance === '' ? 0 : (propertyInsurance).toLocaleString()}
          onChange={(e) => {
            var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
            val = parseFloat(val)
            // .toFixed(2)
            setpropertyInsurance(val)

          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.labelMargin}><label>Daily Living</label> </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
        <TextField fullWidth margin="dense"
          value={dailyLiving === undefined || dailyLiving === '' ? 0 : (dailyLiving).toLocaleString()}
          onChange={(e) => {
            var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
            val = parseFloat(val)
            // .toFixed(2)
            setdailyLiving(val)

          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.labelMargin}>  <label >Entertainment</label> </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
        <TextField fullWidth margin="dense"
          value={entertainment === undefined || entertainment === '' ? 0 : (entertainment).toLocaleString()}
          onChange={(e) => {
            var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
            val = parseFloat(val)
            // .toFixed(2)
            setentertainment(val)

          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.labelMargin}>  <label >Saving</label> </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
        <TextField fullWidth margin="dense"
          value={saving === undefined || saving === '' ? 0 : (saving).toLocaleString()}
          onChange={(e) => {
            var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
            val = parseFloat(val)
            // .toFixed(2)
            setsaving(val)

          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.labelMargin}>  <label >Health</label> </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
        <TextField fullWidth margin="dense"
          value={health === undefined || health === '' ? 0 : (health).toLocaleString()}
          onChange={(e) => {
            var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
            val = parseFloat(val)
            // .toFixed(2)
            sethealth(val)

          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.labelMargin}>  <label >Transportaion</label> </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
        <TextField fullWidth margin="dense"
          value={Transportation === undefined || Transportation === '' ? 0 : (Transportation).toLocaleString()}
          onChange={(e) => {
            var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
            val = parseFloat(val)
            // .toFixed(2)
            setTransportation(val)
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.labelMargin}>  <label >Charity/Gifts</label> </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
        <TextField fullWidth margin="dense"
          value={charityOrGift === undefined || charityOrGift === '' ? 0 : (charityOrGift).toLocaleString()}
          onChange={(e) => {
            var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
            val = parseFloat(val)
            // .toFixed(2)
            setcharityOrGift(val)
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.labelMargin}>  <label >Other</label> </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
        <TextField fullWidth margin="dense"
          value={other === undefined || other === '' ? 0 : (other).toLocaleString()}
          onChange={(e) => {
            var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
            val = parseFloat(val)
            // .toFixed(2)
            setother(val)
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >  <h4>Total Budget</h4> </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
        <TextField fullWidth margin="dense"
          value={totalMontlyExpense === undefined || totalMontlyExpense === '' ? 0 : (totalMontlyExpense).toLocaleString()}
          onChange={(e) => {
            var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
            val = parseFloat(val).toFixed(2)
            settotalMontlyExpense(val)
          }}
          InputProps={{
            readOnly: true,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
        <TextField fullWidth margin="dense"
          value={totalMontlyExpense === undefined || totalMontlyExpense === '' ? 0 : (totalMontlyExpense).toLocaleString()}
          onChange={(e) => {
            var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
            val = parseFloat(val).toFixed(2)
            settotalMontlyExpense(val)

          }}
          InputProps={{
            readOnly: true,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
    </Grid>
  )
}
