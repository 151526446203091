import React, { Suspense, useEffect, useState, useRef } from 'react'
import { Grid, Container, TextField, Link, Button, CircularProgress } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InputAdornment from '@material-ui/core/InputAdornment';
import profile from '../../assest/profile_pic.png';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { inject, observer } from 'mobx-react';
import store from '../../store/index';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import {
    getUser, editProfile,
    getAccessToken
} from '../../const/function';
import getDistanceMatrix from '../../components/DistanceMatrix';
const Header = React.lazy(() => import('../Header'));
const Footer = React.lazy(() => import('../Footer'));

//inline style
const useStyles = makeStyles(theme => ({
    flyout: {
        marginTop: '5px',
        maxWidth: '1250px',
        margin: '0 auto',
    },
    login: {
        float: 'right',
        width: 'auto'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    root1: {
        '& > *': {
            width: '100%',
        },
    },
    box: {
        backgroundColor: 'white',
        padding: '10px',
        width: '80%',
        margin: '32px',
        marginBottom: '18px',
        height: '100%',
        marginTop: '1px'
    }
}));

//Edit profile component
function EditProfile({ }) {
    const classes = useStyles();
    const [valuea, setValue] = useState(false);
    const [loading, setloading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [workAddress, setWorkAddress] = useState('');
    const [schoolAddress, setSchoolAddress] = useState('');
    const [otherName, setOtherName] = useState('')
    const [otherLocation, setOtherLocation] = useState('')
    const [data, setData] = useState('');
    const [isBtn, setIsBtn] = useState(true);
    const myRef = useRef(null)


    const handleOpen = () => {
        setValue(!valuea);
    };

    const history = useHistory()

    useEffect(() => {
        //if don't have data in store call api's and get data then set into store
        setloading(true)
        if (!store.data.User) {

            getUser().then(data => {
                store.update("User", data); profile(data)
                if (data != undefined) {
                    if (data.province) {
                        store.update("Province", data.province)
                    }
                    if (data.properties) {
                        store.update("Properties", data.properties)
                    }
                }
                setloading(false)
            })
        }

        //set token if not have a token
        if (!store.data.getAccessToken) {
            getAccessToken().then(data => store.update("GenerateToken", data))
        }
    }, [])
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
    
    useEffect(() => {
        if (localStorage.getItem("testObject") === 'scrolltoref') {
            console.log("edit profile")
            scrollToRef(myRef);
        }
    }, [])

    //set profile data
    function profile(data) {
        setloading(true)
        if (data) {
            setData(data)
            setName(data.firstName + " " + data.lastName)
            setEmail(data.email)
            setSchoolAddress(data.schoolLocation ? data.schoolLocation : '')
            setWorkAddress(data.workLocation ? data.workLocation : '')
            if (data.otherLocations.length > 0) {
                setOtherName(data.otherLocations[0].name)
                setOtherLocation(data.otherLocations[0].location)
            }
            setloading(false)
        }
        else {
            setloading(false)
        }

    }

    //disable save button
    function saveBtnDisabled() {
        setIsBtn(false)
    }

    //set name of user
    const handleName = (e) => {
        setName(e)
    }

    //Update edit profile data
    function editUserProfile() {
        setloading(true)
        var nameArr = name.replace(' ', '-');
        nameArr = nameArr.split('-')
        var otherLocations = [{ "name": otherName, "location": otherLocation }]
        var id = data._id
        var body = {
            "firstName": nameArr[0],
            "lastName": nameArr[1],
            "workLocation": workAddress,
            "schoolLocation": schoolAddress,
            "locations": otherLocations[0]
        };

        //call edit profile api
        editProfile(id, body).then(res => {
            console.log("138*", res, otherLocation, workAddress, schoolAddress, store)
            if (res && res.message === "UpdateSuccess") {
                swal({
                    title: "Profile Updated",
                    text: res.message,
                    icon: "success",
                })
                // if (res.data.otherLocations[0].location != otherLocation || res.data.workLocation != workAddress || res.data.schoolLocation != schoolAddress) {
                //     CreateDistanceMatrixRequest(res)
                // }
                var a = res.data.firstName
                var b = res.data.lastName
                setName(a + " " + b)
                setSchoolAddress(res.data.schoolLocation ? res.data.schoolLocation : '')
                setWorkAddress(res.data.workLocation ? res.data.workLocation : '')
                setOtherName(res.data.otherLocations[0].name)
                setOtherLocation(res.data.otherLocations[0].location)
                setData(res.data)
                store.update("User", res.data)
                setIsBtn(true)
                setloading(false)
            }
            else {
                setloading(false)
            }
        })
    }

    // function CreateDistanceMatrixRequest(res) {
    //     console.log("166*", res)
    // }

    //loader and form of edit profile
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Container >
                <Grid style={{ backgroundColor: 'white', margin: '87px', padding: '0px' }} >
                    <Header />
                </Grid>
                {loading && <div style={{
                    position: 'fixed',
                    zIndex: 20000,
                    height: '100%',
                    opacity: 1,
                    width: "100%",
                    background: "#ffffff94",
                    textAlign: "center",
                    paddingTop: "30vmin",
                    left: '8px',
                    marginTop: '0px'
                }}>
                    <CircularProgress size={30} />
                </div>}

                <Button variant="outlined" onClick={() => { history.push('/home') }} style={{ margin: "15px", marginLeft: "0px" }} color="primary">Go to Property Board</Button>
                <Grid container style={{ backgroundColor: 'ghostWhite' }}>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: '32px' }}><h3>PROFILE SETTING</h3></Grid>
                    <Grid container className='box' >

                        <Grid item xs={12} sm={12} md={12} lg={8} xl={8} >
                            <img src={profile} style={{ width: '20%' }} /></Grid>
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className={classes.root}>
                            <label >Name</label>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined" value={name}
                                    onInput={saveBtnDisabled}
                                    onChange={e => handleName(e.target.value)} />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className={classes.root}>
                            <label >Email Address</label>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined" value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className={classes.root} >
                            <label >Work Location</label>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined" value={workAddress}
                                    onInput={saveBtnDisabled}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start"><LocationOnIcon /></InputAdornment>,
                                    }}
                                    onChange={e => setWorkAddress(e.target.value)}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className={classes.root}>
                            <label >School Location</label>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined" value={schoolAddress}
                                    onInput={saveBtnDisabled}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start"><LocationOnIcon /></InputAdornment>,
                                    }}
                                    onChange={e => setSchoolAddress(e.target.value)}
                                />
                            </form>
                        </Grid>
                        <div ref={myRef}></div>
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={8} style={{ cursor: 'pointer', padding: '9px' }}
                            onClick={handleOpen}>
                            <span><AddIcon /></span><Link >Add Important Place</Link>
                        </Grid>
                        {valuea ? <React.Fragment> <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className={classes.root}>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" placeholder="Name" variant="outlined"
                                    value={otherName} onInput={saveBtnDisabled}
                                    onChange={e => setOtherName(e.target.value)}
                                />
                            </form>
                        </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className={classes.root}>
                                <form className={classes.root1} noValidate autoComplete="off">
                                    <TextField id="outlined-basic" placeholder="Location" variant="outlined"
                                        value={otherLocation} onInput={saveBtnDisabled}
                                        onChange={e => setOtherLocation(e.target.value)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start"><LocationOnIcon /></InputAdornment>,
                                        }} />
                                </form>
                            </Grid>
                        </React.Fragment> : ''}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button variant="contained"
                                disabled={isBtn ? isBtn : false}
                                style={{ color: 'red', float: 'right', top: '9px', margin: '11px' }}
                                onClick={editUserProfile} >Save</Button>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={3} md={4} lg={4} xl={4}></Grid>
                        <Grid item xs={12} sm={9} md={8} lg={8} xl={8} >
                            <Footer /></Grid>
                    </Grid>
                </Grid>
            </Container>
        </Suspense>
    )
}
export default inject("store")(observer(EditProfile));