import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import { Api } from '../../const';
import validator from 'validator';
import {  useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    padding: "8px"
  },
  textField: {
    // width: "300px",
  },
  buttonProgress: {
    color: "green",
    position: 'absolute',
    left: 0,
    right: 0,
    margin: "auto",
    marginTop: "6px"
  },
  text: {
    marginTop: '10px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  texterror: {
    paddingLeft: '10px',
    fontSize: '14px',
    color: 'red'
  },
  wrapper: {
    paddingTop: "15px",
    paddingBottum: "15px"
  },
  button: {
    background: "#f02834",
    color: '#fff',
    width: '100%',
    textTransform: 'none'
  }
}));

export default function Signup({ isProfileInfo, setisProfileInfo,
  setIsLogin, show, googleId, facebookId, IsExtension, email,
  password, setemail, setpassword, first, setfirst, last, setlast, zip, setzip,
}) {
  const classes = useStyles();
  const [loading, setloading] = useState(false)
  const [EmailError, setEmailError] = useState(false)
  const [PassError, setPassError] = useState(false)
  const [FirstError, setFirstError] = useState(false)
  const [LastError, setLastError] = useState(false)
  const [ZipError, setZipError] = useState(false)
  const [EmailEmpty, setEmailEmpty] = useState(false)
  const [PassEmpty, setPassEmpty] = useState(false)
  const [FirstEmpty, setFirstEmpty] = useState(false)
  const [LastEmpty, setLastEmpty] = useState(false)
  const [ZipEmpty, setZipEmpty] = useState(false)
  const [EmailIdAlreadyExist, setEmailIdAlreadyExist] = useState(false)
  const [signBtnDis, setsignBtnDis] = useState(true)
  const [SchoolLocation, setSchoolLocation] = useState("")
  const [WorkLocation, setWorkLocation] = useState("")
  let location = useHistory()

  //Sign up components
  function Signup() {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    setEmailError(false)
    setPassError(false)
    setFirstError(false)
    setLastError(false)
    setZipError(false)
    setEmailEmpty(false)
    setPassEmpty(false)
    setFirstEmpty(false)
    setLastEmpty(false)
    // setZipEmpty(false)
    if (!email.trim()) {
      setEmailEmpty(true)
      return false
    }
    if (!validator.isEmail(email)) {
      setEmailError(true)
      return false
    }
    if (show && !password.trim()) {
      setPassEmpty(true)
      return false
    }
    if (show && validator.isEmpty(password) && password.length < 7) {
      setPassError(true)
      return false
    }
    if (show && !strongRegex.test(password)) {
      setPassError(true)
      return false
    }
    if (!isProfileInfo) {
      setisProfileInfo(true)
      return false
    }
    if (!first.trim()) {
      setFirstEmpty(true)
      return false
    }
    if (!validator.isAlpha(first)) {
      setFirstError(true)
      return false
    }
    if (!last.trim()) {
      setLastEmpty(true)
      return false
    }
    if (!validator.isAlpha(last)) {
      setLastError(true)
      return false
    }
    // if (!zip.trim(zip)) {
    //   setZipEmpty(true)
    //   return false
    // }
    // if (validator.isEmpty(zip) && zip.length < 5) {
    //   setZipError(true)
    //   return false
    // }


    //Register Aibot User api
    setloading(true)
    Axios.post(Api.baseUrl + '/api/AIBotUserRegister', {
      email,
      password,
      firstName: first,
      lastName: last,
      zipCode: zip,
      googleId,
      facebookId,
      SchoolLocation,
      WorkLocation
    })
      .then(d => {
        console.log(d)
        if (d.data.message === 'EmailIdAlreadyExist') {
          setEmailIdAlreadyExist(true)
        }
        if (d.data.result) {
          localStorage.setItem("token", d.data.result.accessToken)
          localStorage.setItem("refreshToken", d.data.result.refreshToken)
          setIsLogin(true)
          if (!IsExtension) {
            location.push('/home') 
          }
        }
        setloading(false)
      })
      .catch(err => {
        setloading(false)
        console.log(err)
      })
  }

  //Email Validation
  const validationEmail = (email) => {
    console.log("email", email);
    Axios.get(Api.baseUrl + '/api/User/CheckEmail/' + email)
      .then(d => {
        if (d.data.message === "EmailIdAlreadyExist") {
          setEmailIdAlreadyExist(true)
          return false;
        }
        else {
          setsignBtnDis(false)
          setEmailIdAlreadyExist(false)
        }
      }).catch(err => {
        setloading(false)
        console.log(err)
      })
  }
  return (
    <div className={classes.root}>
      {
        !isProfileInfo ?
          <React.Fragment>
            <Typography className={classes.text} >Email Address</Typography>
            <TextField
              className={classes.textField}
              value={email}
              onChange={e => setemail(e.target.value)}
              onBlur={e => validationEmail(e.target.value)}
              fullWidth
              type="email"
              placeholder="example@domain"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
            {
              EmailIdAlreadyExist && <Typography className={classes.texterror} >  Email address  already exist </Typography>
            }
            {
              EmailEmpty && <Typography className={classes.texterror} >Please enter email address </Typography>
            }
            {
              EmailError && <Typography className={classes.texterror} >Invalid email address </Typography>
            }
            {
              show && <React.Fragment>
                <Typography className={classes.text}>Create  Password</Typography>
                <TextField
                  value={password}
                  onChange={e => setpassword(e.target.value)}
                  className={classes.textField}
                  fullWidth
                  type="password"
                  placeholder="******"
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
                <Typography className={classes.text} style={{ margin: 0, opacity: 0.6, fontSize: '14px' }} >Use 8 or more characters with a mix of letters, numbers & symbols </Typography>
                {
                  PassEmpty && <Typography className={classes.texterror} >Please enter password</Typography>
                }
                {
                  PassError && <Typography className={classes.texterror} >Invalid password</Typography>
                }
              </React.Fragment>
            }
          </React.Fragment>
          :
          <React.Fragment>
            <Typography className={classes.text}>First Name</Typography>
            <TextField
              className={classes.textField}
              value={first}
              onChange={e => setfirst(e.target.value)}
              fullWidth
              type="text"
              placeholder="First Name"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
            {
              FirstEmpty && <Typography className={classes.texterror} >Please enter first name </Typography>
            }
            {
              FirstError && <Typography className={classes.texterror} >Invalid first name </Typography>
            }
            <Typography className={classes.text}>Last Name</Typography>
            <TextField
              className={classes.textField}
              value={last}
              onChange={e => setlast(e.target.value)}
              fullWidth
              type="text"
              placeholder="Last Name"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
            {
              LastEmpty && <Typography className={classes.texterror} >Please enter last name</Typography>
            }
            {
              LastError && <Typography className={classes.texterror} >Invalid last name</Typography>
            }
            {/* <Typography className={classes.text}>Zip Code</Typography>
            <TextField
              className={classes.textField}
              value={zip}
              onChange={e => setzip(e.target.value)}
              fullWidth
              placeholder="Zip Code"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
            {
              ZipEmpty && <Typography className={classes.texterror} >Please enter zip Code</Typography>
            }
            {
              ZipError && <Typography className={classes.texterror} >Invalid zip Code</Typography>
            } */}
            {/* <Typography className={classes.text} style={{ margin: 0, marginTop: 8, opacity: 0.6, fontSize: '14px' }} > Option Information  </Typography>

            <Typography className={classes.text}>Work Location</Typography>
            <TextField
              className={classes.textField}
              value={WorkLocation}
              onChange={e => setWorkLocation(e.target.value)}
              fullWidth
              placeholder="Work Location"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
            <Typography className={classes.text}>School Location</Typography>
            <TextField
              className={classes.textField}
              value={SchoolLocation}
              onChange={e => setSchoolLocation(e.target.value)}
              fullWidth
              placeholder="School Location"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            /> */}
          </React.Fragment>
      }
      <div className={classes.wrapper}>
        <Button variant="contained" color="secondary" className={classes.button} onClick={() => Signup()} disabled={signBtnDis || EmailIdAlreadyExist || loading} > {!isProfileInfo ? show ? "Sign up" : "Complete Sign up" : "Complete Sign up"} </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
}
