import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import Flyout from '../../containers/Flyout';
import Axios from 'axios';
import { Api } from '../../const';
import { inject, observer } from 'mobx-react';
import {
  getCat, getUser, getMortgage,
  getAccessToken,
  getCollaborationInvitations
} from '../../const/function';
import Header from '../Header';
import { Helmet } from 'react-helmet';
import { webSites } from '../../const/sitesList';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import StarIcon from '@material-ui/icons/Star';
import { Link } from 'react-router-dom';
import banner from '../../assest/banner.png';
import swal from 'sweetalert';
import getDistanceMatrix from '../DistanceMatrix';

const AutoPlaySwipeableViews = SwipeableViews

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 500,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  flyoutHeader: {
    // display: 'flex',
    // alignItems: 'center',
    // height: 50,
    // paddingLeft: theme.spacing(4),
    // backgroundColor: theme.palette.background.default,

    // height: 50px; 
    /* display: flex; */
    /* align-items: center; */
    /* padding-left: 86px; */
    textAlign: 'center!important',
    backgroundColor: '#fafafa',
    /* margin-top: 15px; */
    padding: '14px'
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
}));

// Extension stepper
function SwipeableTextMobileStepper({ setIsLogin, setloading, store, setisloginscreen, setshow, setisProfileInfo, changeScreen }) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isSave, setisSave] = useState(false)
  const [url, seturl] = useState('')
  const [Site, setSite] = useState('')
  const [EditProp, setEditprop] = useState(null)
  const [maxSteps, setmaxSteps] = useState(1)
  const [PropertiesData, setPropertiesData] = useState(null)
  const [isEditProp, setIsEditProp] = useState(true)
  const [flyEditVal, setflyEditVal] = useState(1)
  const [otherSites, setOtherSites] = useState(true)
  // const obj = { hello: 'world' };  
  // var PropertiesData;
  var d = 0;

  //update mortgage store
  useEffect(() => {
    if (!store.data.Mortgage) {
      getMortgage().then(data => store.update("Mortgage", data))
    }

    //update User store
    if (!store.data.User) {
      getUser().then(data => {
        store.update("User", data)
        if (data != undefined) {
          if (data.province) {
            store.update("Province", data.province)
          }
          if (data.properties) {
            var propertiesObj = {}
            getCollaborationInvitations().then(res => {
              // properties = {}
              // console.log("getCollaborationInvitations 364", res)
              res.data && res.data.length > 0 && res.data.map((i, j) => {
                // console.log("collaboratin merge iiii 349", i)
                if (i.invitedUserRole === "Editor") {
                  propertiesObj = i.properties
                }

              })

              // property.push(properties)
              //  var ss =  data.properties.concat(properties)

              //update Properties store
              if (propertiesObj.length > 0) {
                store.update("Properties", data.properties.concat(propertiesObj))
                //   setPropertiesData(data.properties.concat(propertiesObj))
              }
              else {
                store.update("Properties", data.properties)
                // setPropertiesData(data.properties)
              }
              // console.log("store", store.data)
            })
            // getCollaborationInvitations().then(res => {
            //   console.log("66666666666666 stepper", res)
            //   var collborationlist = res.data
            //   collborationlist.map((i, j) => {
            //     if (i.invitedUserRole === "Editor") {
            //       i.properties.map((m, n) => {
            //         propertiesObj = m
            //         console.log("mmmmmmmmm", propertiesObj, m)
            //         propertiesArr = [...propertiesArr, propertiesObj]
            //       })
            //     }
            //     console.log("propertiesArrpropertiesArr 80", propertiesArr)
            //   })
            // })
            // propertiesArr = [...propertiesArr, data.properties]
            // store.update("Properties", data.properties.concat(propertiesArr))
          }
        }
      })
    }
    if (!store.data.Cat) {
      //update Categories store
      getCat().then(data => store.update("Cat", data))
    }
    if (!store.data.getAccessToken) {
      // call getAccessToken api and update store GenerateToken
      getAccessToken().then(data => store.update("GenerateToken", data))
    }
    // var a = localStorage.getItem("token");
    // if (a) {
    //   console.log("get token aaaa", a, store)
    //   getGenerateToken(a).then(a => store.update("GenerateToken", a))
    // }
  }, [])


  // go to next and set Active step
  const handleNext = () => {

    var divs = document.getElementsByClassName("react-swipeable-view-container")[0].childNodes;
    var maxstepVal = maxSteps
    if (maxSteps == NaN || maxSteps == undefined) {
      maxstepVal = 1
    }
    for (var a = 0; divs.length > a; a++) {
      if (!divs[a].innerHTML) {
        divs[a].remove()
        if (EditProp) {
          setmaxSteps(maxstepVal - 1)
        }
        // if (EditProp) {
        //   if (store.data.Properties == undefined || store.data.Properties.length <= 0) {
        //     setmaxSteps(1)
        //   } else {
        //     setmaxSteps(store.data.Properties.length - 1)
        //   }
        // }
      }
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    // if(!EditProp){
    //   localStorage.setItem("editPropBack", activeStep)
    //   localStorage.setItem("editPropNext", activeStep)
    //   // activeStep
    // }
  };

  //handle back use for go to back set Active step
  const handleBack = () => {

    // var a = document.getElementsByClassName("react-swipeable-view-container")[0].childNodes
    // var thisishiddenPage
    // for (var i = 0; i < a.length; i++) {
    //   var ab;
    //   if (a[i].ariaHidden == "true") {
    //     thisishiddenPage = a[i] 
    //   } 
    // }
    // thisishiddenPage.style.display = ""
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    // if(!EditProp){
    //   localStorage.setItem("editPropBack", activeStep)
    //   // activeStep
    // }
  };

  // use to change steps
  const handleStepChange = step => {
    setActiveStep(step);
  };

  // api for set 
  function useQuery() {
    return new URLSearchParams(window.location.search);
  }

  let query = useQuery();

  useEffect(() => {
    setisSave(query.get("show"))
    window.addEventListener('message', event => {
      setSite(event.srcElement.document.location.ancestorOrigins[0])
      seturl(event.srcElement.document.referrer)
    });
  }, [])

  // useEffect(() => {

  //   var sitesurl = ""
  //   store.data.Properties && store.data.Properties.length > 0 && store.data.Properties.map(i => {
  //     sitesurl += i.Weblink + ','
  //   })
  //   // var a = store.data.Properties && store.data.Properties.length > 0 && sitesurl.join(',')
  //   // var b = '"' + a + '"'

  //   if (!query.get("sites") && query.get("sites") !== sitesurl) {
  //     var url = new URL(window.location.href);
  //     console.log( typeof sitesurl, sitesurl.length)
  //     if (sitesurl && sitesurl != '"false"') {
  //       url.searchParams.set('sites', sitesurl)
  //       window.location.replace(url)
  //     }
  //   }
  // }, [store.data.Properties])

  useEffect(() => {
    //   window.chrome.runtime.onMessage.addListener((message, sender, sendResponse) => {
    //     alert("8 alert")
    //     if (message.type === "OYO_PLUGIN_EVALUATED_CONFIG") {
    //         this.setState({
    //             configData: message.configData
    //         });
    //     }
    // });
    var urldata = document.getElementsByName("span")
    // [0].getAttribute("data-link");
    var AllProperties = query.get("menuItemId")

    //call scraping api for scrap data
    if (isSave && url) {
      console.log("**** **** call isSave && url **********", isSave, url)
      webSites.map(i => {
        if (Site === i.site) {
          setloading(true)
          setOtherSites(false)
          // Api.baseUrl +
          // http://localhost:7071/api/AIBotC21CaScrapProperties 
          Axios.post(Api.baseUrl + "/api" + i.path,
            {
              url: query.get("urlm"),
              mlsNo: query.get("m")
            }, {
            headers: {
              "x-accesstoken": localStorage.getItem('token')
            }
          }
          ).then(res => {
            if (res.data.result) {

              //call distance api after scrap property
              getDistanceMatrix(res)
            }

            // var storeData = window.store.data.Properties
            // store.update("Properties",[])

            //combine store and scrap into after update store
            var newData = window.store.data.Properties &&
              window.store.data.Properties.length > 0 ? window.store.data.Properties.concat(res.data.result) : res.data.result
            store.update("Properties", newData)

            // if (store.data.Properties == undefined || store.data.Properties.length <= 0) {
            //   setmaxSteps(1)
            // } else {
            //   setmaxSteps(store.data.Properties.length - 1)
            // }

            // store.update("Properties", window.store.data.Properties.concat(res.data.result))
            // setIsEditProp(res.data.result)
            setEditprop(res.data.result)
            // console.log("219 edit prop", store.data.Properties, EditProp)
            setloading(false)
          })
            .catch(err => {
              console.log(err)
              setloading(false)
            })
        }
        // else{

        // }

      })
      setOtherSites(true)

     let isOtherWebsite =  webSites.filter(e => e.site == Site)

     if (isOtherWebsite.length == 0) {
      setOtherSites(false)
      setloading(true)
      // Api.baseUrl +
      // http://localhost:7071/api/AIBotC21CaScrapProperties 
      Axios.post(Api.baseUrl + "/api/AIBotRealtorScrapProperties",
        {
          url: query.get("urlm"),
          mlsNo: query.get("m")
        }, {
        headers: {
          "x-accesstoken": localStorage.getItem('token')
        }
      }
      ).then(res => {
        // var storeData = window.store.data.Properties
        // store.update("Properties",[])
        if (res.data.message == "NotFound") {
          seturl('')
          swal({
            title: "Error",
            className: 'swalWide',
            text: res.data.result.message,
            icon: "error",
            showCloseButton: true,
            showCancelButton: true,
          })
          setloading(false)
        }
        else {
          //update Properties store
          var newData = window.store.data.Properties.concat(res.data.result)
          store.update("Properties", newData)
          // store.update("Properties", window.store.data.Properties.concat(res.data.result))
          // setIsEditProp(res.data.result)
          setEditprop(res.data.result)
          //call distance api
          getDistanceMatrix(res)
          // console.log("219 edit prop", store.data.Properties, EditProp)
          setloading(false)
        }

      })
        .catch(err => {
          console.log(err)
          setloading(false)
        })
     }

    }

    // in case of other site and copy mls number then to go to scrap a property

    // else if (url && otherSites && AllProperties == null) {
    //   console.log("331 else if not call api")
    //   seturl('')
    //   swal({
    //     title: "Error",
    //     className: 'swalWide',
    //     text: "Invalid Mls no",
    //     icon: "error",
    //     showCloseButton: true,
    //     showCancelButton: true,
    //   })
    //   setloading(false)
    // }
  }, [isSave, url])


  useEffect(() => {
     if (store.data.Properties) {

      store.update("Properties", store.data.Properties)
      setPropertiesData(store.data.Properties)
      var storeproplength = store.data.Properties && store.data.Properties.length >= 0 ? store.data.Properties.length : 0
      setmaxSteps(storeproplength + 1)
      // if (EditProp) {
      //   if (store.data.Properties == undefined || store.data.Properties.length <= 0) {
      //     setmaxSteps(1)
      //   } else {
      //     setmaxSteps(store.data.Properties.length - 1)
      //   }
      // } else {
      //   setmaxSteps(store.data.Properties.length + 1)
      // }

    }
  }, [store.data.Properties])

  function Logout() {
    setloading(true)
    localStorage.clear("token");
    localStorage.clear("refreshToken");
    store.update("Cat", "")
    store.update("User", "")
    store.update("Properties", [])
    setIsLogin(false)
    setisloginscreen(true)
    setshow(true)
    setloading(false)
    setisProfileInfo(false)
    changeScreen(true)
  }


  // useEffect(() => {
  //   store.update("Properties", store.data.Properties)
  // }, [store, EditProp])
  // setPropertiesData(store.data.Properties)
  return (
    <div className={classes.root}>
      <Helmet>
        <style>{'body { background-color: #fff; }'}</style>
      </Helmet>
      <Header
        buttons
        Logout={Logout}
        handleNext={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        maxSteps={maxSteps}
      />
      <div style={{ padding: "12px" }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          disableDragging={false}
          disabled={maxSteps <= 1 && true}
          id="blankfly2"
        >
          {EditProp ? <div key={"index"} style={{ overflow: 'hidden' }} >
            <Flyout data={EditProp} isEditAble setloading={setloading} />
          </div> :
            <div key={"index"} style={{ marginTop: '80px', overflow: 'hidden' }}>
              <p className={classes.header}>
                To save this property as a favorite, click on favorite button 
                {/* <span style={{ marginTop: '6px' }}> <StarIcon color="secondary" /></span> */}
                <span style={{ marginLeft: '5px', color: 'red!important' }}> <img style={{ width: '20px'}} src="https://i.imgur.com/NXEXy2P.png"/> </span>
              </p>
              <p className={classes.flyoutHeader}>A New Way to Find Your <span style={{ color: 'red' }}>Dream Home</span></p>
              <img style={{ width: '100%' }} src={banner}></img>
              <nav style={{ textAlign: "center", marginTop: '20px' }}>
                <a href="https://www.wyzeguide.com/home" target="_blank">Go To Property Board</a>
                {/* <Link onClick={() => window.open("/home", "_blank")} >Go To Dashboard</Link> */}
              </nav>
            </div>
          }

         {/* set data in flyout */}

          {store.data.Properties && store.data.Properties.length > 0 && store.data.Properties.map(item => item).reverse().map((data, index) => {
            if (EditProp) {
              if (EditProp._id !== data._id) {
                return (
                  <div key={index} style={{ overflow: 'hidden' }} id={"lisi-" + index}>
                    {/* {console.log("call flyout if 301", data.Property.Price)} */}
                    <Flyout data={data} setloading={setloading} />
                  </div>
                )
              }
            }
            else {
              return (
                <div key={index} id={"lisi-" + index} style={{ overflow: 'hidden' }}>
                  <Flyout data={data} setloading={setloading} />
                </div>
              )
            }
          })}
        </AutoPlaySwipeableViews>
      </div>
    </div>
  );
}


export default inject("store")(observer(SwipeableTextMobileStepper));


// function Example() {
//   const [show, setShow] = useState(false);

//   return (
//     <>
//       <Button variant="primary" onClick={() => setShow(true)}>
//         Custom Width Modal
//       </Button>

//       <Modal
//         show={show}
//         onHide={() => setShow(false)}
//         dialogClassName="modal-90w"
//         aria-labelledby="example-custom-modal-styling-title"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="example-custom-modal-styling-title">
//             Custom Modal Styling
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>
//             Ipsum molestiae natus adipisci modi eligendi? Debitis amet quae unde
//             commodi aspernatur enim, consectetur. Cumque deleniti temporibus
//             ipsam atque a dolores quisquam quisquam adipisci possimus
//             laboriosam. Quibusdam facilis doloribus debitis! Sit quasi quod
//             accusamus eos quod. Ab quos consequuntur eaque quo rem! Mollitia
//             reiciendis porro quo magni incidunt dolore amet atque facilis ipsum
//             deleniti rem!
//           </p>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// }

// render(<Example />);
