import React from 'react'
import SocialLogin from 'react-social-login'
 
class SocialButton extends React.Component {
 //social Button for sign up and sign in  use for facebook
    render() {
        return (
            <button onClick={this.props.triggerLogin} {...this.props}>
              { this.props.children }
            </button>
        );
    }
}
 
export default SocialLogin(SocialButton);