import React, { Suspense, useState } from 'react'
import { Grid, Container, Typography, TextField, Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Api } from '../../const';
import Axios from 'axios';
import swal from 'sweetalert';
const Header = React.lazy(() => import('../Header'));
const Footer = React.lazy(() => import('../Footer'));

const useStyles = makeStyles(theme => ({
    flyout: {
        marginTop: '5px',
        maxWidth: '1250px',
        margin: '0 auto',
    },
    login: {
        float: 'right',
        width: 'auto'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            // width: '37ch',
        },
    },
    root1: {
        '& > *': {
            // margin: theme.spacing(),
            width: '100%',
        },
    },
    box: {
        backgroundColor: 'white',
        padding: '10px',
        width: '50%',
        /* left: 22px; */
        margin: '32px',
        marginBottom: '18px',
        height: '100%',
        marginTop: '1px'
    },
    labelMargin: {
        marginTop: '20px',
        marginLeft: '10px'
    },
    text: {
        paddingLeft: '10px',
        marginTop: '10px',
        fontSize: '14px',
        cursor: 'pointer',
    },
    buttonProgress: {
        color: "green",
        position: 'absolute',
        left: 0,
        right: '39px',
        margin: "auto",
        marginTop: "24px",
        textTransform: 'none'
    },
    wrapper: {
        paddingTop: "15px",
        paddingBottum: "15px"
    },
}));


//change password component
function ChangePassword({ }) {
    const [OldPassword, setOldPassword] = useState('');
    const [NewPassword, setNewPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [isDisabled, setisDisabled] = useState(true);
    const [oldPasswordError, setOldPasswordError] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [loading, setloading] = useState(false)
    const classes = useStyles();

    //reset password
    async function resetPassword() {
        if (!OldPassword || !NewPassword || !ConfirmPassword) {
            !OldPassword ? setOldPasswordError(true) : setOldPasswordError(false)
            !NewPassword ? setNewPasswordError(true) : setNewPasswordError(false)
            !ConfirmPassword ? setConfirmPasswordError(true) : setConfirmPasswordError(false)
            return false;
        }
        setloading(true)
        return Axios.post(Api.baseUrl + '/api/User/Manage/resetPassword', {
            'oldPassword': OldPassword,
            'newPassword': NewPassword
        }, {
            headers: {
                "x-accesstoken": localStorage.getItem('token')
            }
        })
            .then(res => {
                if (res.data.result.message === "InvalidToken") {
                    // return getAccessToken().then(res => res ? resetPassword() : '')
                    setloading(false)
                }
                else {
                    return (
                        (res.data.result.message === "success" ?
                            (sweetpopup(),
                                setloading(false))
                            : ''),
                        setOldPassword(''),
                        setNewPassword(''),
                        setConfirmPassword(''),
                        setloading(false)
                    )
                }
            })
            .catch(err => {
                setloading(false)
                console.log("catch", err)
            })
    }

    //open popup on changed password
    function sweetpopup() {
        return (
            swal({
                title: "Success!",
                text: "Your password changed!",
                icon: "success",
            })
        )
    }

    //check password validation
    function validateOldPassword(pass) {
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        var result = strongRegex.test(pass);
        if (pass.length < 1) {
            setOldPasswordError(false)
            setisDisabled(true)
        }
        else {
            if (result === true) {
                setisDisabled(false)
                setOldPasswordError(false)
            } else {
                setOldPasswordError(true)
                setisDisabled(true)
            }
        }
    }

    //new password validation
    function validateNewPassword(pass) {
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        var result = strongRegex.test(pass);
        if (pass.length < 1) {
            setNewPasswordError(false)
            setisDisabled(true)
        }
        else {
            if (result === true) {
                setNewPasswordError(false)
                setisDisabled(false)
            } else {
                setNewPasswordError(true)
                setisDisabled(true)
            }
        }
    }

    //confirm password
    function validateConfirmPassword(pass) {
        if (pass.length < 1) {
            setConfirmPasswordError(false)
            setisDisabled(true)
        }
        else {
            if (pass === NewPassword) {
                setConfirmPasswordError(false)
                setisDisabled(false)
            } else {
                setisDisabled(true)
                setConfirmPasswordError(true)
            }
        }
    }

    return (
        <Suspense fallback={<div>loading...</div>}>
            <Container >
                <Grid style={{ backgroundColor: 'white', margin: '87px', padding: '0px' }} >
                    <Suspense fallback={<div>loading...</div>}><Header /></Suspense></Grid>

                <Grid container style={{ backgroundColor: 'ghostWhite' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: '32px' }}><h3>CHANGE PASSWORD</h3></Grid>
                    <Grid container className='box' >

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.labelMargin}>
                            <Typography className={classes.text}>Current Password</Typography></Grid>

                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} autoComplete="off">
                                <TextField
                                    style={{ margin: 8 }}
                                    type="password"
                                    placeholder="******"
                                    margin="dense"
                                    value={OldPassword}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => {
                                        setOldPassword(e.target.value)
                                        validateOldPassword(e.target.value)
                                    }}
                                    required={true}
                                    variant="outlined"
                                />
                                {oldPasswordError ? <span style={{ color: "red" }}> Must be enter 8 digits password with uppercase lowercase numeric and symbol</span> : ''}
                            </form>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.labelMargin}>
                            <Typography className={classes.text}>New Password</Typography></Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} autoComplete="off">
                                <TextField
                                    style={{ margin: 8 }}
                                    type="password"
                                    placeholder="******"
                                    margin="dense"
                                    value={NewPassword}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => {
                                        setNewPassword(e.target.value)
                                        validateNewPassword(e.target.value)
                                    }
                                    }
                                    variant="outlined"
                                    required={true}
                                />
                                {newPasswordError ? <span style={{ color: "red" }}> Must be enter 8 digits password with uppercase lowercase numeric and symbol</span> : ''}
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.labelMargin}>
                            <Typography className={classes.text}>Confirm Password</Typography></Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} autoComplete="off">
                                <TextField
                                    style={{ margin: 8 }}
                                    type="password"
                                    placeholder="******"
                                    margin="dense"
                                    value={ConfirmPassword}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value)
                                        validateConfirmPassword(e.target.value)
                                    }}
                                    variant="outlined"
                                    required={true}
                                />
                                {confirmPasswordError ? <span style={{ color: "red" }}> Password don't match, enter correct password</span> : ''}
                            </form>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.wrapper}>

                            <Button variant="contained"
                                style={{ float: 'right', backgroundColor: 'red', color: 'white', top: '9px', margin: '11px', width: '20px' }}
                                onClick={async () => await resetPassword()} disabled={loading || isDisabled} >Save</Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={3} md={4} lg={5} xl={5}></Grid>
                        <Grid item xs={12} sm={9} md={8} lg={7} xl={7} >
                            <Suspense fallback={<div>loading...</div>}><Footer /></Suspense></Grid>
                    </Grid>

                </Grid>
            </Container>
        </Suspense >
    )
}
export default ChangePassword;