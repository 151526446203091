import React, { Suspense, useState, useEffect } from 'react'
import { Grid, Container, TextField, Button, CircularProgress } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import { monthlyExpenses, getUser } from '../../const/function';
import { useHistory } from "react-router-dom";
const Header = React.lazy(() => import('../Header'));
const Footer = React.lazy(() => import('../Footer'));

//component style
const useStyles = makeStyles(theme => ({
    flyout: {
        marginTop: '5px',
        maxWidth: '1250px',
        margin: '0 auto',
    },
    login: {
        float: 'right',
        width: 'auto'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            // width: '37ch',
        },
    },
    root1: {
        '& > *': {
            // margin: theme.spacing(),
            width: '100%',
        },
    },
    box: {
        backgroundColor: 'white',
        padding: '10px',
        width: '50%',
        /* left: 22px; */
        margin: '32px',
        marginBottom: '18px',
        height: '100%',
        marginTop: '1px'
    },
    labelMargin: {
        marginTop: '20px',
        marginLeft: '10px'
    }
}));

//Monthly Expenses functional component
function MonthlyExpense({ }) {
    const classes = useStyles();
    const [netPayPerMonth, setnetPayPerMonth] = useState(0);
    const [loansAndDebtPayments, setloansAndDebtPayments] = useState(0);
    const [homeExpenses, sethomeExpenses] = useState(0);
    const [propertyInsurance, setpropertyInsurance] = useState(0);
    const [dailyLiving, setdailyLiving] = useState(0);
    const [entertainment, setentertainment] = useState(0);
    const [saving, setsaving] = useState(0);
    const [health, sethealth] = useState(0);
    const [Transportation, setTransportation] = useState(0);
    const [charityOrGift, setcharityOrGift] = useState(0);
    const [other, setother] = useState(0);
    const [totalMontlyExpense, settotalMontlyExpense] = useState(0);
    const [loading, setloading] = useState(false);
    // const [data, setdata] = useState('')
  
  //get monthly expenses on component render
    useEffect(() => {
        getExpenses();
    }, [])

    //function call user profile api and get monthly expenses detail 
    const getExpenses = () => {
        setloading(true);
        getUser().then(res => {
            // setdata(res.monthlyExpense)
            if (res.monthlyExpense) {
                setnetPayPerMonth(res.monthlyExpense.netPayPerMonth)
                setloansAndDebtPayments(res.monthlyExpense.loansAndDebtPayments)
                sethomeExpenses(res.monthlyExpense.homeExpenses)
                setpropertyInsurance(res.monthlyExpense.propertyInsurance)
                setdailyLiving(res.monthlyExpense.dailyLiving)
                setentertainment(res.monthlyExpense.entertainment)
                setsaving(res.monthlyExpense.saving)
                sethealth(res.monthlyExpense.health)
                setTransportation(res.monthlyExpense.Transportation)
                setcharityOrGift(res.monthlyExpense.charityOrGift)
                setother(res.monthlyExpense.other)
                settotalMontlyExpense(res.monthlyExpense.totalMontlyExpense)
            }

            setloading(false);
        })
    }

    //monthly expenses body
    var body = {
        "monthlyExpense": {
            "netPayPerMonth": netPayPerMonth,
            "loansAndDebtPayments": loansAndDebtPayments,
            "homeExpenses": homeExpenses,
            "propertyInsurance": propertyInsurance,
            "dailyLiving": dailyLiving,
            "entertainment": entertainment,
            "saving": saving,
            "health": health,
            "Transportation": Transportation,
            "charityOrGift": charityOrGift,
            "other": other,
            "totalMontlyExpense": totalMontlyExpense
        }
    }
    
    //total of monthly expenses
    useEffect(() => {
        var a = ( loansAndDebtPayments + homeExpenses + propertyInsurance + dailyLiving + entertainment + saving
            + health + Transportation + charityOrGift + other)
        settotalMontlyExpense(a)
    }, [ loansAndDebtPayments, homeExpenses, propertyInsurance, dailyLiving, entertainment, saving
        , health, Transportation, charityOrGift, other])
        
        //call api to save monthly expenses
    function saveMonthlyExpense() {
        setloading(true)
        monthlyExpenses(body).then(res => {
            console.log("res monthlyExpenses", res)
            if (res.message === "MonthlyExpensiveUpdated") {
                setloading(false)
            }
            setloading(false)
        })
    }
    const history = useHistory()
    return (
        <Suspense fallback={<div>loading...</div>}>
            <Container >
                <Grid style={{ backgroundColor: 'white', margin: '87px', padding: '0px' }} >
                    <Suspense fallback={<div>loading...</div>}><Header /></Suspense></Grid>
                {loading && <div style={{
                    position: 'fixed',
                    zIndex: 20000,
                    height: '100%',
                    // background: "#0000004D",
                    opacity: 1,
                    // backgroung:'rgba(0, 0, 0.2, 0.2)',
                    // hsla(240, 100%, 0%, 0.2)
                    width: "100%",
                    background: "#ffffff94",
                    textAlign: "center",
                    paddingTop: "30vmin",
                    left: '8px',
                    marginTop: '0px'
                }}>
                    <CircularProgress size={30} />
                </div>}
                <Button variant="outlined"  onClick={() => { history.push('/home') }} style={{margin: "15px", marginLeft:"0px"}} color="primary">Go to Property Board</Button> 
                <Grid container style={{ backgroundColor: 'ghostWhite' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: '32px' }}><h3>BUDGET</h3></Grid>
                    <Grid container className='box' >
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.labelMargin}>  <label >Net Pay Per Month</label> </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined"
                                    value={netPayPerMonth === undefined || netPayPerMonth === '' ? 0 : (netPayPerMonth).toLocaleString()}
                                    onChange={(e) => {
                                        var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                                        val = parseFloat(val);
                                        setnetPayPerMonth(val)
                                        // console.log("val in expense",val)
                                        // onChange(val)
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <h4 style={{ color: 'red', marginLeft: '10px' }} >Add Your Expense</h4>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.labelMargin}>  <label >Loans and Debt Payments</label> </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined"
                                    value={loansAndDebtPayments === undefined || loansAndDebtPayments === '' ? 0 : (loansAndDebtPayments).toLocaleString()}
                                    onChange={(e) => {
                                        var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                                        val = parseFloat(val);
                                        setloansAndDebtPayments(val)
                                        // onChange(val)
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.labelMargin}>  <label >Home Expenses</label> </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined"
                                    value={homeExpenses === undefined || homeExpenses === '' ? 0 : (homeExpenses).toLocaleString()}
                                    onChange={(e) => {
                                        var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                                        val = parseFloat(val);
                                        sethomeExpenses(val)
                                        // onChange(val)
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} />
                            </form>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.labelMargin}>  <label >Property Insurance</label> </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined"
                                    value={propertyInsurance === undefined || propertyInsurance === '' ? 0 : (propertyInsurance).toLocaleString()}
                                    onChange={(e) => {
                                        var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                                        val = parseFloat(val);
                                        setpropertyInsurance(val)
                                        // onChange(val)
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.labelMargin}><label>Daily Living</label> </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined"
                                    value={dailyLiving === undefined || dailyLiving === '' ? 0 : (dailyLiving).toLocaleString()}
                                    onChange={(e) => {
                                        var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                                        val = parseFloat(val);
                                        setdailyLiving(val)
                                        // onChange(val)
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.labelMargin}>  <label >Entertainment</label> </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined"
                                    value={entertainment === undefined || entertainment === '' ? 0 : (entertainment).toLocaleString()}
                                    onChange={(e) => {
                                        var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                                        val = parseFloat(val);
                                        setentertainment(val)
                                        // onChange(val)
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.labelMargin}>  <label >Saving</label> </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined"
                                    value={saving === undefined || saving === '' ? 0 : (saving).toLocaleString()}
                                    onChange={(e) => {
                                        var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                                        val = parseFloat(val);
                                        setsaving(val)
                                        // onChange(val)
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.labelMargin}>  <label >Health</label> </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined"
                                    value={health === undefined || health === '' ? 0 : (health).toLocaleString()}
                                    onChange={(e) => {
                                        var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                                        val = parseFloat(val);
                                        sethealth(val)
                                        // onChange(val)
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.labelMargin}>  <label >Transportaion</label> </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined"
                                    value={Transportation === undefined || Transportation === '' ? 0 : (Transportation).toLocaleString()}
                                    onChange={(e) => {
                                        var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                                        val = parseFloat(val);
                                        setTransportation(val)
                                        // onChange(val)
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.labelMargin}>  <label >Charity/Gifts</label> </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined"
                                    value={charityOrGift === undefined || charityOrGift === '' ? 0 : (charityOrGift).toLocaleString()}
                                    onChange={(e) => {
                                        var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                                        val = parseFloat(val);
                                        setcharityOrGift(val)
                                        // onChange(val)
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.labelMargin}>  <label >Other</label> </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} noValidate autoComplete="off">
                                <TextField id="outlined-basic" variant="outlined"
                                    value={other === undefined || other === '' ? 0 : (other).toLocaleString()}
                                    onChange={(e) => {
                                        var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                                        val = parseFloat(val);
                                        setother(val)
                                        // onChange(val)
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >  <h4>Total Budget</h4> </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} className={classes.root}>
                            <form className={classes.root1} autoComplete="off">
                                <TextField
                                    value={totalMontlyExpense === undefined || totalMontlyExpense === '' ? 0 : (totalMontlyExpense).toLocaleString()}
                                    //  onChange={(e) => {
                                    //     var val = e.target.value === '' ? 0 : e.target.value.replace(/,/g, '');
                                    //     val = parseFloat(val);
                                    //     setnetPayPerMonth(val)
                                    //     // onChange(val)
                                    //   }}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }} />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button variant="contained"
                                style={{ backgroundColor: 'red', color: 'white', top: '9px', margin: '11px', width: '20px' }}
                                disabled={loading}
                                onClick={saveMonthlyExpense}>Save</Button>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={3} md={4} lg={5} xl={5}></Grid>
                        <Grid item xs={12} sm={9} md={8} lg={7} xl={7} >
                            <Suspense fallback={<div>loading...</div>}><Footer /></Suspense></Grid>
                    </Grid>
                </Grid>
            </Container>
        </Suspense >
    )
}
export default MonthlyExpense;